import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { AgreementsQuery } from '../Queries'
import { EnumQuery } from '../../../Functions/querys'
import RentalDetail from './RentalDetail'
import Loader from "../../../Material/Loader"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class RentalTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRentalId: this.props.rentals && this.props.rentals && this.props.rentals.edges.length > 0 ? this.props.rentals.edges[0].node.id : null,
            // dataAvailable: true
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rentals !== this.props.rentals && this.props.rentals && this.props.rentals.edges.length > 0) {
            this.setState({ activeRentalId: this.props.rentals.edges[0].node.id })
        }
    }
    render() {
        return (
            <div className="rental-container">
                <Row>
                    <Col className="agreements-list col-2">
                        {this.props.loading ? <Loader /> : <>
                            {
                                this.props.rentals && this.props.rentals.edges && this.props.rentals.edges.length > 0 ?
                                    this.props.rentals.edges.map((rental, i) =>
                                        <div
                                            className={"car-agreement" + (this.state.activeRentalId === (rental && rental.node && rental.node.id) ? " active" : "")}
                                            onClick={() => this.setState({ activeRentalId: rental && rental.node && rental.node.id })}
                                            key={i}
                                        >
                                            <div className={"sidebar-listing-wrapper-icon"}>
                                                <i className={rental && rental.node && rental.node.stage === "A__1_CANCELED" ? "fa fa-2x fa-ban" : "fa fa-2x fa-user"} style={{ color: rental && rental.node && rental.node.stage === "A__1_CANCELED" ? "red" : rental && rental.node && rental.node.stage === "A_10_ACTIVE" ? "green" : "orange" }} aria-hidden="true"></i>&nbsp;
                                            </div>
                                            <div className={"sidebar-listing-wrapper-text"}>
                                                <p>{rental && rental.node && rental.node.driver ? rental.node.driver.name : "---"}</p>
                                            </div>
                                        </div>
                                    ) : <p className="text-center no-rentals">No Rentals Found</p>
                            }
                            <div id="more-agreements">
                                {this.props.rentals && this.props.rentals.pageInfo.hasNextPage &&
                                    <>
                                        <i className={"fa fa-2x fa-arrow-circle-down"} style={{ color: "#1B4D7E" }} onClick={this.props.loadMoreAgreements} aria-hidden="true" id={"viewMore"}></i>
                                        <UncontrolledTooltip target={"viewMore"} placement={"left"}>View More</UncontrolledTooltip>
                                    </>
                                }
                            </div>
                        </>}
                    </Col>
                    {this.state.activeRentalId && <RentalDetail id={this.state.activeRentalId} detailPageType="car" />}
                </Row>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(EnumQuery, {
    props: ({ data: { agreementStates, accountTypes, chargeTypes } }) => ({
        agreementStates,
        accountTypes,
        chargeTypes,
    }),
})(
    graphql(AgreementsQuery, {
        options: (props) => ({
            variables: {
                carPk: parseInt(props?.params?.pk),
                first: 8,
            },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { loading, rentals, fetchMore, variables } }) => ({
            loading,
            rentals,
            variables,
            loadMoreAgreements: () => {
                return fetchMore({
                    query: AgreementsQuery,
                    variables: {
                        cursor: rentals.pageInfo.endCursor,
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.rentals.edges
                        const pageInfo = fetchMoreResult.rentals.pageInfo

                        return {
                            rentals: {
                                edges: [...previousResult.rentals.edges, ...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.rentals.__typename,
                            },
                        }
                    },
                })
            },
        }),
    })(withApolloClient(RentalTab))
)
