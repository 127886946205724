import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import { Col, Row, Label, UncontrolledTooltip } from 'reactstrap'
import toTitleCase from "../../../Functions/toTitleCase"
import CarTrackerModal from "../../../Material/CarTrackerModal"
import Loader from "../../../Material/Loader"
import gql from "graphql-tag"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import { ReactComponent as Repo } from '../../../assets/images/icons/repo.svg'
import { updateTracker } from "../Mutations"
import fleetsynclogo from '../../../assets/images/icons/fleetsync-logo.png'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const CarDetailQuery = gql`query Car($pk: Int!){
    car(id: $pk){
      pk
      id
      dmvPlate
      vin
      model
      color
      trackerStatus
      currentAgreement{
        id
        driver {
            id
            name
            tlcLicense
            phone
            email
            balance
        }
      }
      equipmentSet(removed:false){
        edges {
          node {
            id
            customDeviceId
            dateAdded
            removed
            equipmentItems{
              id
              pk
              name
              description
              equipmentItemsGroup {
                id
                name
              }
            }
          }
        }
      }
    }
  }`



class TrackersTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        return (
            <div className="tab-container">
                {this.props.loading ? <Loader /> : <>
                    {this.props.car && this.props.car.equipmentSet && this.props.car.equipmentSet.edges && this.props.car.equipmentSet.edges.length > 0 && this.props.car.equipmentSet.edges.filter(equipment => (equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS")) &&
                        <div>
                            {this.props.car.equipmentSet.edges.filter(equipment => equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS").map((equipment, i) =>
                                <Row className="info-container" style={{ backgroundColor: "#f0a8198c", marginBottom: "20px" }}>
                                    <Col>
                                        <Label>Date Added</Label>
                                        <p>{equipment.node.dateAdded && <DatetimeRenderer datetime={equipment.node.dateAdded} />}</p>
                                    </Col>
                                    <Col>
                                        <Label>Name</Label>
                                        <p>{equipment.node.equipmentItems && equipment.node.equipmentItems.name ? toTitleCase(equipment.node.equipmentItems.name) : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Custom Device Id</Label>
                                        <p>{equipment.node.customDeviceId && equipment.node.customDeviceId ? equipment.node.customDeviceId : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Description</Label>
                                        <p>{equipment.node.equipmentItems ? equipment.node.equipmentItems.description : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Group</Label>
                                        <p>{equipment.node.equipmentItems && equipment.node.equipmentItems.equipmentItemsGroup ? equipment.node.equipmentItems.equipmentItemsGroup.name : "--"}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <ul className="actions" style={{ display: "flex", alignItems: "center", padding: 0, margin: 0, listStyle: "none", }}>
                                            <li>
                                                <a id={"LocationPopup" + i} href={`https://fleetsync.io/cars/${this.props.car.pk}?tab=Map`} target={"_blank"} rel="noopener noreferrer">
                                                    <img src={fleetsynclogo} style={{ height: "15px" }} alt="FleetSync Logo" />
                                                </a>
                                                <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>For Tracker Actions</UncontrolledTooltip>
                                            </li>
                                            <li>
                                                Repo<br />
                                                <Repo id={"repo" + i} fill="#303E67" width={25} height={25} onClick={() => this.toggleModal("repo" + i)} />
                                                {this.state.openModal === "repo" + i &&
                                                    <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "repo" + i} driver={this.props.car && this.props.car.currentAgreement && this.props.car.currentAgreement.driver} handleClose={() => this.toggleModal("repo" + i)} command={"Repo"} car={this.props.car} />
                                                }
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    }
                </>}
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarDetailQuery, {
    options: (props) => ({
        variables: { pk: parseInt(props.params.pk) },
    }),
    props: ({ data: { car, fetchMore, loading, variables } }) => ({
        car,
        loading,
    })
})(
    graphql(updateTracker, {
        props: ({ mutate }) => ({
            update: (input) => mutate({ variables: { input } }),
        })
    })(
        withApolloClient(TrackersTab)
    )
)
