import React, { Component } from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import './TenantListContainer.css'
import TenantList from "./TenantList"
import NewTenantModal from "./NewTenantModal"
import RestrictedSection from "../../Components/RestrictedSection"

class TenantListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            showLoading: false,
            openModal: "",
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_tenant">
                <Container fluid className="bos-listing-container" >
                    <Row>
                        <Col xs={4}>
                            <span className="driver-search-filter">
                                <Input type="text" name="searchTerm" placeholder="Search" onChange={this.updateInput} />
                                <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col xs={8} className="text-right mt-2">
                            <a id="newBranch" className="driver-list-button" onClick={() => this.toggleModal("NewTenantModal")}>
                                Add New Tenant | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>
                            {this.state.openModal === "NewTenantModal" && <NewTenantModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewTenantModal"} />}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <TenantList currentUser={this.props.currentUser} searchTerm={this.state.searchTerm} />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

//Iagami - Code upgradation React 18
export default TenantListContainer

