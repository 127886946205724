import React, { Component } from 'react'
import { Container, Col, Row, Input } from 'reactstrap'
import FinancialAccountList from "../../FinancialAccount/FinancialAccountList"

class FinancialAccountsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            orderBy: "-id"
        }
    }

    updateOrderBy = (name) => {
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState({ orderBy: this.state.orderBy === name ? "-" + name : name })
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <Row className="mb-3">
                    <Col xs={4}>
                        <span className="driver-search-filter">
                            <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.setState({ searchTerm: e.target.value })} />
                            <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col className="bos-object-section-wrapper">
                        <FinancialAccountList branchIds={this.props.branchId} orderBy={this.state.orderBy} updateOrderBy={this.updateOrderBy} searchTerm={this.state.searchTerm} isBranchView />
                    </Col>
                </Row>
            </Container>
        )
    }
}


//Iagami - Code upgradation React 18
export default FinancialAccountsTab