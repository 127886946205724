import React, { Component } from "react"
import { Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Input, FormFeedback, Badge, Row, Col, UncontrolledTooltip } from "reactstrap"
import Select from "react-select"
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { AllCarModels, AllAgreementTypes } from "./Queries"
import { CreatePricingTemplateMutation } from './Mutations'
import toTitleCase from "../Functions/toTitleCase"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
class AddPricingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {
        price: null,
        minimumPrice: null,
        maximumPrice: null,
        interval: null,
        intervalUnit: '',
        carYearMin: null,
        carYearMax: null,
        carMileageMin: null,
        carMileageMax: null,
        visibleToCustomers: false,
        isDefault: false,
        carModels: null,
      },
      defaultMileageUnit: "MI",
      createAnother: false,
      loading: false,
      error: "",
    }
  }
  componentDidMount() {
    const { apolloClient } = this.props
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let input = this.state.input
    if (!input.price || !input.minimumPrice || !input.maximumPrice || !input.interval || !input.intervalUnit || !input.agreementType) {
      this.setState({ loading: false, error: "Please provide all required fields, for creating a pricing template!" })
      return
    } else if (!input.carModels && !input.carYearMin && !input.carYearMax && !input.carMileageMin && !input.carMileageMax) {
      this.setState({ loading: false, error: "Please provide at least one of the following: Car Model, Car Year Min, Car Year Max, Car Mileage Min, Car Mileage Max!" })
      return
    } else if (input.carYearMin && input.carYearMax && input.carYearMin > input.carYearMax) {
      this.setState({ loading: false, error: "Car Year Min must be less than Car Year Max!" })
      return
    } else if (input.carMileageMin && input.carMileageMax && parseInt(input.carMileageMin) > parseInt(input.carMileageMax)) {
      this.setState({ loading: false, error: "Car Mileage Min must be less than Car Mileage Max!" })
      return
    }
    try {
      input = {
        ...input,
        price: parseFloat(input.price),
        minimumPrice: parseFloat(input.minimumPrice),
        maximumPrice: parseFloat(input.maximumPrice),
        interval: parseInt(input.interval),
        carYearMin: parseInt(input.carYearMin),
        carYearMax: parseInt(input.carYearMax),
        carMileageMin: input.carMileageMin ? { mileage: parseFloat(input.carMileageMin), mileageUnit: this.state.defaultMileageUnit === "KM" ? "KM" : "MI" } : null,
        carMileageMax: input.carMileageMax ? { mileage: parseFloat(input.carMileageMax), mileageUnit: this.state.defaultMileageUnit === "KM" ? "KM" : "MI" } : null,
      }
      this.setState({ loading: true })

      this.props.apolloClient.mutate({
        mutation: CreatePricingTemplateMutation,
        variables: { input },
      }).then((result) => {
        if (result.data && result.data.createPricingTemplate && result.data.createPricingTemplate.ok) {
          this.props.refetch()
          if (!this.state.createAnother) {
            this.props.toggleModal()
          } else {
            this.setState({ createAnother: false })
          }
          this.setState({ loading: false, error: "" })
        } else if (result.data && result.data.createPricingTemplate && !result.data.createPricingTemplate.ok && result.data.createPricingTemplate.errors && result.data.createPricingTemplate.errors[0]) {
          this.setState({ loading: false, error: result.data.createPricingTemplate.errors[0].messages.toString() })
        } else {
          this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
        }
      }).catch((err) => {
        this.setState({ loading: false, error: err.message })
      })

    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  handleChange = (e, number = false) => {
    if (e.target.type === "checkbox")
      this.setState({ input: { ...this.state.input, [e.target.name]: !this.state.input[e.target.name] } })
    else if (number) {
      this.setState({ input: { ...this.state.input, [e.target.name]: parseFloat(e.target.value) } })
    }
    else
      this.setState({ input: { ...this.state.input, [e.target.name]: e.target.value } })
  }

  handleAgreementTypeChange = (agreementType) => {
    if (agreementType) {
      this.setState({
        input: {
          ...this.state.input,
          agreementType: agreementType.value,
        },
        defaultMileageUnit: agreementType.defaultMileageUnit,
      })
    } else {
      this.setState({ input: { ...this.state.input, agreementType: null }, })
    }
  };


  render() {
    return (
      <Modal isOpen={this.props.isOpen} className="bos-car-attr-modal">
        <ModalHeader>Add New Pricing Templates</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatePrice">Default Price *</Label>
                  <Input type="number" step="any" name="price" id="templatePrice" placeholder="$ Price" min={0} value={this.state.input.price} onChange={(e) => this.handleChange(e, true)} />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templateMinimumPrice">Minimum Price *</Label>
                  <Input valid={this.state.input.price && this.state.input.minimumPrice && this.state.input.minimumPrice < this.state.input.price}
                    invalid={this.state.input.price && this.state.input.minimumPrice && this.state.input.minimumPrice > this.state.input.price}
                    type="number" step="any" name="minimumPrice" id="templateMinimumPrice" placeholder="$ Minimum Price" min={0}
                    value={this.state.input.minimumPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Minimum Price must be less than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatemaximumPrice">Maximum Price *</Label>
                  <Input
                    valid={this.state.input.price && this.state.input.maximumPrice && this.state.input.maximumPrice > this.state.input.price}
                    invalid={this.state.input.price && this.state.input.maximumPrice && this.state.input.maximumPrice < this.state.input.price}
                    type="number" step="any" name="maximumPrice" id="templatemaximumPrice" placeholder="$ Maximum Price" min={0}
                    value={this.state.input.maximumPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Maximum Price must be higher than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="title">Pricing Template Title</Label>
                  <Input type="string" name="title" id="title" placeholder="Pricing Template Title" value={this.state.input.title} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="agreementType">Agreement Type *</Label>
                  {/* <Select
                    isLoading={!(this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0)}
                    options={this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0 && this.props.allAgreementTypes.edges.map((item) => ({ value: item.node.id, label: item.node.name }))}
                    placeholder="Select Agreement Type"
                    onChange={(agreementType) => this.setState({ input: { ...this.state.input, agreementType: agreementType && agreementType.value ? agreementType.value : null } })}
                  /> */}
                  <Select
                    isLoading={!(this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0)}
                    options={
                      this.props.allAgreementTypes &&
                      this.props.allAgreementTypes.edges &&
                      this.props.allAgreementTypes.edges.length > 0 &&
                      this.props.allAgreementTypes.edges.map((item) => ({
                        value: item.node.id,
                        label: item.node.name,
                        defaultMileageUnit: item.node.branch && item.node.branch.defaultMileageUnit || "MI",
                      }))
                    }
                    placeholder="Select Agreement Type"
                    onChange={(agreementType) => this.handleAgreementTypeChange(agreementType)}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="templateInterval">Interval *</Label>
                  <Input type="number" name="interval" id="templateInterval" placeholder="Interval" min="1" max="9" value={this.state.input.interval} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}><FormGroup>
                <Label style={{ display: "block", marginBottom: "10px" }} for="templateIntervalUnit">Interval Unit *</Label>
                <Input style={{ padding: "5px", display: "block", marginBottom: "10px", boxSizing: "border-box", width: "100%", borderRadius: "6px" }} type="select" name="intervalUnit" id="templateIntervalUnit" placeholder="Interval Unit" value={this.state.input.intervalUnit} onChange={this.handleChange}>
                  <option value='' disabled>Select Interval Unit</option>
                  {['Hour', 'Day', 'Week', 'Month', 'Year', 'Mile', 'Trip'].map(item => <option value={item} disabled={['Hour', 'Year', 'Mile', 'Trip'].includes(item)}>{item}</option>)}
                </Input>
              </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="carModel">Car Model</Label>
                  <Select
                    isLoading={!(this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0)} isMulti
                    options={this.props.allCarModels && this.props.allCarModels.edges.length > 0 && this.props.allCarModels.edges.filter(item => item.node.make && item.node.name).map((item) => ({
                      value: item.node.id, label: toTitleCase(item.node.make) + " " + item.node.name + (item.node.series ? " | " + item.node.series : "") + (item.node.groupType ? " | " + item.node.groupType.name : " | No Group Selected")
                    }))}
                    placeholder="Select Model" onChange={(carModel) => this.setState({ input: { ...this.state.input, carModels: carModel && carModel.length > 0 ? carModel.map(model => model.value) : null } })} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="carYearMin">Car Year Min</Label>
                  <Input type="number" name="carYearMin" id="carYearMin" placeholder="Car Year Minimum" min={2000} max={3000} value={this.state.input.carYearMin} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="carYearMax">Car Year Max</Label>
                  <Input type="number" name="carYearMax" id="carYearMax" placeholder="Car Year Maximum" min={this.state.input.carYearMin ? this.state.input.carYearMin : 2000} max={3000} value={this.state.input.carYearMax} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={2}>
                <FormGroup>
                  <Label for="mileageUnit">Unit</Label>
                  <Input type="text" name="mileageUnit" id="mileageUnit" disabled value={this.state.defaultMileageUnit} />
                  <UncontrolledTooltip placement="top" target="mileageUnit">{this.state.defaultMileageUnit === "MI" ? "Miles" : "Kilometers"}</UncontrolledTooltip>
                </FormGroup>
              </Col>
              <Col xs={5}>
                <FormGroup>
                  <Label for="carMileageMin">Car Mileage Min</Label>
                  <Input
                    type="number"
                    name="carMileageMin"
                    id="carMileageMin"
                    placeholder={`Car Minimum ${this.state.defaultMileageUnit === "MI" ? "Miles" : "Kilometers"}`}
                    min={0}
                    value={this.state.input.carMileageMin}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={5}>
                <FormGroup>
                  <Label for="carMileageMax">Car Mileage Max</Label>
                  <Input
                    type="number"
                    name="carMileageMax"
                    id="carMileageMax"
                    placeholder={`Car Maximum ${this.state.defaultMileageUnit === "MI" ? "Miles" : "Kilometers"}`}
                    min={this.state.input.carMileageMin ? this.state.input.carMileageMin : 0}
                    value={this.state.input.carMileageMax}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup check>
                  <Label check>
                    <Input name="isDefault" placeholder="Is Default" type="checkbox" onChange={this.handleChange} />
                    {" "}
                    Is Default
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup check>
                  <Label check>
                    <Input name='visibleToCustomers' placeholder="Visible To Customers" type="checkbox" onChange={this.handleChange} />
                    {" "}
                    Visible to Customers
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <ModalFooter>
              {this.state.error && <Badge pill='true' style={{ whiteSpace: 'pre-wrap' }} color="danger">{this.state.error}</Badge>}
              {this.state.loading ? "Loading..." : <>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="createAnother"
                      type="checkbox"
                      checked={this.state.createAnother}
                      onChange={() => this.setState({ createAnother: !this.state.createAnother })}
                    />
                    {/* <Input name='createAnother' type="checkbox" onChange={() => this.setState({ createAnother: !this.state.createAnother })} /> */}
                    {" "}
                    Create another
                  </Label>
                </FormGroup>
                <Button color="primary" type="submit">Create Price</Button>
              </>
              }
              <Button color="secondary" onClick={() => this.props.toggleModal()}>Close</Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    )
  }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCarModels, {
  options: { fetchPolicy: "cache-first" },
  props: ({ data: { loading, allCarModels } }) => ({ loading, allCarModels })
})(
  graphql(AllAgreementTypes, {
    options: { fetchPolicy: "cache-first" },
    props: ({ data: { loading, allAgreementTypes } }) => ({ loading, allAgreementTypes })
  })(withApolloClient(AddPricingModal))
)
