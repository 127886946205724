import React, { useState } from "react"
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import {
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Progress
} from 'reactstrap'
import { AllAvailableModelsQuery, TriggerTypes, TriggerQuery } from "./Queries"
import Select from "react-select"
import { CreateTriggerMutation, UpdateTriggerMutation } from "./Mutations"
import { useEffect } from "react"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

const TriggerForm = ({ trigger, loading, allAvailableModels, triggerTypes, handleClose, isOpen, isUpdate, createTrigger, updateTrigger, triggerId, automationId, refetchQuery, refetchAutomationQuery }) => {

    const [triggerType, setTriggerType] = useState('')
    const [columnsUpdated, setColumnsUpdated] = useState([])
    const [stateLoading, setStateLoading] = useState(false)

    const [error, setError] = useState(null)
    const [availableModel, setAvailableModel] = useState("")
    const [errors, setErrors] = useState({})

    const validateForm = () => {
        const newErrors = {}
        if (!triggerType) newErrors.triggerType = "Trigger Type is required."
        if (!availableModel) newErrors.availableModel = "Model is required."
        if (triggerType === 'ON_UPDATE' && columnsUpdated.length === 0) newErrors.columnsUpdated = "Columns to be updated are required."
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStateLoading(true)
        if (validateForm()) {
            if (isUpdate) {
                updateTrigger({
                    variables: {
                        input: {
                            id: triggerId,
                            triggerType: triggerType,
                            columnsUpdated: columnsUpdated,
                            contentTypeId: availableModel.contentType.id,
                        },
                    },
                }).then(({ data }) => {
                    if (data.updateTrigger.ok) {
                        handleClose()
                        refetchQuery()
                    } else {
                        setError(data.updateTrigger.errors[0].messages[0])
                    }
                    setStateLoading(false)
                })
            } else {
                createTrigger({
                    variables: {
                        input: {
                            automationId,
                            triggerType: triggerType,
                            columnsUpdated: columnsUpdated,
                            contentTypeId: availableModel.contentType.id,
                        }
                    }
                }).then(({ data }) => {
                    if (data.createTrigger.ok) {
                        handleClose()
                        refetchAutomationQuery()
                    } else {
                        setError(data.createTrigger.errors[0].messages[0])
                    }
                    setStateLoading(false)
                })
            }
        }
    }

    useEffect(() => {
        if (trigger && allAvailableModels) {
            setTriggerType(trigger.triggerType)
            if (trigger.triggerType === 'ON_UPDATE') {
                let updateCoulmns = JSON.parse(trigger.columnsUpdated.replace(/'/g, '"'))
                setColumnsUpdated(updateCoulmns.join(',').replace(/[\[\]']+/g, '').trim().split(','))
            }
            setAvailableModel(allAvailableModels.edges.find(model => trigger.contentType.model === model.node.contentType.model).node)
        }
    }, [trigger, allAvailableModels])

    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Trigger Form</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || stateLoading) && <Progress animated color="primary" value={100} />}
                <Form className="ActionForm" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label>Model to trigger from</Label>
                        <br />
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                allAvailableModels && allAvailableModels.edges.map(({ node }) => ({
                                    label: `${node.contentType.appLabel.toUpperCase()} - ${node.contentType.model.toUpperCase()}`,
                                    value: node,
                                }))}
                            defaultValue={trigger && trigger.contentType ? {
                                label: `${trigger.contentType.model.toUpperCase()}`,
                                value: trigger.contentType.moode,
                            } : null}
                            value={availableModel ? {
                                label: `${availableModel.contentType.appLabel.toUpperCase()} - ${availableModel.contentType.model.toUpperCase()}`,
                                value: availableModel,
                            } : null}
                            isDisabled={isUpdate}
                            placeholder="-- Select model --"
                            onChange={(option) => setAvailableModel(option.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="triggerType">Trigger Type</Label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                triggerTypes && triggerTypes.states.map((item) => ({
                                    label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                    value: item.name,
                                }))
                            }
                            value={triggerType ? {
                                label: triggerType.replaceAll('_', ' ').toUpperCase(),
                                value: triggerType,
                            } : null}
                            defaultValue={trigger && trigger.triggerType ? {
                                label: trigger.triggerType.replaceAll('_', ' ').toUpperCase(),
                                value: trigger.triggerType,
                            } : null}

                            placeholder="-- Select trigger type --"
                            onChange={(option) => setTriggerType(option.value)}
                        />
                    </FormGroup>
                    {triggerType === 'ON_UPDATE' && (
                        <FormGroup>
                            <Label for="columnsUpdated">Updated Columns</Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                isLoading={false}
                                isMulti
                                options={
                                    availableModel ? availableModel.availableModelFields.map((field) => ({
                                        label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                        value: field,
                                    })) : []
                                }
                                value={columnsUpdated ? columnsUpdated.map((item) => ({
                                    label: item.replaceAll('_', ' ').toUpperCase(),
                                    value: item,
                                })) : []}
                                placeholder="Select columns"
                                onChange={(options) => setColumnsUpdated(options.map((option) => option.value))}
                            />
                        </FormGroup>
                    )}
                    <Button type="submit" className="float-right">Submit</Button>
                </Form>

            </ModalBody>
        </Modal>
    )
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllAvailableModelsQuery, {
    options: () => ({
        variables: {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, allAvailableModels } }) => ({
        loading,
        allAvailableModels,
    }),
})(
    graphql(TriggerQuery, {
        options: ({ triggerId }) => ({
            variables: { id: triggerId },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { trigger, loading, refetch, variables } }) => ({
            trigger,
            loading,
            variables,
            refetchQuery: () => {
                return refetch({
                    variables: {
                        ...variables,
                    },
                });
            },
        }),
    })(
        graphql(TriggerTypes, {
            options: () => ({
                variables: {},
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            }),
            props: ({ data: { loading, triggerTypes } }) => ({
                loading,
                triggerTypes,
            }),
        })(
            graphql(CreateTriggerMutation, { name: 'createTrigger' })(
                graphql(UpdateTriggerMutation, { name: 'updateTrigger' })(
                    withApolloClient(TriggerForm)
                )
            )
        )
    )
);
