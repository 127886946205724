import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Row, Col, FormGroup, Label, Input, Button, Progress, Alert } from 'reactstrap'
import Select from 'react-select'
import ConfirmationPopup from "../Material/ConfirmationPopup"
import { StripeCards } from "./Queries"
import { updateDefaultCardMutation, createStripePaymentMutation, deleteCardMutation, requestPaymentFromDriverMutation, requestCardFromDriverMutation } from "./Mutations"
import "./StripeCard.css"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

class StripeCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCard: "",
            openModal: "",
            errorMessage: "",
            confirmationLoading: false,
            confirmationSuccess: "",
            confirmationError: "",
            loading: false,
            stripChargesLoading: false,
            requestPaymentSuccess: false,
            requestCardSuccess: false,
            amountToCharge: this.props.amountToPay ? this.props.amountToPay : 0,
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = parseFloat(e.target.value)

        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }


    handleUpdateDefaultCard = () => {
        this.setState({ confirmationLoading: true })
        let input = {
            driverId: this.props.driverId,
            sourceId: this.state.selectedCard
        }
        this.props.apolloClient.mutate({
            mutation: updateDefaultCardMutation,
            variables: { input }
        }).then((result) => {
            if (result && result.data && result.data.updateDefaultCard && result.data.updateDefaultCard.ok) {
                this.props.refetchCardsQuery()
                this.setState({ confirmationLoading: false })
                this.toggleModal("")
            } else {
                let errorMessage = "Change Default Failed"
                if (result.data.updateDefaultCard.errors && result.data.updateDefaultCard.errors[0] && result.data.updateDefaultCard.errors[0].messages) {
                    errorMessage = result.data.updateDefaultCard.errors[0].messages
                }
                this.setState({ confirmationError: errorMessage, confirmationLoading: false })
            }
        }).catch(err => {
            this.setState({ confirmationError: "Error Updating Default Card. Please contact Admin" })
        })
    }

    handleDeleteCard = () => {
        if (this.state.selectedCard === null) {
            this.setState({ error: "Please Select a Card to Delete" })
            return
        }
        this.setState({ confirmationLoading: true })
        let input = {
            driverId: this.props.driverId,
            sourceId: this.state.selectedCard
        }
        this.props.apolloClient.mutate({
            mutation: deleteCardMutation,
            variables: { input }
        }).then(result => {
            if (result && result.data && result.data.deleteCard && result.data.deleteCard.ok) {
                this.props.refetchCardsQuery()
                this.setState({ confirmationLoading: false, selectedCard: "" })
                this.toggleModal("")
            } else {
                let errorMessage = "Unable to Delete Card"
                if (result.data.deleteCard.errors && result.data.deleteCard.errors[0] && result.data.deleteCard.errors[0].messages) {
                    errorMessage = result.data.deleteCard.errors[0].messages
                }
                this.setState({ confirmationError: errorMessage, confirmationLoading: false })
            }
        }).catch(err => {
            this.setState({ confirmationError: "Error Deleting Card. Please contact Admin" })
        })
    }

    chargeCard = ({ paymentMethodId }) => {
        if (this.state.amountToCharge > 0) {
            this.setState({ loading: true })
            const input = {
                driverId: this.props.driverId,
                amount: parseFloat(this.state.amountToCharge), // Ensure it's a Float
                description: this.props.isPickupPayment ? "Pickup Payment" : "Bill Payment",
                metadata: JSON.stringify({ is_pickup_payment: !!this.props.isPickupPayment }),
            }

            if (paymentMethodId) {
                input["paymentMethodId"] = paymentMethodId
            }

            this.props.apolloClient.mutate({
                mutation: createStripePaymentMutation,
                variables: { input },
            }).then((result) => {
                if (result.data.createStripePayment.ok) {
                    if (this.props.paymentSuccess)
                        this.props.paymentSuccess(this.state.amountToCharge)
                    this.setState({ loading: false, })
                } else {
                    let errorMessage = "Payment Error"
                    if (result.data.createStripePayment.errors && result.data.createStripePayment.errors[0] && result.data.createStripePayment.errors[0].messages) {
                        errorMessage = result.data.createStripePayment.errors[0].messages
                    }
                    this.setState({
                        errorMessage,
                        loading: false
                    })
                }
            })
        }
    };


    handleRequestPayment = () => {
        this.setState({ loading: true, errorMessage: "" })
        let input = {
            driverId: this.props.driverId,
            amount: parseFloat(this.state.amountToCharge)// Iagami - Code upgradation React 18
        }
        this.props.apolloClient.mutate({
            mutation: requestPaymentFromDriverMutation,
            variables: { input }
        }).then((result) => {
            if (result && result.data && result.data.requestPaymentFromDriver && result.data.requestPaymentFromDriver.ok) {
                this.setState({ loading: false, requestPaymentSuccess: true })
            } else {
                let errorMessage = "Unable to Request Payment"
                if (result.data.requestPaymentFromDriver.errors && result.data.requestPaymentFromDriver.errors[0] && result.data.requestPaymentFromDriver.errors[0].messages) {
                    errorMessage = result.data.requestPaymentFromDriver.errors[0].messages
                }
                this.setState({ errorMessage: errorMessage, loading: false })
            }
        })
    }
    handleRequestCard = () => {
        this.setState({ loading: true })
        let input = {
            driverId: this.props.driverId,
        }
        this.props.apolloClient.mutate({
            mutation: requestCardFromDriverMutation,
            variables: { input }
        }).then((result) => {
            if (result && result.data && result.data.requestCardFromDriver && result.data.requestCardFromDriver.ok) {
                this.setState({ loading: false, requestCardSuccess: true })
            } else {
                let errorMessage = "Unable to Request Card"
                if (result.data.requestCardFromDriver.errors && result.data.requestCardFromDriver.errors[0] && result.data.requestCardFromDriver.errors[0].messages) {
                    errorMessage = result.data.requestCardFromDriver.errors[0].messages
                }
                this.setState({ errorMessage: errorMessage, loading: false })
            }
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.cards !== this.props.cards) {
            if (this.props.cards.cards && this.props.cards.cards.length > 0) {
                const defaultCard = this.props.cards.cards.find(card => card.default)
                if (defaultCard) {
                    this.setState({ selectedCard: defaultCard.id })
                } else {
                    this.setState({ selectedCard: "" })
                }
            }
        }
        if (this.props.amountToPay !== prevProps.amountToPay)
            this.setState({ amountToCharge: this.props.amountToPay })
    }
    componentDidMount() {
        const { apolloClient } = this.props// Iagami - Code upgradation React 18
        if (this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 0 && this.state.selectedCard === "") {
            const defaultCard = this.props.cards.cards.find(card => card.default)
            if (defaultCard) {
                this.setState({ selectedCard: defaultCard.id })
            } else {
                this.setState({ selectedCard: "" })
            }
        }
    }

    render() {
        return (
            <div className="card-component">
                {this.state.errorMessage && <Row><Col><Alert color="danger">{this.state.errorMessage}</Alert></Col></Row>}
                {this.state.successMessage && <Row><Col><Alert color="success">{this.state.successMessage}</Alert></Col></Row>}
                {(this.state.loading || this.props.loading) && <Row><Col><Progress animated color="info" value="100" /></Col></Row>}
                {this.props.actions.includes("pay") && <Row>
                    <Col>
                        <FormGroup>
                            <Label><b>Amount To Charge</b></Label><br />
                            <Input
                                type="number"
                                onChange={this.updateInput}
                                disabled={this.props.amountToPay}
                                value={this.state.amountToCharge}
                                name="amountToCharge"
                                placeholder="Enter Amount"
                            />
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <FormGroup>
                            <Row>
                                <Col className="d-flex justify-content-start">
                                    <Label for="selectedCard"><b>Choose Card To Charge</b></Label>
                                </Col>
                                {this.props.actions && this.props.actions.includes("cardOnFile") && this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 0 && this.state.selectedCard && !(this.props.cards.cards.some(card => card.default && card.id === this.state.selectedCard)) &&
                                    <Col className="d-flex justify-content-end">
                                        <a className={"set-as-default"} onClick={() => this.setState({ openModal: "setCardAsDefault" })}>Set As Default</a>
                                    </Col>
                                }
                                <ConfirmationPopup
                                    open={this.state.openModal === "setCardAsDefault"}
                                    message={("ARE YOU SURE YOU WANT TO SET THIS CARD AS DEFAULT CARD?")}
                                    loading={this.state.confirmationLoading}
                                    handleClose={() => this.toggleModal("")}
                                    action={"Yes"}
                                    confirmAction={this.handleUpdateDefaultCard}
                                    color="primary"
                                    error={this.state.confirmationError ? this.state.confirmationError : ""}
                                />
                            </Row>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                options={this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 0 ? this.props.cards.cards.map(card => ({ value: card.id, label: (card.brand + ": **" + card.last4 + ". Exp: " + card.expMonth + "/" + card.expYear) + (card.default ? " (default)" : "") })) : []}
                                value={this.state.selectedCard ? this.props.cards.cards.filter(card => card.id === this.state.selectedCard).map(card => ({ value: card.id, label: (card.brand + ": **" + card.last4 + ". Exp: " + card.expMonth + "/" + card.expYear) + (card.default ? " (default)" : "") })) : null}
                                onChange={(card) => this.setState({ selectedCard: card.value })}
                                loading={this.props.loading}
                                isDisabled={this.props.loading}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {this.props.actions && this.props.actions.includes("requestCard") &&
                    <Row>
                        <Col className="d-flex justify-content-start">
                            {this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 1 &&
                                <Button className="card-component-btn bg-danger" disabled={this.state.selectedCard === "" || this.props.cards.cards.find(card => card.default && card.id === this.state.selectedCard)} onClick={() => this.setState({ openModal: "deleteCard" })}>DELETE CARD</Button>}
                            <ConfirmationPopup
                                open={this.state.openModal === "deleteCard"}
                                message={("ARE YOU SURE YOU WANT TO DELETE THIS CARD?")}
                                loading={this.state.confirmationLoading}
                                handleClose={() => this.toggleModal("")}
                                action={"Yes"}
                                confirmAction={() => this.handleDeleteCard()}
                                color="primary"
                                error={this.state.confirmationError ? this.state.confirmationError : ""}
                            />&nbsp;&nbsp;
                            {this.props.cards && this.props.cards.cards && this.props.cards.cards.length >= 1 && this.props.actions && this.props.actions.includes("pay") &&
                                <Button className="card-component-btn bg-info" disabled={this.state.amountToCharge <= 0 || this.state.requestPaymentSuccess || this.state.loading} onClick={this.handleRequestPayment}>{this.state.requestPaymentSuccess ? "PAYMENT REQUESTED" : "REQUEST PAYMENT"}</Button>
                            }
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {this.props.actions && this.props.actions.includes("pay") && <Button className="card-component-btn bg-success" disabled={this.state.amountToCharge <= 0 || !this.state.selectedCard || this.state.loading} onClick={() => this.chargeCard({ paymentMethodId: this.state.selectedCard })}>CHARGE CARD</Button>}&nbsp;&nbsp;
                            <Button className="card-component-btn bg-warning" disabled={this.state.requestCardSuccess || this.state.loading} onClick={this.handleRequestCard}>{this.state.requestCardSuccess ? "CARD REQUESTED" : "REQUEST CARD"}</Button>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}
export default graphql(StripeCards, {
    options: ({ driverId }) => ({ variables: { id: driverId }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
    props: ({ data: { loading, cards, variables, refetch } }) => (
        {
            loading, cards, variables,
            refetchCardsQuery: () => {
                return refetch({
                    query: StripeCards,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return { cards: fetchMoreResult.cards }
                    },
                })
            },
        }
    )
})(withApolloClient(StripeCard)
)
