import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index-dark.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

//Iagami - Code upgradation React 18
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <App />
)
registerServiceWorker()
