import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Alert, Button, Row, Col, Progress, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { BulkUpdateUser } from "../Mutations"
import Select from 'react-select'
import { AllGroups } from "../Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class UpdateMembers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            selectedUser: this.props.memberDetails,
            openModal: "",
            loading: false
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };

    handleSubmit = () => {
        if (this.state.groupIds) {
            try {
                this.setState({ loading: true })
                let input = {}
                let userIds = this.state.selectedUser
                let groupIds = this.state.groupIds
                input['userIds'] = userIds
                input['groupIds'] = groupIds
                this.props.apolloClient.mutate({
                    mutation: BulkUpdateUser,
                    variables: { input },
                }).then((result) => {
                    if (result.data.bulkUpdateUser && result.data.bulkUpdateUser.ok) {
                        this.props.refetchQuery()
                        this.setState({ loading: false, error: null })
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.bulkUpdateUser && result.data.bulkUpdateUser.errors && result.data.bulkUpdateUser.errors[0] && result.data.bulkUpdateUser.errors[0].messages[0])
                            errorMessage = result.data.bulkUpdateUser.errors[0].messages[0]
                        this.setState({ loading: false, error: errorMessage })
                    }
                    this.props.refetchQuery()
                    this.props.handleClose()
                }).catch((error) => {
                    let errorMessage = "An error has occured " + error
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            catch (error) {
                let errorMessage = "An error has occured " + error
                this.setState({ error: errorMessage, loading: false })
            }
        } else {
            this.setState({ error: "Error: Required fields are missing" })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props;
          }    

    render() {
        return (
            <Modal isOpen={this.props.isOpen} >
                <ModalHeader>Assign Groups</ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}


                    <Row>
                        <Col xs={12}>
                            <FormGroup className="mb-0">
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    options={this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                    placeholder="Select Groups"
                                    isClearable
                                    isMulti
                                    onChange={(groups) => this.setState({ groupIds: (groups.map(item => item.value)) })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { this.props.handleClose() }}>Cancel</Button>
                    <Button color="primary" onClick={() => this.handleSubmit()}>Add</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllGroups, { props: ({ data: { allGroups } }) =>({ allGroups }) })
(withApolloClient(UpdateMembers));
