import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, ModalFooter, Alert } from 'reactstrap'
import StripeCard from "./StripeCard"
import StripeTerminal from "./StripeTerminal"
import "./StripeModal.css"
import History from "./History"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

const TABS = [
    { id: "CARD_PAYMENT", name: "Cards" },
    { id: "TERMINAL_PAYMENT", name: "Terminal" },
    { id: "STRIPE_HISTORY", name: "Stripe History" },
]

class StripeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentSuccess: false,
            activeTab: "CARD_PAYMENT",
            paidAmount: 0,
        }
    }

    handleClose = () => {
        this.setState({ paymentSuccess: false })
        this.props.handleClose()
    };

    paymentSuccess = (amount) => {
        this.setState({ paymentSuccess: true, paidAmount: amount })
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="charge-driver-modal">
                <ModalHeader>
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className={"fa fa-cc-stripe"} />&nbsp;&nbsp;Customer Payment Modal
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    {this.state.paymentSuccess ?
                        <Alert color="success">Successfully Paid {this.state.paidAmount}.</Alert> :
                        <>
                            <Nav tabs>
                                {TABS.map(tab =>
                                    <NavItem key={tab.id} style={{ "cursor": "pointer" }}>
                                        <NavLink className={`${this.state.activeTab === tab.id ? 'active' : ''}`} onClick={() => { this.setState({ activeTab: tab.id }) }}> {tab.name} </NavLink>
                                    </NavItem>
                                )
                                }
                            </Nav>
                            <br />
                            <TabContent activeTab={this.state.activeTab}>
                                {this.state.activeTab === "CARD_PAYMENT" && <TabPane tabId="CARD_PAYMENT">
                                    <StripeCard driverId={this.props.driverId} actions={["pay", "requestCard", "cardOnFile"]} isPickupPayment={this.props.isPickupPayment} paymentSuccess={(amount) => this.paymentSuccess(amount)} />
                                </TabPane>}
                                {this.state.activeTab === "TERMINAL_PAYMENT" && <TabPane tabId="TERMINAL_PAYMENT">
                                    <StripeTerminal driverId={this.props.driverId} paymentSuccess={(amount) => this.paymentSuccess(amount)} />
                                </TabPane>}
                                {this.state.activeTab === "STRIPE_HISTORY" && <TabPane tabId="STRIPE_HISTORY">
                                    <History driverId={this.props.driverId} />
                                </TabPane>}
                            </TabContent>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className="card-component-secondary-btn" onClick={this.handleClose}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
//Iagami - Code upgradation React 18
export default withApolloClient(StripeModal)
