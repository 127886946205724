import React from 'react'
import { AllDriversQuery, CarsQuery, RepsQuery, AllBoardsQuery, GroupsQuery } from "./Queries"
import { Query } from '@apollo/client/react/components'
import { useQuery } from '@apollo/client'

import Select from 'react-select'

export const DriverTypeAhead = ({ searchTerm, setSearchTerm, onChange, defaultValue }) => (
    <Query query={AllDriversQuery} variables={{ searchTerm }} skip={!searchTerm || searchTerm && searchTerm.length < 3}>
        {({ loading, error, data }) => {
            if (loading) return <Select isLoading={true}></Select>
            if (error) return <Select isLoading={false} isDisabled={true} placeholder={`Error! ${error.message}`}></Select>
            return <Select
                options={data && data.allDrivers && data.allDrivers.edges &&
                    data.allDrivers.edges.map(
                        driver => ({ value: driver.node.id, label: `${driver.node.name} · ${driver.node.email}` })
                    )}
                placeholder="Select Driver"
                onInputChange={(inputValue) => { setSearchTerm(inputValue) }}
                onChange={(selected) => onChange(selected.value)}
                defaultValue={defaultValue}
            />
        }}
    </Query>
)


export const CarTypeAhead = ({ searchTerm, setSearchTerm, onChange, defaultValue }) => (
    <Query query={CarsQuery} variables={{ searchTerm }} skip={!searchTerm || searchTerm && searchTerm.length < 3}>
        {({ loading, error, data }) => {
            if (loading) return <Select isLoading={true}></Select>
            if (error) return <Select isLoading={false} isDisabled={true} placeholder={`Error! ${error.message}`}></Select>
            return <Select
                options={data && data.allCars && data.allCars.edges &&
                    data.allCars.edges.map(
                        car => ({ value: car.node.id, label: `${car.node.year} ${car.node.make} ${car.node.model} · ${car.node.vin}` })
                    )}
                placeholder="Select Car"
                onInputChange={(inputValue) => { setSearchTerm(inputValue) }}
                onChange={(selected) => onChange(selected.value)}
                defaultValue={defaultValue}
            />
        }}
    </Query>
)


export const RepsTypeAhead = ({ searchTerm, setSearchTerm, onChange, defaultValue }) => (
    <Query query={RepsQuery} variables={{ searchTerm }} skip={!searchTerm || searchTerm && searchTerm.length < 2}>
        {({ loading, error, data }) => {
            if (loading) return <Select isLoading={true}></Select>
            if (error) return <Select isLoading={false} isDisabled={true} placeholder={`Error! ${error.message}`}></Select>
            return <Select
                options={data && data.allReps && data.allReps.edges &&
                    data.allReps.edges.map(
                        rep => ({ value: rep.node.id, label: `${rep.node.username} · ${rep.node.name}` })
                    )}
                placeholder="Select Reps"
                onInputChange={(inputValue) => { setSearchTerm(inputValue) }}
                onChange={(selected) => { onChange(selected ? selected.map(rep => rep.value) : []) }}
                isMulti={true}
                defaultValue={defaultValue}
            />
        }}
    </Query>
)


export const GroupsTypeAhead = ({ searchTerm, setSearchTerm, onChange, defaultValue }) => (
    <Query query={GroupsQuery} variables={{ searchTerm }}>
        {({ loading, error, data }) => {
            if (loading) return <Select isLoading={true}></Select>
            if (error) return <Select isLoading={false} isDisabled={true} placeholder={`Error! ${error.message}`}></Select>
            return <Select
                options={data && data.allGroups && data.allGroups.edges &&
                    data.allGroups.edges.map(
                        group => ({ value: group.node.id, label: group.node.name })
                    )}
                placeholder="Select Groups"
                onInputChange={(inputValue) => { setSearchTerm(inputValue) }}
                onChange={(selected) => { onChange(selected ? selected.map(group => group.value) : []) }}
                isMulti={true}
                defaultValue={defaultValue}
            />
        }
        }
    </Query>
)

export const BoardTypeAhead = ({ searchTerm, setSearchTerm, onChange, defaultValue }) => (
    <Query query={AllBoardsQuery} variables={{ searchTerm }}>
        {({ loading, error, data }) => {
            if (loading) return <Select isLoading={true}></Select>
            if (error) return <Select isLoading={false} isDisabled={true} placeholder={`Error! ${error.message}`}></Select>
            const options = data && data.allBoards && data.allBoards.edges &&
                data.allBoards.edges.map(board => ({
                    value: board.node.id,
                    label: board.node.title
                }))
            return <Select
                options={options}
                placeholder="Select Board"
                onInputChange={(inputValue) => { setSearchTerm(inputValue) }}
                onChange={(selected) => onChange(selected.value)}
                defaultValue={defaultValue}
                key="boardTypeAhead" // defaultvalue Won't work without this https://github.com/JedWatson/react-select/issues/5888
            />
        }}
    </Query>
)