import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Table, UncontrolledTooltip, Badge } from 'reactstrap'
import MassMessageModal from "../../Material/MassMessageModal"
import CarModelGroupIcon from "../../CA/CarModelGroupIcon"
import toTitleCase from "../../Functions/toTitleCase"
import NewAgreementPrice from "../../Material/NewAgreementPrice"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import "./Rentals.css"
import { ReactComponent as InOffice } from "../../assets/images/icons/in-office.svg"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const RentalsQuery = gql`query RentalsQuery($cursor: String, $searchTerm: String, $first:Int!, $stage: String, $orderBy: [String], $contractSigned: Boolean, $startDate_Gte: DateTime, $startDate_Lte: DateTime, $pickupTypes: [String], $agreementTypes: [ID], $hasPromo: Boolean, $pikcupLocations: [ID], $carModelNames: [String], $promoNames: [String]  ){
    rentals: allRentals(stage_Exact:$stage, first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, contractSigned:$contractSigned, startDate_Gte: $startDate_Gte, startDate_Lte: $startDate_Lte, pickupTypes: $pickupTypes, agreementTypes: $agreementTypes, hasPromo: $hasPromo, pikcupLocations: $pikcupLocations, carModelNames: $carModelNames, promoNames: $promoNames){
        edges{
            node{
                id
                pk
                weeklyCharge
                startDate
                stage
                baseCharge
                insuranceUpcharge
                discount
                agreementType
                endDate
                notes
                pickupType
                stageDisplay
                dateAdded
                representative{
                    id
                    username
                    firstName
                    lastName
                }
                car{
                    id
                    pk
                    stage
                    pk
                    year
                    color
                    carModel{
                        id
                        make
                        name
                        series
                        groupType{
                            id
                            name
                        }
                    }
                }
                branch{
                    id
                    name
                }
                driver{
                    name
                    balance
                    tlcLicense
                    inOffice
                    id
                }
                agreementpriceSet{
                    edges{
                        node{
                            id
                            price
                            minimumPrice
                            maximumPrice
                            intervalUnit
                            interval
                            isActive
                            currency { code symbol }
                        }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    }
}`

const tableHeaders = [
    { id: "driver__id", name: "Driver Info", sortable: true },
    { id: "car__id", name: "Car Info", sortable: true },
    { id: "id", name: "Rental Info", sortable: true },
    { id: "start_date", name: "Start Date", sortable: true },
    { id: "end_date", name: "End Date", sortable: true },
    { id: "stage", name: "Stage", sortable: true },
    { id: "rental_pricing", name: "Rental Pricing", sortable: false },
    { id: "actions", name: "Action", sortable: false },
]

class RentalList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectAll: false,
        }
    }
    updateOrder = (value) => {
        this.props.updateOrder(value)
    };
    toggleSelectAllClick = () => {
        if (this.props.rentals && this.props.rentals.edges && this.props.rentals.edges.length > 0 && !this.state.selectAll) {
            let selection = this.props.rentals.edges.map(item => item.node.driver.id)
            this.props.updateDriversSelection(selection)
            this.setState({ selectAll: true })
        } else {
            this.props.updateDriversSelection([])
            this.setState({ selectAll: false })
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.rentals && this.props.rentals.edges.length} pageInfo={this.props.rentals && this.props.rentals.pageInfo} loadMoreEntries={this.props.rentals && this.props.rentals.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchRentalsQuery}>
                <Table responsive loading={this.props.loading}>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {
                                tableHeaders.map(tableHeader => {
                                    return (
                                        tableHeader.sortable ?
                                            <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.includes(tableHeader.id) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rentals && this.props.rentals.edges && this.props.rentals.edges.length > 0 ? this.props.rentals.edges.map((rental, i) =>
                            rental && rental.node ?
                                <tr className={rental.node && rental.node.driver.inOffice && "driver-in-office-row"}>
                                    <td scope="row" className="listing-checkbox-wrapper">
                                        {!this.props.selectedDrivers.includes(rental.node.driver.id) && <strong className="listing-counter">{i + 1}</strong>}
                                        <span className="listing-checkbox" onClick={() => this.props.updateDriversSelection(rental.node.driver.id)}>
                                            {this.props.selectedDrivers.includes(rental.node.driver.id) ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                        </span>
                                    </td>
                                    <td>
                                        {rental.node.driver ?
                                            <>
                                                <small className={rental.node.driver.balance > 0 ? "text-danger" : "text-success"}>${rental.node.driver.balance}</small> | <small>{rental.node.driver.tlcLicense}</small>
                                                <br />
                                                <a className="bos-custom-link" href={'/driver/' + rental.node.driver.id} target="_blank">
                                                    <strong>{rental.node.driver.name.trim() ? rental.node.driver.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}</strong>
                                                </a>
                                                {rental.node.driver && rental.node.driver.inOffice &&
                                                    <>
                                                        <InOffice id={"inOffice" + String(rental.node.driver.pk)} width={25} height={25} fill="#198754" />
                                                        <UncontrolledTooltip target={"inOffice" + String(rental.node.driver.pk)}>Driver in Office</UncontrolledTooltip>
                                                    </>
                                                }
                                            </> : ""
                                        }
                                    </td>
                                    <td>
                                        {rental.node.car &&
                                            <div className="d-flex flex-row">
                                                <div className="pr-3">
                                                    <CarModelGroupIcon />
                                                    <br />
                                                    <span>{rental.node.car.pk}</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <small>
                                                            {rental.node.car.year} | {rental.node.car.color} | {rental.node.car.carModel && rental.node.car.carModel?.groupType && rental.node.car.carModel.groupType?.name || 'N/A'}
                                                        </small>
                                                    </span>
                                                    <br />
                                                    {rental.node.car.carModel &&
                                                        <>
                                                            <a className="bos-custom-link" href={'/car/' + rental.node.car.pk} target="_blank">
                                                                <strong>{toTitleCase(rental.node.car.carModel?.name)} {toTitleCase(rental.node.car.carModel?.make)}</strong>
                                                            </a> <small className="text-truncate">({rental.node.car.carModel?.series})</small><br />
                                                        </>
                                                    }
                                                </div>
                                            </div>}
                                    </td>
                                    <td>
                                        <div>
                                            <UncontrolledTooltip placement="top" target={"pickupType" + i}>Pickup Type</UncontrolledTooltip>
                                            <UncontrolledTooltip placement="top" target={"agreementType" + i}>Agreement Type</UncontrolledTooltip>
                                            <UncontrolledTooltip placement="top" target={"createdBy" + i}>Created By</UncontrolledTooltip>
                                            <small id={"pickupType" + i}>{rental.node.pickupType ? toTitleCase(rental.node.pickupType.split("_").join(" ")) : "--"}</small> | <small id={"createdBy" + i}> {rental.node.representative ? toTitleCase(rental.node.representative.firstName ? rental.node.representative.firstName + " " + rental.node.representative.lastName : rental.node.representative.username) : "--"}&nbsp;&nbsp;</small>
                                            <br />
                                            <strong id={"agreementType" + i}>{toTitleCase(rental.node.agreementType)}</strong>
                                            <br />
                                            <Badge className='branch-badge'>{rental.node.branch && rental.node.branch.name ? rental.node.branch.name : "No Branch Seleted"}</Badge>
                                        </div>
                                    </td>
                                    <td>
                                        {rental.node.startDate ? <DatetimeRenderer datetime={rental.node.startDate} placement={"left"} /> : '--'}
                                    </td>
                                    <td>
                                        {rental.node.endDate ? <DatetimeRenderer datetime={rental.node.endDate} placement={"right"} /> : '--'}
                                    </td>
                                    <td><Badge className="stage-badge" style={{ color: "black" }} color={rental.node.stageDisplay === "Active" ? "success" : rental.node.stageDisplay === "Returned" ? "danger" : "warning"}><span>{rental.node.stageDisplay}</span></Badge></td>
                                    <td>
                                        <div className="d-flex">
                                            <UncontrolledTooltip target={"updateRentalPricing" + i}>Edit Rental Pricing</UncontrolledTooltip>
                                            {this.state.openModal === ("updateRentalPricing" + i) &&
                                                <NewAgreementPrice
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchQuery={this.props.refetchRentalsQuery}
                                                    rental={rental.node}
                                                    open={this.state.openModal === "updateRentalPricing" + i}
                                                />
                                            }
                                            <span className='pr-3 align-self-center'>
                                                <i id={"updateRentalPricing" + i} className="fa fa-pencil fa-lg" onClick={() => this.toggleModal("updateRentalPricing" + i)}></i>
                                            </span>
                                            <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                {rental.node.agreementpriceSet && rental.node.agreementpriceSet.edges && rental.node.agreementpriceSet.edges.length > 0 && rental.node.agreementpriceSet.edges[0].node.price &&
                                                    ("$" + rental.node.agreementpriceSet.edges[0].node.price + "/- " + (rental.node.agreementpriceSet.edges[0].node.interval > 1 ? rental.node.agreementpriceSet.edges[0].node.interval + " " : "") + rental.node.agreementpriceSet.edges[0].node.intervalUnit)}

                                                <br />
                                                <small>
                                                    {rental.node.agreementpriceSet && rental.node.agreementpriceSet.edges && rental.node.agreementpriceSet.edges.length > 0 && rental.node.agreementpriceSet.edges[0].node.minimumPrice &&
                                                        ("$" + rental.node.agreementpriceSet.edges[0].node.minimumPrice + (rental.node.agreementpriceSet.edges[0].node.maximumPrice ? " - $" + rental.node.agreementpriceSet.edges[0].node.maximumPrice : ""))}
                                                </small>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <ul className="actions d-flex">
                                            <li className="align-self-center">
                                                {this.state.openModal == ("sendMessage" + i) && <MassMessageModal
                                                    handleClose={() => this.toggleModal(("sendMessage" + i))}
                                                    open={this.state.openModal === ("sendMessage" + i)}
                                                    drivers={[rental.node.driver.id]}
                                                />}
                                                <UncontrolledTooltip target={"sendMessage" + i}>Message Driver</UncontrolledTooltip>
                                                <i id={"sendMessage" + i} className="fa fa-lg fa-envelope-o" onClick={() => this.toggleModal('sendMessage' + i)}></i>
                                            </li>
                                        </ul>
                                    </td>
                                </tr> : <tr></tr>) :
                            this.props.loading ?
                                <tr>
                                    <td colSpan={7}>Loading Agreements List...</td>
                                </tr> :
                                <tr>
                                    <td colSpan={7}>No Agreement Found</td>
                                </tr>
                        }

                    </tbody>
                </Table>
            </PrimaryListingContainer>

        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(RentalsQuery, {
    options: ({ searchTerm, stage, orderBy, contractSigned, startDate_Gte, startDate_Lte, pickupTypes, agreementTypes, hasPromo, pikcupLocations, carModelNames, promoNames, }) => ({
        variables: { searchTerm, stage, orderBy, contractSigned, startDate_Gte, startDate_Lte, pickupTypes, agreementTypes, first: 30, hasPromo, pikcupLocations, carModelNames, promoNames },
        notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
    }),
    // This function re-runs every time `data` changes, including after `updateQuery`,
    // meaning our loadMoreEntries function will always have the right cursor
    props: ({ data: { loading, rentals, fetchMore, variables } }) => (
        {
            loading,
            rentals,
            variables,
            refetchRentalsQuery: () => {
                //No apollo function that refetches in place with pagination considered so this function instead
                let currentLength = rentals && rentals.edges ? rentals.edges.length : 30
                return fetchMore({
                    query: RentalsQuery,
                    variables: {
                        ...variables,
                        first: currentLength,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.rentals.edges
                        const pageInfo = fetchMoreResult.rentals.pageInfo
                        return {
                            rentals: {
                                edges: [...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.rentals.__typename
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: RentalsQuery,
                    variables: {
                        cursor: rentals.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.rentals.edges
                        const pageInfo = fetchMoreResult.rentals.pageInfo

                        return {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            rentals: {
                                edges: [...previousResult.rentals.edges, ...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.rentals.__typename
                            },
                        }
                    },
                })
            },
        }
    )
})(withApolloClient(RentalList))
