import React, { useState } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import {
    Row, Col,
    Form, Input, FormGroup, Button, Label, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter,

} from "reactstrap"
import { BoardTypeAhead, CarTypeAhead, DriverTypeAhead, RepsTypeAhead } from "./TypeAheadComponents"
import { CreateTaskMutation } from "./Mutations"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

function NewTaskModal({ isOpen, closeModal, ...props }) {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [driverSearchTerm, setDriverSearchTerm] = useState("")
    const [driverId, setDriverId] = useState("")
    const [vehicleSearchTerm, setVehicleSearchTerm] = useState("")
    const [vehicleId, setVehicleId] = useState("")
    const [repsSearchTerm, setRepsSearchTerm] = useState("")
    const [boardSearchTerm, setBoardSearchTerm] = useState("")
    const [boardId, setBoardId] = useState("")
    const [repIds, setRepIds] = useState([])
    const [dueDate, setDueDate] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)


    const createTask = (e) => {
        e.preventDefault()
        try {
            if (!props.apolloClient) {
                throw new Error("GQL Client not found")
            }
            if (!boardId) {
                setError("Board is required")
                return
            }
            // const { apolloClient } = this.props;
            if (!props.apolloClient) {
                throw new Error("GQL Client not found")
            }
            if (!title || title.length < 4) {
                setError("Title is required or too short")
                return
            }
            setLoading(true)
            const input = {
                title: title,
                description: description,
                driverId: driverId,
                vehicleId: vehicleId,
                assignedToIds: repIds,
                dueDate: dueDate || null,
                boardId: boardId
            }

            props.apolloClient.mutate({
                mutation: CreateTaskMutation,
                refetchQueries: ["AllTasks"],
                variables: { input },
            }).then((result) => {
                setLoading(false)
                if (!result) {
                    throw new Error("Error creating task")
                }
                if (result.data && result.data.createTask && result.data.createTask.ok) {
                    setError("")
                    closeModal("")
                } else {
                    const errorMessage = result.data && result.data.createTask && result.data.createTask.errors && result.data.createTask.errors.messages ? result.data.createTask.errors.messages[0] : "Unknown Error"
                    throw new Error(errorMessage)
                }
            }).catch((error) => {
                setError("Error creating task" + error.toString())
                setLoading(false)
            })
        } catch (error) {
            console.log("Error creating task", error)
            setError("Error creating task" + error.toString())
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} className="">
            <ModalHeader>Create Task</ModalHeader>
            <ModalBody>
                <Form onSubmit={createTask}>
                    <Row>
                        {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                        <Col xs={12}><FormGroup>
                            <Label for="repIds">Board *</Label>
                            <BoardTypeAhead searchTerm={boardSearchTerm} setSearchTerm={setBoardSearchTerm} onChange={setBoardId} />
                        </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="title">Title *</Label>
                                <Input type="string" name="title" id="title" placeholder="What needs to be done" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input type="string" name="description" id="description" placeholder="Add more details" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}><FormGroup>
                            <Label for="repIds">Assigned To</Label>
                            <RepsTypeAhead searchTerm={repsSearchTerm} setSearchTerm={setRepsSearchTerm} onChange={(selectedRepIds) => { setRepIds(selectedRepIds) }} />
                        </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="driverId">Driver</Label>
                                <DriverTypeAhead searchTerm={driverSearchTerm} setSearchTerm={setDriverSearchTerm} onChange={setDriverId} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}><FormGroup>
                            <Label for="vehicleId">Vehicle</Label>
                            <CarTypeAhead searchTerm={vehicleSearchTerm} setSearchTerm={setVehicleSearchTerm} onChange={setVehicleId} />
                        </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="dueDate">Due Date</Label>
                                <Input type="date" name="dueDate" id="dueDate" placeholder="Due Date" min={new Date()} value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                            </FormGroup>
                        </Col>


                    </Row>
                    <br />
                    <ModalFooter>
                        {loading ?
                            <Button color="primary" disabled={true}>Creating Task...</Button> :
                            <Button color="primary" type="submit" disabled={!title || loading}>Create Task</Button>}
                        <Button color="secondary" onClick={closeModal}>Close</Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default (withApolloClient(NewTaskModal))
