import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert, DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem } from 'reactstrap'
import Select from 'react-select'
import moment from 'moment-timezone'
import toTitleCase from "../../Functions/toTitleCase"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const UpdateTransaction = gql`
mutation updateTransaction($input: UpdateTransactionMutationInput!){
    updateTransaction(input:$input){
        ok
        errors{ messages }
    }
} `

const DriversQuery = gql`query DriversQuery($searchTerm: String){
    drivers: allDrivers(searchTerm: $searchTerm,first:10){
      edges{
        node{
          id
          name
          tlcLicense
          phone
        }
      }
} }`

const UpdateImmutableTransactionFields = gql`
mutation updateImmutableTransactionFields($input: UpdateImmutableTransactionFieldsMutationInput!){
    updateImmutableTransactionFields(input:$input){
        ok
        errors{ messages }
    }
} `

const AllRentals = gql`query AllRentals($driverId:ID!) {
    allRentals(driverId:$driverId,orderBy:["-start_date"]){
      edges{
        node{
          id
          startDate
          endDate
          car {
            id
            pk
            carModel{
                id
                name
                make
            }
          }
        }
      }
    } 
  }`
const AllBranchesQuery = gql`
  query AllBranches($tenantId:ID!){
    allBranches(tenantId:$tenantId, orderBy:["name"]){
        edges{
            node{
                id
                name
            }
        }
    }
  }
`

class UpdateTransactionInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            driverSuggestions: null,
            loading: false,
            openModal: '',
            selectedDriver: null,
        }
    }
    updateTransaction = () => {
        if (this.props.transactionId) {
            this.setState({ loading: true })
            let input = this.state.input
            let mutation = UpdateTransaction
            let resultKey = 'updateTransaction'
            input['transactionId'] = this.props.transactionId

            if (['chargeTypeId', 'amount'].includes(this.props.name)) {
                input['updateForId'] = this.props.transactionId
                delete input.transactionId
                mutation = UpdateImmutableTransactionFields
                resultKey = 'updateImmutableTransactionFields'
            }

            this.props.apolloClient.mutate({
                mutation: mutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data[resultKey] && result.data[resultKey].ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else {
                    let error = "An error has occurred. Please check your input or contact admin."
                    if (result.data && result.data[resultKey] && result.data[resultKey].errors && result.data[resultKey].errors[0] && result.data[resultKey].errors[0].messages[0]) {
                        error = result.data[resultKey].errors[0].messages[0]
                    }
                    this.setState({ loading: false, error: error })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
            this.setState({ loading: false, error: "Unable to submit your request. Please contact admin" })
        }
    }
    updateDriverSearchInput = (e) => {
        if (e.target.value.length >= 6) {
            this.props.apolloClient.query({
                query: DriversQuery,
                variables: { orderBy: ["first_name"], searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.drivers && response.data.drivers.edges) {
                    this.setState({ driverSuggestions: response.data.drivers.edges })
                } else {
                    this.setState({ driverSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ driverSuggestions: null })
            })
        } else {
            this.setState({ driverSuggestions: null })
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateInput = (e) => {
        if (this.props.transactionId) {
            let name = e.target.name
            let value = e.target.value
            let type = e.target.type
            let input = this.state.input
            if (type === "checkbox") {
                input[name] = e.target.checked
            } else {
                input[name] = value
            }
            this.setState({ input: input })
        }
    }
    updateSelectedDriver = (driver) => {
        if (this.props.transactionId) {
            let input = this.state.input
            input["driverId"] = driver.id
            this.setState({ input: input, selectedDriver: driver })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} style={{ minWidth: "250px" }}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        {this.props.name === "driver" ?
                            <FormGroup>
                                <Label>{this.state.selectedDriver ? "Selected driver: " + this.state.selectedDriver.name : "No driver selected"}</Label><br />
                                <ButtonDropdown isOpen={this.state.openModal === "DriverFinder"} toggle={() => this.toggleModal("DriverFinder")}>
                                    <DropdownToggle caret>Choose driver for this transaction</DropdownToggle>
                                    <DropdownMenu>
                                        <Label for="driverSearch">Search by name, tlc license, email or phone</Label>
                                        <Input type="text" onChange={this.updateDriverSearchInput} name="driverSearch" id="driverSearch" />
                                        {this.state.driverSuggestions && this.state.driverSuggestions.length > 0 && this.state.driverSuggestions.map((driver, i) =>
                                            <DropdownItem key={i} onClick={() => this.updateSelectedDriver(driver.node)}>{driver.node.name} · {driver.node.tlcLicense}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </FormGroup> :
                            this.props.name == "branchId" ?
                                <FormGroup>
                                    <Label>Search By Name</Label>
                                    {this.props.allBranches && this.props.allBranches.edges ? <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        defaultValue={this.props.value && this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(branch => branch.node.id === this.props.value).map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                        placeholder="Choose Branch"
                                        onChange={(branch) => this.setState({ input: { ...this.state.input, [this.props.name]: branch && branch.value ? branch.value : null } })}
                                    /> : <><br />Loading Branches...</>}
                                </FormGroup>
                                : this.props.name == "agreementId" ?
                                    <FormGroup>
                                        <Label>Search By Car ID</Label>
                                        {this.props.allRentals && this.props.allRentals.edges ? <Select
                                            className="bos-custom-select"
                                            classNamePrefix="bos-select"
                                            defaultValue={this.props.value && this.props.allRentals && this.props.allRentals.edges && this.props.allRentals.edges.length > 0 && this.props.allRentals.edges.filter(rental => rental.node.id === this.props.value).map(rental => ({ value: rental.node.id, label: <>{rental.node.car ? rental.node.car.pk : ""}{rental.node.car.carModel && rental.node.car.carModel.make != null ? " - " + toTitleCase(rental.node.car.carModel.make) : ""} {rental.node.car.carModel && rental.node.car.carModel.name != null ? toTitleCase(rental.node.car.carModel.name) : ""}<br /><small>{moment(rental.node.startDate).format('ll')} to {rental.node.endDate ? moment(rental.node.endDate).format('ll') : "--"}</small></> }))}
                                            options={this.props.allRentals && this.props.allRentals.edges && this.props.allRentals.edges.length > 0 && this.props.allRentals.edges.map(rental => ({ value: rental.node.id, label: <>{rental.node.car ? rental.node.car.pk : ""}{rental.node.car.carModel && rental.node.car.carModel.make != null ? " - " + toTitleCase(rental.node.car.carModel.make) : ""} {rental.node.car.carModel && rental.node.car.carModel.name != null ? toTitleCase(rental.node.car.carModel.name) : ""}<br /><small>{moment(rental.node.startDate).format('ll')} to {rental.node.endDate ? moment(rental.node.endDate).format('ll') : "--"}</small></> }))}
                                            placeholder="Choose Agreement"
                                            isClearable={true}
                                            onChange={(rental) => this.setState({ input: { ...this.state.input, [this.props.name]: rental && rental.value ? rental.value : null } })}
                                        /> : <><br />Loading Driver Rentals...</>}
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label for={this.props.name}>{this.props.title}</Label>
                                        <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                            name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                            placeholder={this.props.title} onChange={this.updateInput}
                                        />
                                    </FormGroup>
                        }
                        {(this.props.name === "chargeTypeId" || this.props.name === "amount") &&
                            <FormGroup>
                                <Label for="changeReason">Details on why the transaction is being changed</Label>
                                <Input type="text" name="changeReason" id="changeReason" placeholder="Why are you change this transaction" onChange={this.updateInput} />
                                {this.props.canForceUpdate && <>
                                    <br />
                                    <span className="ml-4">
                                        <Input type="checkbox" name="ignoreDaysLimit" id="ignoreDaysLimit" onChange={this.updateInput} /> Ignore days limit
                                    </span><br />
                                    <span className="ml-4">
                                        <Input type="checkbox" name="ignoreUpdateCheck" id="ignoreUpdateCheck" onChange={this.updateInput} /> Ignore update check
                                    </span>
                                </>}
                            </FormGroup>
                        }

                        {this.state.loading ?
                            <Progress animated color="info" value={100} /> :
                            <><Button type="button" size="sm" className="float-right" disabled={!this.props.transactionId || (this.props.name === "amount" && (!this.state.input["changeReason"] || this.state.input["changeReason"].length < 5))} onClick={this.updateTransaction}>Submit</Button><br /></>
                        }
                    </PopoverBody>
                    <br />
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllRentals, {
    skip: ({ name }) => name !== "agreementId",
    options: ({ driverId }) => ({ variables: { driverId }, fetchPolicy: 'cache-and-network' }),
    props: ({ data: { allRentals, loading } }) => ({
        allRentals,
        loading
    })
})(graphql(AllBranchesQuery, {
    skip: ({ name }) => name !== "branchId",
    options: ({ tenantId }) => ({ variables: { tenantId }, fetchPolicy: 'cache-first' }),
    props: ({ data: { loading, allBranches } }) => ({
        loading,
        allBranches
    })
})(withApolloClient(UpdateTransactionInfo)))
