import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import './MergeDriverModal.css'
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap'
import Badge from "reactstrap/lib/Badge"
import { Link } from 'react-router-dom'
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const TransferDriverDataToOtherDriver = gql`
mutation transferDriverDataToOtherDriver($input: TransferDriverDataToOtherDriverInput!){
    transferDriverDataToOtherDriver(input:$input){
        ok
        errors{
            messages
        }
    }
}`


const DriverQueryWithRelatedProfileId = gql`query Driver($id: ID) {
    driverQueryWithRelatedProfileId: driver(id: $id){
        id
        pk 
        dmvLicense
        name
        branch{
            id
            name
        }
        customerhandleSet {
            edges {
                    node {
                        id
                        type
                        handle
                        isPrimary
                        isArchived
                    }
            }
        }
    } 
}`

const DriverQueryWithParamId = gql`query Driver($id: ID) {
    driverQueryWithParamId: driver(id: $id){
        id
        pk 
        dmvLicense
        name
        branch{
            id
            name
        }
        customerhandleSet {
            edges {
                    node {
                        id
                        type
                        handle
                        isPrimary
                        isArchived
                    }
            }
        }
    } 
}`


class MergeDriverModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            messageSuccess: false,
            fromDriverId: this.props.fromDriverId,
            toDriverId: this.props.toDriverId,
            fromDriverData: null,
            toDriverData: null,
            fromDriverEmailHandles: [],
            fromDriverPhoneHandles: [],
            toDriverEmailHandles: [],
            toDriverPhoneHandles: [],
            primaryEmailHandle: "",
            primaryPhoneHandle: "",
            primaryDmvLicense: "",
        }
    }

    updateInput = (name, value) => {
        this.setState({ [name]: value })
    };

    handleClose = (e) => {
        let state = {
            useCannedMessage: false,
            loading: false,
            error: null,
        }
        this.setState({ ...state })
        this.props.handleClose()
    };

    mergeDrivers = () => {
        if (this.props.fromDriverId && this.props.toDriverId && this.state.primaryEmailHandle && this.state.primaryPhoneHandle && this.state.primaryDmvLicense) {
            try {
                this.setState({ loading: true })
                let input = {
                    fromDriverId: this.state.fromDriverId,
                    toDriverId: this.state.toDriverId,
                    primaryEmailHandleId: this.state.primaryEmailHandle || "",
                    primaryPhoneHandleId: this.state.primaryPhoneHandle || "",
                    dmvLicense: this.state.primaryDmvLicense || "",
                }
                this.props.apolloClient.mutate({
                    mutation: TransferDriverDataToOtherDriver,
                    variables: { input: input },
                }).then((result) => {
                    if (result.data && result.data.transferDriverDataToOtherDriver && result.data.transferDriverDataToOtherDriver.ok) {
                        this.setState({ messageSuccess: true })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let error = "An error has occured. Please contact system admin"
                        if (result.data && result.data.transferDriverDataToOtherDriver && result.data.transferDriverDataToOtherDriver.errors && result.data.transferDriverDataToOtherDriver.errors[0] && result.data.transferDriverDataToOtherDriver.errors[0].messages[0]) {
                            error = result.data.transferDriverDataToOtherDriver.errors[0].messages[0]
                        }
                        this.setState({ loading: false, messageSuccess: false, error: error })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occured: " + error
                    this.setState({ error: errorMessage, loading: false, messageSuccess: false, })
                })
            } catch (err) {
                let errorMessage = "An error has occured: " + err
                this.setState({ error: errorMessage, loading: false, messageSuccess: false, })
            }
        } else {
            let errorMessage = "Error: Missing Required Field"
            this.setState({ error: errorMessage, loading: false, messageSuccess: false, })
        }
    };

    swapHandles = () => {
        const { fromDriverEmailHandles, fromDriverPhoneHandles, toDriverEmailHandles, toDriverPhoneHandles, toDriverId, toDriverData, fromDriverId, fromDriverData } = this.state

        const tempEmailHandles = [...fromDriverEmailHandles]
        this.setState({ fromDriverEmailHandles: [...toDriverEmailHandles], toDriverEmailHandles: tempEmailHandles, fromDriverId: toDriverId, fromDriverData: toDriverData })

        const tempPhoneHandles = [...fromDriverPhoneHandles]
        this.setState({ fromDriverPhoneHandles: [...toDriverPhoneHandles], toDriverPhoneHandles: tempPhoneHandles, toDriverId: fromDriverId, toDriverData: fromDriverData })
    };

    componentDidUpdate(prevProps) {
        if (this.props.driverQueryWithRelatedProfileId !== prevProps.driverQueryWithRelatedProfileId) {
            let fromDriverEmailHandles = []
            let fromDriverPhoneHandles = []
            if (this.props.driverQueryWithRelatedProfileId && this.props.driverQueryWithRelatedProfileId.customerhandleSet && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.length > 0 && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").length > 0) {
                this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item) =>
                    fromDriverEmailHandles.push({ id: item.node.id, value: item.node.handle })
                )
            }
            if (this.props.driverQueryWithRelatedProfileId && this.props.driverQueryWithRelatedProfileId.customerhandleSet && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.length > 0 && this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.filter(item => item.node.type === "PHONE").length > 0) {
                this.props.driverQueryWithRelatedProfileId.customerhandleSet.edges.filter(item => item.node.type === "PHONE").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item) =>
                    fromDriverPhoneHandles.push({ id: item.node.id, value: item.node.handle })
                )
            }
            this.setState({ fromDriverEmailHandles: fromDriverEmailHandles, fromDriverPhoneHandles: fromDriverPhoneHandles, fromDriverData: this.props.driverQueryWithRelatedProfileId })
        }
        if (this.props.driverQueryWithParamId !== prevProps.driverQueryWithParamId) {
            let toDriverEmailHandles = []
            let toDriverPhoneHandles = []
            if (this.props.driverQueryWithParamId && this.props.driverQueryWithParamId.customerhandleSet && this.props.driverQueryWithParamId.customerhandleSet.edges && this.props.driverQueryWithParamId.customerhandleSet.edges.length > 0 && this.props.driverQueryWithParamId.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").length > 0) {
                this.props.driverQueryWithParamId.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item) =>
                    toDriverEmailHandles.push({ id: item.node.id, value: item.node.handle })
                )
            }
            if (this.props.driverQueryWithParamId && this.props.driverQueryWithParamId.customerhandleSet && this.props.driverQueryWithParamId.customerhandleSet.edges && this.props.driverQueryWithParamId.customerhandleSet.edges.length > 0 && this.props.driverQueryWithParamId.customerhandleSet.edges.filter(item => item.node.type === "PHONE").length > 0) {
                this.props.driverQueryWithParamId.customerhandleSet.edges.filter(item => item.node.type === "PHONE").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item) =>
                    toDriverPhoneHandles.push({ id: item.node.id, value: item.node.handle })
                )
            }
            this.setState({ toDriverEmailHandles: toDriverEmailHandles, toDriverPhoneHandles: toDriverPhoneHandles, toDriverData: this.props.driverQueryWithParamId })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Modal className="merge-driver-modal" isOpen={this.props.open}>
                    <ModalHeader>
                        MERGE DRIVER
                    </ModalHeader>
                    <ModalBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        {this.state.messageSuccess && <Row><Col><Alert color="success">Message sent successfully!</Alert></Col></Row>}
                        {(this.props.loading || this.state.loading) ? <Progress animated color="info" value="100" /> : <>
                            <Row>
                                <Col xs={5}>
                                    {this.state.fromDriverData && <>
                                        <h4 style={{ color: "#2DAFD3" }}>From</h4>
                                        <h5><Link color="secondary" to={'/driver/' + this.state.fromDriverData.id} target="_blank">{this.state.fromDriverData && this.state.fromDriverData.name ? " (" + this.state.fromDriverData.pk + ")" : "--"} {this.state.fromDriverData && this.state.fromDriverData.name ? this.state.fromDriverData.name : "--"}</Link></h5>
                                        <Badge>{this.state.fromDriverData && this.state.fromDriverData.branch && this.state.fromDriverData.branch ? this.state.fromDriverData.branch.name : "--"}</Badge>&nbsp;
                                        <Badge color={this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk ? "success" : "danger"}>{this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk ? "Old Profile" : "New Profile"}</Badge>
                                    </>}
                                </Col>
                                <Col xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Button id={"moveDriver"} onClick={this.swapHandles}><i className="fa fa-exchange" aria-hidden="true"></i></Button>
                                    <UncontrolledTooltip target={"moveDriver"}>Change Merge Direction</UncontrolledTooltip>
                                </Col>
                                <Col xs={5}>
                                    {this.state.toDriverData && <>
                                        <h4 style={{ color: "#2DAFD3" }}>To</h4>
                                        <h5><Link color="secondary" to={'/driver/' + this.state.toDriverData.id} target="_blank">{this.state.toDriverData && this.state.toDriverData.name ? " (" + this.state.toDriverData.pk + ")" : "--"} {this.state.toDriverData && this.state.toDriverData.name ? this.state.toDriverData.name : "--"}</Link></h5>
                                        <Badge>{this.state.toDriverData && this.state.toDriverData.branch && this.state.toDriverData.branch ? this.state.toDriverData.branch.name : "--"}</Badge>&nbsp;
                                        <Badge color={this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk ? "danger" : "success"}>{this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk ? "New Profile" : "Old Profile"}</Badge>
                                    </>}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label style={{ textTransform: "uppercase" }}><b>Choose Primary Email Handles</b></Label>
                                            {this.state.fromDriverEmailHandles && this.state.fromDriverEmailHandles.length > 0 ? this.state.fromDriverEmailHandles.map((item) =>
                                                <FormGroup tag="primaryEmailHandle">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="primaryEmailHandle"
                                                                onChange={() => this.updateInput("primaryEmailHandle", item.id)}
                                                            />{' '}{item.value ? item.value : "--"}
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            ) : <p>No Email Handle Found</p>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}></Col>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label></Label>
                                            {this.state.toDriverEmailHandles && this.state.toDriverEmailHandles.length > 0 ? this.state.toDriverEmailHandles.map((item) =>
                                                <FormGroup tag="primaryEmailHandle">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="primaryEmailHandle"
                                                                onChange={() => this.updateInput("primaryEmailHandle", item.id)}
                                                            />{' '}{item.value ? item.value : "--"}
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            ) : <p>No Email Handle Found</p>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label style={{ textTransform: "uppercase" }}><b>Choose Primary Phone Handles</b></Label>
                                            {this.state.fromDriverPhoneHandles && this.state.fromDriverPhoneHandles.length > 0 ? this.state.fromDriverPhoneHandles.map((item) =>
                                                <FormGroup tag="primaryPhoneHandle">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="primaryPhoneHandle"
                                                                onChange={() => this.updateInput("primaryPhoneHandle", item.id)}
                                                            />{' '}{item.value ? item.value : "--"}
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            ) : <p>No Phone Handle Found</p>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}></Col>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label></Label>
                                            {this.state.toDriverPhoneHandles && this.state.toDriverPhoneHandles.length > 0 ? this.state.toDriverPhoneHandles.map((item) =>
                                                <FormGroup tag="primaryPhoneHandle">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="primaryPhoneHandle"
                                                                onChange={() => this.updateInput("primaryPhoneHandle", item.id)}
                                                            />{' '}{item.value ? item.value : "--"}
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            ) : <p>No Phone Handle Found</p>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label style={{ textTransform: "uppercase" }}><b>DMV License</b></Label>
                                            <FormGroup tag="primaryDmvLicense">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="primaryDmvLicense"
                                                            onChange={() => this.updateInput("primaryDmvLicense", this.state.fromDriverData.dmvLicense)}
                                                        />{' '}{this.state.fromDriverData && this.state.fromDriverData.dmvLicense ? this.state.fromDriverData.dmvLicense : "--"}
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}></Col>
                                <Col xs={5}>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Label></Label>
                                            <FormGroup tag="primaryDmvLicense">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="primaryDmvLicense"
                                                            onChange={() => this.updateInput("primaryDmvLicense", this.state.toDriverData.dmvLicense)}
                                                        />{' '}{this.state.toDriverData && this.state.toDriverData.dmvLicense ? this.state.toDriverData.dmvLicense : "--"}
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br /><br />
                            {((this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk) || !this.state.primaryDmvLicense || !this.state.primaryEmailHandle || !this.state.primaryPhoneHandle) &&
                                <Row>
                                    <Col xs={12}>
                                        <Alert color="danger">Ensure all items are selected and make sure that you are merging new profile into old profile.</Alert>
                                    </Col>
                                </Row>
                            }
                        </>}
                    </ModalBody>
                    <ModalFooter>
                        {!(this.state.loading || this.props.loading) &&
                            <Row>
                                <Col xs={12}>
                                    <Button className="merge-driver-modal-secondary-btn" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button className="merge-driver-modal-primary-btn" onClick={this.mergeDrivers} id={"disabledBtn"} disabled={((this.state.fromDriverData && this.state.toDriverData && this.state.fromDriverData.pk < this.state.toDriverData.pk) || !this.state.primaryDmvLicense || !this.state.primaryEmailHandle || !this.state.primaryPhoneHandle)}>Merge Drivers</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>

            </div >
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(DriverQueryWithRelatedProfileId, {
    options: ({ fromDriverId }) => ({
        variables: { id: fromDriverId },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    }),
    props: ({ data: { driverQueryWithRelatedProfileId, loading, refetch, variables } }) => ({
        driverQueryWithRelatedProfileId,
        loading,
        variables,
        refetchDriverQuery: () => refetch({
            query: DriverQueryWithRelatedProfileId,
            variables: { ...variables },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
                driverQueryWithRelatedProfileId: fetchMoreResult.driverQueryWithRelatedProfileId,
            }),
        }),
    }),
})(
    graphql(DriverQueryWithParamId, {
        options: ({ toDriverId }) => ({
            variables: { id: toDriverId },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { driverQueryWithParamId, loading, refetch, variables } }) => ({
            driverQueryWithParamId,
            loading,
            variables,
            refetchDriverQuery: () => refetch({
                query: DriverQueryWithParamId,
                variables: { ...variables },
                updateQuery: (previousResult, { fetchMoreResult }) => ({
                    DriverQueryWithParamId: fetchMoreResult.DriverQueryWithParamId,
                }),
            }),
        }),
    })(
        withApolloClient(MergeDriverModal)
    )
);


