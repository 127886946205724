import React, { Component, Fragment } from 'react'
import { Nav, NavItem, NavLink, Row, Col, Button, Label, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import '../DriverDetailNew.css'
import { CarlessmilesDiscountQuery, UberTripsQuery } from "../../Queries"
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import moment from 'moment'
import withApolloClient from "../../../../../withApolloClient"

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        name
        phone
        email
        dmvLicense
        tlcLicense
        canDelete
        history{
            date
            comment
            user
          }
        actionSet(first:10,orderBy:"-date_added"){
            edges{
              node{
                actionType
                status
                dateAdded
                representative
              }
            }
        }
        mobileappdriver {
            id
        }
        tags{
            id
            name
        }
        branch{
            id
            name
            defaultMileageUnit
        }
    }
}
`

class HistoryTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            totalMiles: 0,
            addedMiles: [],
        }
    }

    toggleAddMiles = (node) => {
        if (this.state.addedMiles && this.state.addedMiles.includes(node.id)) {
            let newAddedMiles = this.state.addedMiles.filter(item => item !== node.id)
            let totalMiles = parseFloat(this.state.totalMiles) - parseFloat(node.mileageDifference)
            totalMiles = totalMiles.toFixed(2)
            this.setState({ addedMiles: newAddedMiles, totalMiles: totalMiles })
        } else {
            let newAddedMiles = this.state.addedMiles
            newAddedMiles.push(node.id)
            let totalMiles = parseFloat(this.state.totalMiles) + parseFloat(node.mileageDifference)
            totalMiles = totalMiles.toFixed(2)
            this.setState({ addedMiles: newAddedMiles, totalMiles: totalMiles })
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        let { driver } = this.props
        return (
            <div className="tab-container">
                <h4>DRIVER PROFILE HISTORY</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={3}><Label>Date</Label></Col>
                                <Col xs={3}><Label>Comment</Label></Col>
                                <Col xs={3}><Label>Rep</Label></Col>
                            </Row>
                            {driver && driver.history && driver.history.length > 0 ? driver.history.map((history, i) =>
                                <Row key={i}>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.date ? <DatetimeRenderer datetime={history.date} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.comment || ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.user || ""}</p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No profile history recorded for driver</p></Col></Row>
                            }
                        </Col>
                    </Row>
                </div>
                <br /><br />
                <h4>DISABLE - ENABLE HISTORY</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={3}><Label>Date Added</Label></Col>
                                <Col xs={3}><Label>Type</Label></Col>
                                <Col xs={3}><Label>Status</Label></Col>
                                <Col xs={3}><Label>Rep</Label></Col>
                            </Row>
                            {driver && driver.actionSet && driver.actionSet.edges && driver.actionSet.edges.length > 0 ? driver.actionSet.edges.map((action, i) =>
                                <Row key={i}>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.dateAdded ? <DatetimeRenderer datetime={action.node.dateAdded} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.actionType ? action.node.actionType : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.status ? action.node.status : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.representative ? action.node.representative : ""}</p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No Enable Disable History Found</p></Col></Row>
                            }
                        </Col>
                    </Row>
                </div>
                <br /><br />
                <Row>
                    <Col xs={6}>
                        <h4>MILEAGE HISTORY</h4>
                        <div className="info-container set-max-height">
                            <Row>
                                <Col>
                                    {this.state.totalMiles > 0 &&
                                        <Row>
                                            <Col xs={12}>
                                                <div style={{ border: "1px solid black", float: "right", padding: "6px", borderRadius: "20px", backgroundColor: "#e0e0e0" }}>
                                                    Total Miles:  {driver && this.state.totalMiles ? driver.branch.defaultMileageUnit === "KM" ? Math.round(this.state.totalMiles * 1.60934) + "KM" : this.state.totalMiles + "MI" : "--"}
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col xs={2}>
                                            <Label id={"action"}>Action</Label>
                                            <UncontrolledTooltip placement="right" target={"action"}>Click on checkbox to calculate total miles</UncontrolledTooltip>
                                        </Col>
                                        <Col><Label>Car ID</Label></Col>
                                        <Col><Label>Date</Label></Col>
                                        <Col><Label>Car Driven</Label></Col>
                                    </Row>
                                    {this.props.allCarlessmilesDiscount && this.props.allCarlessmilesDiscount.edges && this.props.allCarlessmilesDiscount.edges.length > 0 ? this.props.allCarlessmilesDiscount.edges.map((miles, i) =>
                                        <Row key={i}>
                                            <Col xs={2}>
                                                <span className="listing-checkbox" onClick={() => this.toggleAddMiles(miles.node)}>
                                                    {this.state.addedMiles.includes(miles.node.id) ? (
                                                        <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fa fa-square-o unchecked" aria-hidden="true"></i>
                                                    )}
                                                </span>
                                            </Col>
                                            <Col>
                                                <p className="columnContent">
                                                    {miles.node.car ? miles.node.car.pk : "---"}&nbsp;
                                                    {miles.node.car && miles.node.car.pk && <small>&nbsp;<Link color="primary" to={'/car/' + miles.node.car.pk} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></small>}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="columnContent">{miles.node.dateRecorded ? <DatetimeRenderer datetime={miles.node.dateRecorded} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                            </Col>
                                            <Col>
                                                <p className="columnContent">{driver && miles.node.mileageDifference ? driver.branch.defaultMileageUnit === "KM"
                                                    ? Math.round(miles.node.mileageDifference * 1.60934) + "KM"
                                                    : miles.node.mileageDifference + "MI"
                                                    : "--"} &nbsp;&nbsp;
                                                </p>
                                            </Col>
                                        </Row>
                                    ) : <Row><Col><p>No Mileage History Found</p></Col></Row>
                                    }
                                </Col>
                                {this.props.allCarlessmilesDiscount && this.props.allCarlessmilesDiscount.edges && this.props.allCarlessmilesDiscount.pageInfo && this.props.allCarlessmilesDiscount.pageInfo.hasNextPage &&
                                    <Button size="sm" onClick={this.props.loadMoreCarlessmilesDiscount} className="primary">Show More...</Button>
                                }
                            </Row>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <h4>UBER TRIPS</h4>
                        <div className="info-container set-max-height">
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={3}><Label>Date</Label></Col>
                                        <Col xs={7}><Label>Car Vin</Label></Col>
                                        <Col xs={2}><Label>Trips</Label></Col>
                                    </Row>
                                    {this.props.allUberTrips && this.props.allUberTrips.edges && this.props.allUberTrips.edges.length > 0 ? this.props.allUberTrips.edges.map((uberTrip, i) =>
                                        <Row key={i}>
                                            <Col xs={3}>
                                                <p className="columnContent">{uberTrip.node.tripWeek ? <DatetimeRenderer datetime={uberTrip.node.tripWeek} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                            </Col>
                                            <Col xs={7}>
                                                <p className="columnContent">{uberTrip.node.vin}</p>
                                            </Col>
                                            <Col xs={2}>
                                                <p className="columnContent">{uberTrip.node.tripCount}</p>
                                            </Col>
                                        </Row>
                                    ) : <Row><Col><p>No Uber Trip Found</p></Col></Row>
                                    }
                                    {this.props.allUberTrips && this.props.allUberTrips.edges && this.props.allUberTrips.pageInfo && this.props.allUberTrips.pageInfo.hasNextPage &&
                                        <Button size="sm" onClick={this.props.loadMoreUberTrips} className={"ghost"}>Show More...</Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <br /><br />

            </div>
        )
    }
}

export default graphql(DriverQuery, {
    options: ({ id }) => ({
        variables: { id },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }),
    props({ data: { driver, loading, refetch, variables } }) {
        return {
            driver,
            loading,
            variables,
            refetchDriverQuery: () => {
                return refetch({
                    query: DriverQuery,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return { driver: fetchMoreResult.driver }
                    },
                })
            },
        }
    },
})(
    graphql(CarlessmilesDiscountQuery, {
        options: ({ id }) => ({ variables: { driverId: id, first: 8 } }),
        props({ data: { allCarlessmilesDiscount, fetchMore, loading, variables } }) {
            return {
                allCarlessmilesDiscount,
                loading,
                variables,
                loadMoreCarlessmilesDiscount: () => {
                    return fetchMore({
                        query: CarlessmilesDiscountQuery,
                        variables: {
                            cursor: allCarlessmilesDiscount.pageInfo.endCursor,
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarlessmilesDiscount.edges
                            const pageInfo = fetchMoreResult.allCarlessmilesDiscount.pageInfo

                            return {
                                allCarlessmilesDiscount: {
                                    edges: [...previousResult.allCarlessmilesDiscount.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allCarlessmilesDiscount.__typename,
                                },
                            }
                        },
                    })
                },
            }
        },
    })(
        graphql(UberTripsQuery, {
            options: ({ id }) => ({ variables: { driverId: id, first: 10 } }),
            props({ data: { allUberTrips, fetchMore, loading, variables } }) {
                return {
                    allUberTrips,
                    loading,
                    variables,
                    loadMoreUberTrips: () => {
                        return fetchMore({
                            query: UberTripsQuery,
                            variables: {
                                cursor: allUberTrips.pageInfo.endCursor,
                                ...variables,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allUberTrips.edges
                                const pageInfo = fetchMoreResult.allUberTrips.pageInfo
                                return {
                                    allUberTrips: {
                                        edges: [...previousResult.allUberTrips.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.allUberTrips.__typename,
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })(withApolloClient(HistoryTab))
    )
)