import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody, Button, Input } from 'reactstrap'
import AttributeConfigurations from './AttributeConfigurations'
import AttributeList from './AttributeList'
import AttributeModal from './AttributeModal'
import Alert from 'reactstrap/lib/Alert'
import './Attribute.css'
import { debounce } from 'lodash'
import { HasPermissionsQuery } from "../Functions/querys"
import withApolloClient from '../withApolloClient'

class AttributeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            filters: { searchTerm: "" },
            userPermissions: null,
            searchInput: ""
        }
        this.debouncedSearch = debounce(this.handleSearch, 500)
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) this.setState({ openModal: "" })
        else this.setState({ openModal: modalName })
    }

    getAttributePermissions = (attributeName) => {
        let configurations = AttributeConfigurations[attributeName]
        this.props.apolloClient.query({
            query: HasPermissionsQuery,
            variables: { userPermissions: [configurations.permissions.get, configurations.permissions.post, configurations.permissions.update] },
        }).then((result) => {
            if (result && result.data && result.data.hasPermissions) {
                let userPermissions = result.data.hasPermissions
                if (!userPermissions) {
                    window.location = "/page_not_found"
                } else if (!userPermissions.includes(configurations.permissions.get) && !userPermissions.includes(configurations.permissions.post) && !userPermissions.includes(configurations.permissions.update)) {
                    window.location = "/page_not_found"
                } else {
                    this.setState({ userPermissions: userPermissions })
                }
            } else {
                this.setState({ userPermissions: [] })
            }
        }).catch((err) => { this.setState({ userPermissions: [] }) })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.name !== this.props.params.name) {
            this.setState({ searchInput: "" })
            this.setState({ filters: { ...this.state.filters, searchTerm: "" } })
            this.getAttributePermissions(this.props.params.name)
        }
    }

    componentDidMount() {
        this.getAttributePermissions(this.props.params.name)
    }

    handleSearch = (searchTerm) => {
        this.setState({ filters: { ...this.state.filters, searchTerm } })
    }

    handleInputChange = (e) => {
        const searchTerm = e.target.value
        this.setState({ searchInput: searchTerm })
        this.debouncedSearch(searchTerm)
    }

    render() {
        let configurations = AttributeConfigurations[this.props.params.name]
        return (
            <Container fluid className="bos-listing-container">
                <Card className="bos-listing-filters-card">
                    <CardBody>
                        {configurations ? <Row>
                            <Col xs={4}>
                                <span className="driver-search-filter">
                                    <Input
                                        value={this.state.searchInput}
                                        type="text"
                                        name="searchTerm"
                                        placeholder="Search"
                                        onChange={this.handleInputChange}
                                    />
                                    <i className="fa fa-lg fa-search search-icon" aria-hidden="true" style={{ pointerEvents: "none" }}></i>
                                </span>
                            </Col>
                            <Col xs={{ offset: 4, size: 4 }}>
                                {this.state.userPermissions && this.state.userPermissions.includes(configurations.permissions.post) && configurations && configurations.createMutation && configurations.createMutation.mutation && <Button className='bos-btn-primary float-right' color='primary' onClick={() => this.toggleModal("attributeModal")}>Create {configurations.title}</Button>}
                                {this.state.openModal && this.state.openModal === "attributeModal" && <AttributeModal configurations={configurations} open={this.state.openModal === "attributeModal"} handleClose={() => this.toggleModal("attributeModal")} />}
                            </Col>
                        </Row> : <Row><Col><Alert color='danger'>No configurations found for this attribute. Please contact admin to setup configurations!</Alert></Col></Row>}
                    </CardBody>
                </Card>

                {this.props.params.name && configurations && this.state.userPermissions && this.state.userPermissions.includes(configurations.permissions.get) && <AttributeList filters={this.state.filters} configurations={configurations} hasUpdatePermission={this.state.userPermissions && this.state.userPermissions.includes(configurations.permissions.update)} />}
            </Container>
        )
    }
}

export default withApolloClient(AttributeContainer)
