import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { useApolloClient } from '@apollo/client'
import { Button, Table, Progress, Card, CardBody, Row, Col, UncontrolledTooltip } from 'reactstrap'
import RegistrationModal from '../Registrations/RegistrationModal'
import { AllCarInspectionsQuery, InspectionsQuery, AllOwnersQuery } from '../Queries'
import toTitleCase from '../../Functions/toTitleCase'
import CarModelGroupIcon from '../../CA/CarModelGroupIcon'
import RegistrationNotesHistory from '../Registrations/RegistrationNotesHistory'
import CarInfoModal from '../../CA/CarInfoModal'
import InspectionModal from './InspectionModal'

const tableHeaders = [
    { id: "car__id", name: "Car Details", sortable: true },
    { id: "registration__id", name: "Registration Details", sortable: true },
    { id: "status", name: "Status", sortable: true },
    { id: "inspection_metadata", name: "Payment Date" },
    { id: "inspection_date", name: "Inspection Date", sortable: true },
    { id: "inspection__runner", name: "Runner" },
    { id: "inspection_notes", name: "Inspection Notes" },
]

class InspectionsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            error: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    renderRegistrationDetails = (registration) => {
        let metaObject = JSON.parse(registration.metadata.replace(/'/g, '"'))
        return <>
            <h4>{registration.number}&nbsp;&nbsp;&nbsp;<small>({registration.registrationType.name} - {registration.registrationType.stateCode})</small></h4>
            <small style={{ maxWidth: "300px", display: "block" }}>Expiration: {registration.expirationDate}</small>
        </>
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    renderCarDetails = (car, i = 0) => {
        return <div className='car-details-section'>
            <span className='left-box__'>
                {this.state.openModal === "CarInfo" + car.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + car.pk} id={car.pk} carPk={car.pk} carId={car.id} refetchQuery={this.props.refetchQuery} />}
                <a onClick={() => this.toggleModal("CarInfo" + car.pk)}>
                    <CarModelGroupIcon carGroup={car.carModel && car.carModel.groupType ? car.carModel.groupType.name : null} carColor={car.color} id={car.pk} key={i + 1} />
                </a>
                <br />
                <span>{car.pk}</span>
            </span>
            <span className='right-box__'>
                <small>{car.year} | {car.color} | {car.branch.name}&nbsp;&nbsp;<a href={"/car/" + car.pk} target="_blank"><i className='fa fa-external-link-square' /></a></small>
                <h4 className="text-left">{toTitleCase(car.carModel.make)} {toTitleCase(car.carModel.name)}
                    {/* <small>{car.carModel.series ? "("+car.carModel.series+")" : ""}</small> */}
                    {car.stage == 10 && <>
                        <i id={"driverIsActive" + i} className="fa fa-circle text-success" ></i>
                        <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                    </>}
                </h4>
            </span>
        </div>
    }


    render() {
        return <>
            <Card className="car-registration-table-container mt-2"><CardBody><Row><Col sm="12">
                {this.props.loading || this.state.loading ? <Progress animated color="info" value="100" /> : ""}
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allCarInspections && this.props.allCarInspections.edges && this.props.allCarInspections.edges.length > 0 ? this.props.allCarInspections.edges.map((inspection, i) =>
                            <tr>
                                <td>
                                    {this.renderCarDetails(inspection.node.car, i)}
                                </td>
                                <td>
                                    <div className="registration-details-wrapper">
                                        <span className='left-box'>
                                            {this.state.openModal && this.state.openModal === "editRegistration" + i && <RegistrationModal open={this.state.openModal === "editRegistration" + i} isUpdate registration={inspection.node.registration} handleClose={() => this.toggleModal("editRegistration" + i)} refetchQuery={this.props.refetchQuery} />}
                                            <i id={"editRegistration" + i} className="fa fa-pencil fa-lg" aria-hidden="true" onClick={() => this.toggleModal("editRegistration" + i)}></i>
                                            {/* <br /><small>{inspection.node.registration.registrationType.name} - {inspection.node.registration.registrationType.stateCode}</small> */}
                                        </span>
                                        <span className='right-box'>
                                            {this.renderRegistrationDetails(inspection.node.registration)}
                                        </span>
                                    </div>
                                    {/* {this.renderRegistrationDetails(inspection.node.registration)} */}
                                </td>
                                <td>{toTitleCase(inspection.node.status)}</td>
                                <td>{inspection.node.metadata ? this.fetchMetaData(inspection.node.metadata, "payment_date") : "No Date Selected!"}</td>
                                <td>{inspection.node.inspectionDate ? inspection.node.inspectionDate : "No Date Selected!"}</td>
                                <td>
                                    <h4 className={inspection.node.runner ? "" : "text-danger"}>{inspection.node.runner ? inspection.node.runner.firstName ? inspection.node.runner.firstName + " " + inspection.node.runner.lastName : inspection.node.runner.name : "Not Selected!"}</h4>
                                    {inspection.node.runner && <small>{inspection.node.runnerType === "auth | user" ? "Rep" : "Driver"}</small>}
                                </td>
                                <td>
                                    <div className="car-details-wrapper">
                                        <span className='left-box'>
                                            {this.state.openModal === "inspectionNotesHistory" + i && <RegistrationNotesHistory registration={inspection.node.registration} noteType="INSPECTION" title="Registration Notes History" objectId={inspection.node.id} target={"inspectionNotesHistory" + i} open={this.state.openModal === "inspectionNotesHistory" + i} handleClose={() => this.toggleModal("")} placement={"bottom"} />}
                                            <i className="fa fa-history fa-lg" aria-hidden="true" id={"inspectionNotesHistory" + i} onClick={() => this.toggleModal("inspectionNotesHistory" + i)}></i>
                                        </span>
                                        <span className='right-box'>
                                            {inspection.node.notes ? inspection.node.notes : "--"}
                                        </span>
                                    </div>
                                </td>
                                {/* <td style={{ maxWidth: "16rem" }}>
                                    {(() => {
                                        let metaObject = JSON.parse(inspection.node.metadata.replace(/'/g, '"'))
                                        return <small>{Object.keys(metaObject).map(item => toTitleCase(item.replace("_", " ")) + ": " + metaObject[item] + ", ")}</small>
                                    })()}
                                </td> */}
                                <td scope="row" className="text-center">
                                    <i id={"updateInspection" + i} className="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => this.toggleModal("updateInspection" + i)} />
                                    <UncontrolledTooltip placement="bottom" target={"updateInspection" + i}>Update Inspection</UncontrolledTooltip>
                                    {this.state.openModal === "updateInspection" + i && <InspectionModal isUpdate carInspection={inspection.node} open={this.state.openModal === "updateInspection" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                </td>
                                {/* <td>
                                    <div className="registration-details-wrapper">
                                        <span className='left-box'>
                                            {this.state.openModal === "notesHistory" + i && <RegistrationNotesHistory registration={inspection.node.registration} noteType="GENERAL" title="Registration Notes History" objectId={inspection.node.registration} target={"notesHistory" + i} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} placement={"bottom"} />}
                                            <i className="fa fa-history fa-lg" aria-hidden="true" id={"notesHistory" + i} onClick={() => this.toggleModal("notesHistory" + i)}></i>
                                        </span>
                                        <span className='right-box'>
                                            {inspection.node.registration.notes ? inspection.node.registration.notes : "--"}
                                        </span>
                                    </div>
                                </td> */}
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Inspections Found!"}</td>
                        </tr>}
                        {this.props.allCarInspections && this.props.loading ? <tr><td colSpan={10}><Progress animated color="info" value="100" /></td></tr> :
                            this.props.allCarInspections && this.props.allCarInspections.pageInfo && this.props.allCarInspections.pageInfo.hasNextPage &&
                            <tr>
                                <td colSpan={10}><Button onClick={this.props.loadMoreEntries}>Show More <i className={"fa fa-caret-down"}></i></Button></td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Col></Row></CardBody></Card>
        </>
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllOwnersQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data }) => {
        // Check if data is available and safe to use
        if (!data) {
            console.error('AllOwnersQuery data is undefined')
            return { allOwners: [] }  // Return default value to avoid breaking the app
        }
        const { allOwners } = data
        return { allOwners }
    }
})(
    graphql(AllCarInspectionsQuery, {
        options: ({ searchTerm, orderBy, registrationTypeIn, isInstalled, isActive, inspectionStatusIn }) => ({
            variables: { searchTerm, orderBy, registrationTypeIn, isInstalled, isActive, inspectionStatusIn, first: 30 },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }),
        props: ({ data }) => {
            // Check if data is available and safe to use
            if (!data) {
                console.error('AllCarInspectionsQuery data is undefined')
                return { loading: true, allCarInspections: [], variables: {} }  // Return default value for loading state
            }

            const { loading, allCarInspections, fetchMore, variables } = data

            // Safeguard: Check if allCarInspections is valid
            if (!allCarInspections) {
                console.error('allCarInspections data is undefined or empty')
                return { loading, allCarInspections: [], variables }  // Provide fallback for allCarInspections
            }

            return {
                loading,
                allCarInspections,
                variables,
                refetchQuery: () => {
                    // Refetch logic with pagination
                    let currentLength = allCarInspections && allCarInspections.edges ? allCarInspections.edges.length : 30
                    return fetchMore({
                        query: AllCarInspectionsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarInspections.edges
                            const pageInfo = fetchMoreResult.allCarInspections.pageInfo
                            return {
                                allCarInspections: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allCarInspections.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllCarInspectionsQuery,
                        variables: {
                            cursor: allCarInspections.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarInspections.edges
                            const pageInfo = fetchMoreResult.allCarInspections.pageInfo
                            return {
                                allCarInspections: {
                                    edges: [...previousResult.allCarInspections.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allCarInspections.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    })(
        InspectionsList
    )
)
