import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from 'reactstrap'
import Select from 'react-select'
import moment from 'moment-timezone'
import { CarSearchQuery, RepSearchQuery, DriverSearchQuery, DMVSearchQuery, AllOwnersQuery, RegistrationSearchQuery, RegistrationQuery, CarInspectionStatus } from '../Queries'
import { CreateCarInspectionMutation, UpdateCarInspectionMutation } from '../Mutations'
import toTitleCase from '../../Functions/toTitleCase'
import InspectionSuccessConfirmationModal from "./InspectionSuccessConfirmationModal"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class InspectionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inspectionDate: this.props.carInspection && this.props.carInspection.inspectionDate ? this.props.carInspection.inspectionDate : null,
            inspectionStatus: this.props.carInspection && this.props.carInspection.status ? this.props.carInspection.status.toUpperCase() : "PENDING",
            runnerType: this.props.carInspection && this.props.carInspection.runnerType ? this.props.carInspection.runnerType === "auth | user" ? "REP" : "DRIVER" : "REP",
            inspectionType: this.props.carInspection && this.props.carInspection.metadata ? this.fetchMetaData(this.props.carInspection.metadata, "inspection_type") : null,
            paymentDate: this.props.carInspection && this.props.carInspection.metadata ? this.fetchMetaData(this.props.carInspection.metadata, "payment_date") : null,
            associatedRegistration: this.props.carInspection && this.props.carInspection.metadata ? this.fetchMetaData(this.props.carInspection.metadata, "associated_registration") : null,
            notes: this.props.carInspection && this.props.carInspection.notes ? this.props.carInspection.notes : null,
            selectedCar: this.props.carInspection && this.props.carInspection.car ? this.props.carInspection.car : null,
            selectedRunner: this.props.carInspection && this.props.carInspection.runner ? this.props.carInspection.runner : null,
            selectedRegistration: this.props.carInspection && this.props.carInspection.registration ? this.props.carInspection.registration : null,
            selectedAssociatedRegistration: null,
            suggestions: [],

            showFinder: "",
            loading: false,
            error: "",
            openModal: "",
        }
    }


    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
        }
        this.setState({ [name]: value })
    };

    handleClose = (e) => {
        this.props.handleClose()
    };

    handleSuggestionsUpdate = (e) => {
        this.setState({ suggestions: [] })
        this.props.apolloClient.query({
            query: e.target.name === "carSearch" ? CarSearchQuery : e.target.name === "regSearch" ? RegistrationSearchQuery : e.target.name === "dmvSearch" ? DMVSearchQuery : e.target.name === "runnerSearch" && this.state.runnerType === "DRIVER" ? DriverSearchQuery : RepSearchQuery,
            variables: { searchTerm: e.target.value }
        }).then((response) => {
            if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                this.setState({ suggestions: response.data.allCars.edges })
            } else if (response && response.data && response.data.allDrivers && response.data.allDrivers.edges) {
                this.setState({ suggestions: response.data.allDrivers.edges })
            } else if (response && response.data && response.data.allReps && response.data.allReps.edges) {
                this.setState({ suggestions: response.data.allReps.edges })
            } else if (response && response.data && response.data.allRegistrations && response.data.allRegistrations.edges) {
                this.setState({ suggestions: response.data.allRegistrations.edges })
            } else {
                this.setState({ suggestions: [] })
            }
        }).catch((err) => {
            this.setState({ suggestions: [] })
        })
    }

    handleSubmit = (isConfirmed) => {
        try {
            this.setState({ loading: true, error: "" })
            if (!this.state.selectedCar || !this.state.inspectionStatus || !this.state.selectedRegistration) {
                throw 'Error: Required fields are missing!'
            } else if (this.state.selectedRegistration.registrationType.name === "TLC" && (!this.state.selectedAssociatedRegistration || !this.state.selectedAssociatedRegistration.id)) {
                throw 'Error: Associated DMV Plate is required for starting TLC inspection!'
            } else if (this.state.selectedRegistration.registrationType.name === "TLC" && !this.state.inspectionType) {
                throw 'Error: TLC inspection type is required for starting TLC Inspection!'
            }
            let input = {
                inspectionDate: this.state.inspectionDate,
                status: toTitleCase(this.state.inspectionStatus),
                notes: this.state.notes,
                runnerId: this.state.selectedRunner ? this.state.selectedRunner.id : null,
                associatedRegistration: this.state.selectedAssociatedRegistration ? this.state.selectedAssociatedRegistration.id : null,
                inspectionType: this.state.inspectionType,
                paymentDate: this.state.paymentDate ? this.state.paymentDate : null,
            }
            if (this.props.isUpdate) {
                input["id"] = this.props.carInspection ? this.props.carInspection.id : null
            } else {
                input["carId"] = this.state.selectedCar ? this.state.selectedCar.id : null
                input["registrationId"] = this.state.selectedRegistration ? this.state.selectedRegistration.id : null
            }

            if (input["status"] == "Success") {
                if (!input["runnerId"]) {
                    throw 'Error: Runner is required to update the status to success!'
                }
                if (!input["inspectionDate"]) {
                    throw 'Error: Inspection Date is required to update the status to success!'
                }
            }
            if (input["status"] == "Success" && !isConfirmed) {
                this.toggleModal("confirmationModal")
                this.setState({ loading: false, error: "" })
                return
            }
            this.props.apolloClient.mutate({
                mutation: this.props.isUpdate ? UpdateCarInspectionMutation : CreateCarInspectionMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && ((this.props.isUpdate && result.data.updateCarInspection && result.data.updateCarInspection.ok) || (!this.props.isUpdate && result.data.createCarInspection && result.data.createCarInspection.ok))) {
                    this.setState({ loading: false, error: null })
                    this.props.refetchQuery()
                    this.handleClose()
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (result.data && result.data.createCarInspection && result.data.createCarInspection.errors && result.data.createCarInspection.errors[0] && result.data.createCarInspection.errors[0].messages[0])
                        errorMessage = result.data.createCarInspection.errors[0].messages[0]
                    else if (this.props.isUpdate && result.data.updateCarInspection && result.data.updateCarInspection.errors && result.data.updateCarInspection.errors[0] && result.data.updateCarInspection.errors[0].messages[0])
                        errorMessage = result.data.updateCarInspection.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured" + error
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: errorMessage, loading: false })
        }
    };

    renderRegistrationDetails = (registration) => {
        let metaObject = JSON.parse(registration.metadata.replace(/'/g, '"'))
        return <>
            <h4>{registration.number} {!this.props.isUpdate && <i className="fa fa-times ml-2" onClick={() => this.setState({ selectedRegistration: null, suggestions: null })} />}<br /><small>({registration.registrationType.name} {registration.registrationType.stateCode})</small></h4>
            <small style={{ display: "block" }}>Expiration Date: {registration.expirationDate}</small>
        </>
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    fetchAssociatedRegistration = (registrationId) => {
        this.props.apolloClient.query({
            query: RegistrationQuery,
            variables: { pk: registrationId }
        }).then((response) => {
            this.setState({ suggestions: null, suggestions: null })
            if (response && response.data && response.data.registration && response.data.registration.id) {
                this.setState({ selectedAssociatedRegistration: response.data.registration })
            } else {
                this.setState({ error: "Error while fetching registration!" })
            }
        }).catch((err) => {
            this.setState({ error: "Error while fetching registration!" })
        })
    }

    componentDidMount() {
        const { apolloClient } = this.props
        if (this.props.isUpdate && this.props.carInspection && this.fetchMetaData(this.props.carInspection.metadata, "associated_registration")) {
            this.fetchAssociatedRegistration(this.fetchMetaData(this.props.carInspection.metadata, "associated_registration"))
        }
    }

    toggleFinder = (name) => {
        this.setState({ showFinder: this.state.showFinder === name ? "" : name, suggestions: [] })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open && !this.state.openModal} className="bos-registration-modal">
                    <ModalHeader>{this.props.isUpdate ? "Update" : "Create"} Car Inspection</ModalHeader>
                    <ModalBody>
                        {this.state.loading || this.props.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.props.isUpdate && <Row>
                            <Col>
                                <FormGroup>
                                    <Label><small>Created By</small></Label>
                                    <Input disabled value={(this.props.carInspection.createdBy.firstName ? this.props.carInspection.createdBy.firstName + " " + this.props.carInspection.createdBy.lastName : this.props.carInspection.createdBy.username) + " @ " + (moment(this.props.carInspection.dateAdded).tz("America/New_York").format('lll'))} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><small>Last Updated By</small></Label>
                                    <Input disabled value={(this.fetchMetaData(this.props.carInspection.metadata, "updated_by") || "--") + " @ " + moment(this.props.carInspection.dateAdded).tz("America/New_York").format('lll')} />
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className='text-center pb-3'>
                                {this.state.selectedRegistration ? this.renderRegistrationDetails(this.state.selectedRegistration)
                                    : <ButtonDropdown className="w-100" isOpen={this.state.showFinder === "Reg"} toggle={() => this.toggleFinder("Reg")}>
                                        <DropdownToggle caret>Choose Registration</DropdownToggle>
                                        <DropdownMenu className='w-100 px-2'>
                                            <Label for="regSearch"><small>Search by Reg Number</small></Label>
                                            <Input type="text" onChange={this.handleSuggestionsUpdate} name="regSearch" id="regSearch" disabled={this.props.isUpdate} />
                                            {this.state.showFinder === "Reg" && this.state.suggestions.length > 0 && this.state.suggestions.map((reg, i) =>
                                                <DropdownItem key={i} onClick={() => this.setState({ selectedRegistration: reg.node })} style={{ display: "content" }} >{reg.node.number} - {reg.node.registrationType.name}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                }
                            </Col>
                        </Row>
                        {this.state.selectedRegistration && this.state.selectedRegistration.registrationType && this.state.selectedRegistration.registrationType.name === "TLC" && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="inspectionType" className="d-block"><small>TLC Inspection Type *</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={this.props.loading}
                                        defaultValue={this.state.inspectionType ? { value: this.state.inspectionType.toUpperCase(), label: this.state.inspectionType.toUpperCase() } : null}
                                        options={[{ value: "DMV", label: "DMV" }, { value: "VISUAL", label: "VISUAL" }]}
                                        placeholder="Select TLC Inspection Type"
                                        onChange={type => this.setState({ inspectionType: type ? type.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="associatedRegistration" className="d-block"><small>Associated License Plate *</small></Label>
                                    {this.state.selectedAssociatedRegistration ?
                                        <><b>{this.state.selectedAssociatedRegistration.number} - {this.state.selectedAssociatedRegistration.registrationType.name}</b>&nbsp;&nbsp;&nbsp;
                                            {this.props.carInspection && this.props.carInspection.status && this.props.carInspection.status.toUpperCase() === "PENDING" && <i className="fa fa-times" onClick={() => this.setState({ selectedAssociatedRegistration: null, suggestions: null })} />}
                                        </>
                                        : <ButtonDropdown className="w-100" isOpen={this.state.showFinder === "dmv"} toggle={() => this.toggleFinder("dmv")}>
                                            <DropdownToggle caret>Choose DMV Plate</DropdownToggle>
                                            <DropdownMenu className='w-100 px-2'>
                                                <Label for="dmvSearch"><small>Search DMV by number</small></Label>
                                                <Input type="text" onChange={this.handleSuggestionsUpdate} name="dmvSearch" id="dmvSearch" />
                                                {this.state.showFinder === "dmv" && this.state.suggestions.length > 0 && this.state.suggestions.map((reg, i) =>
                                                    <DropdownItem key={i} onClick={() => this.setState({ selectedAssociatedRegistration: reg.node })} style={{ display: "content" }} >{reg.node.number} - {reg.node.registrationType.name}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="carId"><small>What's the Car ID? *</small></Label>
                                    {this.state.selectedCar ?
                                        <><br /><b>{toTitleCase(this.state.selectedCar.carModel.make)} {toTitleCase(this.state.selectedCar.carModel.name)} - {this.state.selectedCar.year}<br />{this.state.selectedCar.pk} - {this.state.selectedCar.vin}</b>&nbsp;&nbsp;&nbsp;{!this.props.isUpdate && <i className="fa fa-times" onClick={() => this.setState({ selectedCar: null, suggestions: null })} />} </>
                                        : <ButtonDropdown className="w-100" isOpen={this.state.showFinder === "Car"} toggle={() => this.toggleFinder("Car")}>
                                            <DropdownToggle caret>Choose Car</DropdownToggle>
                                            <DropdownMenu className='w-100 px-2'>
                                                <Label for="carSearch"><small>Search by vin, car id</small></Label>
                                                <Input type="text" onChange={this.handleSuggestionsUpdate} name="carSearch" id="carSearch" disabled={this.props.isUpdate} />
                                                {this.state.showFinder === "Car" && this.state.suggestions.length > 0 && this.state.suggestions.map((car, i) =>
                                                    <DropdownItem key={i} onClick={() => this.setState({ selectedCar: car.node })} style={{ display: "content" }} >{car.node.pk} · {car.node.vin}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    }
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="inspectionStatus" className="d-block"><small>Inspection Status</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={this.props.loading}
                                        defaultValue={{ value: this.state.inspectionStatus, label: this.state.inspectionStatus }}
                                        options={this.props.carInspectionStatus && this.props.carInspectionStatus.edges && this.props.carInspectionStatus.edges.filter(item => this.props.isUpdate || ["PENDING", "APPLIED"].includes(item.name)).map(item => ({ value: item.name, label: item.name }))}
                                        placeholder="Select Inspection Status"
                                        onChange={status => this.setState({ inspectionStatus: status ? status.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="ruunerType" className="d-block"><small>Runner Type</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={this.props.loading}
                                        defaultValue={[{ value: this.state.runnerType, label: this.state.runnerType }]}
                                        options={[{ value: "DRIVER", label: "DRIVER" }, { value: "REP", label: "REP" }]}
                                        placeholder="Select Runner Type"
                                        onChange={runner => this.setState({ runnerType: runner ? runner.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                            {this.state.runnerType && <Col>
                                <FormGroup>
                                    <Label for="runner" className="d-block"><small>Runner</small></Label>
                                    {this.state.selectedRunner ?
                                        <><b>{this.state.runnerType === "DRIVER" ? (this.state.selectedRunner.name + " " + this.state.selectedRunner.tlcLicense) : this.state.selectedRunner.username}</b>&nbsp;&nbsp;&nbsp;<i className="fa fa-times" onClick={() => this.setState({ selectedRunner: null, suggestions: null })} /> </>
                                        : <ButtonDropdown className="w-100" isOpen={this.state.showFinder === "runner"} toggle={() => this.toggleFinder("runner")}>
                                            <DropdownToggle caret>Choose Runner</DropdownToggle>
                                            <DropdownMenu className='w-100 px-2'>
                                                <Label for="runnerSearch"><small>Search by name</small></Label>
                                                <Input type="text" onChange={this.handleSuggestionsUpdate} name="runnerSearch" id="runnerSearch" />
                                                {this.state.showFinder === "runner" && this.state.suggestions.length > 0 && this.state.suggestions.map((runner, i) =>
                                                    <DropdownItem key={i} onClick={() => this.setState({ selectedRunner: runner.node })} style={{ display: "content" }} >{this.state.runnerType === "DRIVER" ? (runner.node.name + " " + runner.node.tlcLicense) : runner.node.username}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    }
                                </FormGroup>
                            </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="inspectionDate" className="d-block"><small>Inspection Date</small></Label>
                                    <Input
                                        type="date"
                                        name="inspectionDate"
                                        value={this.state.inspectionDate}
                                        onChange={this.handleChange}
                                        placeholder={`Enter the Date`}
                                        min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="paymentDate" className="d-block"><small>Payment Date</small></Label>
                                    <Input
                                        type="date"
                                        name="paymentDate"
                                        value={this.state.paymentDate}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="notes"><small>Inspection Notes</small></Label>
                                    <Input
                                        type="text"
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.handleChange}
                                        defaultValue={this.state.notes}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter className='d-block'>
                        {!this.state.loading &&
                            <Row>
                                <Col className='text-right'>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    {!(this.props.isUpdate && this.props.carInspection && this.props.carInspection.status && ["SUCCESS", "FAILED", "MISSED"].includes(this.props.carInspection.status.toUpperCase())) && <Button color="primary" onClick={() => this.handleSubmit(false)} disabled={this.state.loading}>{this.props.isUpdate ? "Update" : "Add"} Inspection</Button>}
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
                {this.state.openModal === "confirmationModal" && <InspectionSuccessConfirmationModal open={this.state.openModal === "confirmationModal"} handleClose={() => this.toggleModal("")} handleConfirmation={() => this.handleSubmit(true)} registrationId={this.state.selectedRegistration.id} associatedRegistrationId={this.state.selectedAssociatedRegistration.id} allOwners={this.props.allOwners} />}
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllOwnersQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data }) => {
        if (!data) {
            console.error('AllOwnersQuery data is undefined', data)
            return { allOwners: [] } // or any fallback value
        }
        const { allOwners } = data
        return { allOwners }
    },
})(
    graphql(CarInspectionStatus, {
        options: () => ({ fetchPolicy: 'cache-first' }),
        props: ({ data }) => {
            if (!data) {
                console.error('CarInspectionStatus data is undefined', data)
                return { carInspectionStatus: null } // or any fallback value
            }
            const { carInspectionStatus } = data
            return { carInspectionStatus }
        },
    })(withApolloClient(
        InspectionModal)
    )
);


