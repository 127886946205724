import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, UncontrolledTooltip, Row, Col, Progress, Alert } from 'reactstrap'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18

const TransactionsQuery = gql`query TransactionsQuery($driverId: ID, $paymentPlanCompatible: Boolean) {
    transactions: allTransactions(driverId: $driverId, paymentPlanCompatible: $paymentPlanCompatible) {
        edges {
            node {
                id
                pk
                amount
                dynamicAmount
                dueDate
                dateAdded
                chargeType {
                    id
                    name
                }
                groupType {
                    id
                    name
                }
            }
        }
    }
}`

const UpdatePaymentPlan = gql`
    mutation updatePaymentPlan($input: UpdatePaymentPlanMutationInput!){
        updatePaymentPlan(input:$input){
            ok
            errors{
                messages
            }
        }
    } 
`

class AddPaymentPlanTransactionsModal extends Component {
    state = {
        selectedTransactions: [],
        transactionAmounts: {},
        showNext: false,
        error: null,
        loading: false
    };

    handleCheckboxChange = (transactionId) => {
        const { selectedTransactions } = this.state
        const isSelected = selectedTransactions.includes(transactionId)
        const newSelectedTransactions = isSelected
            ? selectedTransactions.filter(id => id !== transactionId)
            : [...selectedTransactions, transactionId]

        this.setState({
            selectedTransactions: newSelectedTransactions
        })
    };

    handleAmountChange = (transactionId, amount) => {
        this.setState(prevState => ({
            transactionAmounts: {
                ...prevState.transactionAmounts,
                [transactionId]: amount
            }
        }))
    };

    handleNext = () => {
        this.setState({ showNext: true })
    };

    handleSubmit = () => {
        const { selectedTransactions, transactionAmounts } = this.state
        const { paymentPlanId } = this.props
        const transactions = selectedTransactions.map(id => ({
            id,
            amount: parseFloat(transactionAmounts[id] || 0)
        }))
        if (transactions) {
            this.setState({ loading: true })
            this.props.apolloClient.mutate({
                mutation: UpdatePaymentPlan,
                variables: {
                    input: {
                        paymentPlanId: paymentPlanId,
                        transactions
                    }
                },
            }).then((result) => {
                if (result && result.data && result.data.updatePaymentPlan && result.data.updatePaymentPlan.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data && result.data.updatePaymentPlan.errors && result.data.updatePaymentPlan.errors.length > 0) {
                    this.setState({ loading: false, error: result.data.updatePaymentPlan.errors[0].messages[0] })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
            this.setState({ loading: false, error: "No compatiable transaction found!" })
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props;
        const { transactions } = this.props
        if (transactions && transactions.edges && transactions.edges.length > 0) {
            const transactionAmounts = transactions.edges.reduce((acc, { node }) => {
                acc[node.id] = node.amount
                return acc
            }, {})
            this.setState({ transactionAmounts })
        }
    }


    render() {
        const { isOpen, toggle } = this.props
        const { selectedTransactions, showNext, transactionAmounts } = this.state
        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: !showNext ? "750px" : "900px" }}>
                <ModalHeader toggle={toggle}>Add Transactions to Payment Plan</ModalHeader>
                <ModalBody style={{ maxHeight: "500px", overflow: "auto" }}>
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    <Table>
                        <thead>
                            <tr>
                                {!showNext && <th>Select</th>}
                                <th>ID</th>
                                <th>Date Added</th>
                                <th>Charge Type<small>{"(Group Type)"}</small></th>
                                <th>Due Date</th>
                                <th>Amount</th>
                                {selectedTransactions && showNext && <th>Planned Amount</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTransactions && showNext ?
                                this.props.transactions.edges.filter(({ node }) => selectedTransactions.includes(node.id)).map(({ node }, i) => <tr key={node.id}>
                                    <td>{node.pk}</td>
                                    <td>{node.dateAdded ? <DatetimeRenderer datetime={node.dateAdded} /> : "--"}</td>
                                    <td>
                                        {node.chargeType ? node.chargeType.name : "--"}
                                        {node.groupType && <><small id={"groupType"}>{" (" + node.groupType.name + ")"}</small>
                                            <UncontrolledTooltip placement="top" target={"groupType"}>Group Type</UncontrolledTooltip>
                                        </>}
                                    </td>
                                    <td>{node.dueDate ? <DatetimeRenderer datetime={node.dueDate} /> : "--"}</td>
                                    <td>
                                        {parseFloat(node.amount) !== parseFloat(node.dynamicAmount) ? <>
                                            <b id={"dynamicAmount" + i}>${node.dynamicAmount}*</b>
                                            <UncontrolledTooltip placement="right" target={"dynamicAmount" + i}>${node.amount} {"->"} ${node.dynamicAmount}</UncontrolledTooltip>
                                        </> : <>${node.amount}</>}
                                    </td>
                                    <td>
                                        <Input
                                            type="number"
                                            value={transactionAmounts[node.id] || node.amount}
                                            onChange={(e) => this.handleAmountChange(node.id, e.target.value)}
                                        />
                                    </td>
                                </tr>)
                                : (this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0 ? this.props.transactions.edges.map((transaction, i) => <tr key={transaction.node.id}>
                                    <td className="listing-checkbox-wrapper">
                                        <span onClick={() => this.handleCheckboxChange(transaction.node.id)}>
                                            {selectedTransactions.includes(transaction.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                        </span>
                                    </td>
                                    <td>{transaction.node.pk}</td>
                                    <td>{transaction.node.dateAdded ? <DatetimeRenderer datetime={transaction.node.dateAdded} /> : "--"}</td>
                                    <td>
                                        {transaction.node.chargeType ? transaction.node.chargeType.name : "--"}
                                        {transaction.node.groupType && <><small id={"groupType"}>{" (" + transaction.node.groupType.name + ")"}</small>
                                            <UncontrolledTooltip placement="top" target={"groupType"}>Group Type</UncontrolledTooltip>
                                        </>}
                                    </td>
                                    <td>{transaction.node.dueDate ? <DatetimeRenderer datetime={transaction.node.dueDate} /> : "--"}</td>
                                    <td>
                                        {parseFloat(transaction.node.amount) !== parseFloat(transaction.node.dynamicAmount) ? <>
                                            <b id={"dynamicAmount" + i}>${transaction.node.dynamicAmount}*</b>
                                            <UncontrolledTooltip placement="right" target={"dynamicAmount" + i}>${transaction.node.amount} {"->"} ${transaction.node.dynamicAmount}</UncontrolledTooltip>
                                        </> : <>${transaction.node.amount}</>}
                                    </td>
                                </tr>)
                                    : <tr><td>No compatible transaction found</td></tr>)
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    {showNext ? <Button className="payment-plan-modal-secondary-btn" onClick={() => this.setState({ showNext: false })}>Back</Button>
                        : <Button className="payment-plan-modal-secondary-btn" onClick={toggle}>Cancel</Button>}
                    {selectedTransactions && showNext ?
                        <Button className="payment-plan-modal-primary-btn" onClick={this.handleSubmit}>Submit</Button>
                        : <Button className="payment-plan-modal-primary-btn" onClick={this.handleNext} disabled={selectedTransactions.length === 0}>Next</Button>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(TransactionsQuery, {
    options: ({ driverId }) => ({
        variables: {
            driverId: driverId,
            paymentPlanCompatible: true,
        },
    }),
    props: ({ data: { loading, transactions, variables } }) => ({
        loading,
        transactions,
        variables,
    }),
})(withApolloClient(AddPaymentPlanTransactionsModal));
