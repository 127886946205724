import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import { Button, Table, Row, Col, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import CarModelGroupIcon from '../../CA/CarModelGroupIcon'
import Progress from 'reactstrap/lib/Progress'
import toTitleCase from '../../Functions/toTitleCase'
import CarInfoModal from '../../CA/CarInfoModal'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import '../CarIssue.css'
import WorkOrderModal from "./WorkOrderModal"
import { AllWorkOrders } from "./Queries"
import moment from "moment"
import "../../Material/ListingHeader.css"

const tableHeaders = [
    { id: "vendor", name: "Vendor", sortable: true },
    { id: "salesTax", name: "Sales Tax", sortable: true },
    { id: "totalPrice", name: "Total Price", sortable: false },
    { id: "completionDate", name: "Completion Date", sortable: true },
    { id: "id", name: "Car", sortable: true },
    { id: "workItem", name: "Work Items", sortable: false },
    { id: "actions", name: "Actions", sortable: false },
]

class WorkordersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    totalPrice(workItems, salesTax) {
        let prices = workItems.map(x => Number(x.node.price))
        let totalAmount = 0
        if (prices.length > 0) {
            totalAmount = prices.reduce((x, y) => x + y)
        }
        return totalAmount + Number(salesTax)
    }

    render() {
        return (<>
            {this.state.openModal === "WorkOrder" && <WorkOrderModal isUpdate={false} refetchQuery={this.props.refetchQuery} open={this.state.openModal === "WorkOrder"} handleClose={() => this.setState({ openModal: "" })} />}
            {this.props.loading || this.state.loading ? <Progress animated color="info" value="100" /> : ""}
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={{ size: 12 }} className="text-right py-2">
                            <Button className="work-order" onClick={() => this.setState({ openModal: "WorkOrder" })}>
                                <i className="fa fa-plus" aria-hidden="true"></i> Create Work Order
                            </Button>
                        </Col>
                    </Row>
                    <div className="table-container">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th onClick={this.toggleSelectAllClick} style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                                    {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>
                                            {tableHeader.name}
                                            &nbsp;<i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? "fa-sort-asc" : this.props.orderBy.includes("-" + tableHeader.id) ? "fa-sort-desc" : "fa-sort")} aria-hidden="true"></i>
                                        </th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.allWorkOrders && this.props.allWorkOrders.edges && this.props.allWorkOrders.edges.length > 0 ? this.props.allWorkOrders.edges.map((workOrder, i) =>
                                    <tr>
                                        <td scope="row">{i + 1}</td>
                                        <td>
                                            <div><b>{workOrder.node && workOrder.node.vendor ? workOrder.node.vendor.name : "--"}</b></div>
                                        </td>
                                        <td>
                                            <div>
                                                <b>
                                                    {workOrder.node && workOrder.node.salesTax}
                                                </b>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <b>
                                                    {workOrder.node && workOrder.node.workitemSet ? this.totalPrice(workOrder.node.workitemSet.edges, workOrder.node.salesTax) : "--"}
                                                </b>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <b>
                                                    {workOrder.node && workOrder.node.completionDate ? moment(workOrder.node.completionDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"}
                                                </b>
                                            </div>
                                        </td>
                                        <td className="py-3">
                                            <div className="cars-wrapper">
                                                <div className="bos-table-td-left-box">
                                                    {this.state.openModal === "CarInfo" + workOrder.node.car.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + workOrder.node.car.pk} id={workOrder.node.car.pk} carPk={workOrder.node.car.pk} carId={workOrder.node.car.id} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} />}
                                                    <small>{workOrder.node.car.pk}</small>
                                                    <br />
                                                    <a onClick={() => this.toggleModal("CarInfo" + workOrder.node.car.pk)} style={{ cursor: "pointer" }}>
                                                        <CarModelGroupIcon carGroup={workOrder.node.car.group} carColor={workOrder.node.car.color} id={workOrder.node.car.pk} key={i + 1} />
                                                    </a>
                                                    <br />
                                                    <small>Stage {renderEnumToInt(workOrder.node.car.stage)}</small>
                                                </div>

                                                <div className="bos-table-td-right-box">
                                                    <span>
                                                        <small>{workOrder.node.car.year} | {workOrder.node.car.color} {workOrder.node.car.group ? "| " + workOrder.node.car.group : ""}</small>
                                                        &nbsp;&nbsp;<a href={"/car/" + workOrder.node.car.pk} target="_blank">
                                                            <i className="fa fa-external-link" aria-hidden="true"></i>
                                                        </a>
                                                    </span>
                                                    <div className="car-name">
                                                        {toTitleCase(workOrder.node.car.make)} {toTitleCase(workOrder.node.car.model)} <small>{workOrder.node.car.modelSeries ? "(" + workOrder.node.car.modelSeries + ")" : ""}</small>
                                                        {(workOrder.node.car.stage == 10 || (workOrder.node.car.currentAgreement && workOrder.node.car.currentAgreement.id)) && <>
                                                            <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                            <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                        </>}
                                                    </div>
                                                    <small>
                                                        {workOrder.node.car.carpolicySet && workOrder.node.car.carpolicySet.edges && workOrder.node.car.carpolicySet.edges.length > 0 &&
                                                            workOrder.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1').length > 0 ?
                                                            workOrder.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1')[0].node.insurancePolicy.policyNumber
                                                            : "No Insurance Found!"}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {workOrder.node.workitemSet.edges.length === 0 ?
                                                <p>
                                                    No Work Item
                                                </p> :
                                                <div>
                                                    {workOrder.node.workitemSet.edges.slice(0, 3).map((item) => {
                                                        return <div>
                                                            {item.node.jobType.name} | ${item.node.price}
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            {this.state.openModal === `updateWorkOrder${i}` && <WorkOrderModal isUpdate={true} id={workOrder.node.id} refetchQuery={this.props.refetchQuery} open={this.state.openModal === `updateWorkOrder${i}`} handleClose={() => this.setState({ openModal: "" })} />}
                                            <i className="fa fa-pencil fa-2x fa-lg" aria-hidden="true" onClick={() => this.toggleModal(`updateWorkOrder${i}`)}></i>
                                        </td>
                                    </tr>
                                ) : <tr>
                                    <td colSpan={10}>{this.props.loading ? "Loading..." : "No Work Order!"}</td>
                                </tr>}
                                {/* {this.props.allWorkOrders && this.props.loading ? <tr><td colSpan={10}><Progress animated color="info" value="100" /></td></tr> :
                        this.props.allWorkOrders && this.props.allWorkOrders.pageInfo && this.props.allWorkOrders.pageInfo.hasNextPage &&
                        <tr>
                            <td colSpan={10}><Button style={{ paddingLeft: 0 }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show More <i className={"fa fa-caret-down"}></i></Button></td>
                        </tr>
                    } */}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <div>
                <Button disabled={this.props.loading} className="float-left mt-2 btn btn-secondary" onClick={() => this.props.refetchQuery()}>Reload &nbsp;&nbsp;<i className="fa fa-refresh"></i></Button>
                {this.props.allWorkOrders && this.props.allWorkOrders.pageInfo && this.props.allWorkOrders.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} className="float-right mt-2 btn btn-primary-showmore-outline">Show More <i className={"fa fa-caret-down"}></i></Button>}
            </div>
        </>)
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllWorkOrders, {
    options: ({ searchTerm, orderBy, vendorIn }) => ({
        variables: { searchTerm, orderBy, first: 30, vendorIn },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ data: { loading, allWorkOrders, fetchMore, variables } }) => ({
        loading,
        allWorkOrders,
        variables,
        refetchQuery: () => {
            let currentLength = allWorkOrders && allWorkOrders.edges ? allWorkOrders.edges.length < 30 ? allWorkOrders.edges.length + 1 : allWorkOrders.edges.length : 30;
            return fetchMore({
                query: AllWorkOrders,
                variables: {
                    ...variables,
                    first: currentLength,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allWorkOrders.edges;
                    const pageInfo = fetchMoreResult.allWorkOrders.pageInfo;
                    return {
                        allWorkOrders: {
                            edges: [...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            __typename: previousResult.allWorkOrders.__typename
                        },
                    };
                },
            });
        },
        loadMoreEntries: () => {
            return fetchMore({
                query: AllWorkOrders,
                variables: {
                    cursor: allWorkOrders.pageInfo.endCursor,
                    ...variables
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allWorkOrders.edges;
                    const pageInfo = fetchMoreResult.allWorkOrders.pageInfo;

                    return {
                        allWorkOrders: {
                            edges: [...previousResult.allWorkOrders.edges, ...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            stageIn: previousResult.stageIn,
                            jobTypeIn: previousResult.jobTypeIn,
                            statusIn: previousResult.statusIn,
                            vendorIn: previousResult.vendorIn,
                            __typename: previousResult.allWorkOrders.__typename
                        },
                    };
                },
            });
        },
    })
})(withApolloClient(WorkordersList));

