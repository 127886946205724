//Iagami - Code upgradation React 18
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, gql, makeVar } from '@apollo/client'

function getCookie(name) {
    var value = "; " + document.cookie
    var parts = value.split("; " + name + "=")
    if (parts.length === 2) return parts.pop().split(";").shift()
}

// Create reactive variables for local state management
const selectedFilterVar = makeVar({
    __typename: 'SelectedFilter',
    active: 0,
    rep: 'all',
    carStatus: 'all'
})

const currentUserVar = makeVar({
    __typename: 'CurrentUser',
    username: "",
    isManager: false,
    isBilling: false,
    isFrontOffice: false,
    isTopManagement: false,
    authLevel: 0
})

// Set up the cache with type policies to handle reactive variables
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                selectedFilter: {
                    read() {
                        return selectedFilterVar()
                    }
                },
                currentUser: {
                    read() {
                        return currentUserVar()
                    }
                }
            }
        }
    }
})

// Define the mutation to update the selected filter
const UPDATE_FILTER = gql`
    mutation UpdateFilter($index: String!, $value: String!) {
        updateFilter(index: $index, value: $value) @client
    }
`

// Create the Apollo Client instance
const client = new ApolloClient({
    link: ApolloLink.from([
        new HttpLink({
            uri: '/graphiql/',
            headers: { "X-CSRFToken": getCookie('csrftoken') },
            credentials: 'same-origin'
        })
    ]),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        }
    }
})

// Example function to update the filter directly using reactive variable
export function updateFilter(index, value) {
    selectedFilterVar({
        ...selectedFilterVar(),
        [index]: value
    })
}

export default client
