import React, { useCallback, useEffect, useState, useRef } from "react"
import "./MultiRangeSlider.css"

const MultiRangeSlider = ({ min, max, minLabel, maxLabel, onChange, defaultValue }) => {
    const [minVal, setMinVal] = useState(defaultValue[0])
    const [maxVal, setMaxVal] = useState(defaultValue[1])
    const minValRef = useRef(null)
    const maxValRef = useRef(null)
    const range = useRef(null)

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    )

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal)
            const maxPercent = getPercent(+maxValRef.current.value)

            if (range.current) {
                range.current.style.left = `${minPercent}%`
                range.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [minVal, getPercent])

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value)
            const maxPercent = getPercent(maxVal)

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [maxVal, getPercent])

    useEffect(() => {
        onChange(maxLabel, maxVal)
    }, [maxVal])
    useEffect(() => {
        onChange(minLabel, minVal)
    }, [minVal])

    useEffect(() => {
        onChange(maxLabel, maxVal)
        onChange(minLabel, minVal)
    }, [])

    return (
        <div className="container">
            <input
                type="range"
                name={minLabel}
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={(event) => {
                    const value = Math.min(+event.target.value, maxVal - 1)
                    setMinVal(value)
                    event.target.value = value.toString()
                }}
                className={"thumb thumb--zindex-5"}
            />
            <input
                type="range"
                name={maxLabel}
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={(event) => {
                    const value = Math.max(+event.target.value, minVal + 1)
                    setMaxVal(value)
                    event.target.value = value.toString()
                }}
                className="thumb thumb--zindex-3"
            />

            <div className="slider">

                <div className="slider__track" />
                <div ref={range} className="slider__range" />
                <div className="row mt-5">
                    <div className="col mr-5">
                        <div className="slider__left-value">{minVal}</div>
                    </div>
                    <div className="col ml-5">
                        <div className="slider__right-value">{maxVal}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiRangeSlider
