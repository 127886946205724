import React, { useState, useEffect } from "react"
import gql from "graphql-tag"
import { Modal, ModalBody, Button, UncontrolledTooltip, Progress, ButtonGroup } from "reactstrap"
import { checkAllInputsFilled, handleDriverDataChange, validateDocumentData } from "./utils"
import { ReactComponent as DocVerified } from "../../assets/images/icons/doc-verified.svg"
import { isImage } from "./DocumentsListNew"
import { HasPermissionsQuery } from "../../Functions/querys"
import UpdateDocumentModal from "./UpdateDocumentModal"
import ConfirmationPopup from "../ConfirmationPopup"
import DocumentDetailsPopover from "./DocumentDetailsPopover"
import DocumentHistoryPopover from "./DocumentHistoryPopover"
import DocumentFields from "./DocumentFields"
import "./DocumentListNew.css"
import { graphql } from '@apollo/client/react/hoc'
import withApolloClient from '../../withApolloClient'

const DriverDocumentDataQuery = gql`
  query DriverDocumentData($driverId: ID!) {
    driverDocumentData(driverId: $driverId) {
      documentData {
        documentName
        requiredData {
          fieldCode
          fieldType
          displayName
          value
          dataCategory
        }
      }
      country
    }
  }
`

const ExecuteOCRMutation = gql`
  mutation ExecuteOCR($input: ExecuteOCRInput!) {
    executeOcr(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`

const DeleteDriverDocumentMutation = gql`
  mutation deleteDriverDocumentMutation($input: DeleteDriverDocumentMutationInput!) {
    deleteDriverDocument(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`

const UpdateDocumentMutation = gql`
  mutation updateDocumentMutation($input: UpdateDocumentMutationInput!) {
    updateDocument(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`


function DocumentModalNew({
    currentUser,
    handleClose,
    next,
    prev,
    objectType = "driver",
    driverId,
    document: doc,
    driverDocumentData,
    apolloClient,
    isPicture,
    refetch,
    hasPermissions,
    driverDataLoading,
    permissionsLoading,
}) {
    const [modal, setModal] = useState(true)
    const [rotation, setRotation] = useState(0)
    const [scale, setScale] = useState(1)
    const [documentTypeDocumentData, setDocumentTypeDocumentData] = useState([])
    const [allInputsFilled, setAllInputsFilled] = useState(false)
    const [ocrError, setOcrError] = useState("")
    const [ocrSuccess, setOcrSuccess] = useState("")
    const [deleteError, setDeleteError] = useState("")
    const [operationType, setOperationType] = useState("")
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState("")
    const selectedDocumentType = doc && doc.documentType ? doc.documentType : ""

    useEffect(() => {
        if (selectedDocumentType && driverDocumentData?.documentData) {
            const initialData = validateDocumentData(driverDocumentData, selectedDocumentType)
            const updatedDocumentData = driverDocumentData.country
                ? initialData.map((item) =>
                    item.fieldCode.startsWith("COUNTRY")
                        ? { ...item, value: driverDocumentData.country }
                        : item
                )
                : initialData
            setDocumentTypeDocumentData(updatedDocumentData)
            const allFilled = checkAllInputsFilled(updatedDocumentData)
            setAllInputsFilled(allFilled)
        }
    }, [driverDocumentData, selectedDocumentType])

    useEffect(() => {
        if (!modal) return

        const handleKeyPress = (event) => {
            if (event.keyCode === 39) return next && next()
            if (event.keyCode === 37) return prev && prev()
        }

        window.addEventListener("keydown", handleKeyPress)

        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, [modal, next, prev])

    const toggleModal = () => setModal(!modal)
    const rotateDoc = () => setRotation((prev) => (prev + 90) % 360)
    const scaleDoc = (increment) => setScale((prev) => Math.max(prev + increment, 0.1))

    const downloadDoc = (url) => {
        // TODO: Add download functionality
        window.open(url, "_blank")
    }

    const printDoc = (url) => {
        var printWindow = window.open(url, "Print", "width=800,height=600")
        printWindow.print()
    }

    const handleCloseModal = () => {
        handleClose && handleClose()
    }

    const handleClosePopover = () => {
        setOpenModal("")
    }

    const handleFieldChange = (input, numbersOnly = false, allowNumbersAndDot = false) => {
        handleDriverDataChange(
            input,
            numbersOnly,
            allowNumbersAndDot,
            documentTypeDocumentData,
            (updatedData) => {
                setDocumentTypeDocumentData(updatedData)
                const allFilled = checkAllInputsFilled(updatedData)
                setAllInputsFilled(allFilled)
            }
        )
    }

    const handleExecuteOCR = async (id, forceVerify = false) => {
        if (!id) {
            setOcrError("Document ID is required for OCR.")
            return
        }

        try {
            setOcrError("")
            setOcrSuccess("")
            const { data } = await apolloClient.mutate({
                mutation: ExecuteOCRMutation,
                variables: { input: { documentId: id, forceVerify } },
            })

            if (data.executeOcr.ok) {
                setOcrSuccess("OCR executed successfully.")
            } else {
                setOcrError(data.executeOcr.errors?.[0]?.messages?.[0] ?? "An error occurred.")
            }
        } catch (error) {
            setOcrError(`Error executing OCR: ${error.message}`)
        }
    }

    const documentHandler = async (id, action) => {
        if (!id || !action) return

        try {
            setLoading(true)
            setDeleteError("")

            const input = {
                delete: { documentId: id },
                archive: { id, isArchived: true },
                unarchive: { id, isArchived: false },
            }[action] || {}

            if (!Object.keys(input).length) {
                throw new Error(`Unsupported action: ${action}`)
            }

            const mutation = {
                delete: DeleteDriverDocumentMutation,
                archive: UpdateDocumentMutation,
                unarchive: UpdateDocumentMutation,
            }[action]

            const result = await apolloClient.mutate({
                mutation,
                variables: { input },
            })
            refetch && refetch()
            const responseKey = {
                delete: "deleteDriverDocument",
                archive: "updateDocument",
                unarchive: "updateDocument",
            }[action]

            let responseData = result?.data?.[responseKey]

            if (responseData?.ok) {
                handleCloseModal()
            } else {
                const errorMessage = responseData?.errors?.[0]?.messages?.[0] ?? "An error occurred."
                setDeleteError(errorMessage)
            }
        } catch (error) {
            setDeleteError(`An error occurred: ${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "") return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'))
        } catch {
            metadata = null
        }
        return metadata?.[name] ?? null
    }


    if (driverDataLoading || permissionsLoading) {
        return (
            <Modal isOpen={modal} toggle={toggleModal} fullscreen>
                <ModalBody>
                    <Progress animated color="info" value={100} />
                </ModalBody>
            </Modal>
        )
    }

    return (
        <Modal
            className="DocViewerModal"
            isOpen={modal}
            toggle={toggleModal}
            onClosed={handleCloseModal}
            fullscreen
        >
            {!(doc?.documentUrl) ?
                <div>No document passed</div> :
                <>
                    {operationType && ["delete", "archive", "unarchive"].includes(operationType) && (
                        <ConfirmationPopup
                            open={!!operationType}
                            message={`Are you sure you want to ${operationType} this document?`}
                            loading={loading}
                            handleClose={() => setOperationType("")}
                            action="Yes"
                            confirmAction={() => documentHandler(doc.id, operationType)}
                            color="danger"
                            error={deleteError || ""}
                        />
                    )}
                    {openModal === "UpdateDocumentModal" &&
                        <UpdateDocumentModal
                            currentUser={currentUser}
                            selectedDocId={doc?.id}
                            handleClose={handleClosePopover}
                            open={openModal === "UpdateDocumentModal"}
                            target="UpdateDocumentButton"
                            objectType={objectType}
                            refetchQuery={refetch}
                            driverId={driverId}
                        />
                    }
                    {openModal === "DocumentDetailsPopover" &&
                        <DocumentDetailsPopover
                            isOpen={openModal === "DocumentDetailsPopover"}
                            target="DocumentDetailsPopover"
                            doc={doc}
                            objectType={objectType}
                            handleClosePopover={handleClosePopover}
                        />
                    }
                    {openModal === "documentHistory" &&
                        <DocumentHistoryPopover
                            isOpen={openModal === "documentHistory"}
                            target="documentHistory"
                            doc={doc}
                            handleClosePopover={handleClosePopover}
                        />
                    }
                    <div className="DocViewerModalHeader">
                        <p className="DocViewerModalTitle">
                            {doc?.documentType?.typeName || doc?.name || "Document Name"} &nbsp;
                            {doc?.isVerified && <>
                                <UncontrolledTooltip target="verified">{"Verified By " + (fetchMetaData(doc?.metadata, "verified_by") || "System")}</UncontrolledTooltip>
                                {fetchMetaData(doc.metadata, "verified_by") !== "System" ? <DocVerified id="verified" width="20" height="20" className="fa fa-check-circle" fill="green" /> : <i id="verified" className="fa fa-check-circle" style={{ color: "green" }}></i>}
                            </>}
                        </p>
                        <div className="DocViewerModalActions">
                            <p onClick={rotateDoc}><i className="fa fa-rotate-right"></i></p>
                            <p onClick={() => scaleDoc(0.1)}><i className="fa fa-search-plus"></i></p>
                            <p onClick={() => scaleDoc(-0.1)}><i className="fa fa-search-minus"></i></p>
                            <p onClick={() => downloadDoc(doc.documentUrl)}><i className="fa fa-download"></i></p>
                            <p onClick={() => printDoc(doc.documentUrl)}><i className="fa fa-print"></i></p>
                            <p><i className="fa fa-times" onClick={handleCloseModal}></i></p>
                        </div>
                    </div>
                    <ModalBody>
                        {ocrError && <div style={{ color: "red" }}>{ocrError}</div>}
                        {ocrSuccess && <div style={{ color: "green" }}>{ocrSuccess}</div>}
                        {deleteError && <div style={{ color: "red" }}>{deleteError}</div>}
                        {document ? (
                            isImage(doc.documentUrl, doc.name)
                                ? <img style={{ transform: `rotate(${rotation}deg) scale(${scale})` }} className="DocViewerImage" src={doc.documentUrl} alt="Document" />
                                : <iframe scrolling="yes" className="DocViewerFrame" src={doc.documentUrl} title="Document" />)
                            : <p>No document available.</p>
                        }
                        <DocumentFields
                            documentTypeDocumentData={documentTypeDocumentData}
                            allInputsFilled={allInputsFilled}
                            handleFieldChange={handleFieldChange}
                        />
                    </ModalBody>
                    <div className="DocViewerModalFooter">
                        <ButtonGroup>
                            <Button className="btn btn-dark" onClick={prev}><i className="fa fa-chevron-left"> Prev</i></Button>
                            <Button className="btn btn-dark" onClick={next}>Next <i className="fa fa-chevron-right"></i></Button>
                        </ButtonGroup>
                        <div className="DocViewerFooterAction">
                            <Button className="btn btn-secondary" id="DocumentDetailsPopover" onClick={() => setOpenModal("DocumentDetailsPopover")}><i className="fa fa-info-circle"></i> Details</Button>
                            <Button className="btn btn-secondary" style={{ background: "#008B8B" }} id="documentHistory" onClick={() => setOpenModal("documentHistory")}><i className="fa fa-history"></i> History</Button>
                            {!isPicture && (
                                <>
                                    {doc?.isArchived && (
                                        <Button className="btn btn-danger" onClick={() => setOperationType("unarchive")}>
                                            <i className="fa fa-file-archive-o" aria-hidden="true"></i>
                                            &nbsp;Unarchive
                                        </Button>
                                    )}
                                    {!doc?.isArchived && (
                                        <Button className="btn btn-danger" onClick={() => setOperationType("archive")}>
                                            <i className="fa fa-trash"></i>
                                            &nbsp;Archive
                                        </Button>
                                    )}
                                    {doc?.isArchived && hasPermissions?.includes("delete_driverdocument") &&
                                        objectType === "driver" && (
                                            <Button className="btn btn-danger" onClick={() => setOperationType("delete")}>
                                                <i className="fa fa-trash"></i>
                                                &nbsp;Delete
                                            </Button>
                                        )}
                                    <Button className="btn btn-warning" id="UpdateDocumentButton" onClick={() => setOpenModal("UpdateDocumentModal")}>
                                        <i className="fa fa-pencil-square"></i>
                                        &nbsp;Edit
                                    </Button>
                                </>
                            )}
                            {["TLC", "Driver License", "Constancia de Situacion Fiscal"].includes(doc?.documentType?.typeName ?? "") && (
                                <>
                                    <Button className="btn btn-info" onClick={() => handleExecuteOCR(doc?.id)}>
                                        <i className="fa fa-id-card"></i> Doc Validation
                                    </Button>
                                    {hasPermissions?.includes("custom_force_run_ocr") && (
                                        <Button className="btn btn-info" onClick={() => handleExecuteOCR(doc?.id, true)}>
                                            <i className="fa fa-id-card"></i> Manual Doc Validation
                                        </Button>
                                    )}
                                </>
                            )}
                            <Button className="btn btn-dark" onClick={handleCloseModal}><i className="fa fa-times"></i> Close</Button>
                        </div>
                    </div>
                </>
            }
        </Modal>
    )
}

export default graphql(HasPermissionsQuery, {
    options: () => ({
        variables: { userPermissions: ["custom_force_run_ocr", "delete_driverdocument"] },
        fetchPolicy: "cache-first",
    }),
    props: ({ data: { hasPermissions, loading, variables } }) => (
        { hasPermissions, loading, variables }
    )
})(
    graphql(DriverDocumentDataQuery, {
        options: ({ driverId }) => ({
            variables: { driverId },
            fetchPolicy: "cache-first",
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { loading: driverDataLoading, driverDocumentData } }) => (
            { driverDataLoading, driverDocumentData }
        )
    })(withApolloClient(DocumentModalNew))
)

