import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Button, Popover, PopoverHeader, PopoverBody, Table, UncontrolledTooltip, Row, Col, CardBody, Card } from 'reactstrap'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location-icon.svg'
import CarModelGroupIcon from '../../CA/CarModelGroupIcon'
import toTitleCase from '../../Functions/toTitleCase'
import CarNotes from '../../Material/CarNotes'; import CarInfoModal from '../../CA/CarInfoModal'
import UpdateCarIssue from '../../CarIssues/UpdateCarIssue'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import CarIssueModal from '../CarIssueModal'
import CarRepAssignmentModal from '../../Material/CarRepAssignmentModal'
import UpdateCarInfo from '../../Material/UpdateCarInfo'
import moment from 'moment'
import gql from 'graphql-tag'
import '../CarIssue.css'
import Badge from "reactstrap/lib/Badge"
import { CATEGORIES } from "./CarIssueContainer"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { HasPermissionsQuery } from "../../Functions/querys"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import { Link } from 'react-router-dom'
import { cleanDocumentUrl } from '../../Material/DocumentModal/DocumentsListNew'
import DocumentModalNew from '../../Material/DocumentModal/DocumentModalNew'


const CarIssuesQuery = gql`query CarIssuesQuery($cursor: String, $searchTerm: String, $stageIn:[Int], $vendorIn:[ID], $assignedToIn:[ID], $categoryIn:[ID], $statusIn:[String], $orderBy: [String], $first:Int, $startDate:Date, $endDate:Date , $locationIn:[ID] ) {
    carIssues: allCarIssues(first:$first,orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, stageIn:$stageIn, vendorIn:$vendorIn, assignedToIn:$assignedToIn, categoryIn:$categoryIn, statusIn:$statusIn, startDate:$startDate, endDate:$endDate, locationIn:$locationIn){
      edges{
        cursor
        node{
            id
            dateAdded
            dateModified
            issueDesc
            issueCategory{
                id
                name:category
            }
            reportedBy
            dateClosed
            canPickup
            alertDriver
            needsReview
            status
            notes
            metadata
            projectedCost
            severity
            incidentdamage{
                id
                incident{
                    id
                    carCondition{
                        id
                    }
                }
            }
            pictures{
                edges{
                    node{
                        id
                        dateAdded
                        pictureUrl
                        pictureType
                        description
                    }
                }
            }
            vendor{
                id
                name
            }
            user{
                id
                username
                firstName
                lastName
            }
            car{
                id
                pk
                currentAgreement{
                    driver{
                        id
                        firstName
                    }
                }
                carModel{
                    make
                    name
                    series
                    groupType{
                        id
                        name
                    }
                }
                year
                location
                color
                notes
                stage
                assignedTo
                branch{
                    id
                    name
                }
                carpolicySet{
                    edges{
                        node{
                            id
                            status
                            insurancePolicy{
                                id
                                startDate
                                endDate
                                status
                                policyNumber
                                broker{
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                currentAgreement{
                    driver{
                        id
                        tlcLicense
                        name
                    }
                }
            }
        }
      }
      pageInfo{
          endCursor
          hasNextPage
      }
    } 
}`

const locateCar = gql`
    mutation locateCar($input: TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
            }
            location{
                address
                longitude
                latitude
                speed
                date
            }
        }
    }`

const tableHeaders = [
    { id: "id", name: "Issue Details", sortable: true },
    { id: "car__id", name: "Car", sortable: true },
    { id: "car__location__name", name: "Car Location", sortable: true },
    { id: "assigned_to", name: "Assigned To", sortable: false },
    { id: "notes", name: "Issue Notes", sortable: false },
    { id: "action", name: "Actions", sortable: false },
]

class IssuesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectedDrivers: [],
            location: null,
            loading: false,
            selectAll: false,
            error: "",
        }
    }
    viewPictures = (carPictures, index = 0) => {
        let pictures = carPictures.map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }
    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    locateCar = (carId, modalName) => {
        if (carId) {
            this.setState({ loading: true })
            let input = { carId: carId, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address, longitude: Number(location.longitude), latitude: Number(location.latitude), speed: location.speed, date: location.date
                    }
                    this.setState({ location: location, loading: false, openModal: modalName })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", openModal: modalName, loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    }
    updateSelected = (value) => {
        let selectedDrivers = this.props.selectedDrivers
        if (value.car && value.car.currentAgreement && value.car.currentAgreement.driver && value.car.currentAgreement.driver.id && selectedDrivers && selectedDrivers.includes(value.car.currentAgreement.driver.id)) {
            selectedDrivers = selectedDrivers.filter(item => item !== value.car.currentAgreement.driver.id)
        } else if (value.car && value.car.currentAgreement && value.car.currentAgreement.driver && value.car.currentAgreement.driver.id && renderEnumToInt(value.car.stage) == 10 && !selectedDrivers.includes(value.car.currentAgreement.driver.id)) {
            selectedDrivers.push(value.car.currentAgreement.driver.id)
        }
        this.props.setSelectedDrivers(selectedDrivers)
    };

    toggleSelectAllClick = () => {
        if (this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 && !this.state.selectAll) {
            this.props.setSelectedDrivers(this.props.carIssues.edges.map(n => n.node.car && n.node.car.currentAgreement && n.node.car.currentAgreement.driver ? n.node.car.currentAgreement.driver.id : ""))
            this.setState({ selectAll: true })
        } else {
            this.props.setSelectedDrivers([])
            this.setState({ selectAll: false })
        }
    };
    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} loadMoreEntries={this.props.carIssues && this.props.carIssues.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ? <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}&nbsp;<i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? "fa-sort-asc" : this.props.orderBy.includes("-" + tableHeader.id) ? "fa-sort-desc" : "fa-sort")} aria-hidden="true"></i></th> : <th key={tableHeader.id}>{tableHeader.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 ? this.props.carIssues.edges.map((issue, i) =>
                            <tr style={issue.node.status === "CLOSED" ? { backgroundColor: 'aliceblue' } : {}}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedDrivers.includes(issue.node && issue.node.car && issue.node.car.currentAgreement && issue.node.car.currentAgreement.driver && issue.node.car.currentAgreement.driver.id) && <span className="listing-counter">{i + 1}</span>}
                                    <span className="listing-checkbox" onClick={() => this.updateSelected(issue.node)}>
                                        {issue.node && issue.node.car && issue.node.car.currentAgreement && issue.node.car.currentAgreement.driver && issue.node.car.currentAgreement.driver.id && this.props.selectedDrivers.includes(issue.node.car.currentAgreement.driver.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                <td>
                                    <div className="bos-table-td-left-box">
                                        {this.state.openModal && this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={issue.node} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                        {issue.node.incidentdamage && issue.node.incidentdamage.incident ?
                                            <Link target="_blank" to={`/car/${issue.node.car.pk}?inspectionId=${issue.node.incidentdamage.incident.carCondition.id}&incidentId=${issue.node.incidentdamage.incident.id}#inspections`}>
                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                            </Link>
                                            : <i id={"editCarIssue" + i} className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.toggleModal("editCarIssue" + i)}></i>
                                        }
                                    </div>

                                    <div className="bos-table-td-right-box">
                                        <small>{issue.node.reportedBy ? issue.node.reportedBy : "--"} | {issue.node.dateAdded ? moment(issue.node.dateAdded).tz("America/New_York").format('ll') : "--"} | {issue.node.status ? issue.node.status : "--"}</small>
                                        <div><b>{issue.node.issueCategory ? issue.node.issueCategory.name : "--"} | <span className='color-blue-neon'><i className="fa fa-location-arrow" aria-hidden="true" />&nbsp;&nbsp;{issue.node.vendor ? issue.node.vendor.name : "--"}</span></b></div>
                                        <small className='limit-text-long' style={{ whiteSpace: (this.state.showDesc && this.state.showDesc === issue.node.id ? "pre-wrap" : "nowrap") }}><i className={this.state.showDesc && this.state.showDesc === issue.node.id ? "fa fa-chevron-up" : "fa fa-chevron-down"} onClick={() => this.setState({ showDesc: (!this.state.showDesc || this.state.showDesc !== issue.node.id ? issue.node.id : null) })}></i> {issue.node.issueDesc ? issue.node.issueDesc : "--"}</small>
                                    </div>
                                    <div className='bos-table-td-right-box-actions-wrapper'>
                                        <ul className="bos-table-td-right-box-actions">
                                            <li>
                                                <i id={"canPickup" + i} className={"fa fa-circle " + (issue.node.canPickup ? "text-success" : "text-danger")} aria-hidden="true"></i>
                                                <UncontrolledTooltip placement="bottom" target={"canPickup" + i}>{issue.node.canPickup ? "Can Pickup" : "Can't Pickup"}</UncontrolledTooltip>
                                            </li>
                                            {issue.node.issueCategory && issue.node.issueCategory.name && <li>
                                                <Badge color="blue">{Object.keys(CATEGORIES).find(item => CATEGORIES[item].map(item => item.toLowerCase()).includes(issue.node.issueCategory.name.toLowerCase())) || "Other"}</Badge>
                                            </li>}
                                        </ul>
                                    </div>
                                </td>
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <div className="bos-table-td-left-box">
                                            {this.state.openModal === "CarInfo" + issue.node.id && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + issue.node.id} id={issue.node.car.pk} carPk={issue.node.car.pk} carId={issue.node.car.id} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} />}
                                            <small>{issue.node.car.pk}</small>
                                            <br />
                                            <a onClick={() => this.toggleModal("CarInfo" + issue.node.id)} style={{ cursor: "pointer" }}>
                                                {/* <i className="fa fa-cog" style={{fontSize: "22px"}} aria-hidden="true"></i> */}
                                                <CarModelGroupIcon carGroup={issue.node.car.group} carColor={issue.node.car.color} id={issue.node.car.pk} key={i + 1} />
                                            </a>
                                            <br />
                                            <small>Stage {renderEnumToInt(issue.node.car.stage)}</small>
                                        </div>

                                        <div className="bos-table-td-right-box">
                                            <span>
                                                <small>{issue.node.car.year} | {issue.node.car.color} {issue.node.car.carModel && issue.node.car.carModel.groupType ? "| " + issue.node.car.carModel.groupType.name : ""}</small>
                                            </span>
                                            <div>
                                                <a className="bos-custom-link" href={"/car/" + issue.node.car.pk} target="_blank">
                                                    {toTitleCase(issue.node.car.carModel.make)} {toTitleCase(issue.node.car.carModel.name)}
                                                </a>
                                                <small>{issue.node.car.carModel.series ? "(" + issue.node.car.carModel.series + ")" : ""}</small> | <span className='color-blue-neon'>{issue.node.car.branch ? issue.node.car.branch.name : "--"}</span>
                                                {(issue.node.car.stage == 10 || (issue.node.car.currentAgreement && issue.node.car.currentAgreement.id)) && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                </>}
                                            </div>
                                            <small>
                                                {/* <span id={"daysInStage"+i}>{issue.node.car.daysInStage} Days</span> |  */}
                                                {issue.node.car.carpolicySet && issue.node.car.carpolicySet.edges && issue.node.car.carpolicySet.edges.length > 0 &&
                                                    issue.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1').length > 0 ?
                                                    issue.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1')[0].node.insurancePolicy.policyNumber
                                                    : "No Insurance Found!"}
                                                {/* <UncontrolledTooltip placement="right" target={"daysInStage"+i}>Since in Stage {renderEnumToInt(issue.node.car.stage)}</UncontrolledTooltip> */}
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={"car-location" + (this.props.isFleetManagement ? " not-bold" : "")}>
                                        <div className="edit-location-icon">
                                            <UpdateCarInfo car={issue.node.car} title="Edit Location" name="location" value={issue.node.car.location} target={"locationEdit" + i} id="location" type="text" open={this.state.openModal === "locationEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />
                                            <i id={"locationEdit" + i} className="fa fa-pencil" aria-hidden="true" onClick={() => this.toggleModal("locationEdit" + i)}></i>
                                        </div>

                                        {issue.node.car.location ? issue.node.car.location : "--"}
                                    </div>
                                </td>
                                <td>
                                    {this.state.openModal && this.state.openModal === "carAssignment" + i && <CarRepAssignmentModal
                                        open={this.state.openModal === "carAssignment" + i}
                                        target={"carAssignment" + i}
                                        selectedCars={[issue.node.car.id]}
                                        handleClose={() => this.toggleModal("carAssignment" + i)}
                                        refetch={this.props.refetchQuery}
                                        groups={["Maintenance"]}
                                        orderBy={["username"]}
                                    />}
                                    <span id={"carAssignment" + i}>
                                        <i onClick={() => this.toggleModal("carAssignment" + i)} className={"fa fa-pencil"}></i>&nbsp;&nbsp;{issue.node.car && issue.node.car.assignedTo ? issue.node.car.assignedTo : "--"}
                                    </span>
                                </td>
                                <td>
                                    <div className="notes">
                                        {this.state.openModal === "notesHistory" + i &&
                                            <CarNotes car={issue.node.car} target={"issueNotesEdit" + i} noteType="Car Issue" title="Issue Notes History" objectId={issue.node.id} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} />
                                        }
                                        {this.state.openModal === "issueNotesEdit" + i &&
                                            <UpdateCarIssue carIssue={issue.node} title="Edit Issue Notes" name="notes" value={issue.node.notes} target={'issueNotesEdit' + i} id="notes" type="text" open={this.state.openModal === 'issueNotesEdit' + i} handleClose={() => this.toggleModal('issueNotesEdit' + i)} refetchQuery={this.props.refetchQuery} />
                                        }

                                        <span id={"issueNotesEdit" + i}>
                                            <i onClick={() => this.toggleModal("notesHistory" + i)} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            {this.props.hasPermissions && this.props.hasPermissions.includes("change_carissues") &&
                                                <i onClick={() => this.toggleModal("issueNotesEdit" + i)} className={"fa fa-pencil-square-o"}></i>}&nbsp;&nbsp;
                                            <small className="note-text">
                                                {issue.node.notes ? issue.node.notes : "--"}
                                            </small>
                                            <UncontrolledTooltip placement="top" target={"issueNotesEdit" + i}>{issue.node.notes ? issue.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <ul className="actions pt-3 pl-0">
                                        <li>
                                            <a id={"LocationPopup" + i} href={`https://fleetsync.io/cars/${issue.node.car.pk}?tab=Map`} target={"_blank"} rel="noopener noreferrer">
                                                <LocationIcon width="20" fill="#303E67" />
                                            </a>
                                            <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>
                                                Locate car on fleetSync
                                            </UncontrolledTooltip>
                                        </li>
                                        {issue.node.pictures && issue.node.pictures.edges && issue.node.pictures.edges.length > 0 && <li>
                                            {this.state.selectedImage &&
                                                <DocumentModalNew
                                                    document={this.state.selectedImage}
                                                    handleClose={() => this.setState({ selectedImage: null })}
                                                    next={this.next}
                                                    prev={this.prev}
                                                    objectType={"car"}
                                                    isPicture={true}
                                                    refetch={this.props.refetch}
                                                    currentUser={this.props.currentUser}
                                                />
                                            }
                                            <span onClick={() => this.viewPictures(issue.node.pictures.edges)}>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                                                {issue.node.pictures.edges.length > 1 &&
                                                    <a className="bos-custom-link" target="_blank">
                                                        {" "}+{issue.node.pictures.edges.length - 1} more
                                                    </a>}
                                            </span>
                                        </li>}
                                    </ul>
                                </td>
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Car Issues Found!"}</td>
                        </tr>}
                    </tbody>
                </Table>
            </PrimaryListingContainer>)
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(HasPermissionsQuery, {
    options: () => ({
        variables: { userPermissions: ["change_carissues"] },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    }),
    props: ({ data: { hasPermissions, loading, variables } }) => ({
        hasPermissions, loading, variables
    })
})(
    graphql(locateCar, {
        props: ({ mutate }) => ({
            locate: (input) => mutate({ variables: { input } })
        })
    })(
        graphql(CarIssuesQuery, {
            options: ({ searchTerm, orderBy, stageIn, categoryIn, statusIn, vendorIn, assignedToIn, startDate, endDate, locationIn }) => ({
                variables: { searchTerm, orderBy, stageIn, categoryIn, statusIn, first: 30, vendorIn, assignedToIn, startDate, endDate, locationIn },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only'
            }),
            props: ({ data: { loading, carIssues, fetchMore, variables } }) => ({
                loading,
                carIssues,
                variables,
                refetchQuery: () => {
                    let currentLength = carIssues && carIssues.edges ? carIssues.edges.length : 30
                    return fetchMore({
                        query: CarIssuesQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.carIssues.edges
                            const pageInfo = fetchMoreResult.carIssues.pageInfo
                            return {
                                carIssues: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.carIssues.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: CarIssuesQuery,
                        variables: {
                            cursor: carIssues.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.carIssues.edges
                            const pageInfo = fetchMoreResult.carIssues.pageInfo
                            return {
                                carIssues: {
                                    edges: [...previousResult.carIssues.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    stageIn: previousResult.stageIn,
                                    categoryIn: previousResult.categoryIn,
                                    statusIn: previousResult.statusIn,
                                    vendorIn: previousResult.vendorIn,
                                    locationIn: previousResult.locationIn,
                                    assignedToIn: previousResult.assignedToIn,
                                    __typename: previousResult.carIssues.__typename
                                },
                            }
                        },
                    })
                },
            })
        })(
            withApolloClient(IssuesList)
        )
    )
)
