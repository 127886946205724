import { US_STATES, MX_STATES, MX_CITIES, US_CITIES, PHONE_REGEX_MX, PHONE_REGEX_US } from "./Constants"

export function getStatesByCountry(countryCode) {
    const statesMap = {
        US: US_STATES,
        MX: MX_STATES,
    }

    if (countryCode === "ALL") {
        return Object.entries(statesMap).flatMap(([country, stateList]) =>
            Object.entries(stateList).map(([stateCode, stateName]) => ({
                value: stateCode,
                label: `${country} - ${stateCode} - ${stateName}`,
            }))
        )
    }

    return statesMap[countryCode] || US_STATES
}

export function getStateOptions(states) {
    return Object.keys(states).map((stateCode) => ({
        value: stateCode,
        label: `${stateCode} - ${states[stateCode]}`,
    }))
}

export function getCitiesByState(stateCode, countryCode) {
    const citiesMap = {
        US: US_CITIES,
        MX: MX_CITIES,
    }

    const statesMap = {
        US: US_STATES,
        MX: MX_STATES,
    }

    const stateName = statesMap[countryCode] && statesMap[countryCode][stateCode]
    return stateName && citiesMap[countryCode] && citiesMap[countryCode][stateName]
        ? citiesMap[countryCode][stateName]
        : []
}

export function getCityOptions(cities) {
    return cities.map((city) => ({
        value: city,
        label: city,
    }))
}

export const validatePhone = (phone, branchCountry) => {
    let isValid = false
    let errorMessage = ""

    if (branchCountry === "US") {
        isValid = PHONE_REGEX_US.test(phone)
        errorMessage = "Invalid phone number. Use +1 format, e.g., +19171230000."
    } else if (branchCountry === "MX") {
        isValid = PHONE_REGEX_MX.test(phone)
        errorMessage = "Invalid phone number. Use +52 format, e.g., +525551234567."
    } else {
        errorMessage = `Invalid phone number format. The provided country code '${branchCountry}' is not supported.`
    }

    return {
        isValid,
        errorMessage: isValid ? null : errorMessage,
    }
}

export const getPhonePlaceholder = (branchCountry) => {
    if (branchCountry === "US") {
        return "e.g., +19171230000."
    } else if (branchCountry === "MX") {
        return "e.g., +525551234567."
    }
    return "Phone"
}

export const getPhoneMaxLength = (branchCountry) => {
    if (branchCountry === "US") {
        return 12
    } else if (branchCountry === "MX") {
        return 13
    }
    return 15
}
