import React, { Component } from "react"
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Alert, Input, Label } from 'reactstrap'
import { RefundStripePaymentMutation } from "./Mutations"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

class RefundModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: "",
            loading: false,
            input: {
                driverId: this.props.driverId,
                paymentId: this.props.paymentId,
            }
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName)
            this.setState({ openModal: "" })
        else
            this.setState({ openModal: modalName })
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState((prevState) => ({ input: { ...prevState.input, [name]: value } }))
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    handleRefund = () => {
        if (!this.state.input.amountToRefund || this.state.input.amountToRefund <= 0 || this.state.input.amountToRefund > this.props.maxRefund) {
            this.setState({ error: `Invalid amount to refund, it should be greater than 0 and less than ${this.props.maxRefund}` })
            return
        }
        if (!this.state.input.reason) {
            this.setState({ error: `Please enter a reason for the refund.` })
            return
        }
        this.setState({ loading: true })
        this.props.apolloClient.mutate({
            mutation: RefundStripePaymentMutation,
            variables: {
                input: {
                    ...this.state.input,
                    amountToRefund: parseFloat(this.state.input.amountToRefund, 10)
                }
            }
        }).then((result) => {
            if (result && result.data && result.data.refundStripePayment && result.data.refundStripePayment.ok) {
                this.setState({ loading: false })
                this.props.refetchQuery()
                this.props.handleClose()
            } else {
                let errorMessage = "Something went wrong. Please contact Admin"
                if (result.data.refundStripePayment.errors && result.data.refundStripePayment.errors[0] && result.data.refundStripePayment.errors[0].messages) {
                    errorMessage = result.data.refundStripePayment.errors[0].messages
                }
                this.setState({ error: errorMessage, loading: false })
            }
        }).catch(err => {
            this.setState({ error: "Something went wrong. Please contact Admin", loading: false })
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="charge-driver-modal">
                <ModalHeader>
                    Refund Stripe Payment
                </ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    <Alert color="danger">This is a irreversible action. Please be sure you know what you are doing.</Alert>
                    <Row>
                        <Col>
                            <Label>Amount to Refund</Label>
                            <Input name="amountToRefund" type="number" min={0} max={this.props.maxRefund} onChange={this.updateInput} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Label>Reason for Refund</Label>
                            <Input name="reason" onChange={this.updateInput} />
                        </Col>
                    </Row>
                    <br />
                </ModalBody>
                <ModalFooter>
                    <Button className="card-component-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                    <Button color="primary" onClick={() => this.toggleModal("refundPayment")}>Refund</Button>
                    {this.state.openModal === "refundPayment" &&
                        <ConfirmationPopup
                            open={this.state.openModal === "refundPayment"}
                            message={`Are you sure you want to make a refund of $${this.state.input.amountToRefund}?`}
                            loading={this.state.loading}
                            handleClose={() => this.toggleModal("")}
                            action={"Yes"}
                            confirmAction={this.handleRefund}
                            error={this.state.error ? this.state.error : ""}
                        />
                    }
                </ModalFooter>
            </Modal>
        )
    }
} //Iagami - Code upgradation React 18
export default withApolloClient(RefundModal)
