import React, { useState } from "react"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Badge, Input, FormGroup, Row, Col, Alert, Progress } from "reactstrap"
import moment from "moment"
import { UncontrolledTooltip } from "reactstrap"
import UpdatePaymentScheduleInfo from "./UpdatePaymentScheduleInfo"
import { PreferredDateTimeContext } from "../../Context/PreferredDateTimeContext"
import Label from "reactstrap/lib/Label"
import { HasPermissionsQuery } from "../../Functions/querys"
import { CreatePaymentScheduleMutation, UpdatePaymentScheduleMutation } from "../Transactions/Mutations"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import DateTimePicker from "../../Material/DateTimePicker"
import { PaymentScheduleQuery } from "../../Drivers/Driver/Detail/Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import "./PaymentScheduleModal.css"
import { PAYMENT_SCHEDULE_STATUS_COLOR_MAP } from "../../Constants"
//Iagami - Code upgradation React 18
const PaymentScheduleModal = ({ action, paymentSchedule, driverId, paymentScheduleId, handleClose, open, apolloClient, hasPermissions, refetchQuery, refetchPaymentScheduleQuery }) => {
    const [showEdit, setShowEdit] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [paymentScheduleCanceled, setPaymentScheduleCanceled] = useState(false)
    const [input, setInput] = useState({})

    const toggleEdit = (field) => {
        if (showEdit === field) {
            setShowEdit("")
        } else {
            setShowEdit(field)
        }
    }

    const createPaymentSchedule = (e) => {
        try {
            setLoading(true)
            let mutationInput = input
            mutationInput["driverId"] = driverId
            //Iagami - Code upgradation React 18
            apolloClient.mutate({
                mutation: CreatePaymentScheduleMutation,
                variables: { input: mutationInput },
            }).then((result) => {
                if (result && result.data && result.data.createPaymentSchedule && result.data.createPaymentSchedule.ok) {
                    refetchQuery()
                    handleClose()
                } else if (result && result.data && result.data.createPaymentSchedule && result.data.createPaymentSchedule.errors[0] && result.data.createPaymentSchedule.errors[0].messages) {
                    setError(result.data.createPaymentSchedule.errors[0].messages)
                } else {
                    setError("An error has occurred. Please contact Admin!")
                }
                setLoading(false)
            }).catch((error) => {
                setError(error.message)
                setLoading(false)
            })
        } catch (error) {
            setError(error.message)
        }
    }

    const deletePaymentSchedule = () => {
        try {
            setLoading(true)
            //Iagami - Code upgradation React 18
            apolloClient.mutate({
                mutation: UpdatePaymentScheduleMutation,
                variables: { input: { "status": "Canceled", "paymentScheduleId": paymentScheduleId } },
            }).then((result) => {
                if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.ok) {
                    setPaymentScheduleCanceled(true)
                    setError(null)
                }
                else if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.errors[0] && result.data.updatePaymentSchedule.errors[0].messages) {
                    setError(result.data.updatePaymentSchedule.errors[0].messages)
                }
                setLoading(false)
            }).catch((err) => {
                setError("An error has occurred " + err)
                setLoading(false)
            })
        } catch (err) {
            let error = "An error has occurred " + err
            setError(error)
            setLoading(false)
        }
    }

    const updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            value = parseFloat(value) || 0
        }
        setInput(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const closeConfirmationPopup = () => {
        handleClose("")
        refetchQuery()
    }

    return (
        <Modal isOpen={open} toggle={handleClose} className="payment-schedule-modal">
            <ModalHeader>{action} Payment Schedule</ModalHeader>
            <ModalBody>
                <Row>
                    {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                    {loading && <Progress animated color="info" value={100} />}
                    <Col xs={12}>
                        <FormGroup>
                            {showEdit === "amountToCharge" &&
                                <UpdatePaymentScheduleInfo
                                    open={showEdit === "amountToCharge"}
                                    target="amountToCharge"
                                    title="Amount to charge"
                                    type="number"
                                    name="amountToCharge"
                                    value={paymentSchedule && paymentSchedule.amountToCharge}
                                    paymentScheduleId={paymentScheduleId}
                                    refetchQuery={refetchPaymentScheduleQuery}
                                    handleClose={() =>
                                        toggleEdit("")
                                    }
                                />
                            }
                            <Label for="amountToCharge">Enter Amount to Charge</Label> {action === "UPDATE" && paymentSchedule && ["PENDING", "SCHEDULED"].includes(paymentSchedule.status) && <i id="amountToCharge" className="fa fa-pencil-square-o float-right" onClick={() => toggleEdit("amountToCharge")} />}
                            <Input type={"number"} name="amountToCharge" id="amountToCharge" min={0} onChange={updateInput} value={paymentSchedule && paymentSchedule.amountToCharge} placeholder="Enter Amount to Charge" disabled={action === "UPDATE"} />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            {showEdit === "scheduleDatetime" &&
                                <UpdatePaymentScheduleInfo
                                    open={showEdit === "scheduleDatetime"}
                                    target="scheduleDatetime"
                                    title="Schedule Date Time"
                                    type="datetime-local"
                                    name="scheduleDatetime"
                                    value={paymentSchedule && paymentSchedule.scheduleDatetime}
                                    paymentScheduleId={paymentScheduleId}
                                    refetchQuery={refetchPaymentScheduleQuery}
                                    handleClose={() =>
                                        toggleEdit("")
                                    }
                                />
                            }
                            <Label for="scheduleDatetime">Select Date & Time to Charge</Label> {action === "UPDATE" && paymentSchedule && ["PENDING", "SCHEDULED"].includes(paymentSchedule.status) && <i id="scheduleDatetime" className="fa fa-pencil-square-o float-right" onClick={() => toggleEdit("scheduleDatetime")} />}
                            {action === "CREATE" &&
                                <PreferredDateTimeContext.Consumer>
                                    {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                        <DateTimePicker
                                            type="datetime-local"
                                            min={moment().tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                            max={moment().tz(preferredDateTimeInfo.timezone).add(5, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                            name="scheduleDatetime"
                                            id="scheduleDatetime"
                                            value={new Date()}
                                            placeholder="Select schedule datetime"
                                            setError={(error) => setError(error)}
                                            updateInput={(name, value) => setInput({ ...input, [name]: value })}
                                        />
                                    )}
                                </PreferredDateTimeContext.Consumer>
                            }
                            {action === "UPDATE" && <PreferredDateTimeContext.Consumer>
                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                    <Input
                                        autoComplete={"off"}
                                        type={"datetime"}
                                        id={"scheduleDatetime"}
                                        name={"scheduleDatetime"}
                                        value={paymentSchedule && moment(paymentSchedule.scheduleDatetime).tz(preferredDateTimeInfo.timezone).format("YYYY-MM-DD HH:mm A")}
                                        placeholder="Select schedule datetime"
                                        disabled={true}
                                    />
                                )}
                            </PreferredDateTimeContext.Consumer>}
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            {showEdit === "notes" &&
                                <UpdatePaymentScheduleInfo
                                    open={showEdit === "notes"}
                                    target="notes"
                                    title="Notes"
                                    type="text"
                                    name="notes"
                                    value={paymentSchedule && paymentSchedule.nnotes}
                                    paymentScheduleId={paymentScheduleId}
                                    refetchQuery={refetchPaymentScheduleQuery}
                                    handleClose={() =>
                                        toggleEdit("")
                                    }
                                />
                            }
                            <Label for="notes">Payment schedule notes</Label> {action === "UPDATE" && paymentSchedule && ["PENDING", "SCHEDULED"].includes(paymentSchedule.status) && <i id="notes" className="fa fa-pencil-square-o float-right" onClick={() => toggleEdit("notes")} />}
                            <Input type={"text"} name="notes" id="notes" onChange={updateInput} placeholder="Notes" disabled={action === "UPDATE"} value={paymentSchedule && paymentSchedule.notes} />
                        </FormGroup>
                    </Col>
                    {paymentSchedule && paymentSchedule.status && <Col xs={6}>
                        <FormGroup>
                            <Label for="status">Status</Label>
                            <p className="column-content">
                                <Badge color={PAYMENT_SCHEDULE_STATUS_COLOR_MAP[paymentSchedule && paymentSchedule.status] || 'primary'} style={{ fontSize: 10, padding: 5 }}>{paymentSchedule && paymentSchedule.status}</Badge>
                            </p>
                        </FormGroup>
                    </Col>}
                    {paymentSchedule && paymentSchedule.status && <Col xs={6}>
                        {["PENDING", "SCHEDULED"].includes(paymentSchedule.status) &&
                            <>
                                <Label>Created By</Label>
                                <p>{paymentSchedule.createdBy ? paymentSchedule.createdBy.firstName ? paymentSchedule.createdBy.firstName + " " + paymentSchedule.createdBy.lastName : paymentSchedule.createdBy.username : "--"}</p>
                            </>
                        }
                        {paymentSchedule.status === "CANCELED" &&
                            <>
                                <Label>Canceled By</Label>
                                <p>{paymentSchedule.canceledBy ? paymentSchedule.canceledBy.firstName ? paymentSchedule.canceledBy.firstName + " " + paymentSchedule.canceledBy.lastName : paymentSchedule.canceledBy.username : "--"}</p>
                            </>
                        }
                    </Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Col className="d-flex justify-content-end ">
                    {paymentSchedule && paymentSchedule.status && ["PENDING", "SCHEDULED"].includes(paymentSchedule.status) && <>
                        {showEdit === "cancelPaymentSchedule" && <ConfirmationPopup
                            open={showEdit === "cancelPaymentSchedule"}
                            title={"Cancel Payment Schedule"}
                            loading={loading}
                            message={"Are you sure you want to cancel this payment schedule?"}
                            handleClose={() => paymentScheduleCanceled ? closeConfirmationPopup() : toggleEdit("")}
                            action={"Cancel Payment Schedule"}
                            confirmAction={deletePaymentSchedule}
                            color="danger"
                            error={error ? error : ""}
                            success={paymentScheduleCanceled ? "Scheduled Payment canceled successfully" : ""}
                        />
                        }
                        <Button className="payment-schedule-modal-danger-btn float-left" id={"deletePaymentSchedule"} onClick={() => toggleEdit("cancelPaymentSchedule")} disabled={!(hasPermissions && hasPermissions.includes("delete_paymentschedule")) || paymentScheduleCanceled}>
                            CANCEL
                        </Button>
                        {!(hasPermissions && hasPermissions.includes("delete_paymentschedule")) && <UncontrolledTooltip target={"deletePaymentSchedule"}>Don't have enough permission to perform this action</UncontrolledTooltip>}

                    </>}
                    {action === "CREATE" && <Button className="payment-schedule-modal-primary-btn float-right" color="primary" onClick={createPaymentSchedule} disabled={loading || (input.amountToCharge == 0 || input.amountToCharge < 1) || input.scheduleDatetime == null}>{loading ? "Loading..." : "Schedule Payment"}</Button>}
                    &nbsp;&nbsp;<Button className="payment-schedule-modal-secondary-btn float-right" color="secondary" onClick={handleClose}>Close</Button>
                </Col>
                {/* <Col xs={6}>
                    {action === "CREATE" && <Button className="payment-schedule-modal-primary-btn float-right" color="primary" onClick={createPaymentSchedule} disabled={loading || (input.amountToCharge == 0 || input.amountToCharge < 1) || input.scheduleDatetime == null}>{loading ? "Loading..." : "Schedule Payment"}</Button>}
                    &nbsp;&nbsp;<Button className="payment-schedule-modal-secondary-btn float-right" color="secondary" onClick={handleClose}>Close</Button>
                </Col> */}
            </ModalFooter>
        </Modal>
    )
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(PaymentScheduleQuery, {
    skip: ({ paymentScheduleId }) => !paymentScheduleId,
    options: ({ paymentScheduleId }) => ({ variables: { id: paymentScheduleId }, fetchPolicy: 'cache-first' }),
    props: ({ data: { paymentSchedule, loading, refetch, variables } }) => ({
        paymentSchedule,
        loading,
        variables,
        refetchPaymentScheduleQuery: () => {
            return refetch({
                query: PaymentScheduleQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { paymentSchedule: fetchMoreResult.paymentSchedule }
                },
            })
        },
    }),
})(graphql(HasPermissionsQuery, {
    options: () => ({ variables: { userPermissions: ["delete_paymentschedule"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
    props: ({ data: { hasPermissions, variables } }) => ({
        hasPermissions,
        variables,
    }),
})(withApolloClient(PaymentScheduleModal)))
