import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import {
    Label, Input, FormGroup, Progress, Button, Modal, ModalHeader,
    ModalBody, ModalFooter, Alert, Row, Col, ButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, FormText
} from 'reactstrap'
import Select from "react-select"
import "../CarIssue.css"
import { CreateWorkOrderMutation, UpdateWorkOrderMutation } from "./Mutations"
import { VendorsQuery, CarsQuery, JobTypeQuery, WorkOrderQuery } from "./Queries"
import UpdateWorkorderInfo from "./UpdateWorkorderInfo"
import moment from "moment"
import Lightbox from "react-awesome-lightbox"
import "react-awesome-lightbox/build/style.css"

class WorkOrderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            vendor: null,
            date: null,
            loading: false,
            openModal: "",
            selectedCar: null,
            carSuggestions: null,
            salesTax: 0,
            workOrderId: null,
            workItemId: null,
            document: null,
            total: null,
            workOrderItems: [{ price: 0, quantity: 0, jobTypeId: "" }],
            show: true,
        }
        this.expandImage = this.expandImage.bind(this)

    }
    toggleModal = (modalName) => {
        this.state.openModal === modalName ? this.setState({ openModal: "" }) : this.setState({ openModal: modalName })
    }
    handleUpdateWorkItem = (...args) => {
        if (args[0] === "add") {
            let item = this.state.workOrderItems
            item.push({ price: 0, quantity: 0, jobTypeId: "" })
            this.setState({ workOrderItems: item })
        } else if (args[0] === "remove") {
            let items = this.state.workOrderItems
            items.splice(args[1], 1)
            this.setState({ workOrderItems: [...items] })
        } else {
            const list = [...this.state.workOrderItems]
            list[args[2]][args[0]] = args[1]
            this.setState({ workOrderItems: list })
        }
    }
    handleUpdateCarSearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.apolloClient.query({
                query: CarsQuery,
                variables: { searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                    this.setState({ carSuggestions: response.data.allCars.edges })
                } else {
                    this.setState({ carSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ carSuggestions: null })
            })
        } else {
            this.setState({ carSuggestions: null })
        }
    };
    handleUpdateSalesTax = (value) => {
        this.setState({ salesTax: value })
    }
    handleSubmit = () => {
        try {
            if (
                !this.state.selectedCar ||
                !this.state.salesTax ||
                !this.state.vendor ||
                this.state.workOrderItems.find(item => item.price === 0 || item.quantity === 0 || item.jobTypeId === "")
            )
                throw 'Error: Required fields are missing'
            else
                this.updateWorkOrder()
        } catch (err) {
            this.setState({ error: err, loading: false })
        }

    }
    removeInvoice = () => {
        if (this.props.isUpdate) {
            let input = {}
            input["workOrderId"] = this.state.workOrderId
            input["invoice"] = ""
            this.props.updateWO(input).then(result => {
            })
        }
        this.setState({ document: null })
    }
    updateWorkOrder = () => {
        let input = {}
        input['vendorId'] = this.state.vendor
        input['carId'] = this.state.selectedCar.id
        input['salesTax'] = this.state.salesTax
        input['workItems'] = this.state.workOrderItems
        if (this.state.completionDate) {
            input['completionDate'] = this.state.completionDate
        }
        if (this.state.document) {
            input['invoice'] = this.state.document
        }
        this.props.create(input).then(result => {
            if (result.data && result.data.createWorkOrder && result.data.createWorkOrder.ok) {
                this.props.refetchQuery()
                this.handleClose()
            } else {
                this.setState({ error: result.data.createWorkOrder.errors[0].messages })
            }
        }).catch((error) => {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        })
    }
    handleClose = () => {
        let state = {
            error: null,
            completionDate: null,
            loading: false,
            openModal: "",
            selectedCar: null,
            carSuggestions: null,
            document: null,
            salesTax: 0,
            total: 0,
            workOrderItems: [{ price: 0, quantity: 0, jobTypeId: "" }],
            vendor: null,
            expand: false
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }
    updateDocument = (e) => {
        let base64Img = e.target.files[0]
        let name = e.target.files[0].name
        let _this = this
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            _this.setState({ document: imageInBase64, fileName: name })
        }
    }
    fetchData = () => {
        this.props.apolloClient.query({
            query: WorkOrderQuery,
            variables: { id: this.props.id }
        }).then(result => {
            if (result && result.data && result.data.workOrder) {
                let workOrder = result.data.workOrder
                let metadata = this.fetchMetaData(workOrder.metadata, "invoice_url")
                this.setState({
                    workOrderId: workOrder.id,
                    workOrderItems: workOrder.workitemSet.edges.map(item => ({
                        jobTypeId: item.node.jobType.id,
                        name: item.node.jobType.name,
                        price: item.node.price,
                        quantity: item.node.quantity,
                        id: item.node.id
                    })),
                    vendor: workOrder.vendor.name,
                    selectedCar: workOrder.car,
                    salesTax: workOrder.salesTax,
                    completionDate: workOrder.completionDate ? moment(workOrder.completionDate).format(moment.HTML5_FMT.DATE) : null,
                    document: metadata
                })
            }
        })
    }
    componentDidMount() {
        const { apolloClient } = this.props
        if (this.props.isUpdate) {
            this.fetchData()
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.workOrderItems !== this.state.workOrderItems || prevState.salesTax !== this.state.salesTax) {
            let prices = this.state.workOrderItems.map(x => Number(x.price))
            let totalAmount = 0
            if (prices.length > 0) {
                totalAmount = prices.reduce((x, y) => x + y)
            }
            this.setState({ total: totalAmount + Number(this.state.salesTax) })
        }
    }
    expandImage() {
        let showing = this.state.show
        this.setState({ show: !showing })
    }

    render() {
        return (
            <div>
                {this.state.openModal === "viewInvoice" &&
                    <div className="bos-img-viewer">"
                        <Lightbox image={this.props.isUpdate ? this.state.document : ("data:image/jpeg;base64," + this.state.document)} title="invoice" onClose={() => this.toggleModal("viewInvoice")} />
                    </div>
                }
                <Modal className="WorkOrderModal" style={{ maxWidth: "850px" }} isOpen={this.props.open} size={'lg'}>
                    <ModalHeader>
                        {this.props.isUpdate ? "Update" : "Create"} Work Order
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {!this.state.expand && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="carId"><small>What's the Car ID? *</small></Label>
                                    {this.state.selectedCar ? <><br /><b>{this.state.selectedCar.pk}</b>&nbsp;&nbsp;&nbsp;{!this.props.isUpdate && <i className="fa fa-times" onClick={() => this.setState({ selectedCar: null, carSuggestions: null })} />} </> :
                                        <ButtonDropdown
                                            className="w-100"
                                            isOpen={this.state.openModal === "CarFinder"}
                                            toggle={() => this.toggleModal("CarFinder")}
                                        >
                                            <DropdownToggle caret>
                                                Choose Car for Work Order
                                            </DropdownToggle>
                                            <DropdownMenu
                                                className='w-100'
                                            >
                                                <Label
                                                    for="carSearch"
                                                    className='pl-3'
                                                >
                                                    <small>
                                                        Search by id, vin, dmv plate
                                                    </small>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    onChange={this.handleUpdateCarSearch}
                                                    name="driverSearch"
                                                    id="driverSearch"
                                                />
                                                {this.state.carSuggestions &&
                                                    this.state.carSuggestions.length > 0 &&
                                                    this.state.carSuggestions.map((car, i) =>
                                                        <DropdownItem key={i}
                                                            onClick={() => this.setState({ selectedCar: car.node })}
                                                            style={{ display: "content" }}
                                                        >
                                                            {car.node.pk} · {car.node.vin} · {car.node.dmvPlate ? car.node.dmvPlate : "No Plate"}
                                                        </DropdownItem>
                                                    )}
                                            </DropdownMenu>
                                        </ButtonDropdown>}
                                </FormGroup>

                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="vendor" className="d-block"><small>Select a Vendor *</small></Label>
                                    {!this.props.isUpdate ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={this.props.loading}
                                            options={this.props.allVendors &&
                                                this.props.allVendors.edges &&
                                                this.props.allVendors.edges.length > 0 &&
                                                this.props.allVendors.edges.map(item =>
                                                    ({ value: item.node.id, label: item.node.name })
                                                )
                                            }
                                            placeholder="Select Car Vendor"
                                            onChange={vendor => this.setState({ vendor: vendor ? vendor.value : null })}
                                        /> :
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="vendor"
                                                value={this.state.vendor ? this.state.vendor : ""}
                                                disabled={this.props.isUpdate ? true : false}
                                            />
                                            {this.props.isUpdate &&
                                                <i
                                                    id={"vendor"}
                                                    className="fa fa-pencil-square edit-icon"
                                                    aria-hidden="true"
                                                    onClick={() => this.toggleModal("vendor")}
                                                >
                                                </i>
                                            }
                                            {this.state.openModal === "vendor" &&
                                                <UpdateWorkorderInfo
                                                    refetchData={this.fetchData}
                                                    title="Edit Vendor"
                                                    target={"vendor"}
                                                    name="vendorId"
                                                    id={this.state.vendor.id}
                                                    value={this.state.vendor}
                                                    updateWorkItem={false}
                                                    workOrderId={this.state.workOrderId}
                                                    handleClose={() => this.setState({ openModal: "" })}
                                                    open={this.state.openModal === "vendor"}
                                                />
                                            }
                                        </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>}
                        {!this.state.expand && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="completionDate" className="d-block"><small>Completion Date</small></Label>
                                    {!this.props.isUpdate ?
                                        <Input
                                            type="date"
                                            name="completionDate"
                                            value={this.state.completionDate}
                                            onChange={(e) => this.setState({ completionDate: e.target.value })}
                                        /> :
                                        <div className="edit-item">
                                            <Input
                                                type="date"
                                                name="completionDate"
                                                value={this.state.completionDate}
                                                disabled={this.props.isUpdate ? true : false}
                                            />
                                            {this.props.isUpdate &&
                                                <i
                                                    id={"completionDate"}
                                                    className="fa fa-pencil-square edit-icon"
                                                    aria-hidden="true"
                                                    onClick={() => this.toggleModal("completionDate")}
                                                >
                                                </i>
                                            }
                                            {this.state.openModal === "completionDate" &&
                                                <UpdateWorkorderInfo
                                                    refetchData={this.fetchData}
                                                    title="Edit Job Type"
                                                    target={"completionDate"}
                                                    name="completionDate"
                                                    value={this.state.completionDate}
                                                    updateWorkItem={false}
                                                    workOrderId={this.state.workOrderId}
                                                    handleClose={() => this.setState({ openModal: "" })}
                                                    open={this.state.openModal === "completionDate"}
                                                />
                                            }
                                        </div>
                                    }
                                </FormGroup>
                            </Col>
                            <Col>
                                {!this.props.isUpdate ?
                                    <div >
                                        {this.state.document ?
                                            <Row>
                                                <Col>
                                                    <Label for="document">
                                                        <small>Select Invoice</small>
                                                        <Input
                                                            type="file"
                                                            name="document"
                                                            id="document"
                                                            accept="image/*"
                                                            onChange={this.updateDocument}
                                                            className="edit-item"
                                                        />
                                                    </Label>
                                                    <p className="m-0">
                                                        <a onClick={() => this.toggleModal("viewInvoice")}>View Invoice</a>
                                                    </p>
                                                    <FormText color="muted mb-2">
                                                        Accepted files include jpg,jpeg and pngs
                                                    </FormText>
                                                </Col>
                                            </Row> :
                                            <FormGroup>
                                                <Label for="document"><small>Upload Invoice</small></Label>
                                                <Input
                                                    type="file"
                                                    name="document"
                                                    id="document"
                                                    accept="image/*"
                                                    onChange={this.updateDocument}
                                                    className="edit-item"
                                                />
                                                <FormText color="muted">
                                                    Accepted files include jpg,jpeg and pngs
                                                </FormText>
                                            </FormGroup>
                                        }
                                    </div> :
                                    <div>
                                        <Row>
                                            <Col>
                                                <Label for="document" className="w-100">
                                                    <small>Update Invoice</small>
                                                    {this.props.isUpdate &&
                                                        <i
                                                            id={"invoice"}
                                                            className="fa fa-pencil-square float-right"
                                                            aria-hidden="true"
                                                            onClick={() => this.toggleModal("invoice")}
                                                        >
                                                        </i>
                                                    }
                                                </Label>
                                                <p>
                                                    <a onClick={() => this.toggleModal("viewInvoice")}>View Invoice</a>
                                                </p>
                                                <FormText color="muted">
                                                    Accepted files include jpg,jpeg and pngs
                                                </FormText>
                                                {this.state.openModal === "invoice" &&
                                                    <UpdateWorkorderInfo
                                                        refetchData={this.fetchData}
                                                        title="Edit invoice"
                                                        target={"invoice"}
                                                        name="invoice"
                                                        value={this.state.document}
                                                        updateWorkItem={false}
                                                        workOrderId={this.state.workOrderId}
                                                        handleClose={() => this.setState({ openModal: "" })}
                                                        open={this.state.openModal === "invoice"}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                }

                            </Col>
                        </Row>}
                        {!this.state.expand && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="salesTax" className="d-block"><small>Sales Tax *</small></Label>
                                    <div className="edit-item">
                                        <Input
                                            type="number"
                                            placeholder='$0.00'
                                            min="0.1"
                                            name="salesTax"
                                            value={this.state.salesTax ? this.state.salesTax : ""}
                                            onChange={(e) => this.handleUpdateSalesTax(e.target.value)}
                                            disabled={this.props.isUpdate ? true : false}
                                        />
                                        {this.props.isUpdate &&
                                            <i id="salesTax"
                                                onClick={() => this.toggleModal("salesTax")}
                                                className="fa fa-pencil-square edit-icon"
                                                aria-hidden="true"
                                            >
                                            </i>
                                        }
                                        {this.state.openModal === "salesTax" &&
                                            <UpdateWorkorderInfo
                                                refetchData={this.fetchData}
                                                title="Edit Sales Tax"
                                                target={"salesTax"}
                                                name="salesTax"
                                                updateWorkItem={false}
                                                value={this.state.salesTax}
                                                workOrderId={this.state.workOrderId}
                                                handleClose={() => this.setState({ openModal: "" })}
                                                open={this.state.openModal === "salesTax"}
                                            />
                                        }
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="total" className="d-block"><small>Total</small></Label>
                                    <Input placeholder='$0.00' name="total" disbaled value={this.state.total} readOnly />
                                </FormGroup>
                            </Col>
                        </Row>}
                        {!this.state.expand && <Row>
                            <Col><h4 className='text-center'>Work Items</h4></Col>
                        </Row>}
                        {!this.state.expand && this.state.workOrderItems.map((item, index) =>
                            <Row className='work-order-item'>
                                <Col>
                                    {index === 0 && <Label for="total" className="d-block" required><small>Job Type *</small></Label>}
                                    {!this.props.isUpdate ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            name="jobTypeId"
                                            options={this.props.allJobTypes &&
                                                this.props.allJobTypes.edges.length > 0 &&
                                                this.props.allJobTypes.edges.map((job) => ({
                                                    value: job.node.id,
                                                    label: job.node.name
                                                }))
                                            }
                                            placeholder="Select Job"
                                            onChange={job => this.handleUpdateWorkItem("jobTypeId", job.value, index)}
                                        /> :
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="jobType"
                                                value={this.state.workOrderItems[index].name ?
                                                    this.state.workOrderItems[index].name :
                                                    ""
                                                }
                                                disabled={this.props.isUpdate ? true : false}
                                            />
                                            {this.props.isUpdate &&
                                                <i
                                                    id={"jobTypeId" + index}
                                                    className="fa fa-pencil-square edit-icon"
                                                    aria-hidden="true"
                                                    onClick={() => this.toggleModal("jobTypeId" + index)}
                                                >
                                                </i>
                                            }
                                            {this.state.openModal === "jobTypeId" + index &&
                                                <UpdateWorkorderInfo
                                                    refetchData={this.fetchData}
                                                    title="Edit Job Type"
                                                    id={this.state.workOrderItems[index].jobTypeId}
                                                    target={"jobTypeId" + index}
                                                    name="jobTypeId"
                                                    value={this.state.workOrderItems[index].name}
                                                    updateWorkItem={true}
                                                    workItemId={this.state.workOrderItems[index].id}
                                                    handleClose={() => this.setState({ openModal: "" })}
                                                    open={this.state.openModal === "jobTypeId" + index}
                                                />
                                            }
                                        </div>
                                    }
                                </Col>
                                <Col>
                                    {index === 0 && <Label for="quantity" className="d-block" required><small>Quantity *</small></Label>}
                                    <div className="edit-item">
                                        <Input
                                            type="number"
                                            placeholder='Quantity'
                                            min="1"
                                            name="quantity"
                                            value={this.state.workOrderItems[index].quantity ?
                                                this.state.workOrderItems[index].quantity :
                                                ""
                                            }
                                            onChange={e =>
                                                this.handleUpdateWorkItem(e.target.name, e.target.value, index)
                                            }
                                            disabled={this.props.isUpdate ? true : false}
                                        />
                                        {this.props.isUpdate &&
                                            <i
                                                id={"quantity" + index}
                                                className="fa fa-pencil-square edit-icon"
                                                aria-hidden="true"
                                                onClick={() => this.toggleModal("quantity" + index)}
                                            >
                                            </i>
                                        }
                                        {this.state.openModal === "quantity" + index &&
                                            <UpdateWorkorderInfo
                                                refetchData={this.fetchData}
                                                title="Edit Quantity"
                                                target={"quantity" + index}
                                                name="quantity"
                                                value={this.state.workOrderItems[index].quantity}
                                                updateWorkItem={true}
                                                workItemId={this.state.workOrderItems[index].id}
                                                handleClose={() => this.setState({ openModal: "" })}
                                                open={this.state.openModal === ("quantity" + index)}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    {index === 0 && <Label for="price" className="d-block" required><small>Price *</small></Label>}
                                    <div className="edit-item">
                                        <Input
                                            type="number"
                                            placeholder='Price'
                                            min="0.1"
                                            name="price"
                                            value={this.state.workOrderItems[index].price ? this.state.workOrderItems[index].price : ""}
                                            onChange={e => this.handleUpdateWorkItem(e.target.name, e.target.value, index)}
                                            disabled={this.props.isUpdate ? true : false}
                                        />
                                        {this.props.isUpdate &&
                                            <i
                                                id={"price" + index}
                                                className="fa fa-pencil-square edit-icon"
                                                aria-hidden="true"
                                                onClick={() => this.toggleModal("price" + index)}
                                            >
                                            </i>
                                        }
                                        {this.state.openModal === "price" + index &&
                                            <UpdateWorkorderInfo
                                                refetchData={this.fetchData}
                                                title="Edit Quantity"
                                                target={"price" + index}
                                                name="price"
                                                value={this.state.workOrderItems[index].price}
                                                updateWorkItem={true}
                                                workItemId={this.state.workOrderItems[index].id}
                                                handleClose={() => this.setState({ openModal: "" })}
                                                open={this.state.openModal === "price" + index}
                                            />
                                        }
                                    </div>
                                </Col>

                                {!this.props.isUpdate && this.state.workOrderItems.length != 1 &&
                                    <span className='add-remove-work-order' style={{ paddingTop: index === 0 ? "1.5rem" : "0rem" }}>
                                        <i className="fa fa-times text-danger"
                                            aria-hidden="true"
                                            onClick={() =>
                                                this.handleUpdateWorkItem("remove", index)
                                            }>
                                        </i>
                                    </span>
                                }
                                {index === this.state.workOrderItems.length - 1 &&
                                    <span className='add-remove-work-order' style={{ paddingTop: index === 0 ? "1.5rem" : "0rem" }}>
                                        {this.state.openModal === "addWorkItem" &&
                                            <UpdateWorkorderInfo
                                                refetchData={this.fetchData}
                                                title="Add Work Item"
                                                target={"addWorkItem"}
                                                workOrderId={this.state.workOrderId}
                                                updateWorkItem={false}
                                                createWorkItem={true}
                                                handleClose={() => this.setState({ openModal: "" })}
                                                open={this.state.openModal === "addWorkItem"}
                                            />
                                        }
                                        <i id="addWorkItem"
                                            className="fa fa-plus text-success"
                                            aria-hidden="true"
                                            onClick={() => {
                                                this.props.isUpdate ?
                                                    this.toggleModal("addWorkItem") :
                                                    this.handleUpdateWorkItem("add")
                                            }}></i>
                                    </span>
                                }
                            </Row>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                        {!this.props.isUpdate && <Button
                            color="primary"
                            onClick={() => this.handleSubmit()}
                        >
                            Create Work Order
                        </Button>}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(VendorsQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allVendors } }) => ({
        loading,
        allVendors
    })
})(graphql(JobTypeQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allJobTypes } }) => ({
        loading,
        allJobTypes
    })
})(graphql(CreateWorkOrderMutation, {
    props: ({ mutate }) => ({
        create: (input) => mutate({ variables: { input } })
    })
})(graphql(UpdateWorkOrderMutation, {
    props: ({ mutate }) => ({
        updateWO: (input) => mutate({ variables: { input } })
    })
})(withApolloClient(WorkOrderModal)))))
