import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import { Container, Row, Col, Card, CardBody, Button, FormGroup, Input, Form } from 'reactstrap'
import { CarStages } from '../../Functions/querys'
import Select from 'react-select'
import '../Listing.css'
import { debounce } from 'lodash'
import { CarsQuery, VendorsQuery, JobTypeQuery } from "./Queries"
import WorkordersList from "./WorkordersList"
import RestrictedSection from "../../Components/RestrictedSection"


class WorkOrderContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: null,
            selectedVendors: [],
            selectedStatuses: [],
            jobTypes: [],
            selectedReps: [],
            selectedStages: [],
            selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }],
            showDateRangePopup: false,
            searchTerm: "",
            orderBy: ["-id"],
            showAllFilters: false,
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.dataset.type) {
            if (value === "false") value = false
            else if (value === "true") value = true
        }
        if (value === "null") value = null
        if (e.target.type === "date" && !value) value = null
        this.setState({ [name]: value })
    };
    updateSearchTerm = debounce((searchTerm) => {
        this.setState({ searchTerm: searchTerm })
    }, 500)


    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };

    handleDateSelection = (ranges) => {
        this.setState({ selectionRange: [ranges.selection] })
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_workorder">
                <Container fluid className="bos-listing-container">
                    <Card className="bos-listing-filters-card">
                        <CardBody>
                            <Row >
                                <Col xs={3}>
                                    <FormGroup className="mb-0">
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={!(this.props.allVendors && this.props.allVendors.edges && this.props.allVendors.edges.length > 0)}
                                            options={this.props.allVendors && this.props.allVendors.edges && this.props.allVendors.edges.length > 0 && this.props.allVendors.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                            placeholder="All Vendors"
                                            isClearable
                                            isMulti
                                            onChange={vendors => this.setState({ selectedVendors: (vendors.map(item => item.value)) })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={3} style={{ marginLeft: "auto" }}>
                                    <Form className="search-filter" onSubmit={(e) => e.preventDefault()} >
                                        <FormGroup className="mb-0">
                                            <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                                            <Button disabled className="search-icon">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col sm="12">
                            <WorkordersList
                                currentUser={this.props.currentUser}
                                vendorIn={this.state.selectedVendors.length > 0 ? this.state.selectedVendors : null}
                                jobTypeIn={this.state.jobTypes.length > 0 ? this.state.jobTypes : null}
                                stageIn={this.state.selectedStages.length > 0 ? this.state.selectedStages : null}
                                orderBy={this.state.orderBy}
                                searchTerm={this.state.searchTerm}
                                updateOrder={this.updateOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarStages, {
    props: ({ data: { carStages } }) => ({
        carStages
    })
})(graphql(JobTypeQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allJobTypes } }) => ({
        loading,
        allJobTypes
    })
})(graphql(VendorsQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allVendors } }) => ({
        loading,
        allVendors
    })
})(withApolloClient(WorkOrderContainer))));


