import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment-timezone'
import toTitleCase from '../../Functions/toTitleCase'
import Select from 'react-select'
import { HasPermissionsQuery } from "../../Functions/querys"
import { checkAllInputsFilled, handleDriverDataChange, validateDocumentData } from './utils'
import DocumentFields from "./DocumentFields"
import DocumentPreview from "../DocumentPreview/DocumentPreview"
import './UploadDocumentModal.css'
import withApolloClient from "../../withApolloClient"

const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypesQuery{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                  requiresExpiration
                  allowMultiplePages
                  documentCategory {
                    id
                    name
                  }
                }
            }
      }
    }
`

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
        allDriverDocumentTypes(isActive:true, driverId: $driverId){
            edges{
                node{
                  typeName
                  id
                  allowMultiplePages
                }
            }
        }
    }
`

const UploadDocumentMutation = gql`
mutation uploadDocumentMutation($input: UploadDocumentMutationInput!){
    uploadDocument(input:$input){
    ok
    errors{
        messages
    }
}} `

const DriverDocumentDataQuery = gql`query DriverDocumentData($driverId: ID!){
    driverDocumentData(driverId: $driverId){
        documentData {
            documentName
            requiredData {
                fieldCode
                fieldType
                displayName
                value
                dataCategory
            }
        }
        country
    }
}`

const UploadRentalContractMutation = gql`
mutation UploadRentalContractMutation($input: UploadRentalContractMutationInput!){
    uploadRentalContract(input:$input){
    ok
    errors{
        messages
    }
}} `

export function UploadDocumentModal({ currentUser, ...props }) {
    const objectType = props.objectType || "driver"
    const canUploadTlcDocs = props?.hasPermissions?.includes("custom_can_upload_tlc_docs")
    let allCarDocumentTypes = props?.allCarDocumentTypes?.edges.map(item => item.node) ?? []
    if (!canUploadTlcDocs && objectType === "car") {
        allCarDocumentTypes = allCarDocumentTypes.filter(item =>
            item.typeName?.toLowerCase() !== "registration" &&
            item.documentCategory?.name?.toLowerCase() !== "tlc"
        )
    }

    const agreementId = props.agreementId
    let allDriverDocumentTypes = props?.allDriverDocumentTypes?.edges.map(item => item.node) ?? []
    const contractDocumentType = allDriverDocumentTypes.find(item => item.typeName && item.typeName.toLowerCase() === "contract")
    if (agreementId) {
        allDriverDocumentTypes = allDriverDocumentTypes
            .filter(item => item.typeName && item.typeName.toLowerCase() === "contract")
    } else {
        allDriverDocumentTypes = allDriverDocumentTypes
            .filter(item => item.typeName && item.typeName.toLowerCase() !== "contract")
    }

    const documentTypes = objectType === "car" ? allCarDocumentTypes : allDriverDocumentTypes

    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState(null)
    const [files, setFiles] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const [loading, setLoading] = useState(false)
    const [documentDataloading, setDocumentDataLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [documentExpirationDate, setDocumentExpirationDate] = useState(null)
    const [documentTypeDocumentData, setDocumentTypeDocumentData] = useState(null)
    const [allInputsFilled, setAllInputsFilled] = useState(false)
    const [hasIssuedExpirationDates, setHasIssuedExpirationDates] = useState(false)
    const [isIssuedDateValid, setIsIssuedDateValid] = useState(true)
    const filePreviews = useRef(new Map())
    const fileInputRef = useRef(null)
    const selectedDocumentType = documentTypes && documentTypes.length > 0 && documentTypes.find(item => item.id === selectedDocumentTypeId)

    const { data, loading: driverDocumentDataLoading } = useQuery(DriverDocumentDataQuery, {
        variables: { driverId: props.id },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    })
    const driverDocumentData = useMemo(() => data?.driverDocumentData ?? {}, [data])

    useEffect(() => {
        if (files && files.length > 0 && selectedDocumentType && !selectedDocumentType.allowMultiplePages) {
            setFiles((prevFiles) => {
                const validFiles = files.filter((file) => file && file.name)
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""
                }
                return prevFiles.length === 1 && prevFiles[0] === validFiles[0] ? prevFiles : [validFiles[0]]
            })
        }
    }, [files, selectedDocumentType])

    useEffect(() => {
        if (selectedDocumentType && driverDocumentData && driverDocumentData.documentData) {
            setDocumentDataLoading(true)
            const initialData = validateDocumentData(driverDocumentData, selectedDocumentType)
            const updatedDocumentData = driverDocumentData.country
                ? initialData.map((item) =>
                    item.fieldCode.startsWith("COUNTRY")
                        ? { ...item, value: driverDocumentData.country }
                        : item
                )
                : initialData

            setDocumentTypeDocumentData(updatedDocumentData)
            const allFilled = checkAllInputsFilled(updatedDocumentData)
            setAllInputsFilled(allFilled)
            setDocumentDataLoading(false)
        }
    }, [selectedDocumentType, driverDocumentData])

    useEffect(() => {
        if (agreementId && contractDocumentType && contractDocumentType.id) {
            setSelectedDocumentTypeId(contractDocumentType.id)
        }
    }, [agreementId, contractDocumentType])

    useEffect(() => {
        const previews = filePreviews.current

        return () => {
            previews.forEach((url) => URL.revokeObjectURL(url))
            previews.clear()
        }
    }, [])

    const handleClose = () => {
        setDocumentTypeDocumentData([])
        setAllInputsFilled(false)
        setHasIssuedExpirationDates(false)
        setError(null)
        setSuccess(null)
        setLoading(false)
        setFiles([])
        setSelectedDocumentTypeId(null)
        setDocumentExpirationDate(null)
        setIsDragging(false)
        setDocumentDataLoading(false)
        props.handleClose?.()
    }

    ////////////////////////// Document Data Handlers //////////////////////////
    const handleFieldChange = (input, numbersOnly = false, allowNumbersAndDot = false) => {
        handleDriverDataChange(
            input,
            numbersOnly,
            allowNumbersAndDot,
            documentTypeDocumentData,
            (updatedData) => {
                setDocumentTypeDocumentData(updatedData)
                const allFilled = checkAllInputsFilled(updatedData)
                setAllInputsFilled(allFilled)
            }
        )
    }

    ////////////////////////// File Input and Drag and Drop Handlers //////////////////////////

    const handleDrop = (e) => {
        e.preventDefault()
        setIsDragging(false)
        const uploadedFiles = Array.from(e.dataTransfer.files)
        setFiles([...files, ...uploadedFiles])
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false)
    }

    const handleFileSelect = (e) => {
        const allowMultiple = e.target.multiple
        if (!allowMultiple) {
            setFiles([e.target.files[0]])
        } else {
            const uploadedFiles = Array.from(e.target.files)
            setFiles([...files, ...uploadedFiles])
        }
    }

    const handleFileDelete = (index) => {
        setFiles(files.filter((_, i) => i !== index))
    }

    const handleZoneClick = () => {
        fileInputRef.current.click()
    }

    const getFilePreview = (file) => {
        if (!filePreviews.current.has(file)) {
            const blobUrl = URL.createObjectURL(file)
            filePreviews.current.set(file, blobUrl)
        }
        return filePreviews.current.get(file)
    }

    ////////////////////////// End of File Input and Drag and Drop Handlers //////////////////////////

    const uploadFile = async () => {
        try {
            setLoading(true)
            if (!selectedDocumentTypeId) {
                setError("Please select a document type")
                setLoading(false)
                return
            }
            if (!files || files.length === 0) {
                setError("Please select a file to upload")
                setLoading(false)
                return
            }
            if (selectedDocumentType && selectedDocumentType.requiresExpiration && !documentExpirationDate) {
                setError("Please select a document expiration date")
                setLoading(false)
                return
            }

            let filesToUpload = await Promise.all(
                Array.from(files).map(
                    (file) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.readAsDataURL(file)

                            reader.onload = () => resolve(reader.result)
                            reader.onerror = (error) => reject(error)
                        })
                )
            )

            let input = {}
            if (agreementId) {
                let fileToUpload = filesToUpload[0]
                input.file = fileToUpload.substring(fileToUpload.indexOf(',') + 1)
                input.agreementId = agreementId
            } else {
                input.files = filesToUpload
                input[objectType === "car" ? "carId" : "driverId"] = props.id
                input.documentTypeId = selectedDocumentTypeId
                input.fileName = files[0].name
                input.expiration = documentExpirationDate
                input.documentData = documentTypeDocumentData.map(({ fieldType, displayName, __typename, ...rest }) => rest)
            }

            let mutation = agreementId ? UploadRentalContractMutation : UploadDocumentMutation
            let response = await props.apolloClient.mutate({
                mutation,
                variables: { input }
            })
            let responseData = response.data?.uploadDocument ?? response.data?.uploadRentalContract ?? null
            if (responseData && responseData.ok) {
                setSuccess("Document uploaded successfully!")
                setLoading(false)
                if (objectType === "driver") {
                    const cache = props.apolloClient.cache
                    cache.evict({ fieldName: "allDriverDocuments" })
                    cache.evict({ fieldName: "driverDocumentData" })
                    cache.gc()
                }
                if (props.refetchQuery) {
                    await props.refetchQuery()
                }
                setTimeout(() => {
                    handleClose()
                }, 2000)
            } else {
                setError(responseData?.errors?.messages?.[0] ?? "An error occurred while uploading document")
                setLoading(false)
            }
        } catch (error) {
            setError("An error occurred while uploading document" + error.toString())
            setLoading(false)
        }
    }


    return (
        <div>
            <Modal className="" isOpen={props.open}>
                <ModalHeader>Upload Document For {toTitleCase(objectType || "Object")} </ModalHeader>
                <ModalBody>
                    <Row>
                        {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                        {success && <Col xs={12}><Alert color="success">Document Uploaded successfully!</Alert></Col>}
                        {loading && <Col xs={12}><Progress animated value={100} /></Col>}
                        {agreementId && !contractDocumentType &&
                            <Col xs={12}><Alert color="warning">Can't upload contract since no contract document type found for branch.</Alert></Col>
                        }
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="documentType">Select Document Type</Label>
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={props.allCarDocumentTypesLoading || props.allDriverDocumentTypesLoading || driverDocumentDataLoading}
                                    options={documentTypes && documentTypes.length > 0 ? documentTypes.map(item => ({ value: item.id, label: item.typeName })) : []}
                                    placeholder="Select Document Type"
                                    onChange={(selectedOption) => setSelectedDocumentTypeId(selectedOption.value)}
                                    defaultValue={selectedDocumentType && { value: selectedDocumentType.id, label: selectedDocumentType.typeName }}
                                    value={
                                        agreementId && contractDocumentType ?
                                            { value: contractDocumentType.id, label: contractDocumentType.typeName }
                                            : selectedDocumentType ? { value: selectedDocumentType.id, label: selectedDocumentType.typeName }
                                                : null
                                    }
                                    isDisabled={!!agreementId || driverDocumentDataLoading}
                                />
                            </FormGroup>
                        </Col>
                        {selectedDocumentType && selectedDocumentType.requiresExpiration &&
                            <>
                                <Col xs={12}><Alert color="info">Please enter a date that's 10 days from today!</Alert></Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="documentExpirationDate">Document Expiration Date</Label>
                                        <Input
                                            type="date"
                                            min={moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                            value={documentExpirationDate}
                                            onChange={(e) => setDocumentExpirationDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="upload-section">
                                <div
                                    className={`dropzone ${isDragging ? 'dragging' : ''}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={handleZoneClick}
                                >
                                    <i className="fa fa-cloud-upload" aria-hidden="true" />
                                    <p>Drag & Drop files here or click to upload</p>
                                    <input
                                        type="file"
                                        multiple={selectedDocumentType && selectedDocumentType.allowMultiplePages}
                                        onChange={handleFileSelect}
                                        className="file-input"
                                        ref={fileInputRef}
                                    />
                                </div>
                                <ul className="file-list">
                                    <DocumentPreview
                                        files={files}
                                        getFilePreview={getFilePreview}
                                        handleFileDelete={handleFileDelete}
                                    />
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {documentDataloading &&
                        < Row >
                            <Col xs={12}>
                                <Progress animated value={100}>Loading document data...</Progress>
                            </Col>
                        </Row>
                    }
                    {selectedDocumentTypeId && (documentTypeDocumentData && documentTypeDocumentData.length > 0) && !documentDataloading &&
                        <Row>
                            <DocumentFields
                                documentTypeDocumentData={documentTypeDocumentData}
                                allInputsFilled={allInputsFilled}
                                handleFieldChange={handleFieldChange}
                                hasIssuedExpirationDates={hasIssuedExpirationDates}
                                issuedDateValidation={setIsIssuedDateValid}
                            />
                        </Row>
                    }
                </ModalBody>
                {loading && <Col className="loading-bar-footer" xs={12}><Progress animated value={100} /></Col>}
                <ModalFooter>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button
                        color="primary"
                        onClick={uploadFile}
                        disabled={Boolean(
                            !!loading
                            || !!success
                            || !selectedDocumentTypeId
                            || !files
                            || files.length === 0
                            || (!!selectedDocumentType?.requiresExpiration && !documentExpirationDate)
                            || !allInputsFilled
                            || !isIssuedDateValid
                        )}
                    >
                        Upload
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

export default graphql(AllCarDocumentTypesQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { allCarDocumentTypesLoading: loading, allCarDocumentTypes } }) => ({
        loading,
        allCarDocumentTypes,
    }),
})(
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ id }) => ({
            variables: { driverId: id },
            fetchPolicy: "cache-first",
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes } }) => ({
            loading,
            allDriverDocumentTypes,
        }),
    })(
        graphql(HasPermissionsQuery, {
            options: () => ({
                variables: { userPermissions: ["custom_can_upload_tlc_docs"] },
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
            }),
            props: ({ data: { hasPermissions, loading, variables } }) => ({
                hasPermissions,
                loading,
                variables,
            }),
        })(withApolloClient(UploadDocumentModal))
    )
)

