import React, { Component } from "react"
import { useApolloClient } from '@apollo/client'
import { Container } from "reactstrap"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import PromoTable from "./PromoTable"
import './Pricing.css'
import PromoListHeader from "./PromoListHeader"
import RestrictedSection from "../Components/RestrictedSection"
class PromoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            agreementTypeId: null,
            openModal: "",
            isActive: true,
            refetchPromo: false,
            filterValues: {
                orderBy: ["-id"],
                isActive: true,
            },
            selectedPromo: [],
        }
    }

    toggleModal = (name) => {
        this.setState({ openModal: this.state.openModal === name ? "" : name })
    };

    updateOrder = (string) => {
        let orderBy = [...this.state.filterValues.orderBy]
        let index = orderBy.indexOf(string)
        if (index > -1) {
            orderBy[index] = "-" + string
        } else {
            index = orderBy.indexOf("-" + string)
            index > -1 ? orderBy.splice(index, 1) : orderBy.unshift(string)
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy } })
    };

    render() {
        return (
            <RestrictedSection requiredPermission="view_promotiontemplate">
                <Container fluid className="pricing-list-container">
                    <PromoListHeader
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        defaultFilters={this.state.filterValues}
                        refetchQuery={() => this.setState((prevState) => ({ refetchPromo: !prevState.refetchPromo }))}
                        selectedPromo={this.state.selectedPromo} location={this.props.location} history={this.props.navigate}
                        resetSelectedPromo={() => this.setState({ selectedPromo: [] })}
                    />
                    <PromoTable
                        key={String(this.state.refetchPromo)}
                        searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                        modelTypeIds={this.state.filterValues && this.state.filterValues.modelTypeId ? this.state.filterValues.modelTypeId : null}
                        modelGroupTypeId={this.state.filterValues && this.state.filterValues.modelGroupTypeId ? this.state.filterValues.modelGroupTypeId : null}
                        isActive={this.state.filterValues && this.state.filterValues.isActive != null ? this.state.filterValues.isActive : null}
                        orderBy={this.state.filterValues.orderBy}
                    />
                </Container>
            </RestrictedSection>
        )
    }
}

//Iagami - Code upgradation React 18
export default PromoContainer
