import React, { Component } from 'react'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Container, Col, Row, Button, Table, Badge } from 'reactstrap'
import Loader from "../../../Material/Loader"
import moment from "moment"
import CreateUpdateMemberModal from "../../MembersManagement/CreateUpdateMemberModal"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const MembersQuery = gql`query MembersQuery($cursor: String, $first:Int, $branchId: [ID]  ) {
    members: allMembers(first:$first, after: $cursor, branchIds: $branchId){
        edges{
            node{
                id
                pk
                type
                user{
                    id
                    username
                    email
                    firstName
                    lastName
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const tableHeaders = [
    { id: "id", name: "ID", sortable: false },
    { id: "user__username", name: "Username", sortable: false }, { id: "user__full_name", name: "Full Name", sortable: false }, { id: "type", name: "Member Type", sortable: false },
    { id: "action", name: "Actions", sortable: false }
]

class MemberTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    pObj = (jsonString) => {
        try {
            let obj = JSON.parse(jsonString)
            return obj
        } catch (error) {
            return {}
        }

    }
    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.members ? <Loader /> : <>
                    <Row>
                        <Col className="bos-object-section-wrapper">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {tableHeaders.map(tableHeader =>
                                            tableHeader.sortable ?
                                                <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                                <th key={tableHeader.id}>{tableHeader.name}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.members && this.props.members.edges && this.props.members.edges.length > 0 ? this.props.members.edges.map((member, i) =>
                                        <tr key={i + 1}>
                                            <td>{member.node.pk}</td>
                                            <td>
                                                <a color="secondary" onClick={() => { this.toggleModal("editMember" + i); this.setState({ selectedUser: member.node.id }) }}>{member.node.user ? member.node.user.firstName ? member.node.user.firstName + " " + member.node.user.lastName : member.node.user.username : '--'}</a><br />
                                                <small>{member.node.user ? member.node.user.email : '--'}</small>
                                                {this.state.openModal === ("editMember" + i) && <CreateUpdateMemberModal handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === ("editMember" + i)} isUpdate refetchQuery={() => this.props.refetchQuery()} memberDetails={member} />}
                                            </td>
                                            <td>{member.node.user ? `${member.node.user.firstName} ${member.node.user.lastName}` : '--'}</td>
                                            <td>{member.node.type && <Badge>{member.node.type}</Badge>}</td>
                                            <td>
                                                {this.state.openModal === ("removeMember" + i) && <CreateUpdateMemberModal handleClose={() => this.toggleModal("removeMember" + i)} isOpen={this.state.openModal === ("removeMember" + i)} refetchQuery={() => this.props.refetchQuery()} memberDetails={member} action={"delete"} branchId={this.props.branchId} />}
                                                <i id={"remove" + i} onClick={() => this.toggleModal("removeMember" + i)} className="fa fa-trash-o fa-2x text-danger" />
                                            </td>
                                        </tr>
                                    ) :
                                        <tr>
                                            <td colSpan={7}>No Member Found</td>
                                        </tr>
                                    }
                                    {this.props.members && this.props.members.pageInfo.hasNextPage &&
                                        <tr>
                                            <td colSpan={6}><Button style={{ paddingLeft: 0 }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show older <i className={"fa fa-caret-right"}></i></Button></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(MembersQuery, {
    options: ({ branchId }) => ({
        variables: {
            branchId,
            first: 30
        }
    }),
    props: ({ data: { loading, members, fetchMore, variables } }) => ({
        loading,
        members,
        variables,
        refetchQuery: () => {
            let currentLength = members && members.edges && members.edges.length > 30 ? members.edges.length : 30
            return fetchMore({
                query: MembersQuery,
                variables: {
                    ...variables,
                    first: currentLength,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.members.edges
                    const pageInfo = fetchMoreResult.members.pageInfo
                    return {
                        members: {
                            edges: [...newEdges],
                            pageInfo,
                            __typename: previousResult.members.__typename
                        },
                    }
                },
            })
        },
        loadMoreEntries: () => {
            return fetchMore({
                query: MembersQuery,
                variables: {
                    cursor: members.pageInfo.endCursor,
                    ...variables
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.members.edges
                    const pageInfo = fetchMoreResult.members.pageInfo

                    return {
                        members: {
                            edges: [...previousResult.members.edges, ...newEdges],
                            pageInfo,
                            __typename: previousResult.members.__typename
                        },
                    }
                },
            })
        },
    })
})(withApolloClient(MemberTab))
