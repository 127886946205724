import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Button, Row, Col, Label, Badge, Modal, ModalBody, ModalHeader, ModalFooter, Progress, UncontrolledTooltip, Nav, NavItem, NavLink } from 'reactstrap'
import { InsuranceQuery, DriverInsuranceStatus } from "./Queries"
import "./InsurancePreviewModal.css"
import "../Material/DocumentModal/DocumentList.css"
import { getMvrReportMutation } from "../Drivers/Driver/Detail/Mutations"
import UpdateDriverInfo from "../ApplicationsNew/UpdateDriverInfo"
import DriverNotes from "../ApplicationsNew/DriverNotes"
import DocumentListContainer from "../Material/DocumentModal/DocumentListContainer"
import AddToDidntTakeCar from "../Material/AddToDidntTakeCar"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import InteractionContainer from "../Drivers/Interactions/InteractionsContainer"
import DriverInsuranceListing from "./DriverInsuranceListing"
import InsuranceDetailSection from "./InsuranceDetailSection"
import CustomerHandleDetailModal from "../Material/CustomerHandleDetailModal"
import RelatedProfileContainer from "../Material/RelatedProfiles/RelatedProfileContainer"
import withApolloClient from "../withApolloClient.js"
import { HasPermissionsQuery } from "../Functions/querys"

class InsurancePreviewModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            openModal: "",
            documentUrl: "",
            openSection: ["DriverInfo", "InsuranceInfo"],
            runMvrLoading: false,
            actionSuccess: false,
            runMvrLoading: false,
            actionSuccess: false,
            loading: false,
            tab: "InfoTab",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    refetchQuery = () => {
        this.props.refetchInsuranceQuery()
        this.props.refetchQuery()
    }

    toggleSection = (section) => {
        if (this.state.openSection.includes(section)) {
            this.setState({ openSection: this.state.openSection.filter((sec) => sec !== section) })
        } else {
            this.setState({ openSection: [...this.state.openSection, section] })
        }
    }

    handleClose = () => {
        this.setState({ openModal: "" })
        this.props.handleClose()
    }

    getMvrReport = () => {
        this.setState({ runMvrLoading: true, error: "" })
        let input = { driverId: this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.id : "" }
        this.props.apolloClient.mutate({
            mutation: getMvrReportMutation,
            variables: { input }
        }).then((result) => {
            if (result.data && result.data.getMvrReport && result.data.getMvrReport.ok && result.data.getMvrReport.reportData) {
                var a = window.document.createElement('a')
                a.href = "data:application/pdf;base64," + result.data.getMvrReport.reportData
                a.download = 'mvr.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                this.setState({ runMvrLoading: false, actionSuccess: true })
            } else if (result.data && result.data.getMvrReport && result.data.getMvrReport.errors && result.data.getMvrReport.errors[0] && result.data.getMvrReport.errors[0].messages) {
                let error = result.data.getMvrReport.errors[0].messages.toString()
                this.setState({ runMvrLoading: false, error: error, actionSuccess: false })
            } else {
                this.setState({ runMvrLoading: false, error: "An error has occured while running the MVR, please contact admin.", actionSuccess: false })
            }
        })
    };

    renderMetadataVal(metadata, key) {
        let metaObject = JSON.parse(metadata.replace(/'/g, '"'))
        if (metaObject && metaObject[key]) {
            return <><Label>{key.replace("_", " ")}</Label><br /><p>{metaObject[key]}</p></>
        }
        return ""
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <Modal className="insurance-preview-modal" isOpen={this.props.open}>
                <ModalHeader className="title" >
                    {this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.name : "--"}&nbsp;<Badge className='bos-badge-blue'>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.branch ? this.props.insuranceStatus.driver.branch.name : "--"}</Badge>
                </ModalHeader>
                <ModalBody>
                    <Nav tabs className="insurance-preview-modal-nav">
                        <NavItem className="insurance-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "InfoTab" ? "active" : ""} onClick={() => this.setState({ tab: "InfoTab" })}>
                                Driver Info&nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="insurance-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "DocumentTab" ? "active" : ""} onClick={() => this.setState({ tab: "DocumentTab" })}>
                                Documents&nbsp;&nbsp;<i className="fa fa-file-o" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="insurance-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "ChatTab" ? "active" : ""} onClick={() => this.setState({ tab: "ChatTab" })}>
                                Chat&nbsp;&nbsp;<i className="fa fa-comments-o" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="insurance-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "OldInsurances" ? "active" : ""} onClick={() => this.setState({ tab: "OldInsurances" })}>
                                Old Insurances&nbsp;&nbsp;<i className="fa fa-shield" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="insurance-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "RelatedProfilesTab" ? "active" : ""} onClick={() => this.setState({ tab: "RelatedProfilesTab" })}>
                                Related Profiles&nbsp;&nbsp;<i className="fa fa-users" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <br />
                    {this.props.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /><br /></Col></Row>}
                    {this.state.tab === "RelatedProfilesTab" && <RelatedProfileContainer driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.id : ""} currentUser={this.props.currentUser} basedOnTenant={false} dmvLicense={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.dmvLicense ? this.props.insuranceStatus.driver.dmvLicense : ""} listingType={"insurance"} />}
                    {this.state.tab === "ChatTab" && <><InteractionContainer driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} /></>}
                    {this.state.tab === "InfoTab" && <>
                        <hr />
                        <Label className={`toggle-section-heading ${this.state.openSection.includes("DriverInfo") ? "active" : ""}`} onClick={() => this.toggleSection("DriverInfo")} >Driver Info {this.state.openSection.includes("DriverInfo") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                        {this.state.openSection.includes("DriverInfo") && <>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col xs={4}>
                                    <Label>Driver ID</Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.pk : "---"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Email <i id={"emailEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("emailEdit")}></i></Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.email ? this.props.insuranceStatus.driver.email : "---"}</p>
                                    {this.state.openModal === "emailEdit" &&
                                        <CustomerHandleDetailModal
                                            handleType={["email"]}
                                            customerId={this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.id : "--"}
                                            open={this.state.openModal === "emailEdit"}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Label>Phone <i id={"phoneEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("phoneEdit")}></i></Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.phone ? this.props.insuranceStatus.driver.phone : "---"}</p>
                                    {this.state.openModal === "phoneEdit" &&
                                        <CustomerHandleDetailModal
                                            handleType={["phone"]}
                                            customerId={this.props.insuranceStatus && this.props.insuranceStatus.driver ? this.props.insuranceStatus.driver.id : "--"}
                                            open={this.state.openModal === "phoneEdit"}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Label>TLC License <i id={"tlcLicenseEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("tlcLicenseEdit")}></i></Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.tlcLicense ? this.props.insuranceStatus.driver.tlcLicense : "---"}</p>
                                    {this.state.openModal === "tlcLicenseEdit" && <UpdateDriverInfo driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} title="Edit TLC License" name="tlcLicense" value={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.tlcLicense} target="tlcLicenseEdit" id="tlcLicense" type="text" open={this.state.openModal === "tlcLicenseEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                </Col>
                                <Col xs={4}>
                                    <Label>Dmv License <i id={"dmvLicenseEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("dmvLicenseEdit")}></i></Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.dmvLicense ? this.props.insuranceStatus.driver.dmvLicense : "---"}</p>
                                    {this.state.openModal === "dmvLicenseEdit" && <UpdateDriverInfo driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} title="Edit DMV License" name="dmvLicense" value={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.dmvLicense} target="dmvLicenseEdit" id="dmvLicense" type="text" open={this.state.openModal === "dmvLicenseEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                </Col>
                                <Col xs={4}>
                                    <Label>DOB <i id={"dobEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("dobEdit")}></i></Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.dob ? this.props.insuranceStatus.driver.dob : "--"} {this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.age ? `${this.props.insuranceStatus.driver.age} years` : "--"}</p>
                                    {this.state.openModal === "dobEdit" && <UpdateDriverInfo driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} title="Edit DOB" name="dob" value={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.dob} target={"dobEdit"} id="dob" type="date" open={this.state.openModal === "dobEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                </Col>
                                <Col xs={4}>
                                    <Label>Balance</Label>
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.balance ? "$" + this.props.insuranceStatus.driver.balance : "---"}</p>
                                </Col>
                                {this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.balanceWriteOff < 0 &&
                                    <Col xs={4}>
                                        <Label>Bad Debt Write Off</Label>
                                        <p style={{ color: "red" }}>${this.props.insuranceStatus.driver.balanceWriteOff * -1}</p>
                                    </Col>
                                }
                                {this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.creditWriteOff > 0 &&
                                    <Col xs={4}>
                                        <Label>Credit Write Off</Label>
                                        <p style={{ color: "red" }}>${this.props.insuranceStatus.driver.creditWriteOff}</p>
                                    </Col>
                                }
                                <Col>
                                    <Label>
                                        <small>Notes &nbsp;&nbsp;
                                            <i id={"notesEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("notesEdit")}></i>&nbsp;&nbsp;
                                            <i id={"notesHistory"} className="fa fa-history" aria-hidden="true" onClick={() => this.toggleModal("notesHistory")}></i>
                                        </small>
                                    </Label><br></br>
                                    {this.state.openModal === "notesEdit" && <UpdateDriverInfo driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} title="Edit Notes" name="notes" value={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.notes} target={"notesEdit"} id="notes" type="text" open={this.state.openModal === "notesEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                    {this.state.openModal === "notesHistory" && <DriverNotes driver={this.props.insuranceStatus && this.props.insuranceStatus.driver} target={"notesHistory"} open={this.state.openModal === "notesHistory"} handleClose={() => this.toggleModal("")} />}
                                    <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.notes ? this.props.insuranceStatus.driver.notes : "---"}</p>
                                </Col>
                            </Row>
                            <hr />
                        </>}
                        <Label className={`toggle-section-heading ${this.state.openSection.includes("InsuranceInfo") ? "active" : ""}`} onClick={() => this.toggleSection("InsuranceInfo")}>Insurance Info {this.state.openSection.includes("InsuranceInfo") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                        {this.state.openSection.includes("InsuranceInfo") && <>
                            <InsuranceDetailSection
                                insurance={this.props.insuranceStatus}
                                refetchQuery={this.refetchQuery}
                                handleClose={this.props.handleClose}
                                currentUser={this.props.currentUser}
                            />
                            <hr />
                        </>}
                        {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_view_uw_metrics") && <>
                            <Label className={`toggle-section-heading ${this.state.openSection.includes("UWMetrics") ? "active" : ""}`} onClick={() => this.toggleSection("UWMetrics")}>UW Metrics {this.state.openSection.includes("UWMetrics") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                            {this.state.openSection.includes("UWMetrics") && <>
                                <Row>
                                    <Col>
                                        <Label>Percentile</Label>
                                        <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.uwScores && this.props.insuranceStatus.driver.uwScores.percentile !== null ? this.props.insuranceStatus.driver.uwScores.percentile : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Total Days Driver Score</Label>
                                        <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.uwScores && this.props.insuranceStatus.driver.uwScores.totalDaysDriverScore !== null ? this.props.insuranceStatus.driver.uwScores.totalDaysDriverScore : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Total Tenure For Driver</Label>
                                        <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.uwScores && this.props.insuranceStatus.driver.uwScores.totalTenureForDriver !== null ? this.props.insuranceStatus.driver.uwScores.totalTenureForDriver : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Stripe Average Risk Score</Label>
                                        <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.uwScores && this.props.insuranceStatus.driver.uwScores.stripeAvgRiskScore !== null ? this.props.insuranceStatus.driver.uwScores.stripeAvgRiskScore : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Avg Days To Pay</Label>
                                        <p>{this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.uwScores && this.props.insuranceStatus.driver.uwScores.avgDaysToPay !== null ? this.props.insuranceStatus.driver.uwScores.avgDaysToPay : "--"}</p>
                                    </Col>
                                </Row>
                                <hr />
                            </>
                            }
                        </>}
                        <Label className={`toggle-section-heading ${this.state.openSection.includes("DrivingRecord") ? "active" : ""}`} onClick={() => this.toggleSection("DrivingRecord")}>Driving Record {this.state.openSection.includes("DrivingRecord") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                        {this.state.openSection.includes("DrivingRecord") && <>
                            <Row style={{ marginBottom: "10px" }}>
                                {this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.driverRecord ?
                                    this.props.insuranceStatus.driver.driverRecord.map((record, index) =>
                                        <Col xs="4" key={index}>
                                            <Label><small>{record.name ? record.name : ""}</small></Label><br></br>
                                            {record.value ? record.value : 0}
                                        </Col>
                                    ) : <p>No Driving Record Found</p>
                                }
                            </Row>
                            <hr />
                        </>
                        }
                    </>
                    }
                    {this.state.tab === "DocumentTab" && <>
                        <Row style={{ marginBottom: "10px" }}>
                            <Col>
                                <DocumentListContainer
                                    refetchOnUpdate={this.props.refetchQuery}
                                    colSize={"12"}
                                    hideFilters
                                    id={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id}
                                    objectType={"driver"}
                                    currentUser={this.props.currentUser}
                                />
                            </Col>
                        </Row>
                    </>}
                    {this.state.tab === "OldInsurances" && this.props.insuranceStatus && this.props.insuranceStatus.driver && <Row style={{ marginTop: "10px" }}>
                        <Col>
                            <DriverInsuranceListing driverId={this.props.insuranceStatus.driver.id} excludeInsuranceId={this.props.insuranceStatus.id} currentUser={this.props.currentUser} handleClose={this.props.handleClose} />
                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter style={{ width: "100%" }}>
                    <Col className="d-flex justify-content-start">
                        <Button className="insurance-preview-modal-danger-btn" onClick={() => this.toggleModal("AddToDidntTakeCar")}>Add To Didn't Take Car</Button>
                        {this.state.openModal === "AddToDidntTakeCar" && <AddToDidntTakeCar handleClose={() => this.toggleModal("")} open={this.state.openModal === "AddToDidntTakeCar"} driverId={this.props.insuranceStatus && this.props.insuranceStatus.driver && this.props.insuranceStatus.driver.id} refetchQuery={this.refetchQuery} />}
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button className="insurance-preview-modal-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                        &nbsp;&nbsp;<Button className="insurance-preview-modal-primary-btn" onClick={() => this.toggleModal("runDriverMVR")} id={"runDriverMVR"}>Run MVR</Button>
                        <UncontrolledTooltip placement="top" target={"runDriverMVR"}>Genarate Driver MVR</UncontrolledTooltip>
                        {this.state.openModal === "runDriverMVR" &&
                            <ConfirmationPopup
                                open={this.state.openModal === "runDriverMVR"}
                                message={"ARE YOU SURE YOU WANT TO RUN MVR?"}
                                loading={this.state.runMvrLoading}
                                handleClose={() => this.toggleModal("")}
                                action={"Yes"}
                                confirmAction={this.getMvrReport}
                                color="danger"
                                error={this.state.error ? this.state.error : ""}
                                success={this.state.actionSuccess ? "Action Performed Successfully" : ""}
                            />
                        }
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(DriverInsuranceStatus, {
    props: ({ data: { __type: DriverInsuranceStatusStatus } }) => ({
        DriverInsuranceStatusStatus,
    }),
})(
    graphql(InsuranceQuery, {
        options: ({ id }) => ({
            variables: { id },
        }),
        props: ({ data: { insuranceStatus, loading, fetchMore, variables } }) => ({
            insuranceStatus,
            loading,
            refetchInsuranceQuery: () =>
                fetchMore({
                    query: InsuranceQuery,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => ({
                        insuranceStatus: fetchMoreResult.insuranceStatus,
                    }),
                }),
        }),
    })(
        graphql(HasPermissionsQuery, {
            options: () => ({
                variables: { userPermissions: ['custom_can_view_uw_metrics'] },
            }),
            props: ({ data: { hasPermissions, loading, variables } }) => ({
                hasPermissions,
                loading,
                variables,
            }),
        })(withApolloClient(InsurancePreviewModal))
    )
)
