import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress
} from 'reactstrap'
import Alert from 'reactstrap/lib/Alert'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const UpdateReservation = gql`
mutation updateReservationMutation($input: UpdateReservationMutationInput!){
    updateReservation(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const UpdateReservationPromo = gql`mutation updateReservationPromo($input: UpdateReservationPromoMutationInput!){
    updateReservationPromo(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

class UpdateReservationPromotion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: "",
            reservation: props.reservation,
            reservationPlan: props.reservationPlan,
            input: {},
            loading: false,
            disabledMessage: ""
        }
    }

    updateReservation = () => {
        if (this.props.reservationPlan) {
            this.setState({ loading: true })
            let input = this.state.input
            this.props.apolloClient.mutate({
                mutation: UpdateReservationPromo,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateReservationPromo && result.data.updateReservationPromo.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, errorMessage: "" })
                } else {
                    let errorMessage = result.data && result.data.updateReservationPromo && result.data.updateReservationPromo.errors && result.data.updateReservationPromo.errors[0] && result.data.updateReservationPromo.errors[0].messages ?
                        result.data.updateReservationPromo.errors[0].messages.join(". ") : "An error has occured. Please try again or contact admin."
                    this.setState({ loading: false, errorMessage: errorMessage })
                }
            }).catch((err) => {
                this.setState({ loading: false, errorMessage: "An error has occured. Please try again or contact admin." })
            })
        } else {
            if (this.state.input.reservationId) {
                this.setState({ loading: true })
                let input = this.state.input
                this.props.apolloClient.mutate({
                    mutation: UpdateReservation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                        this.setState({ input: {}, loading: false, errorMessage: "" })
                    } else {
                        let errorMessage = result.data && result.data.updateReservation && result.data.updateReservation.errors && result.data.updateReservation.errors[0] && result.data.updateReservation.errors[0].messages ?
                            result.data.updateReservation.errors[0].messages.join(". ") : "An error has occured. Please try again or contact admin."
                        this.setState({ loading: false, errorMessage: errorMessage })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, errorMessage: "An error has occured. Please try again or contact admin." })
                })
            }
        }
    }

    updatePromoInput = (e) => {
        if (this.state.reservation) {
            let { name, value, min, max, type } = e.target
            if (type === "number" && (min != undefined || max != undefined)) {
                if (min != undefined && Number(value) < Number(min)) {
                    this.setState({ disabledMessage: "Please make sure " + name + " is greater than " + Number(min) })
                } else if (max != undefined && Number(value) > Number(max)) {
                    this.setState({ disabledMessage: "Please make sure " + name + " is less than " + Number(max) })
                } else {
                    this.setState({ disabledMessage: "" })
                }
            }
            let input = {
                reservationId: this.state.reservation.id,
                [name]: value
            }
            this.setState({ input: input })
        } else if (this.state.reservationPlan) {
            let { name, value, min, max, type } = e.target

            let input
            if (this.props.name.includes("selectedPromoStartDate"))
                input = {
                    reservationPromo: this.state.reservationPlan.id,
                    startDate: value
                }
            else if (this.props.name.includes("selectedPromoExpirationDate")) {
                input = {
                    reservationPromo: this.state.reservationPlan.id,
                    expirationDate: value
                }
            }
            else if (this.props.name.includes("selectedPromoPrice")) {
                if (type === "number" && (min != undefined || max != undefined)) {
                    if (min != undefined && Number(value) < Number(min)) {
                        this.setState({ disabledMessage: "Promo base price should be greater than " + Number(min) })
                    } else if (max != undefined && Number(max) > Number(value) && Number(value) > Number(max)) {
                        this.setState({ disabledMessage: "Promo base price should be less than " + Number(max) })
                    } else {
                        this.setState({ disabledMessage: "" })
                    }
                }
                input = {
                    reservationPromo: this.state.reservationPlan.id,
                    promoPrice: value
                }
            }
            else if (this.props.name.includes("selectedPromoCycle")) {
                if (type === "number" && (min != undefined || max != undefined)) {
                    if (min != undefined && Number(value) < Number(min)) {
                        this.setState({ disabledMessage: "Promo Cycle should be greater than " + Number(min) })
                    } else if (max != undefined && Number(max) > Number(value) && Number(value) > Number(max)) {
                        this.setState({ disabledMessage: "Promo Cycle should be less than " + Number(max) })
                    } else {
                        this.setState({ disabledMessage: "" })
                    }
                }
                input = {
                    reservationPromo: this.state.reservationPlan.id,
                    cycle: value
                }
            }
            this.setState({ input: input })
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.reservation !== prevProps.reservation) {
            let reservation = this.props.reservation
            this.setState({ reservation: reservation })
        }
        if (this.props.reservationPlan !== prevProps.reservationPlan) {
            let reservationPlan = this.props.reservationPlan
            this.setState({ reservationPlan: reservationPlan })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }


    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        {this.state.errorMessage && <Alert color="danger">{this.state.errorMessage}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name.includes("selectedPromoPrice") ? (
                                <Input
                                    type={this.props.type}
                                    min={this.props.min}
                                    max={this.props.max}
                                    name={this.props.name}
                                    id={this.props.name}
                                    defaultValue={this.props.value}
                                    placeholder={this.props.title}
                                    onChange={this.updatePromoInput}
                                />
                            )
                                : this.props.name.includes("selectedPromoCycle") ? (
                                    <Input
                                        type={this.props.type}
                                        min={this.props.min}
                                        max={this.props.max}
                                        name={this.props.name}
                                        id={this.props.name}
                                        defaultValue={this.props.value}
                                        placeholder={this.props.title}
                                        onChange={this.updatePromoInput}
                                    />
                                ) : this.props.name.includes("selectedPromoStartDate") ? (
                                    <Input
                                        type={this.props.type}
                                        min={this.props.min}
                                        max={this.props.max}
                                        name={this.props.name}
                                        id={this.props.name}
                                        defaultValue={this.props.value}
                                        placeholder={this.props.title}
                                        onChange={this.updatePromoInput}
                                    />
                                ) : this.props.name.includes("selectedPromoExpirationDate") ? (
                                    <Input
                                        type={this.props.type}
                                        min={this.props.min}
                                        max={this.props.max}
                                        name={this.props.name}
                                        id={this.props.name}
                                        defaultValue={this.props.value}
                                        placeholder={this.props.title}
                                        onChange={this.updatePromoInput}
                                    />
                                ) : (
                                    <Input
                                        type={this.props.type}
                                        maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                        min={this.props.min}
                                        max={this.props.max}
                                        name={this.props.name}
                                        id={this.props.name}
                                        defaultValue={this.props.value}
                                        placeholder={this.props.title}
                                        onChange={this.updatePromoInput}
                                    />
                                )}
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="button"
                            disabled={this.state.disabledMessage ? true : false} size="sm" onClick={this.updateReservation}>{this.state.disabledMessage ? this.state.disabledMessage : "Submit"}</Button>}
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient((UpdateReservationPromotion))