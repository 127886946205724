import React, { Component } from "react"
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Container, Row, Col, Nav, NavItem, Button } from "reactstrap"
import LeadsListHeader from "./LeadsListHeader"
import LeadsList from "./LeadsList"
import ManagePreferenceHOC from "../Material/ManagePreferenceHOC"
import withApolloClient from "../withApolloClient"
const TABS = {
    applicationView: [{ id: "#applications", name: "New Applications", iconClassName: "fa fa-user-o" }],
    leadView: [
        { id: "#open", name: "Open", iconClassName: "fa fa-user-o" },
        { id: "#review", name: "Need Review", iconClassName: "fa fa-wpforms" },
        { id: "#didnttakecar", name: "Didn't Take a Car", iconClassName: "fa fa-low-vision" },
        { id: "#dnr", name: "DNR", iconClassName: "fa fa-ban" },
        { id: "#all", name: "All Leads", iconClassName: "fa fa-handshake-o" },
    ]
}

const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
    { id: "id", name: "Lead Info", sortable: true, visible: true, showInPicker: false, positonIsFixed: true, view: ["applicationView", "leadView"] },
    { id: "status", name: "Status", sortable: true, visible: true, showInPicker: true, positonIsFixed: false, view: ["leadView"] },
    { id: "missing_docs", name: "Required Application Docs", sortable: false, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
    { id: "notes", name: "Notes", sortable: false, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
    { id: "branch", name: "Applied Branch", sortable: false, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
    { id: "tags", name: "Driver Tags", sortable: false, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
    { id: "assigned_to", name: "Assigned To", sortable: false, visible: true, showInPicker: true, positonIsFixed: false, view: ["applicationView", "leadView"] },
]
class LeadsListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: this.props.viewType === "applicationView" ? TABS["applicationView"] : TABS["leadView"],
            filterValues: {
                orderBy: ["-customer__in_office", "-date_added"],
            },
            refetchLeads: false,
            selectedLeads: [],
            activeTab: null,
        }
    }

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-customer__in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    changeTab = (e) => {
        let value = e.target.value
        let activeTab = this.state.tabs.find(item => item.id === value)

        if (!activeTab) return

        if (value !== this.state.activeTab) {
            window.location.hash = activeTab.id

            let { searchTerm, ...updatedFilterValues } = this.state.filterValues

            this.setState({
                activeTab: activeTab.id,
                filterValues: updatedFilterValues,
            })
        }
    }


    componentDidMount() {
        let defaultTab = this.state.tabs[0]
        if (window.location.hash && this.state.tabs.map(item => item.id).includes(window.location.hash)) {
            this.setState({ activeTab: this.state.tabs.find(item => item.id === window.location.hash).id, selectedLeads: [] })
        } else {
            window.location.hash = defaultTab.id
            this.setState({ activeTab: defaultTab.id, selectedLeads: [], })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.viewType !== this.props.viewType) {
            let relatedTabs = this.props.viewType === "applicationView" ? TABS["applicationView"] : TABS["leadView"]
            this.setState({ tabs: relatedTabs, activeTab: relatedTabs[0].id, selectedLeads: [], filterValues: { orderBy: ["-customer__in_office", "-date_added"] } })
            window.location.hash = relatedTabs[0].id
        }
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                {this.state.tabs && this.state.tabs.length > 1 && <Nav pills className="available-car-wrap">
                    {this.state.tabs.map(tab =>
                        <NavItem key={tab.id}>
                            <Button color="primary" value={tab.id} className={this.state.activeTab === tab.id ? "active" : "inactive"} onClick={this.changeTab}><i className={`${tab.iconClassName} pr-3`} aria-hidden="true"></i>{tab.name}</Button>
                        </NavItem>
                    )}
                </Nav>}
                <LeadsListHeader
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedLeads={this.state.selectedLeads}
                    activeTab={this.state.activeTab}
                    defaultFilters={this.state.filterValues}
                    resetSelectedLeads={() => this.setState({ selectedLeads: [] })}
                    currentUser={this.props.currentUser} location={this.props.location} history={this.props.navigate}
                    refetchQuery={() => this.setState({ refetchLeads: !this.state.refetchLeads })}
                    preferenceType={this.props.viewType === "leadView" ? "lead" : "application"}
                    columnConfig={this.props.columnConfig.filter(col => col.view.includes(this.props.viewType))}
                    refetchPreferences={this.props.refetchPreferences}
                />
                <Row>
                    <Col>
                        {this.state.activeTab && (this.props.viewType === "applicationView" ? <LeadsList
                            key={String(this.state.activeTab)}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            updateOrder={this.updateOrder}
                            columnConfig={this.props.columnConfig.filter(col => col.view.includes(this.props.viewType))}
                            currentUser={this.props.currentUser}
                            viewType={this.props.viewType || ""}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                            statuses={["Onboarding"]}
                            isArchived={false}
                            inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                            isBlocked={this.state.filterValues && this.state.filterValues.isBlocked !== null ? this.state.filterValues.isBlocked : null}
                            selectedLeads={this.state.selectedLeads}
                            setSelectedLeads={(selectedLeads) => this.setState({ selectedLeads })}
                            driverTags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                            allTags={this.props.driverTags}
                            refetchLeads={this.state.refetchLeads}
                            setRefetchQuery={() => this.setState({ refetchLeads: !this.state.refetchLeads })}
                        /> :
                            <LeadsList
                                key={String(this.state.refetchLeads)}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                                updateOrder={this.updateOrder}
                                currentUser={this.props.currentUser}
                                columnConfig={this.props.columnConfig.filter(col => col.view.includes(this.props.viewType))}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                                statuses={this.state.activeTab === "#open" ? ["Open", "Contacted"] : this.state.activeTab === "#review" ? ["Need Review"] : this.state.activeTab === "#didnttakecar" ? ["Canceled"] : this.state.filterValues && this.state.filterValues.statuses ? this.state.filterValues.statuses : []}
                                leadTypes={this.state.filterValues && this.state.filterValues.leadTypes ? this.state.filterValues.leadTypes : null}
                                isArchived={["#open", "#review", "#dnr"].includes(this.state.activeTab) ? false : this.state.filterValues && this.state.filterValues.isArchived ? this.state.filterValues.isArchived : null}
                                inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                                isBlocked={["#dnr"].includes(this.state.activeTab) ? true : this.state.filterValues && this.state.filterValues.isBlocked !== null ? this.state.filterValues.isBlocked : null}
                                refetchReps={this.refetchReps}
                                selectedLeads={this.state.selectedLeads}
                                driverTags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                                allTags={this.props.driverTags}
                                setSelectedLeads={(selectedLeads) => this.setState({ selectedLeads })}
                                refetchLeads={this.state.refetchLeads}
                                setRefetchQuery={() => this.setState({ refetchLeads: !this.state.refetchLeads })}
                                viewType={this.props.viewType || ""}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(
    ManagePreferenceHOC(
        (props) => props.viewType === "leadView" ? "lead" : "application",
        tableHeaders
    )(LeadsListContainer)
)
