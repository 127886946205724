import React from 'react'
import { Popover, PopoverBody } from "reactstrap"
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { HasPermissionsQuery } from "../../Functions/querys"
import "./ActionsPopup.css"
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18

const ActionsPopup = ({ selectedDrivers, activeTab, filterValues, hasPermissions, toggleHideDrivers, unAssignDrivers, openModal, toggleModal, handleUpdateAutoCharge }) => {
    return (
        <Popover trigger="legacy" placement="bottom" className="listing-action-items" target="viewActions" isOpen={openModal === "viewActions"} toggle={() => toggleModal("")}>
            <PopoverBody>
                <ul>
                    {activeTab === "Collections" && hasPermissions && hasPermissions.includes("custom_can_auto_charge") && <li>
                        <a className="driver-list-button" onClick={() => toggleModal("WeeklyChargeModal")}>
                            <i className="fa fa-dollar" aria-hidden="true"></i> | Weekly Charge
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </li>}
                    {selectedDrivers && selectedDrivers.length > 0 &&
                        <>
                            <li>
                                <a className="driver-list-button" onClick={() => handleUpdateAutoCharge("autoChargeConfirmation", true)}>
                                    <i className="fa fa-money" aria-hidden="true"></i> | Auto Charge On
                                </a>&nbsp;&nbsp;
                            </li>
                            <li>
                                <a className="driver-list-button" onClick={() => handleUpdateAutoCharge("autoChargeConfirmation", false)}>
                                    <i className="fa fa-money" aria-hidden="true"></i> | Auto Charge Off
                                </a>&nbsp;&nbsp;
                            </li>
                            {activeTab === "Collections" && filterValues.hideFromCollections !== undefined && (filterValues.hideFromCollections === true ?
                                hasPermissions && hasPermissions.includes("custom_can_unhide_collection") && <li>
                                    <a className="driver-list-button" onClick={() => toggleHideDrivers(false)}>
                                        <i className="fa fa-eye" aria-hidden="true"></i> | Unhide From Collections
                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                </li>
                                : hasPermissions && hasPermissions.includes("custom_can_hide_collection") && <li> <a className="driver-list-button" onClick={() => toggleHideDrivers(true)}>
                                    <i className="fa fa-eye-slash" aria-hidden="true"></i> | Hide From Collections
                                </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                </li>)
                            }
                            {["All Drivers", "Active Drivers", "Collections"].includes(activeTab) && hasPermissions && hasPermissions.includes("add_driverassignment") &&
                                <>
                                    <li>
                                        <a className="driver-list-button" onClick={unAssignDrivers}>
                                            <i className="fa fa-minus" aria-hidden="true"></i> | Unassign Drivers
                                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </li>
                                    <li>
                                        <a className="driver-list-button" onClick={() => toggleModal("RepAssignmentModal")}>
                                            <i className="fa fa-plus" aria-hidden="true"></i> | Assign Drivers
                                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </li>
                                </>
                            }
                        </>
                    }
                    {activeTab !== "Schedules" &&
                        <li>
                            <a className="driver-list-button" onClick={() => toggleModal("MassMessageModal")}>
                                <i className="fa fa-commenting-o" aria-hidden="true"></i> | Message Drivers
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </li>
                    }

                    {["All Drivers", "Active Drivers"].includes(activeTab) && <li>
                        <a className="driver-list-button" onClick={() => toggleModal("AddNewDriver")}>
                            <i className="fa fa-plus" aria-hidden="true"></i> | New Driver
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>}
                    {activeTab === "Schedules" && <li>
                        <a className="driver-list-button">
                            No action found
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>}
                </ul>
            </PopoverBody>
        </Popover>
    )
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default (ActionsPopup)
