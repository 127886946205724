import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import {
    Col,
    Row, UncontrolledTooltip
} from 'reactstrap'
import { CarConditionQuery } from "../Queries"
import Loader from "../../../Material/Loader"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import CarInspectionDetail from "./CarInspectionDetail"
import { ReactComponent as InspectionIcon } from '../../../assets/images/icons/car-inspection.svg'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class InspectionTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectedImage: null,
            activeCarConditionId: null
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    pObj = (jsonString) => {
        let obj = JSON.parse(jsonString)
        return obj
    }
    inspectionPictures = (issueSet) => {
        let pictures = []
        if (issueSet.edges && issueSet.edges.length > 0) {
            for (let i = 0; i < issueSet.edges.length; i++) {
                let metadata = JSON.parse(issueSet.edges[i].node.metadata.replace(/'/g, '"'))
                if (metadata && metadata['pictures_url']) {

                    pictures = [...pictures, ...metadata['pictures_url'].map((picture, index) => ({ ...picture, id: issueSet.edges[i].node.id + picture.picture_type + picture.issue_category_name }))]
                }
            }
            this.setState({ inspectionImages: pictures, selectedImage: pictures[0] })
        }
    }
    next = () => {
        const inspectionImages = this.state.inspectionImages
        const currentIndex = inspectionImages.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === inspectionImages.length - 1) return
        this.setState({ selectedImage: inspectionImages[currentIndex + 1] })
    }

    prev = () => {
        const inspectionImages = this.state.inspectionImages
        const currentIndex = inspectionImages.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: inspectionImages[currentIndex - 1] })
    }
    componentDidUpdate(prevProps) {
        if ((prevProps.allCarConditions !== this.props.allCarConditions || !this.state.activeCarConditionId) && this.props.allCarConditions && this.props.allCarConditions.edges && this.props.allCarConditions.edges.length > 0) {
            this.setState({ activeCarConditionId: this.props.allCarConditions.edges[0].node.id })
        }
        if ((prevProps.allCarConditions !== this.props.allCarConditions || (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === "inspections")) && this.props.allCarConditions && this.props.allCarConditions.edges && this.props.allCarConditions.edges.length > 0) {
            if (this.props.location.search) {
                let queryParams = new URLSearchParams(this.props.location.search)
                let inspectionId = this.props.allCarConditions.edges.find(inspection => inspection.node.id === queryParams.get("inspectionId"))
                this.setState({ activeCarConditionId: inspectionId ? inspectionId.node.id : this.props.allCarConditions.edges[0].node.id })
            } else {
                this.props.navigate({ hash: this.props.location.hash, search: `inspectionId=${this.props.allCarConditions.edges[0].node.id}` })
                this.setState({ activeCarConditionId: this.props.allCarConditions.edges[0].node.id })
            }
        }
    }
    setInspectionId = (id) => {
        const queryString = new URLSearchParams({ "inspectionId": id }).toString()
        this.props.navigate({ hash: this.props.location.hash, search: queryString })
        this.setState({ activeCarConditionId: id })
    }

    setUrlParams = (id) => {
        const queryString = new URLSearchParams({ "inspectionId": this.state.activeCarConditionId, "incidentId": id }).toString()
        this.props.navigate({ hash: this.props.location.hash, search: queryString })
    }

    render() {
        return (
            <div className="inspection-container container-fluid">
                <Row>
                    <Col className="inspections-list" xs={2}>
                        {this.props.loading ? <Loader /> : <>
                            {this.props.allCarConditions && this.props.allCarConditions.edges && this.props.allCarConditions.edges.length > 0 ?
                                this.props.allCarConditions.edges.map((carCondition, i) =>
                                    <div
                                        className={"car-inspection" + (this.state.activeCarConditionId === (carCondition && carCondition.node && carCondition.node.id) ? " active" : "")}
                                        onClick={() => this.setInspectionId(carCondition.node.id)}
                                        key={i}
                                    >
                                        <div className={"sidebar-listing-wrapper-icon"}>
                                            <InspectionIcon width='25' height="25" fill={carCondition.node.formData && this.pObj(carCondition.node.formData) && "form_type" in this.pObj(carCondition.node.formData) && this.pObj(carCondition.node.formData)["form_Type"] === "PICKUP" ? "green" : "orange"} />&nbsp;
                                        </div>
                                        <div className={"sidebar-listing-wrapper-text"}>
                                            <p>{carCondition.node.formData && this.pObj(carCondition.node.formData) && "form_type" in this.pObj(carCondition.node.formData) ? this.pObj(carCondition.node.formData)["form_type"].charAt(0).toUpperCase() + this.pObj(carCondition.node.formData)["form_type"].slice(1).toLowerCase() : "---"}</p>
                                            <small>{carCondition.node.dateAdded ? <DatetimeRenderer datetime={carCondition.node.dateAdded} /> : "--"}</small>
                                        </div>
                                    </div>
                                ) : <p className="text-center no-rentals">No Car Condition Found!</p>
                            }
                            <div className="more-inspections">
                                {this.props.allCarConditions && this.props.allCarConditions.pageInfo.hasNextPage && <>
                                    <i className={"fa fa-2x fa-arrow-circle-down"} style={{ color: "#1B4D7E" }} onClick={this.props.loadMoreCarConditions} aria-hidden="true" id={"viewMore"}></i>
                                    <UncontrolledTooltip target={"viewMore"} placement={"left"}>View More</UncontrolledTooltip>
                                </>
                                }
                            </div>
                        </>}
                    </Col>
                    {this.state.activeCarConditionId ? <CarInspectionDetail id={this.state.activeCarConditionId} location={this.props.location} search={this.props.location.search} setUrlParams={this.setUrlParams} activeTab={this.props.activeTab} /> :
                        <Col xs={10}>
                            <p>No Car Condition Info Found!</p>
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarConditionQuery, {
    skip: ({ id }) => id === undefined,
    options: ({ id }) => ({ variables: { carId: id, first: 8 }, fetchPolicy: "cache-and-network" }),
    props: ({ data: { allCarConditions, fetchMore, loading, variables } }) => (
        {
            allCarConditions,
            loading,
            loadMoreCarConditions: () => {
                return fetchMore({
                    query: CarConditionQuery,
                    variables: {
                        cursor: allCarConditions.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allCarConditions.edges
                        const pageInfo = fetchMoreResult.allCarConditions.pageInfo

                        return {
                            allCarConditions: {
                                edges: [...previousResult.allCarConditions.edges, ...newEdges],
                                pageInfo,
                                __typename: previousResult.allCarConditions.__typename
                            },
                        }
                    },
                })
            },
        }
    )
})(withApolloClient(InspectionTab))
