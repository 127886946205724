import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'

import { Table, UncontrolledTooltip } from "reactstrap"
import toTitleCase from "../Functions/toTitleCase"
import { AllPromotionsTemplates } from "./Queries"
import UpdatePopup from "./updatePromoPopup"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"


const tableHeaders = [
  { id: "pk", name: <i className="fa fa-square-o" aria-hidden="true"></i>, sortable: false },
  { id: "details", name: "Promo Details", sortable: false },
  { id: "car_model", name: "Car Model", sortable: false },
  { id: "carYearMax", name: "Model Year Range", sortable: false },
  { id: "interval", name: "Interval", sortable: false },
  { id: "startDate", name: "Start Date", sortable: false },
  { id: "expirationDate", name: "Expiration Date", sortable: false },
  { id: "promo", name: "Promo Value($, %)", sortable: false },
  { id: "promoRange", name: "Promo Range($, %)", sortable: false },
  { id: "cycle", name: "Cycle", sortable: false },
  { id: "cycleRange", name: "Cycle Range", sortable: false },
  { id: "is_active", name: "Is Active", sortable: false },
  { id: "visibleToCustomers", name: "Customer Visibility", sortable: false },
]

class PromoTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openModal: "",
    }
  }

  toggleModal = (name) => {
    this.setState({ openModal: this.state.openModal === name ? "" : name })
  };

  render() {
    return (
      <PrimaryListingContainer listLoading={this.props.loading || this.state.loading} totalFetchedRows={this.props.allPromotionsTemplates && this.props.allPromotionsTemplates.edges.length} pageInfo={this.props.allPromotionsTemplates && this.props.allPromotionsTemplates.pageInfo} loadMoreEntries={this.props.allPromotionsTemplates && this.props.allPromotionsTemplates.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
        <Table responsive>
          <thead>
            <tr>
              {tableHeaders.map(header => <th id={header.id}>{header.name}</th>)}
            </tr>
          </thead>
          <tbody>
            {this.props.allPromotionsTemplates && this.props.allPromotionsTemplates.edges && this.props.allPromotionsTemplates.edges.length > 0 ? this.props.allPromotionsTemplates.edges.map((promo, index) =>
              <tr key={promo.node.id} id={promo.node.id}>
                <td scope="row">{index + 1}</td>
                <td>
                  <small id={"agreementType" + promo.node.pk}>
                    {promo.node.agreementType ? promo.node.agreementType.name : "--"}
                    <UncontrolledTooltip target={"agreementType" + promo.node.pk}>Agreement Type</UncontrolledTooltip>
                  </small>
                  <br />
                  <span id={"title" + promo.node.pk}> {promo.node.title || "--"}</span>
                  <UncontrolledTooltip target={"title" + promo.node.pk}>Promo Name</UncontrolledTooltip>
                </td>
                <td>
                  {promo.node.carModel && <>
                    <small>{promo.node.carModel && promo.node.carModel.groupType ? promo.node.carModel.groupType.name : "--"}</small>
                    <br />
                    <span>{toTitleCase(promo.node.carModel.make ? promo.node.carModel.make : "") + " " + toTitleCase(promo.node.carModel.name)}</span>{promo.node.carModel.series && <> ({promo.node.carModel.series})</>}
                  </>}
                </td>
                <td>
                  {promo.node.carYearMin}&nbsp;&nbsp;<small><i id={"updatecarYearMin" + index} onClick={() => this.toggleModal("updatecarYearMin" + index)} className={"fa fa-pencil"} /></small>
                  &nbsp;&#8212;&nbsp;{promo.node.carYearMax}&nbsp;&nbsp;<small><i id={"updatecarYearMax" + index} onClick={() => this.toggleModal("updatecarYearMax" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatecarYearMax" + index && <UpdatePopup target={"updatecarYearMax" + index} isOpen={this.state.openModal == "updatecarYearMax" + index} header="Update Template Car Year Maximum" dataId={promo.node.id} value={promo.node.carYearMax} name="carYearMax" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updatecarYearMax" + index)} />}
                  {this.state.openModal === "updatecarYearMin" + index && <UpdatePopup target={"updatecarYearMin" + index} isOpen={this.state.openModal == "updatecarYearMin" + index} header="Update Template Car Year Minimum" dataId={promo.node.id} value={promo.node.carYearMin} name="carYearMin" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updatecarYearMin" + index)} />}
                </td>
                <td>
                  {promo.node.interval} {promo.node.intervalUnit}
                </td>
                <td>
                  {promo.node.startDate}&nbsp;&nbsp;<small><i id={"startDate" + index} onClick={() => this.toggleModal("startDate" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "startDate" + index && <UpdatePopup target={"startDate" + index} isOpen={this.state.openModal == "startDate" + index} header="Update Template Is visible to Customer" dataId={promo.node.id} value={promo.node.startDate} name="startDate" type="date" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("startDate" + index)} />}
                </td>
                <td>
                  {promo.node.expirationDate}&nbsp;&nbsp;<small><i id={"expirationDate" + index} onClick={() => this.toggleModal("expirationDate" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "expirationDate" + index && <UpdatePopup target={"expirationDate" + index} isOpen={this.state.openModal == "expirationDate" + index} header="Update Template Is visible to Customer" dataId={promo.node.id} value={promo.node.expirationDate} name="expirationDate" type="date" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("expirationDate" + index)} />}
                </td>
                <td>
                  {promo.node.isPercentage ? promo.node.promoPrice + '%' : '$' + promo.node.promoPrice}&nbsp;&nbsp;<small><i id={"updatePrice" + index} onClick={() => this.toggleModal("updatePrice" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatePrice" + index && <UpdatePopup target={"updatePrice" + index} isOpen={this.state.openModal == "updatePrice" + index} header="Update Template promo" dataId={promo.node.id} value={promo.node.promoPrice} name="promoPrice" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updatePrice" + index)} />}
                </td>
                <td>
                  {promo.node.isPercentage ? promo.node.minimumPromoPrice + '%' : promo.node.minimumPromoPrice}&nbsp;&nbsp;<small><i id={"updateminimumPromoPrice" + index} onClick={() => this.toggleModal("updateminimumPromoPrice" + index)} className={"fa fa-pencil"} /></small>
                  &nbsp;&#8212;&nbsp;{promo.node.isPercentage ? promo.node.maximumPromoPrice + '%' : promo.node.maximumPromoPrice}&nbsp;&nbsp;<small><i id={"updatemaximumPromoPrice" + index} onClick={() => this.toggleModal("updatemaximumPromoPrice" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatemaximumPromoPrice" + index && <UpdatePopup target={"updatemaximumPromoPrice" + index} isOpen={this.state.openModal == "updatemaximumPromoPrice" + index} header="Update Template Maximum promo" dataId={promo.node.id} value={promo.node.maximumPromoPrice} name="maximumPromoPrice" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updatemaximumPromoPrice" + index)} />}
                  {this.state.openModal === "updateminimumPromoPrice" + index && <UpdatePopup target={"updateminimumPromoPrice" + index} isOpen={this.state.openModal == "updateminimumPromoPrice" + index} header="Update Template Minimum promo" dataId={promo.node.id} value={promo.node.minimumPromoPrice} name="minimumPromoPrice" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updateminimumPrice" + index)} />}
                </td>
                <td>
                  {promo.node.cycle}&nbsp;&nbsp;<small><i id={"updateCycle" + index} onClick={() => this.toggleModal("updateCycle" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updateCycle" + index && <UpdatePopup target={"updateCycle" + index} isOpen={this.state.openModal == "updateCycle" + index} header="Update Template Cycle" dataId={promo.node.id} value={promo.node.cycle} name="cycle" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updateCycle" + index)} />}
                </td>
                <td>
                  {promo.node.minCycle}&nbsp;&nbsp;<small><i id={"updateminCycle" + index} onClick={() => this.toggleModal("updateminCycle" + index)} className={"fa fa-pencil"} /></small>
                  &nbsp;&#8212;&nbsp;{promo.node.maxCycle}&nbsp;&nbsp;<small><i id={"updatemaxCycle" + index} onClick={() => this.toggleModal("updatemaxCycle" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatemaxCycle" + index && <UpdatePopup target={"updatemaxCycle" + index} isOpen={this.state.openModal == "updatemaxCycle" + index} header="Update Template Maximum Cycle" dataId={promo.node.id} value={promo.node.maxCycle} name="maxCycle" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updatemaxCycle" + index)} />}
                  {this.state.openModal === "updateminCycle" + index && <UpdatePopup target={"updateminCycle" + index} isOpen={this.state.openModal == "updateminCycle" + index} header="Update Template Minimum Cycle" dataId={promo.node.id} value={promo.node.minCycle} name="minCycle" type="number" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("updateminCycle" + index)} />}
                </td>
                <td>
                  &nbsp;&nbsp;{promo.node.isActive ? <>
                    <i id={"promoIsActive" + index} className="ml-2 fa fa-check text-success" />
                    <UncontrolledTooltip placement="right" target={"promoIsActive" + index}>Active Promo</UncontrolledTooltip>
                  </> : <>
                    <i id={"promoIsNotActive" + index} className="ml-2 fa fa-times text-danger" />
                    <UncontrolledTooltip placement="right" target={"promoIsNotActive" + index}>In-Active Promo</UncontrolledTooltip>
                  </>}
                  &nbsp;&nbsp;&nbsp;&nbsp;<i id={"isActive" + index} onClick={() => this.toggleModal("isActive" + index)} className={"fa fa-pencil"} />
                  {this.state.openModal === "isActive" + index && <UpdatePopup target={"isActive" + index} isOpen={this.state.openModal == "isActive" + index} header="Update Template Is Active" dataId={promo.node.id} value={promo.node.isActive} name="isActive" type="checkbox" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("isActive" + index)} />}
                </td>
                <td>
                  {promo.node.visibleToCustomers ? "True" : "False"}&nbsp;&nbsp;<small><i id={"visibleToCustomers" + index} onClick={() => this.toggleModal("visibleToCustomers" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "visibleToCustomers" + index && <UpdatePopup target={"visibleToCustomers" + index} isOpen={this.state.openModal == "visibleToCustomers" + index} header="Update Template Is visible to Customer" dataId={promo.node.id} value={promo.node.visibleToCustomers} name="visibleToCustomers" type="checkbox" refetchAllPromotionTemplates={this.props.refetchQuery} handleClose={() => this.toggleModal("visibleToCustomers" + index)} />}
                </td>
              </tr>
            ) : <tr> <td colSpan={20}>
              <p className="column-text">{this.props.loading ? "Loading promotions..." : "No Templates Found!"}</p>
            </td></tr>
            }
          </tbody>
        </Table>
      </PrimaryListingContainer>
    )
  }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllPromotionsTemplates, {
  options: ({ modelGroupTypeId, modelTypeIds, agreementTypeId, isActive, searchTerm, orderBy }) => ({
    variables: { modelGroupTypeId, modelTypeIds, agreementTypeId, isActive, searchTerm, orderBy, first: 30 },
    notifyOnNetworkStatusChange: true, fetchPolicy: "cache-and-network",
  }), props: ({ data: { loading, allPromotionsTemplates, fetchMore, variables } }) => (
    {
      loading, allPromotionsTemplates, variables,
      refetchQuery: () => {
        //No apollo function that refetches in place with pagination considered so this function instead
        let currentLength = allPromotionsTemplates && allPromotionsTemplates.edges ? allPromotionsTemplates.edges.length : 30
        return fetchMore({
          query: AllPromotionsTemplates,
          variables: {
            ...variables,
            first: currentLength,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.allPromotionsTemplates.edges
            const pageInfo = fetchMoreResult.allPromotionsTemplates.pageInfo
            return {
              allPromotionsTemplates: {
                edges: [...newEdges],
                pageInfo,
                searchTerm: previousResult.searchTerm,
                __typename: previousResult.allPromotionsTemplates.__typename
              },
            }
          },
        })
      },

      loadMoreEntries: () => {
        return fetchMore({
          query: AllPromotionsTemplates,
          variables: {
            cursor: allPromotionsTemplates.pageInfo.endCursor,
            ...variables,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.allPromotionsTemplates.edges
            const pageInfo = fetchMoreResult.allPromotionsTemplates.pageInfo

            return {
              allPromotionsTemplates: {
                edges: [
                  ...previousResult.allPromotionsTemplates.edges,
                  ...newEdges,
                ],
                pageInfo,
                status: previousResult.status,
                __typename: previousResult.allPromotionsTemplates.__typename,
              },
            }
          },
        })
      },
    }
  )
})
  (PromoTable)
