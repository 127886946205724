import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'
import '../BranchMangement/BranchListContainer.css'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { AllGroupObjectPermissions } from "./Queries"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import UpdateObjectPermissionModal from "./UpdateObjectPermissionModal"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const tableHeaders = [
    { id: "pk", name: "ID", sortable: false },
    { id: "date_added", name: "Date Added", sortable: false },
    { id: "object_name", name: "Object Name/Object ID", sortable: false },
    { id: "content_type", name: "Content Type", sortable: false },
    { id: "groups", name: "Groups", sortable: false },
]

class ObjectPermissionsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            openModal: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    render() {
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.allGroupObjectPermissions && this.props.allGroupObjectPermissions.edges.length} pageInfo={this.props.allGroupObjectPermissions && this.props.allGroupObjectPermissions.pageInfo} loadMoreEntries={this.props.allGroupObjectPermissions && this.props.allGroupObjectPermissions.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaders.map(tableHeader => {
                                    return (
                                        tableHeader.sortable ?
                                            <th key={tableHeader.id} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )
                                })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.allGroupObjectPermissions && this.props.allGroupObjectPermissions.edges && this.props.allGroupObjectPermissions.edges.length > 0 ? this.props.allGroupObjectPermissions.edges.map((groupObjectPermission, i) =>
                                <tr key={i + 1}>
                                    <td>&nbsp;{i + 1}</td>
                                    <td><DatetimeRenderer datetime={groupObjectPermission.node.dateAdded} placement={"left"} /></td>
                                    <td>{groupObjectPermission.node.objectName} | {groupObjectPermission.node.objectId}</td>
                                    <td>{groupObjectPermission.node.contentType ? groupObjectPermission.node.contentType.appLabel : "--"} <span style={{ fontSize: 15 }}>|</span> {groupObjectPermission.node.contentType ? groupObjectPermission.node.contentType.model : "--"}</td>
                                    <td>

                                        {groupObjectPermission.node.groups && groupObjectPermission.node.groups.edges && groupObjectPermission.node.groups.edges.length > 0 ? groupObjectPermission.node.groups.edges.filter((_, i) => i < 2).map((group, i) =>
                                            <><Link to={`/organization/group/${group.node.id}`}>{group.node.name}</Link>,&nbsp;&nbsp;</>
                                        ) : "--"}
                                        {groupObjectPermission.node.groups && groupObjectPermission.node.groups.edges && groupObjectPermission.node.groups.edges.length > 2 && <>
                                            <span id={"more-groups-" + i}>+{groupObjectPermission.node.groups.edges.length - 2} more</span>
                                            <UncontrolledTooltip className="more-info-tooltip" target={"more-groups-" + i} placement="right" delay={{ "show": 400, "hide": 200 }} autohide={false}>
                                                {groupObjectPermission.node.groups.edges.filter((_, i) => i >= 2).map((group, i) =>
                                                    <>
                                                        <Link to={`/organization/group/${group.node.id}`}>{group.node.name}</Link><br />
                                                    </>
                                                )}
                                            </UncontrolledTooltip>
                                        </>}
                                        &nbsp;&nbsp;<i className={"fa fa-pencil"} onClick={() => this.toggleModal("editGroups" + i)} id={"editGroups" + i}></i>
                                        {this.state.openModal === "editGroups" + i &&
                                            <UpdateObjectPermissionModal
                                                groupObjectPermissionId={groupObjectPermission.node.id}
                                                title="Update Groups"
                                                name="groups"
                                                value={groupObjectPermission.node.groups.edges.map(branch => branch.node.id)}
                                                target={"editGroups" + i}
                                                id={"editGroups" + i}
                                                type="select"
                                                open={this.state.openModal === "editGroups" + i}
                                                handleClose={() => this.toggleModal("")}
                                                refetchQuery={this.props.refetchQuery}
                                            />
                                        }
                                    </td>
                                </tr>
                            ) :
                                <tr>
                                    {!this.props.loading ?
                                        <td colSpan={7}>No Group Permission Found</td> :
                                        <td colSpan={7}>Loading ...</td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </Table>
                </PrimaryListingContainer>
            </>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllGroupObjectPermissions, {
        options: ({ searchTerm }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                name_Icontains: searchTerm,
                first: 30
            }
        }),
        props:({ data: { loading, allGroupObjectPermissions, fetchMore, variables } })=>({
                loading,
                allGroupObjectPermissions,
                variables,
                refetchQuery: () => {
                    let currentLength = allGroupObjectPermissions && allGroupObjectPermissions.edges && allGroupObjectPermissions.edges.length > 30 ? allGroupObjectPermissions.edges.length : 30
                    return fetchMore({
                        query: AllGroupObjectPermissions,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allGroupObjectPermissions.edges
                            const pageInfo = fetchMoreResult.allGroupObjectPermissions.pageInfo
                            return {
                                allGroupObjectPermissions: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allGroupObjectPermissions.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllGroupObjectPermissions,
                        variables: {
                            cursor: allGroupObjectPermissions.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allGroupObjectPermissions.edges
                            const pageInfo = fetchMoreResult.allGroupObjectPermissions.pageInfo

                            return {
                                allGroupObjectPermissions: {
                                    edges: [...previousResult.allGroupObjectPermissions.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allGroupObjectPermissions.__typename
                                },
                            }
                        },
                    })
                },
        })
    })
(withApolloClient(ObjectPermissionsList));