import React, { useState, useEffect } from 'react'
import { useParams, Link, Routes, Route, BrowserRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { Container, Row, Col, Button } from 'reactstrap'
import './TaskManagementContainer.css'
import './TaskManagement.css'
import TaskManagementListHeader from './TaskManagementListHeader'
import TaskList from './TaskList'
import TaskDetailsSidePanel from './TaskDetailsSidePanel'
import { AllBoardsQuery } from './Queries'
import withApolloClient from '../withApolloClient'

function TaskManagementContainer({ allBoards, loading, loadMoreEntries, ...props }) {
    const { boardId } = useParams()

    const [filterValues, setFilterValues] = useState(() => {
        try {
            const savedFilters = localStorage.getItem('TaskManagementFilters')
            return savedFilters ? JSON.parse(savedFilters) : { searchTerm: '', statuses: ['10_OPEN', '20_IN_PROGRESS'] }
        } catch {
            return { searchTerm: '', statuses: ['10_OPEN', '20_IN_PROGRESS'] }
        }
    })

    useEffect(() => {
        localStorage.setItem('TaskManagementFilters', JSON.stringify(filterValues))
    }, [filterValues])

    return (
        <div className="TaskManagementContainer">
            {/* <Switch>
                <Route path="/boards/:boardId/tasks/:taskId" render={(props) => <TaskDetailsSidePanel {...props} />} />
                <Route path="/boards/tasks/:taskId" render={(props) => <TaskDetailsSidePanel {...props} />} />
            </Switch>
            <Switch>
                <Route exact path="/boards/:boardId/tasks/*" render={(props) => <TaskList {...props} {...filterValues} />} />
                <Route exact path="/boards/tasks/*" render={(props) => <TaskList {...props} {...filterValues} />} />
            </Switch> */}
            <Routes>
                <Route path="/boards/:boardId/tasks/:taskId" element={<TaskDetailsSidePanel />} />
                <Route path="/:taskId" element={<TaskDetailsSidePanel />} />
                <Route path="/boards/:boardId/tasks/**" element={<TaskList {...filterValues} />} />
                <Route path="*" element={<TaskList {...filterValues} />} />
                {/* <Route path="*" element={<div>404 Not Found</div>} /> */}
            </Routes>

            <Container fluid>
                <TaskManagementListHeader
                    activeTab="all"
                    filterValues={filterValues}
                    setFilterValues={(res) => setFilterValues({ ...res })}
                />
                <Row>
                    <Col xs="2" className="TaskBoardColumn">
                        <ul className="TaskBoardList">
                            <li className={!boardId || boardId === 'tasks' ? 'active' : ''}>
                                <Link to="/boards/tasks/">All Tasks</Link>
                            </li>
                            {allBoards?.edges?.map((board, idx) => (
                                <li className={boardId === board.node.id ? 'active' : ''} key={idx}>
                                    <Link to={`/boards/${board.node.id}/tasks/`}>{board.node.title}</Link>
                                </li>
                            ))}
                            {allBoards?.pageInfo?.hasNextPage && (
                                <li>
                                    <Button outline color="secondary" onClick={loadMoreEntries}>
                                        Load more...
                                    </Button>
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default graphql(AllBoardsQuery, {
    options: ({ searchTerm }) => ({
        variables: {
            searchTerm,
            orderBy: ["date_added"],
            isArchived: false,
            first: 10
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    }),
    props: ({ data: { loading, allBoards, fetchMore, searchTerm, variables, refetch } }) => (
        {
            loading,
            allBoards,
            variables,
            refetch,
            refetchDriversQuery: () => {
                //No apollo function that refetches in place with pagination considered so this function instead
                let currentLength = allBoards && allBoards.edges ? allBoards.edges.length : 1
                return fetchMore({
                    query: AllBoardsQuery,
                    variables: {
                        ...variables,
                        first: currentLength,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allBoards.edges
                        const pageInfo = fetchMoreResult.allBoards.pageInfo
                        return {
                            allBoards: {
                                edges: [...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.allBoards.__typename
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: AllBoardsQuery,
                    variables: {
                        cursor: allBoards.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allBoards.edges
                        const pageInfo = fetchMoreResult.allBoards.pageInfo

                        return {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            allBoards: {
                                edges: [...previousResult.allBoards.edges, ...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.allBoards.__typename
                            },
                        }
                    },
                })
            },
        }
    )
})
    (withApolloClient(TaskManagementContainer))