import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import Table from "reactstrap/lib/Table"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import { UncontrolledTooltip } from "reactstrap"
import { HasPermissionsQuery } from "../../Functions/querys"
import gql from "graphql-tag"
import { Link } from "react-router-dom"
import UpdateSalesTax from "./UpdateSalesTax"

const DeleteSalesTax = gql`mutation DeleteSalesTax($input: DeleteSalesTaxInput!) {
    deleteSalesTax(input: $input) {
        ok
        errors{
            messages
        }
    }
}`

const TaxesQuery = gql`query TaxQuery($cursor: String, $first: Int!, $searchTerm: String, $branchIds: [ID], $orderBy: [String]) {
    allSalesTaxes(first: $first, after: $cursor, searchTerm: $searchTerm, branchIds: $branchIds, orderBy: $orderBy) {
        edges {
            node {
                id
                pk
                createdBy {
                    id
                    username
                }
                updatedBy {
                    id
                    username
                }
                chargeType {
                    id
                    name
                }
                branches {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
        pageInfo {
            endCursor
            hasNextPage
            length
        }
    }
}`

const taxManagementListHeaders = [
    { id: "id", name: "ID", sortable: true },
    { id: "chargeType", name: "Charge Type", sortable: true },
    { id: "branches", name: "Branches", sortable: false },
    { id: "createdBy", name: "Created By", sortable: true },
    { id: "updatedBy", name: "Updated By", sortable: true },
    { id: "action", name: "Action", sortable: false }
]

class TaxManagementList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            errorMessage: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) this.setState({ openModal: modalName })
        else this.setState({ openModal: "" })
    }

    // Iagami - Code upgradation React 18
    componentDidMount() {
        const { apolloClient } = this.props;
    }   

    deleteSalesTax = async (id) => {
        this.setState({ loading: true })
        try {
            if (!id) throw new Error("No tax id provided")
            const input = { taxId: id }
            const result = await this.props.apolloClient.mutate({// Iagami - Code upgradation React 18
                mutation: DeleteSalesTax,
                variables: { input }
            })

            if (!result || !result.data || !result.data.deleteSalesTax || !result.data.deleteSalesTax.ok) throw new Error(`An error has ocurred, could not complete request. ${result.data.deleteSalesTax.errors[0] && result.data.deleteSalesTax.errors[0].messages}`)

            this.props.refetchQuery()
            this.setState({ loading: false, openModal: "", error: "" })
        } catch (error) {
            let errorMessage = `An error has ocurred ${error}`
            this.setState({ error, errorMessage, loading: false })
        }
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading}
                totalFetchedRows={this.props.allSalesTaxes && this.props.allSalesTaxes.edges.length}
                pageInfo={this.props.allSalesTaxes && this.props.allSalesTaxes.pageInfo}
                loadMoreEntries={this.props.allSalesTaxes && this.props.allSalesTaxes.pageInfo.hasNextPage ? this.props.loadMoreEntries : null}
                refetchListQuery={this.props.refetchQuery}
            >
                <Table responsive>
                    <thead>
                        <tr>
                            {taxManagementListHeaders.map((tableHeader) => {
                                return (
                                    tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrderBy(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.includes(tableHeader.id) ? <i className={"fa" + (this.props.orderBy === tableHeader.id ? "fa-sort-asc" : "fa-sort-desc")} ></i> : <i className="fa fa-sort"></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.allSalesTaxes && this.props.allSalesTaxes.edges && this.props.allSalesTaxes.edges.length > 0 ? this.props.allSalesTaxes.edges.map((salesTax, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <strong>{salesTax.node.pk}</strong>
                                    </td>
                                    <td>
                                        {salesTax.node.chargeType.name}
                                    </td>
                                    <td>
                                        {salesTax.node.branches &&
                                            salesTax.node.branches.edges &&
                                            salesTax.node.branches.edges.length > 0 ?
                                            salesTax.node.branches.edges.filter((branch, i) => i < 2).map((branch, i) => {
                                                return <><Link to={`/organization/branch/${branch.node.id}`}>{branch.node.name}, &nbsp;&nbsp;</Link></>
                                            })
                                            :
                                            "--"
                                        }
                                        {
                                            salesTax.node.branches &&
                                            salesTax.node.branches.edges &&
                                            salesTax.node.branches.edges.length > 2 &&
                                            <>
                                                <span id={`more-branches-${salesTax.node.pk}`}>+ {salesTax.node.branches.edges.length - 2} more</span>
                                                <UncontrolledTooltip className="more-info-tooltip" target={`more-branches-${salesTax.node.pk}`}
                                                    placement="right"
                                                    delay={{ show: 400, hide: 200 }}
                                                    autohide={false}
                                                >
                                                    {salesTax.node.branches.edges.filter((branch, i) => i >= 2).map((branch, i) => {
                                                        return (
                                                            <>
                                                                <Link to={`/organization/branch/${branch.node.id}`}>{branch.node.name}</Link><br />
                                                            </>
                                                        )
                                                    })}
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                        &nbsp;&nbsp;
                                        {
                                            this.props.hasPermissions && (
                                                this.props.hasPermissions.includes("change_salestax") &&
                                                    <>
                                                        <i className="fa fa-pencil"
                                                            id={`editBranches${salesTax.node.pk}`}
                                                            style={{ opacity: 1 }}
                                                            onClick={() => this.toggleModal(`editBranches${salesTax.node.pk}`)}
                                                        ></i>
                                                        <UpdateSalesTax
                                                            placement="bottom-start"
                                                            taxId={salesTax.node.id}
                                                            title="Add New Branches"
                                                            name="branchIds"
                                                            value={salesTax.node.branches.edges.map(branch => branch.node.id)}
                                                            target={`editBranches${salesTax.node.pk}`}
                                                            id={`editBranches${salesTax.node.pk}`}
                                                            type="select"
                                                            open={this.state.openModal === `editBranches${salesTax.node.pk}`}
                                                            handleClose={() => this.toggleModal("")}
                                                            refetchDriverQuery={this.props.refetchQuery}
                                                        />
                                                    </>
                                            )

                                        }
                                    </td >
                                    <td>
                                        {salesTax.node.createdBy.username}
                                    </td>
                                    <td>
                                        {salesTax.node.updatedBy.username}
                                    </td>
                                    <td>
                                        {
                                            this.props.hasPermissions && (
                                                this.props.hasPermissions.includes("delete_salestax") &&
                                                <>
                                                    <i className="fa fa-lg fa-trash" aria-hidden="true"
                                                       id={"deleteSalesTax" + salesTax.node.pk}
                                                       onClick={() => this.toggleModal("deleteSalesTax" + salesTax.node.pk)}
                                                       style={{
                                                           color: "red",
                                                           opacity: 1
                                                       }}></i>
                                                    <ConfirmationPopup
                                                        open={this.state.openModal === "deleteSalesTax" + salesTax.node.pk}
                                                        message={"ARE YOU SURE YOU WANT TO DELETE THIS TAX?"}
                                                        loading={this.state.loading}
                                                        handleClose={() => this.toggleModal("")}
                                                        action={"Yes"}
                                                        confirmAction={() => this.deleteSalesTax(salesTax.node.id)}
                                                        color="danger"
                                                        error={this.state.error ? this.state.error : ""}
                                                    />
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        }) :
                            this.props.loading ? null :
                                <tr>
                                    <td colSpan={7} className="text-center">No taxes found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}
// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(HasPermissionsQuery, {
        options: () => ({
            variables: { userPermissions: ["delete_salestax", "change_salestax"] },
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables };
        }
    })(
        graphql(TaxesQuery, {
            options: ({ searchTerm, orderBy, branchIds }) => ({
                fetchPolicy: "cache-and-network",
                variables: { first: 10, searchTerm, orderBy, branchIds }
            }),
            props({ data: { loading, allSalesTaxes, fetchMore, variables, refetch } }) {
                return {
                    loading,
                    allSalesTaxes,
                    variables,
                    refetch,
                    refetchQuery: () => {
                        let currentLength = allSalesTaxes && allSalesTaxes.edges ? allSalesTaxes.edges.length : 10;
                        return fetchMore({
                            query: TaxesQuery,
                            variables: {
                                ...variables,
                                first: currentLength < 10 ? 10 : currentLength
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allSalesTaxes.edges;
                                const pageInfo = fetchMoreResult.allSalesTaxes.pageInfo;
                                return {
                                    allSalesTaxes: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        __typename: previousResult.allSalesTaxes.__typename
                                    }
                                };
                            }
                        });
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: TaxesQuery,
                            variables: {
                                cursor: allSalesTaxes.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allSalesTaxes.edges;
                                const pageInfo = fetchMoreResult.allSalesTaxes.pageInfo;
                                return {
                                    allSalesTaxes: {
                                        edges: [...previousResult.allSalesTaxes.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.allSalesTaxes.__typename
                                    }
                                };
                            }
                        });
                    }
                };
            }
        })(
            withApolloClient(TaxManagementList)
        )
    )


