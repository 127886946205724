import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Form,
    Label,
    Input,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ButtonDropdown,
} from 'reactstrap'
import { AllInsurancePoliciesQuery } from '../Queries'
import { UpdateCarPolicyMutation } from '../Mutations'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class UpdateCarPolicy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: 1,
            error: "",
            selectedPlates: [],
            carToTransfer: null,
            openModal: "",
            policiesSuggestions: null,
            selectedPolicy: null,
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({ loading: true, error: "" })
        if (this.props.id && this.state.selectedPolicy) {
            let input = {
                carId: this.props.id,
                insurancePolicyId: this.state.selectedPolicy.id,
            }
            this.setState({ loading: true })
            this.props.apolloClient.mutate({
                mutation: UpdateCarPolicyMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.response && result.data.response.ok) {
                    this.setState({ carVin: null, policy: null, loading: false, error: "" })
                    this.props.refetchPolicies()
                    this.props.handleClose()
                } else if (result && result.data && result.data.response && result.data.response.errors && result.data.response.errors[0] && result.data.response.errors[0].messages)
                    this.setState({ loading: false, error: result.data.response.errors[0].messages.toString() })
                else
                    this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
            }).catch((err) => {
                this.setState({ loading: false, error: err.message })
            })
        } else {
            this.setState({ loading: false, error: "Please provide all the information!" })
        }
    }

    handleCarPolicySearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.apolloClient.query({
                query: AllInsurancePoliciesQuery,
                variables: { searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.allInsurancePolicies && response.data.allInsurancePolicies.edges) {
                    this.setState({ policiesSuggestions: response.data.allInsurancePolicies.edges })
                } else {
                    this.setState({ policiesSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ policiesSuggestions: null })
            })
        } else {
            this.setState({ policiesSuggestions: null })
        }
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> Add Car Policy for car {this.props.carpk}</ModalHeader>
            <Form onSubmit={this.handleSubmit}>
                <ModalBody style={{ height: "100px" }}>
                    <Row>
                        {this.state.selectedPolicy ? <><br /><b>{this.state.selectedPolicy.policyNumber}</b>&nbsp;&nbsp;&nbsp;<i className="fa fa-times" onClick={() => this.setState({ selectedPolicy: null, policiesSuggestions: null })} /> </> :
                            <ButtonDropdown
                                className="w-100"
                                isOpen={this.state.openModal === "CarFinder"}
                                toggle={() => this.toggleModal("CarFinder")}
                            >
                                <DropdownToggle caret>
                                    Choose Car Ploicy
                                </DropdownToggle>
                                <DropdownMenu
                                    className='w-100'
                                >
                                    <Label
                                        for="policySearch"
                                        className='pl-3'
                                    >
                                        <small>
                                            Search by policy id
                                        </small>
                                    </Label>
                                    <Input
                                        type="text"
                                        onChange={this.handleCarPolicySearch}
                                        name="policySearch"
                                        id="policySearch"
                                    />
                                    {this.state.policiesSuggestions &&
                                        this.state.policiesSuggestions.length > 0 &&
                                        this.state.policiesSuggestions.map((policy, i) =>
                                            <DropdownItem key={i}
                                                onClick={() => this.setState({ selectedPolicy: policy.node })}
                                                style={{ display: "content" }}
                                            >
                                                {policy.node.pk} . {policy.node.policyNumber}
                                            </DropdownItem>
                                        )}
                                </DropdownMenu>
                            </ButtonDropdown>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                    <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                    <Button type="submit" color="primary">Update Car Policy</Button>
                </ModalFooter>
            </Form>
        </Modal>
    };
}
//Iagami - Code upgradation React 18
export default withApolloClient(UpdateCarPolicy)