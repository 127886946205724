import React, { Component } from 'react'
import { ApolloProvider } from '@apollo/client'
import client from './GraphQL.js'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter as Router } from 'react-router-dom'
import RoutingInfoNew from './RoutingInfoNew.js'
import ErrorBoundary from "./Functions/ErrorBoundary.js"

//Iagami - Code upgradation React 18
class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <ErrorBoundary client={client}>
            <RoutingInfoNew apolloClientValue={client} />
          </ErrorBoundary>
        </Router>
      </ApolloProvider>
    )
  }
}
export default App
