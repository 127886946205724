import React, { Component } from 'react'
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo imports
import gql from 'graphql-tag'
import './DriverUpdateTagsPopover.css'
import Select from 'react-select'
import { TagsQuery } from "../../../Functions/querys"
import withApolloClient from "../../../withApolloClient"


const addTags = gql`
mutation AddDriverTagsMutation($input: AddTagsInput!) {
    addTags(input: $input) {
        driver {
            id
            tags {
                id
                name
                color
            }
        }
    }
}
`

const removeTags = gql`
mutation RemoveDriverTagsMutation($input: AddTagsInput!) {
    removeTags(input: $input) {
        driver {
            id
            tags {
                id
                name
                color
            }
        }
    }
}
`

class DriverUpdateTagsPopover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tag: []
        }
    }

    updateTags = () => {
        let input = { driver: this.props.driverId }
        let tagsFound = []
        let tagsNotFound = []
        let allValues = [...this.state.tag, ...this.props.driverTags.map(tag => tag.name)]
        allValues = allValues.filter((item) => allValues.indexOf(item) === allValues.lastIndexOf(item))
        allValues.forEach(tag => {
            if (this.props.driverTags.find(driverTag => driverTag.name === tag)) {
                tagsFound = [...tagsFound, tag]
            } else {
                tagsNotFound = [...tagsNotFound, tag]
            }
        })
        if (tagsNotFound.length > 0) {
            input['tags'] = tagsNotFound
            this.props.add(input)
        }
        if (tagsFound.length > 0) {
            input['tags'] = tagsFound
            this.props.remove(input)
        }
        this.props.refetchQuery()
        this.props.handleClose()
    };

    render() {
        return (
            <Popover trigger="legacy" isOpen={this.props.isOpen} placement="bottom" target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader onClick={this.props.handleClose}>
                    Edit Tags <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"></i>
                </PopoverHeader>
                <PopoverBody style={{ minWidth: "200px", maxWidth: "300px" }}>
                    {this.props.allDriverTags && this.props.allDriverTags.edges && (
                        <Select
                            key={this.props.driverId}
                            className="bos-custom-select"
                            classNamePrefix="bos-select"
                            placeholder="Select Driver Tags"
                            options={this.props.allDriverTags && this.props.allDriverTags.edges.map(tag => ({ value: tag.node.name, label: tag.node.name }))}

                            defaultValue={this.props.allDriverTags && this.props.allDriverTags.edges
                                .filter(tag =>
                                    this.props.driverTags
                                        .map(driverTags => driverTags.name)
                                        .includes(tag.node.name))
                                .map(tag => ({
                                    value: tag.node.name,
                                    label: tag.node.name
                                }))}
                            isClearable
                            isMulti
                            onChange={tags =>
                                this.setState({ tag: tags && tags.length > 0 ? tags.map(item => item.value) : [] })
                            }
                        />
                    )}
                    <Button
                        className="float-right my-3 py-1 update-btn"
                        type="button"
                        size="sm"
                        onClick={this.updateTags}
                    >
                        Update Tags
                    </Button>
                </PopoverBody>
            </Popover>
        )
    }
}

export default graphql(addTags, {
    props: ({ mutate }) => ({
        add: (input) => mutate({ variables: { input } }),
    }),
})(
    graphql(TagsQuery, {
        props({ data: { loading, driverTags } }) {
            return { allDriverTags: driverTags }
        },
    })(
        graphql(removeTags, {
            props: ({ mutate }) => ({
                remove: (input) => mutate({ variables: { input } }),
            }),
            options: {
                refetchQueries: ['DriversQuery'],
            },
        })(withApolloClient(DriverUpdateTagsPopover))
    )
)
