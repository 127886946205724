import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import './TransactionListNew.css'
import { Alert, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Progress } from 'reactstrap'
import Select from "react-select"
import moment from "moment"
import DocumentModalNew from "../../Material/DocumentModal/DocumentModalNew"
import { AllChargeTypes, AllGroupTypes } from "./Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class DownloadTransactions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            loading: false,
            reportStartDate: new Date().getDay() == 1 ? moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
            reportEndDate: moment(new Date()).format('YYYY-MM-DD'),
            transactionId: null,
            minAmount: null,
            maxAmount: null,
            groupTypeIds: [],
            chargeTypeIds: [],
            excludeGroupTypeIds: [],
            excludeChargeTypeIds: [],
        }
    }
    downloadReport = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        fetch("/billing/driver/" + this.props.driverId + "/history/?start_date=" + this.state.reportStartDate + "&end_date=" + this.state.reportEndDate + (this.state.minAmount ? ("&min_amount=" + this.state.minAmount) : "") + (this.state.maxAmount ? ("&max_amount=" + this.state.maxAmount) : "") + (this.state.groupTypeIds ? this.state.groupTypeIds.map(id => `&group_type_id=${id}`).join() : "") + (this.state.chargeTypeIds ? this.state.chargeTypeIds.map(id => `&charge_type_id=${id}`).join() : "") + (this.state.excludeGroupTypeIds ? this.state.excludeGroupTypeIds.map(id => `&exclude_group_type_id=${id}`).join() : "") + (this.state.excludeChargeTypeIds ? this.state.excludeChargeTypeIds.map(id => `&exclude_charge_type_id=${id}`).join() : ""), {
            method: "GET",
        }).then((response) => {
            return response.blob()
        })
            .then((blob) => {
                var reader = new FileReader()
                reader.readAsDataURL(blob)
                this.setState({ loading: false })
                return new Promise(resolve => {
                    reader.onloadend = function () {
                        console.log(reader.result)
                        resolve(reader.result)
                    }
                })
            })
            .then((base64) => {
                let docObject = {
                    documentUrl: base64,
                    name: "TransactionHistory"
                }
                this.setState({ downloadSuccess: true, selectedDocument: docObject, openModal: "viewDocument" })
            }).catch((err) => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    componentDidUpdate(prevProps) {
        if (this.state.excludeChargeTypeIds.length === 0 && this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0) {
            this.setState({ excludeChargeTypeIds: this.props.allChargeTypes.edges.filter(item => item.node.name === "Bad Debt Write Off" || item.node.name === "Reverse Bad Debt Write Off").map(item => item.node.id) })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.handleClose} >
                <ModalHeader>Download Transaction History</ModalHeader>
                <Progress animated color="info" value={this.state.loading ? 100 : 0} />
                <Form onSubmit={(e) => this.downloadReport(e)}>
                    <ModalBody>
                        {this.state.downloadSuccess && <Alert color="success">Download Successful.</Alert>}
                        {this.state.openModal === "viewDocument" &&
                            <DocumentModalNew
                                document={this.state.selectedDocument}
                                handleClose={() => this.setState({ selectedDocument: null, openModal: "" })}
                                objectType={"driver"}
                                isPicture={false}
                                currentUser={this.props.currentUser}
                            />
                        }
                        <Row>
                            <Col xs="">
                                <FormGroup>
                                    <Label for="reportStartDate">Enter start date</Label>
                                    <Input
                                        type="date"
                                        name="reportStartDate"
                                        id="reportStartDate"
                                        invalid={this.state.reportStartDate === ""}
                                        value={this.state.reportStartDate}
                                        valid={this.state.reportStartDate !== ""}
                                        onChange={(e) => this.setState({ reportStartDate: e.target.value })}
                                        placeholder="Start Date"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="reportEndDate">Enter end date</Label>
                                    <Input
                                        type="date"
                                        name="reportEndDate"
                                        id="reportEndDate"
                                        invalid={this.state.reportEndDate === ""}
                                        value={this.state.reportEndDate}
                                        valid={this.state.reportEndDate !== ""}
                                        onChange={(e) => this.setState({ reportEndDate: e.target.value })}
                                        placeholder={"End Date"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Filter by Charge Type</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allChargeTypes || !this.props.allChargeTypes.edges || !this.props.allChargeTypes.edges.length > 0}
                                        options={this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0 ?
                                            this.props.allChargeTypes.edges.filter(item =>
                                                this.state.excludeChargeTypeIds && this.state.excludeChargeTypeIds.length > 0 ?
                                                    !this.state.excludeChargeTypeIds.includes(item.node.id) : true).map(item => ({ value: item.node.id, label: item.node.name })
                                                    )
                                            : []
                                        }
                                        onChange={(chargeTypes) => this.setState({ chargeTypeIds: chargeTypes.map(item => item.value) })}
                                        placeholder="Select Charge Types"
                                        isClearable
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Filter by Sub-Charge Type</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allGroupTypes || !this.props.allGroupTypes.edges || !this.props.allGroupTypes.edges.length > 0}
                                        options={this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0 ?
                                            this.props.allGroupTypes.edges.filter(item =>
                                                this.state.excludeGroupTypeIds && this.state.excludeGroupTypeIds.length > 0
                                                    ? !this.state.excludeGroupTypeIds.includes(item.node.id) : true).map(item => ({ value: item.node.id, label: item.node.name })
                                                    )
                                            : []
                                        }
                                        onChange={(groupTypes) => this.setState({ groupTypeIds: groupTypes.map(item => item.value) })}
                                        placeholder="Select Sub-Charge Types"
                                        isClearable
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Exclude Charge Types</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allChargeTypes || !this.props.allChargeTypes.edges || !this.props.allChargeTypes.edges.length > 0}
                                        options={this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0 ?
                                            this.props.allChargeTypes.edges.filter(item =>
                                                this.state.chargeTypeIds && this.state.chargeTypeIds.length > 0 ?
                                                    !this.state.chargeTypeIds.includes(item.node.id) : true).map(item => ({ value: item.node.id, label: item.node.name })
                                                    )
                                            : []
                                        }
                                        value={this.state.excludeChargeTypeIds && this.state.excludeChargeTypeIds.map(id => this.props.allChargeTypes.edges.find(item => item.node.id === id)).map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="Select Charge Types"
                                        isClearable
                                        isMulti
                                        onChange={(chargeTypes) => this.setState({ excludeChargeTypeIds: chargeTypes.map(item => item.value) })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Exclude Sub-Charge Types</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allGroupTypes || !this.props.allGroupTypes.edges || !this.props.allGroupTypes.edges.length > 0}
                                        options={this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0 ?
                                            this.props.allGroupTypes.edges.filter(item =>
                                                this.state.groupTypeIds && this.state.groupTypeIds.length > 0
                                                    ? !this.state.groupTypeIds.includes(item.node.id) : true).map(item => ({ value: item.node.id, label: item.node.name })
                                                    )
                                            : []
                                        }
                                        placeholder="Select Sub-Charge Types"
                                        isClearable
                                        isMulti
                                        onChange={(groupTypes) => this.setState({ excludeGroupTypeIds: groupTypes.map(item => item.value) })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="minAmount"><small><b>Min Amount ${this.state.minAmount ? this.state.minAmount : 0}</b></small></Label>
                                    <Input type="number" name="minAmount" id="minAmount" onChange={(e) => this.setState({ minAmount: e.target.value })} />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="maxAmount"><small><b>Max Amount ${this.state.maxAmount ? this.state.maxAmount : 0}</b></small></Label>
                                    <Input type="number" name="maxAmount" id="maxAmount" onChange={(e) => this.setState({ maxAmount: e.target.value })} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!this.props.reportStartDate && !this.state.reportEndDate}>Download</Button>
                        <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllChargeTypes, {
    props: ({ data: { allChargeTypes } }) => ({
        allChargeTypes
    })
})(graphql(AllGroupTypes, {
    props: ({ data: { allGroupTypes } }) => ({
        allGroupTypes
    })
})(withApolloClient(DownloadTransactions)))
