import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, UncontrolledTooltip, Nav, Label } from 'reactstrap'
import './NotesHistory.css'
import NotesHistoryDetail from "./NotesHistoryDetail"
import Loader from '../Loader'
import moment from 'moment'
import { DriverNotesHistory, CarNotesHistory } from "./Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class NotesHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            notesHistory: null,
            hasNextPage: false,
            activeNotesId: null,
            recentNotesId: null,
            limit: 3,
        }
    }

    fetchData = () => {
        this.setState({ loading: true })
        const { driverId, carId, noteType, apolloClient, objectId } = this.props
        const { limit, activeNotesId } = this.state
        if (driverId || carId) {
            let input = {
                first: limit,
            }
            if (driverId) input['driverId'] = driverId
            if (carId) input['carId'] = carId
            if (noteType) input['noteType'] = noteType || 'General'
            if (objectId) input['objectId'] = objectId

            const query = driverId ? DriverNotesHistory : CarNotesHistory
            apolloClient.query({
                query,
                variables: input,
            }).then(result => {
                const dataKey = driverId ? 'driverNotesHistory' : 'carNotesHistory'
                const historyData = result.data[dataKey]
                if (historyData) {
                    const { edges, pageInfo } = historyData
                    this.setState({
                        notesHistory: edges,
                        hasNextPage: pageInfo.hasNextPage,
                        activeNotesId: activeNotesId || (edges.length ? edges[0].node.id : null),
                        recentNotesId: edges.length ? edges[0].node.id : null,
                        loading: false,
                        error: null,
                    })
                }
            }).catch(error => {
                this.setState({ error: "Failed to fetch data: " + error, loading: false })
            })
        } else {
            this.setState({ error: "Error: Required Field Missing ID" })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const needsUpdate = (
            (this.props.driverId !== prevProps.driverId || this.props.carId !== prevProps.carId) ||
            (this.props.open !== prevProps.open && (this.props.driverId || this.props.carId)) ||
            (prevState.limit !== this.state.limit)
        )
        if (needsUpdate) {
            this.fetchData()
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
        this.fetchData()
    }

    refetchQuery = () => {
        this.fetchData()
        this.props.refetchQuery()
    };

    fetchMore = () => {
        let limit = this.state.limit
        limit = limit + 3
        const { activeNotesId } = this.state // Save old activeNotesId
        this.setState({ limit: limit }, () => {
            this.fetchData()
            this.setState({ activeNotesId }) // Restore activeNotesId
        })
    };

    render() {
        const { notesHistory, activeNotesId, recentNotesId, hasNextPage, loading } = this.state
        const { driverId, carId, objectType, name, objectId, noteType } = this.props
        return (
            <Popover trigger="legacy" className="notes-history" placement={this.props.placement || "right"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader onClick={this.props.handleClose}>{this.props.title || "Notes History"} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"></i></PopoverHeader>
                <PopoverBody>
                    <div className="main-container">
                        <div className="side-bar">
                            {loading ? <Loader /> : (
                                <>
                                    <Nav vertical>
                                        {notesHistory && notesHistory.length > 0 ? notesHistory.map((notes, i) => (
                                            <div key={notes.node.id}>
                                                <Label className={`toggle-section-heading ${activeNotesId === notes.node.id ? "active" : ""}`}>
                                                    <span className="left-content">
                                                        <i className="fa fa-comments" style={{ color: i === 0 ? "green" : "" }} aria-hidden="true"></i>&nbsp;&nbsp;
                                                        {moment(notes.node.dateAdded).tz("America/New_York").format('MMM D, YYYY')}&nbsp;&nbsp;
                                                        {activeNotesId !== notes.node.id &&
                                                            <>
                                                                -&nbsp;&nbsp;
                                                                <small className="truncate">{notes.node.changedTo ? notes.node.changedTo : "--"}</small>
                                                            </>
                                                        }
                                                    </span>
                                                    <span className="right-icons">
                                                        {activeNotesId === notes.node.id ?
                                                            <i className="fa fa-minus" aria-hidden="true" onClick={() => this.setState({ activeNotesId: null })}></i> :
                                                            <i className="fa fa-plus" aria-hidden="true" onClick={() => this.setState({ activeNotesId: notes.node.id })}></i>
                                                        }
                                                    </span>
                                                </Label>
                                                {activeNotesId === notes.node.id &&
                                                    <>
                                                        <NotesHistoryDetail
                                                            notesId={activeNotesId}
                                                            objectType={objectType}
                                                            notesType={noteType}
                                                            objectId={objectId}
                                                            recentNotesId={recentNotesId}
                                                            driverId={driverId}
                                                            carId={carId}
                                                            name={name}
                                                            refetchQuery={this.refetchQuery}
                                                        />
                                                        <hr />
                                                    </>
                                                }
                                            </div>
                                        )) : null}
                                    </Nav>
                                    {(!notesHistory || notesHistory.length === 0) && !this.state.activeNotesId && (
                                        <NotesHistoryDetail
                                            notesId={null}
                                            objectType={objectType}
                                            notesType={noteType}
                                            objectId={objectId}
                                            recentNotesId={null}
                                            driverId={driverId}
                                            carId={carId}
                                            name={name}
                                            refetchQuery={this.refetchQuery}
                                        />
                                    )}
                                    <div className="more-notes">
                                        {hasNextPage && (
                                            <>
                                                <i className="fa fa-lg fa-arrow-circle-down" onClick={this.fetchMore} aria-hidden="true" id="viewMore"></i>
                                                <UncontrolledTooltip target="viewMore" placement="left">Load More</UncontrolledTooltip>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(NotesHistory)