import React, { Component } from "react"
import { Container, Row, Col } from 'reactstrap'
import SubscriptionsListHeader from "./SubscriptionsListHeader"
import SubscriptionsList from "./SubscriptionsList"
import moment from "moment"

class SubscriptionsContainer
    extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                orderBy: ["-id"],
                status: "ACTIVE_1"
            },
            refetchCustomers: false,
            selectedCustomers: [],
            activeTab: "Active",
        }
    }

    updateOrder = (string) => {
        let order = this.state.filterValues.orderBy ? [...this.state.filterValues.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState((prevState) => ({ filterValues: { ...prevState.filterValues, orderBy: order } }))
    };

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <SubscriptionsListHeader
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={this.state.filterValues}
                    activeTab={this.state.activeTab}
                    location={this.props.location}
                    history={this.props.history}// Iagami - Code upgradation React 18
                    currentUser={this.props.currentUser}

                />
                <Row>
                    <Col>
                        <SubscriptionsList
                            key={String(this.state.refetchCustomers)}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            updateOrder={this.updateOrder}
                            currentUser={this.props.currentUser}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            statuses={this.state.filterValues && this.state.filterValues.status !== undefined && this.state.filterValues.status !== null ? [this.state.filterValues.status] : null}
                            types={this.state.filterValues && this.state.filterValues.types ? this.state.filterValues.types : null}
                            driverPk={this.state.filterValues && this.state.filterValues.driverPk ? this.state.filterValues.driverPk : null}
                            isDriverActive={this.state.filterValues && this.state.filterValues.isDriverActive !== null ? this.state.filterValues.isDriverActive : null}
                            dateAddedGte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                            dateAddedLte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                        />

                    </Col>
                </Row>
            </Container>

        )
    }
}

export default SubscriptionsContainer
