import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import { Container, Row, Col } from 'reactstrap'
import ReservationsList from '../ReservationsList'
import { EnumQuery } from '../../Functions/querys'
import '../CarsList.css'
import ReservationsListHeader from "../ReservationsListHeader"

class ReservedCarsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: null,
            driverType: null,
            searchTerm: "",
            showAllFilters: false,
            selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }],
            filterValues: {
                orderBy: ["-driver__in_office", "pickup_date"]
            },
            selectedDrivers: [],
            refetchReservations: false,
        }
    }

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-driver__in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    render() {
        // let repOpts = [{ value: "none", label: "Not Assigned" }]
        // if (this.props.allReps && this.props.allReps.edges && this.props.allReps.edges.length > 0)
        //     this.props.allReps.edges.map(item =>
        //         repOpts.push({ value: item.node.id, label: <>{item.node.name}&nbsp;<small className="float-right">{item.node.assignedCount}</small></> })
        //     )
        return (
            <Container fluid className=" ReservationsListContainer">
                <ReservationsListHeader
                    activeTab="active"
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedDrivers={this.state.selectedDrivers} location={this.props.location} history={this.props.navigate}
                    defaultFilters={this.state.filterValues}
                    resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                    refetchQuery={() => this.setState({ refetchReservations: !this.state.refetchReservations })}
                />
                <Row>
                    <Col sm="12">
                        <ReservationsList
                            currentUser={this.props.currentUser}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            updateOrder={this.updateOrder}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                            selectedTab="active"
                            status="OPEN"
                            hasCar={true}
                            driverId={this.state.filterValues && this.state.filterValues.driverId !== null ? this.state.filterValues.driverId : null}
                            inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                            driverType={this.state.filterValues && this.state.filterValues.driverType ? this.state.filterValues.driverType : null}
                            carReadyStatus={this.state.filterValues && this.state.filterValues.isReady !== null ? this.state.filterValues.isReady : null}
                            pickupDateRange={this.state.filterValues && this.state.filterValues.pickupDateRange ? this.state.filterValues.pickupDateRange : null}
                            pickupType={this.state.filterValues && this.state.filterValues.pickupType ? this.state.filterValues.pickupType : null}
                            pickUpLocation={this.state.filterValues && this.state.filterValues.pickUpLocation ? this.state.filterValues.pickUpLocation : null}
                            agreementType={this.state.filterValues && this.state.filterValues.agreementType ? this.state.filterValues.agreementType : null}
                            selectedDrivers={this.state.selectedDrivers}
                            setSelectedDrivers={(selectedDrivers) => this.setState({ selectedDrivers })}
                            refetchReservations={this.state.refetchReservations}
                            setRefetchQuery={() => this.setState({ refetchReservations: !this.state.refetchReservations })}
                            pickupDate_Gte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].startDate : null}
                            pickupDate_Lte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].endDate : null}
                            reservationPickupStates={this.props.reservationPickupStates}
                            hasPromo={this.state.filterValues && this.state.filterValues.hasPromo != null ? this.state.filterValues.hasPromo : null}
                            carModelNames={this.state.filterValues && this.state.filterValues.carModelNames ? this.state.filterValues.carModelNames : null}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(EnumQuery, {
    props: ({ data: { reservationPickupStates } }) => (
        { reservationPickupStates }
    )
})(withApolloClient(ReservedCarsContainer))
    // Uncomment if needed for RepsQuery
    // graphql(RepsQuery, {
    //     options: ({ groups }) => ({ variables: { groups: ["Front Office"] } }),
    //     props({ data: { allReps } }) {
    //         return { allReps }
    //     },
    // })(
    //     graphql(AllCarLocations, {
    //         props({ data: { allCarLocations } }) {
    //             return { allCarLocations }
    //         }
    //     })(
    
    //     )
    // )


