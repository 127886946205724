import React, { useState, useEffect } from "react"
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
    Table,
} from 'reactstrap'
import ModalFooter from "reactstrap/lib/ModalFooter"
import { ClobberDataQuery } from "../Queries"
import withApolloClient from '../../../withApolloClient' // Iagami - Code upgradation React 18

const ClobberDetailsModal = ({ handleClose, isOpen, loading: propsLoading, carPk, fetchClobberData }) => {
    const clobberData = fetchClobberData ? JSON.parse(fetchClobberData) : null
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const SAMPLE_DATA = { 'make': 'Toyota', 'model': 'Corolla', 'year': '2019', 'vin': '1234567890', 'mileage': '10000', 'price': '20000' }


    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Vehicle Details from Clobber for car {carPk}</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || propsLoading) && <Progress animated color="info" value="100" />}
                {!loading && !propsLoading && !clobberData && <div className="alert alert-warning">No data found for this vehicle</div>}
                {!loading && !propsLoading && clobberData &&
                    <Table>
                        <tbody>
                            {clobberData && Object.keys(clobberData).map((key, index) => (
                                <tr key={index}>
                                    <td>{key.toString().toUpperCase().replace("_", " ")}</td>
                                    <td>{clobberData[key] ? clobberData[key].toString().toUpperCase() : 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(ClobberDataQuery, {
        options: ({ vin }) => ({
            variables: { vin },
            fetchPolicy: 'cache-first',
        }),
        props:({ data: { fetchClobberData, loading } })=> (
             {
                fetchClobberData,
                loading
            }
)})(withApolloClient(ClobberDetailsModal))
