import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Container, Row, Col } from 'reactstrap'
import IssuesList from './IssuesList'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import '../Listing.css'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import moment from 'moment'

import MaintenanceListHeader from "../MaintenanceListHeader"
import RestrictedSection from "../../Components/RestrictedSection"

export const CATEGORIES = {
    Mechanical: ["MECHANICAL", "ENGINE", "TIRES", "RADIATOR", "BELT", "WON'T START", "TOTAL LOSS", "BATTERY", "NOISE", "INTAKE", "CROSS MEMBER", "HEADLIGHTS", "BRAKES", "SHUT DOWN", "ACTUATOR", "LEAKING OIL", "CLOCK SPRING", "DOOR LOCK", "DIESEL IN TANK", "AXLE BROKE", "SENSOR", "MUFFLER", "ENGINE MOUNT", "ENGINE LIGHTS", "OVERHEAD", "A/C", "CHECK ENGINE LIGHT", "GAS LEAK", "OBD", "MOBILEYE", "HYBRID", "LOST KEY", "TRANSMISSION", "Won’t Start"],
    Accident: ["BUMPERS", "ACCIDENT", "ACCIDENT + MECHANICAL", "ACCIDENT + TOW", "SUBFRAME", "Touch up", "SMALL SCRATCHES", "GLASS", "INCIDENT"],
    TLC: ["TLC INSPECTION", "LOST PLATE", "INSURANCE/TLC", "TLC"],
    Documents: ["REGISTRATION", "INSURANCE"],
    Alerts: ["OIL CHANGE", "DMV INSPECTION"],
    GPS: ["ITURAN"],
    Other: [],
}

class CarIssueContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderBy: ["-id"],
            activeTab: "Issues",
            filterValues: {
                selectedStatuses: ["OPEN", "URGENT"],
                selectedStages: [11]
            },
            selectedDrivers: []
        }
    }

    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };


    render() {
        return (
            <RestrictedSection requiredPermission="view_carissues">
                <Container fluid className="bos-listing-container">
                    <MaintenanceListHeader
                        activeTab={this.state.activeTab}
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        selectedDrivers={this.state.selectedDrivers}
                        defaultFilters={this.state.filterValues}
                        resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                        location={this.props.location} history={this.props.history}
                        defaultFilters={this.state.filterValues}
                        refetchQuery={() => this.setState({ refetchDrivers: !this.state.refetchDrivers })}
                    />
                    <Row>
                        <Col sm="12">
                            <IssuesList
                                currentUser={this.props.currentUser}
                                selectedDrivers={this.state.selectedDrivers}
                                setSelectedDrivers={(selectedDrivers) => this.setState({ selectedDrivers })}
                                vendorIn={this.state.filterValues && this.state.filterValues.selectedVendors && this.state.filterValues.selectedVendors.length > 0 ? this.state.filterValues.selectedVendors : null}
                                statusIn={this.state.filterValues && this.state.filterValues.selectedStatuses && this.state.filterValues.selectedStatuses.length > 0 ? this.state.filterValues.selectedStatuses : null}
                                categoryIn={this.state.filterValues && this.state.filterValues.selectedSubCategories && this.state.filterValues.selectedSubCategories.length > 0 ? this.state.filterValues.selectedSubCategories : this.state.filterValues && this.state.filterValues.selectedCategories && this.state.filterValues.selectedCategories ? this.state.filterValues.selectedCategories : null}
                                stageIn={this.state.filterValues && this.state.filterValues.selectedStages && this.state.filterValues.selectedStages.length > 0 ? this.state.filterValues.selectedStages : null}
                                assignedToIn={this.state.filterValues && this.state.filterValues.selectedReps && this.state.filterValues.selectedReps.length > 0 ? this.state.filterValues.selectedReps : null}
                                locationIn={this.state.filterValues && this.state.filterValues.selectedLocations && this.state.filterValues.selectedLocations.length > 0 ? this.state.filterValues.selectedLocations : null}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                orderBy={this.state.orderBy}
                                startDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                endDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                updateOrder={this.updateOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(CarIssueContainer)
