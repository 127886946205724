import React, { Component } from "react"
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Form,
  FormGroup,
  Input,
  Alert,
  Button,
  Label,
  Row,
  Col
} from "reactstrap"
import moment from 'moment'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { UpdatePromotionTemplateMutation } from "./Mutations"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

class UpdatePopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      promotionTemplate: this.props.dataId,
      value: this.props.value ? this.props.value : "",
      name: this.props.name,
      error: "",
    }
  }

  componentDidMount() {
    const { apolloClient } = this.props
  }

  handleSubmit = (e) => {
    e.preventDefault()
    try {
      let input = {
        promotionTemplate: this.state.promotionTemplate,
        [this.state.name]: ['promoPrice', 'minimumPromoPrice', 'maximumPromoPrice'].includes(this.state.name) ? parseFloat(this.state.value) : ['interval', 'carYearMin', 'carYearMax'].includes(this.state.name) ? parseInt(this.state.value) : this.state.value,
      }

      this.setState({ loading: true })
      this.props.apolloClient
        .mutate({
          mutation: UpdatePromotionTemplateMutation,
          variables: { input },
        })
        .then((result) => {
          if (result.data && result.data.updatePromotionTemplate && result.data.updatePromotionTemplate.ok) {
            this.props.refetchAllPromotionTemplates()
            this.props.handleClose()
          } else if (result.data && result.data.updatePromotionTemplate && !result.data.updatePromotionTemplate.ok && result.data.updatePromotionTemplate.errors && result.data.updatePromotionTemplate.errors[0]) {
            this.setState({ loading: false, error: result.data.updatePromotionTemplate.errors[0].messages.toString() })
          } else {
            this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
          }
        })
        .catch((err) => {
          this.setState({ loading: false, error: err.message })
        })
    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  render() {
    return (
      <Popover className="m-2" isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.handleClose} placement="left">
        <PopoverHeader>{this.props.header}</PopoverHeader>
        <PopoverBody>
          {this.state.error && (
            <Alert color="danger">{this.state.error}</Alert>
          )}
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              {this.props.type === "number" &&
                <Input type="number" step="any" name={this.props.name} id="templatepromotion" placeholder={this.props.header} value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
              }
            </FormGroup>
            <FormGroup>
              {this.props.type === "date" &&
                <Input type="date" step="any" name={this.props.name} id="templatepromotion" placeholder={this.props.header} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
              }
            </FormGroup>
            <FormGroup>
              {this.props.name === "intervalInput" &&
                <div>
                  <Row className="m-2">
                    <Col>
                      <Input type="radio" checked={this.state.intervalInput === "interval"} name="interval" id="interval" placeholder={this.props.header} onChange={(e) => this.setState({ intervalInput: "interval", name: "interval" })} /> {"Interval"}
                    </Col>
                    <Col>
                      <Input type="radio" checked={this.state.intervalInput === "intervalUnit"} name="intervalUnit" id="intervalUnit" placeholder={this.props.header} onChange={(e) => this.setState({ intervalInput: "intervalUnit", name: "intervalUnit" })} /> {"Interval Unit"}
                    </Col>
                  </Row>

                  {this.state.intervalInput === "intervalUnit" && <Input type="select" name="intervalUnit" id="templatepromotion" placeholder={this.props.header} value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} >
                    <option key={-1} value={null}>Choose Interval Unit</option>
                    {["Year", "Month", "Week", "Hour", "Day"].map((interval) => (
                      <option key={interval} value={interval}>{interval}</option>
                    ))}
                  </Input>}
                  {this.state.intervalInput === "interval" &&
                    <Input type="number" name="interval" id="templatepromotion" placeholder="Select The Interval" onChange={(e) => this.setState({ value: e.target.value })} />
                  }
                </div>}
            </FormGroup>
            <FormGroup check inline>
              {this.props.type === "checkbox" &&
                <Label>
                  <Input type="checkbox" checked={this.state.value} onChange={(e) => this.setState({ value: e.target.checked ? true : false })} />
                  {" "}
                  {this.props.name}
                </Label>}
            </FormGroup>
            {this.state.loading ? "Loading..." : <Button color="success" type="submit">Update</Button>}
          </Form>
        </PopoverBody>
      </Popover >
    )
  }
}

//Iagami - Code upgradation React 18
export default withApolloClient(UpdatePopup)
