import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import FollowupListHeader from "./FollowupListHeader"
import FollowUpCustomersList from "./FollowupCustomersList"

class FollowupCustomersContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                orderBy: ["-in_office", "-lead_count", "-last_lead_date"],
                leadStatuses: ["Canceled"],
                excludeDriverTags: ["Unsubscribe"],
            },
            refetchCustomers: false,
            selectedCustomers: [],
            activeTab: null,
        }
    }

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <FollowupListHeader
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedCustomers={this.state.selectedCustomers}
                    activeTab={this.state.activeTab}
                    defaultFilters={this.state.filterValues}
                    resetSelectedCustomers={() => this.setState({ selectedCustomers: [] })}
                    currentUser={this.props.currentUser} location={this.props.location} history={this.props.navigate}
                    refetchQuery={() => this.setState({ refetchCustomers: !this.state.refetchCustomers })}
                />
                <Row>
                    <Col>
                        <FollowUpCustomersList
                            key={String(this.state.refetchCustomers)}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            updateOrder={this.updateOrder}
                            currentUser={this.props.currentUser}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            insuranceStatuses={this.state.filterValues && this.state.filterValues.insuranceStatuses ? this.state.filterValues.insuranceStatuses : null}
                            insuranceRemovedReasonIds={this.state.filterValues && this.state.filterValues.insuranceRemovedReasonIds ? this.state.filterValues.insuranceRemovedReasonIds : null}
                            didntTakeCarReasonIds={this.state.filterValues && this.state.filterValues.didntTakeCarReasonIds ? this.state.filterValues.didntTakeCarReasonIds : null}
                            driverTags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                            excludeDriverTags={this.state.filterValues && this.state.filterValues.excludeDriverTags ? this.state.filterValues.excludeDriverTags : null}
                            assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                            hasRented={this.state.filterValues && this.state.filterValues.hasRented !== null ? this.state.filterValues.hasRented : null}
                            leadStatuses={this.state.filterValues && this.state.filterValues.leadStatuses ? this.state.filterValues.leadStatuses : null}
                            dtacAfterRental={this.state.filterValues && this.state.filterValues.dtacAfterRental !== null ? this.state.filterValues.dtacAfterRental : null}
                            selectedCustomers={this.state.selectedCustomers}
                            setSelectedCustomers={(selectedCustomers) => this.setState({ selectedCustomers })}
                            refetchCustomers={this.state.refetchCustomers}
                            setRefetchQuery={() => this.setState({ refetchCustomers: !this.state.refetchCustomers })}
                        />

                    </Col>
                </Row>
            </Container>

        )
    }
}

export default FollowupCustomersContainer