import React, { Component } from 'react'


import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, InputGroup, Label, Input, Badge, FormFeedback, DropdownMenu, DropdownToggle, ButtonDropdown, InputGroupText, FormGroup, Table } from 'reactstrap'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const updateCarPolicyMutation = gql`
    mutation updateCarPolicyMutation($input: UpdateCarPolicyMutationInput!){
        response:updateCarPolicy(input:$input){
            ok
            errors{
                messages
            }
    }
} `

const CarQuery = gql`query CarQuery($vin: String!){
    car(vin: $vin){
        id
    }
}`

const InsurancePolicyQuery = gql`query InsurancePolicyQuery($policyNumber: String!){
    insurancePolicy(policyNumber: $policyNumber){
        id
    }
}`

class UpdatePolicyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: 1,
            error: "",
            selectedPlates: [],
            carToTransfer: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({ loading: true, error: "" })

        let carId, policyId
        await this.props.apolloClient.query({
            query: CarQuery,
            variables: { vin: this.state.carVin },
        }).then(result => {
            if (result && result.data && result.data.car && result.data.car.id)
                carId = result.data.car.id
            else
                this.setState({ loading: false, error: "Car with this VIN doesnt exist in the system, please try with right details!" })
        }).catch((_) => { this.setState({ loading: false, error: "Car with this VIN doesnt exist in the system, please try with right details!" }) })

        await this.props.apolloClient.query({
            query: InsurancePolicyQuery,
            variables: { policyNumber: this.state.policy },
        }).then(result => {
            if (result && result.data && result.data.insurancePolicy && result.data.insurancePolicy.id)
                policyId = result.data.insurancePolicy.id
            else
                this.setState({ loading: false, error: "Insurance with this Policy # doesnt exist in the system, please try with right details!" })
        }).catch((_) => { this.setState({ loading: false, error: "Insurance with this Policy # doesnt exist in the system, please try with right details!" }) })

        if (this.state.error) return false

        if (carId && policyId) {
            let input = {
                carId: carId,
                insurancePolicyId: policyId,
            }
            this.setState({ loading: true })
            this.props.apolloClient.mutate({
                mutation: updateCarPolicyMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.response && result.data.response.ok) {
                    this.setState({ carVin: null, policy: null, loading: false, error: "" })
                    this.props.refetchCars()
                    this.props.handleClose()
                } else if (result && result.data && result.data.response && result.data.response.errors && result.data.response.errors[0] && result.data.response.errors[0].messages)
                    this.setState({ loading: false, error: result.data.response.errors[0].messages.toString() })
                else
                    this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
            }).catch((err) => {
                this.setState({ loading: false, error: err.message })
            })
        } else {
            this.setState({ loading: false, error: "Please provide all the information to create the object!" })
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> Update Car Policy </ModalHeader>
            <Form onSubmit={this.handleSubmit}>
                <ModalBody>
                    <Row>
                        <Col xs={12} className="py-3">
                            <Label>Car VIN</Label>
                            <InputGroup>
                                <InputGroupText>
                                    <i className="fa fa-car" />
                                </InputGroupText>
                                <Input placeholder="Enter Car VIN" value={this.state.carVin} onChange={(e) => { this.setState({ carVin: e.target.value }) }} />
                            </InputGroup>

                        </Col>
                        <Col xs={12} className="py-3">
                            <Label>Insurance Policy #</Label>
                            <InputGroup>
                                <InputGroupText>
                                    <i className="fa fa-id-card-o" />
                                </InputGroupText>
                                <Input placeholder="Enter Policy Number" value={this.state.policy} onChange={(e) => { this.setState({ policy: e.target.value }) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                    <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                    <Button type="submit" color="primary">Update Car Policy</Button>
                </ModalFooter>
            </Form>
        </Modal>
    };
}
//Iagami - Code upgradation React 18
export default withApolloClient(UpdatePolicyModal)