import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, Form, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const UpdateAgreementPriceMutation = gql`mutation updateAgreementPriceMutation($input: UpdateAgreementPriceMutationInput!){
    updateAgreementPrice(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

class UpdateAgreementPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false
        }
    }
    updateAgreementPrice = () => {
        try {
            if (this.props.agreementPlan && this.props.agreementPlan.id) {
                this.setState({ loading: true })
                let input = this.state.input
                input["agreementPrice"] = this.props.agreementPlan.id
                this.props.apolloClient.mutate({
                    mutation: UpdateAgreementPriceMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateAgreementPrice && result.data.updateAgreementPrice.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                        this.setState({ input: {}, loading: false, error: null })
                    } else {
                        let errorMessage = result && result.data && result.data.updateAgreementPrice && result.data.updateAgreementPrice.errors && result.data.updateAgreementPrice.errors.length > 0 && result.data.updateAgreementPrice.errors[0].messages ? result.data.updateAgreementPrice.errors[0].messages.join("; ") : "An error has occured. Please retry or contact admin."
                        this.setState({ input: {}, loading: false, error: errorMessage })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: "An error has occured. Please retry or contact admin." })
                })
            }
        } catch (err) {
            this.setState({ loading: false, error: "An error has occured. Please retry or contact admin." })
        }

    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        let input = this.state.input
        input[name] = value
        this.setState({ input: input })
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    <PopoverBody>
                        <Form>
                            <FormGroup>
                                <Label for={this.props.name}>{this.props.title}</Label>
                                <Input type={this.props.type} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                            </FormGroup>
                            {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="submit" size="sm" onClick={this.updateAgreementPrice}>Submit</Button>}
                        </Form>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(UpdateAgreementPlan);

