import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Alert, UncontrolledAlert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Select from 'react-select'
import moment from 'moment-timezone'
import { AllRegistrationTypesQuery, RegistrationQuery, AllOwnersQuery } from '../Queries'
import { CreateRegistration, UpdateRegistration } from '../Mutations'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class RegistrationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            number: this.props.isUpdate && this.props.registration && this.props.registration.number ? this.props.registration.number : null,
            registrationType: this.props.isUpdate && this.props.registration && this.props.registration.registrationType.id ? this.props.registration.registrationType.id : null,
            expirationDate: this.props.isUpdate && this.props.registration && this.props.registration.expirationDate ? this.props.registration.expirationDate : moment().format('MM-DD-YYYY'),
            notes: this.props.isUpdate && this.props.registration && this.props.registration.notes ? this.props.registration.notes : null,
            complianceDate: this.props.isUpdate && this.props.registration && this.props.registration.metadata ? this.fetchMetaData(this.props.registration.metadata, "compliance_date") : null,
            owner: this.props.isUpdate && this.props.registration && this.props.registration.metadata ? this.fetchMetaData(this.props.registration.metadata, "owner") : null,
            loading: false,
            error: "",
            openModal: "",
        }
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
        }
        this.setState({ [name]: value })
    };

    handleClose = (e) => {
        //  let state = {
        //      number: null,
        //      openModal: "",
        //      registrationType: null,
        //      expirationDate: null,
        //      complianceDate: null,
        //      notes: null,
        //  }
        //  this.setState({state})
        this.props.handleClose()
    };

    handleSubmit = () => {
        try {
            this.setState({ loading: true, error: "" })
            if (!this.state.number || !this.state.expirationDate || !this.state.registrationType) {
                throw 'Error: Required fields are missing'
            }
            let input = {
                expirationDate: this.state.expirationDate ? this.state.expirationDate : null,
                notes: this.state.notes ? this.state.notes : null,
                complianceDate: this.state.complianceDate ? this.state.complianceDate : null,
                owner: this.state.owner ? this.state.owner : null,
            }
            if (this.props.isUpdate) {
                input["id"] = this.props.registration.id ? this.props.registration.id : null
            } else {
                input["registrationType"] = this.state.registrationType ? this.state.registrationType : null
                input["number"] = this.state.number ? this.state.number : null
            }

            this.props.apolloClient.mutate({
                mutation: this.props.isUpdate ? UpdateRegistration : CreateRegistration,
                variables: { input },
            }).then((result) => {
                if (result.data && ((this.props.isUpdate && result.data.updateRegistration && result.data.updateRegistration.ok) || (!this.props.isUpdate && result.data.createRegistration && result.data.createRegistration.ok))) {
                    this.setState({ loading: false, error: null })
                    this.props.refetchQuery()
                    this.handleClose()
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (result.data && result.data.createRegistration && result.data.createRegistration.errors && result.data.createRegistration.errors[0] && result.data.createRegistration.errors[0].messages[0])
                        errorMessage = result.data.createRegistration.errors[0].messages[0]
                    else if (this.props.isUpdate && result.data.updateRegistration && result.data.updateRegistration.errors && result.data.updateRegistration.errors[0] && result.data.updateRegistration.errors[0].messages[0])
                        errorMessage = result.data.updateRegistration.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured" + error
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: errorMessage, loading: false })
        }
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="bos-registration-modal">
                    <ModalHeader>{this.props.isUpdate ? "Update" : "Create"} Car Registration</ModalHeader>
                    <ModalBody>
                        {this.state.loading || this.props.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.props.isUpdate && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="createdBy"><small>Created By</small></Label>
                                    <Input
                                        name="createdBy"
                                        value={this.props.registration && this.props.registration.createdBy ? this.props.registration.createdBy.firstName ? this.props.registration.createdBy.firstName + " " + this.props.registration.createdBy.lastName : this.props.registration.createdBy.username : "--"}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="updatedBy"><small>Updated By</small></Label>
                                    <Input
                                        name="updatedBy"
                                        value={this.props.registration && this.fetchMetaData(this.props.registration.metadata, "updated_by")}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="registrationType" className="d-block"><small>Registration Type *</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={this.props.loading}
                                        options={this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges.length > 0 && this.props.allRegistrationTypes.edges.map(item => item.node.isActive && ({ value: item.node.id, label: (item.node.name + " - " + item.node.stateCode) }))}
                                        placeholder="Select Registration Type"
                                        defaultValue={this.props.isUpdate && this.state.registrationType && this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges ? ({ value: this.state.registrationType, label: (this.props.allRegistrationTypes.edges.find(item => item.node.id === this.state.registrationType).node.name + " - " + (this.props.allRegistrationTypes.edges.find(item => item.node.id === this.state.registrationType).node.stateCode)) }) : null}
                                        onChange={registrationType => this.setState({ registrationType: registrationType ? registrationType.value : null })}
                                        isDisabled={this.props.isUpdate}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="carId"><small>What's the Number? *</small></Label>
                                    <Input
                                        type="text"
                                        name="number"
                                        value={this.state.number}
                                        onChange={this.updateInput}
                                        placeholder={`Enter the unique number`}
                                        disabled={this.props.isUpdate}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="expirationDate" className="d-block"><small>Expiration Date *</small></Label>
                                    <Input
                                        type="date"
                                        name="expirationDate"
                                        value={this.state.expirationDate}
                                        onChange={this.updateInput}
                                        placeholder={`Enter the Date`}
                                        min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                    />
                                </FormGroup>
                            </Col>
                            {this.state.registrationType && this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges && this.props.allRegistrationTypes.edges.find(item => this.state.registrationType === item.node.id).node.name === "TLC" &&
                                <Col>
                                    <FormGroup>
                                        <Label for="complianceDate" className="d-block"><small>Compliance Date</small></Label>
                                        <Input
                                            type="date"
                                            name="complianceDate"
                                            value={this.state.complianceDate}
                                            onChange={this.updateInput}
                                            placeholder={"Enter the Date"}
                                            min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                        />
                                    </FormGroup>
                                </Col>}
                        </Row>
                        {this.state.registrationType && this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges && this.props.allRegistrationTypes.edges.find(item => this.state.registrationType === item.node.id).node.name === "DMV" &&
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="owner" className="d-block"><small>Owner</small></Label>
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={this.props.loading}
                                            options={this.props.allOwners && this.props.allOwners && this.props.allOwners.edges.length > 0 && this.props.allOwners.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                            placeholder="Select Registration Owner"
                                            defaultValue={this.props.isUpdate && this.fetchMetaData(this.props.registration.metadata, "owner") && this.props.allOwners && this.props.allOwners.edges ? ({ value: (this.props.allOwners.edges.find(item => item.node.pk == this.fetchMetaData(this.props.registration.metadata, "owner")).node.id), label: (this.props.allOwners.edges.find(item => item.node.pk == this.fetchMetaData(this.props.registration.metadata, "owner")).node.name) }) : null}
                                            onChange={owner => this.setState({ owner: owner ? owner.value : null })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="notes"><small>Notes</small></Label>
                                    <Input type="text" name="notes" value={this.state.notes} onChange={this.updateInput} placeholder={`Enter the notes`} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className='d-block'>
                        {!this.state.loading &&
                            <Row>
                                <Col className='text-right'>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="primary" onClick={this.handleSubmit} disabled={this.state.loading}>{this.props.isUpdate ? "Update" : "Create"} Registration</Button>
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllOwnersQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { allOwners } }) => ({ allOwners })
})(
    graphql(AllRegistrationTypesQuery, {
        options: () => ({ variables: { typeFor: "Car" }, fetchPolicy: 'cache-first' }),
        props: ({ data: { loading, allRegistrationTypes } }) => ({ loading, allRegistrationTypes })
    })
        // (graphql(RegistrationQuery, {
        //     options: ({ id }) => ({ variables: { id }, notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' }),
        //     props: ({ data: { loading, registration } }) => ({ loading, registration })
        // }))
        (withApolloClient(RegistrationModal))
)
