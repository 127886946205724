import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap'
import gql from 'graphql-tag'
import { CarStages, CarIssueStatuses, CarIssueCategories } from "../../../Functions/querys"
import Select from 'react-select'
import CarIssueTab from "./CarIssueTab"
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { HasPermissionsQuery } from "../../../Functions/querys"
import moment from 'moment'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'
import { getEnumDescription } from "../../../Functions/processEnumDescription"

const AllVendorsQuery = gql`
  query AllVendors{
    allVendors(isActive:true, orderBy:["name"]){
      edges {
        node {
          id
          name
          address
        }
      }
    }
  }
`
const AllCarIssueCategoriesQuery = gql`
  query AllCarIssueCategories{
    allCarIssueCategories(orderBy:["category"]){
        edges {
        node {
            id
            name:category
            description
            type
        }
        }
    }
  }
`

export const CATEGORIES = {
    Mechanical: ["MECHANICAL", "ENGINE", "TIRES", "RADIATOR", "BELT", "WON'T START", "TOTAL LOSS", "BATTERY", "NOISE", "INTAKE", "CROSS MEMBER", "HEADLIGHTS", "BRAKES", "SHUT DOWN", "ACTUATOR", "LEAKING OIL", "CLOCK SPRING", "DOOR LOCK", "DIESEL IN TANK", "AXLE BROKE", "SENSOR", "MUFFLER", "ENGINE MOUNT", "ENGINE LIGHTS", "OVERHEAD", "A/C", "CHECK ENGINE LIGHT", "GAS LEAK", "OBD", "MOBILEYE", "HYBRID", "LOST KEY", "TRANSMISSION", "Won’t Start"],
    Accident: ["BUMPERS", "ACCIDENT", "ACCIDENT + MECHANICAL", "ACCIDENT + TOW", "SUBFRAME", "Touch up", "SMALL SCRATCHES", "GLASS", "INCIDENT"],
    TLC: ["TLC INSPECTION", "LOST PLATE", "INSURANCE/TLC", "TLC"],
    Documents: ["REGISTRATION", "INSURANCE"],
    Alerts: ["OIL CHANGE", "DMV INSPECTION"],
    GPS: ["ITURAN"],
    Other: [],
}

class CarIssuesTabContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedVendors: [],
            selectedStatuses: ["OPEN", "URGENT"],
            selectedCategories: [],
            selectedSubCategories: [],
            searchTerm: "",
            selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }],
            showDateRangePopup: false,
            orderBy: ["-id"],
            showAllFilters: false,
            categoryType: "",
        }
    }
    componentDidUpdate(_, prevState) {
        if (prevState.selectedCategories !== this.state.selectedCategories) {
            this.setState({ selectedSubCategories: null })
        }
    }

    handleDateSelection = (ranges) => {
        this.setState({ selectionRange: [ranges.selection] })
    }

    handleCategoryUpdate = (category) => {
        let selectedCategories = []
        if (category && category.value) {
            if (category.value == "Other") {
                selectedCategories = this.props.allCarIssueCategories && this.props.allCarIssueCategories.edges && this.props.allCarIssueCategories.edges.filter(item => category['label'].toLowerCase !== (item.node.type.toLowerCase())).map(item => item.node.id)
            } else {
                selectedCategories = this.props.allCarIssueCategories && this.props.allCarIssueCategories.edges && this.props.allCarIssueCategories.edges.filter(item => category.label.toLowerCase() === (item.node.type.toLowerCase())).map(item => item.node.id)
            }
        }
        this.setState({ selectedCategories: selectedCategories, categoryType: category.value })
    }

    render() {
        return (
            <div className="tab-container">
                {this.props.hasPermissions && !this.props.hasPermissions.includes("change_carissues") ? <Row><Col xs={12}><Alert color="danger">Sorry, you don’t have permission to view this section. For access, please contact the administrator.</Alert></Col></Row> : <>
                    <div>
                        <Row className="pb-3">
                            <Col xs={3}>
                                <FormGroup className="mb-0">
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.carIssueStatuses && this.props.carIssueStatuses.states && this.props.carIssueStatuses.states.length > 0)}
                                        defaultValue={[{ value: "OPEN", label: "Open" }, { value: "URGENT", label: "Urgent" }]}
                                        options={this.props.carIssueStatuses && this.props.carIssueStatuses.states && this.props.carIssueStatuses.states.length > 0 ? getEnumDescription([...this.props.carIssueStatuses.states]).sort((a, b) => (a.name > b.name ? 1 : -1)).map((item) => ({ value: item.name, label: item.description })) : []}
                                        placeholder="All Issue Statuses"
                                        isClearable
                                        isMulti
                                        onChange={(statuses) => this.setState({ selectedStatuses: (statuses.map(item => item.value)) })} />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup className="mb-0">
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allCarIssueCategories && this.props.allCarIssueCategories.edges && this.props.allCarIssueCategories.edges.length > 0)}
                                        options={this.props.carIssueCategories && this.props.carIssueCategories.states && this.props.carIssueCategories.states.length > 0 ? [...this.props.carIssueCategories.states].sort((a, b) => (a.name > b.name ? 1 : -1)).map((item) => ({ value: item.description, label: item.description })) : []}
                                        placeholder="All Issue Types"
                                        isClearable
                                        onChange={category => category ? this.handleCategoryUpdate(category) : this.setState({ selectedCategories: [], categoryType: "" })}
                                    />
                                </FormGroup>
                            </Col>
                            {this.state.selectedCategories && this.state.selectedCategories.length != 0 &&
                                <Col xs={3}>
                                    <FormGroup className="mb-0">
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            options={this.props.allCarIssueCategories && this.props.allCarIssueCategories.edges && this.props.allCarIssueCategories.edges.length > 0 && this.props.allCarIssueCategories.edges.filter(item => item.node.type.toLowerCase() === this.state.categoryType.toLowerCase()).map(item => ({ value: item.node.id, label: item.node.name }))}
                                            placeholder="All Issue Categories"
                                            isClearable
                                            isMulti
                                            onChange={categories => this.setState({ selectedSubCategories: (categories && categories.length > 0 ? categories.map(item => item.value) : []) })}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            <Col xs={3}>
                                <FormGroup className="mb-0">
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allVendors && this.props.allVendors.edges && this.props.allVendors.edges.length > 0)}
                                        options={this.props.allVendors && this.props.allVendors.edges && this.props.allVendors.edges.length > 0 && this.props.allVendors.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="All Vendors"
                                        isClearable
                                        isMulti
                                        onChange={vendors => this.setState({ selectedVendors: (vendors.map(item => item.value)) })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={3} className="pt-1">
                                <InputGroup className="mb-0 date-range-group">
                                    <a id="customDateSelection" onClick={() => this.setState({ showDateRangePopup: true })}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                                    <Input disabled placeholder="Select Custom Date Range" value={this.state.selectionRange && this.state.selectionRange[0].startDate && this.state.selectionRange[0].endDate ? moment(this.state.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.state.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                                    {this.state.selectionRange && this.state.selectionRange[0].startDate && this.state.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => { this.setState({ selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }] }) }} aria-hidden="true"></i>}

                                    <Popover trigger="legacy" placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                                        <PopoverHeader className="text-center">Select Custom Date Range</PopoverHeader>
                                        <PopoverBody>
                                            <DateRange ranges={this.state.selectionRange} onChange={this.handleDateSelection} editableDateInputs={true} moveRangeOnFirstSelection={false} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <CarIssueTab
                        {...this.props}
                        id={this.props.id ? this.props.id : null}
                        currentUser={this.props.currentUser}
                        categoryIn={this.state.selectedSubCategories && this.state.selectedSubCategories.length > 0 ? this.state.selectedSubCategories : this.state.selectedCategories && this.state.selectedCategories.length > 0 ? this.state.selectedCategories : null}
                        vendorIn={this.state.selectedVendors.length > 0 ? this.state.selectedVendors : null}
                        statusIn={this.state.selectedStatuses.length > 0 ? this.state.selectedStatuses : null}
                        searchTerm={this.state.searchTerm}
                        startDate={this.state.selectionRange && this.state.selectionRange[0].startDate && this.state.selectionRange[0].endDate ? moment(this.state.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                        endDate={this.state.selectionRange && this.state.selectionRange[0].startDate && this.state.selectionRange[0].endDate ? moment(this.state.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                    />
                </>}
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarStages, {
    props: ({ data: { carStages } }) => ({
        carStages,
    }),
})(
    graphql(CarIssueCategories, {
        props: ({ data: { carIssueCategories } }) => ({
            carIssueCategories,
        }),
    })(
        graphql(AllVendorsQuery, {
            options: { fetchPolicy: 'cache-first' },
            props: ({ data: { loading, allVendors } }) => ({
                loadingAllVendors: loading,
                allVendors,
            }),
        })(
            graphql(AllCarIssueCategoriesQuery, {
                options: { fetchPolicy: 'cache-first' },
                props: ({ data: { loading, allCarIssueCategories } }) => ({
                    loadingAllCarIssueCategories: loading,
                    allCarIssueCategories,
                }),
            })(
                graphql(CarIssueStatuses, {
                    options: { fetchPolicy: 'cache-first' },
                    props: ({ data: { loading, carIssueStatuses } }) => ({
                        loadingCarIssueStatuses: loading,
                        carIssueStatuses,
                    }),
                })(
                    graphql(HasPermissionsQuery, {
                        options: () => ({
                            variables: {
                                userPermissions: ["change_carissues"],
                            },
                            fetchPolicy: "network-only",
                            notifyOnNetworkStatusChange: true,
                        }),
                        props: ({ data: { hasPermissions, loading, variables } }) => ({
                            hasPermissions,
                            loadingHasPermissions: loading,
                            permissionVariables: variables,
                        }),
                    })(withApolloClient(CarIssuesTabContainer))
                )
            )
        )
    )
);


