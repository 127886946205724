import React, { useState } from "react"
import { Label } from "reactstrap"


const MissingDocuments = ({ groupedMissingFields, missingInsuranceDocuments }) => {
    const [openSections, setOpenSections] = useState([])

    const toggleSection = (documentName) => {
        setOpenSections(prev =>
            prev.includes(documentName) ? prev.filter(name => name !== documentName) : [...prev, documentName]
        )
    }

    return (
        <>
            <p style={{ color: "red", fontSize: "14px", marginBottom: "10px" }}>
                Insurance cannot be processed due to missing documents or incomplete fields.
            </p>
            {Object.entries(groupedMissingFields).map(([documentName, fields], index) => {
                const isMissingDocument = missingInsuranceDocuments.some(doc => doc.typeName === documentName)
                const isOpen = openSections.includes(documentName)

                return (
                    <div key={index} className="document-section">
                        {isMissingDocument ?
                            <div key={`missing-${index}`} className="missing-document-header">
                                {documentName}<span className="missing-label">(Missing document)</span>
                            </div>
                            : <Label className="document-header" onClick={() => toggleSection(documentName)}>
                                <span>
                                    {documentName}{!isOpen && <span className="missing-label">(Missing fields)</span>}
                                </span>
                                <i className={`fa ${isOpen ? "fa-minus" : "fa-plus"}`} />
                            </Label>}
                        {!isMissingDocument && isOpen && fields.length > 0 && (
                            <div className="document-fields">
                                <ul>
                                    {fields.map((field, idx) => (
                                        <li key={idx}>{field.fieldName}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )
            })}
            {missingInsuranceDocuments
                .filter(doc => !groupedMissingFields[doc.typeName])
                .map((doc, index) => (
                    <div key={`missing-${index}`} className="document-section">
                        <div className="missing-document-header">
                            {doc.typeName}<span className="missing-label">(Missing document)</span>
                        </div>
                    </div>
                ))}
        </>
    )
}

export default MissingDocuments
