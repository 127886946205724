import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import './MassMessageModal.css'
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'
import { TagsQuery } from "../Functions/querys"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
const sendMessageMutation = gql`
mutation sendMessage($input: SendGeneralMessageMutationInput!){
  sendMessage(input:$input){
    ok
    errors{
        messages
    }
  }
}`

const AllBranches = gql`query AllBranches{
    allBranches{
        edges{
            node{
                id
                customId
                name
            }
        }
    } 
}`


class MassMessageModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            useCannedMessage: false,
            loading: false,
            error: null,
            messageSuccess: false,
            messageSubject: "",
            messageBody: "",
            stage: null,
            minBalance: 0,
            maxBalance: 0,
            minAgreements: 0,
            maxAgreements: 0,
            minActiveDays: 0,
            maxActiveDays: 0,
            selectedBranch: "",
            selectedGroupType: "",
            carStatus: null,
            isFilteredChecked: true,
            overrideMaxDrivers: false,
            tags: null
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        //Iagami - Code upgradation React  18
        if (e.target.type === "range" && typeof value === "string") {
            value = parseFloat(value); 
        }
        this.setState({ [name]: value })
    };

    handleClose = (e) => {
        let state = {
            useCannedMessage: false,
            loading: false,
            error: null,
            messageSuccess: false,
            messageBody: ""
        }
        this.setState({ ...state })
        this.props.handleClose()
    };

    componentDidMount() {
        const { apolloClient } = this.props
    }
    messageDrivers = () => {
        try {
            this.setState({ loading: true })
            let input = {
                messageText: this.state.messageBody ? this.state.messageBody : "",
                messageSubject: this.state.messageSubject ? this.state.messageSubject : "",
                channel: "", overrideMaxDrivers: this.state.overrideMaxDrivers
            }
            if (this.props.drivers && this.props.drivers.length > 0) {
                input["drivers"] = this.props.drivers ? this.props.drivers : []
            }
            if (this.state.tags) {
                input["tags"] = this.state.tags
            }
            if (this.state.selectedBranch) {
                input["branch"] = this.state.selectedBranch
            }
            if (this.state.stage) {
                input["stage"] = Number(this.state.stage)
            }
            if (this.state.carStatus) {
                input["carStatus"] = this.state.carStatus
            }
            if (this.state.selectedGroupTypeName === "WAV" || this.state.selectedGroupTypeName === "Non WAV") {
                input["isWav"] = this.state.selectedGroupType
            }
            if (this.state.minBalance) {
                input["minBalance"] = this.state.minBalance
            }
            if (this.state.maxBalance) {
                input["maxBalance"] = this.state.maxBalance
            }
            if (this.state.minAgreements) {
                input["minAgreementsCount"] = this.state.minAgreements
            }
            if (this.state.maxAgreements) {
                input["maxAgreementsCount"] = this.state.maxAgreements
            }
            if (this.state.minActiveDays) {
                input["minActiveDays"] = this.state.minActiveDays
            }
            if (this.state.maxActiveDays) {
                input["maxActiveDays"] = this.state.maxActiveDays
            }
            this.props.apolloClient.mutate({
                mutation: sendMessageMutation,
                variables: { input: input },
            }).then((result) => {
                if (result.data && result.data.sendMessage && result.data.sendMessage.ok) {
                    this.setState({ messageSuccess: true, error: null, loading: false })
                } else {
                    let error = "An error has occured. Please contact system admin"
                    if (result.data && result.data.sendMessage && result.data.sendMessage.errors && result.data.sendMessage.errors[0] && result.data.sendMessage.errors[0].messages[0]) {
                        error = result.data.sendMessage.errors[0].messages[0]
                    }
                    this.setState({ loading: false, messageSuccess: false, error: error })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured: " + error
                this.setState({ error: errorMessage, loading: false, messageSuccess: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured: " + err
            this.setState({ error: errorMessage, loading: false, messageSuccess: false, })
        }
    };

    render() {
        return (
            <div>
                <Modal className="mass-message-modal" isOpen={this.props.open}>
                    <ModalHeader>
                        Send Message Modal
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading && <Progress animated color="info" value="100" />}
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        {this.state.messageSuccess && <Row><Col><Alert color="success">Message sent successfully!</Alert></Col></Row>}
                        {this.props.drivers && this.props.drivers.length > 0 ?
                            <Label className="text-success">
                                <strong>
                                    Sending Message To {this.props.drivers.length + (this.props.drivers.length > 1 ? " Drivers" : " Driver")}
                                </strong>
                                <br />
                            </Label> :
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="selectedBranch">Filter By Branch</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ value: branch.node.name, label: branch.node.name }))}
                                                placeholder="SELECT BRANCH"
                                                onChange={(branch) => this.setState({ selectedBranch: branch.value })}
                                                isDisabled={!this.state.isFilteredChecked}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="stage">Filter By Driver Stage</Label>
                                            <Select
                                                classNamePrefix="bos-select"
                                                className="bos-custom-select"
                                                name="stages"
                                                options={[{ value: "6", label: "Active" }, { value: "10", label: "Inactive", }, { value: "1", label: "Applied" }, { value: "0", label: "Didnt Take Car" }]}
                                                placeholder="SELECT STAGE"
                                                onChange={(filterStage) => this.setState({ stage: filterStage.value })}
                                                isDisabled={!this.state.isFilteredChecked}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="stage">Filter By Driver Tag</Label>
                                            <Select
                                                classNamePrefix="bos-select"
                                                className="bos-custom-select"
                                                name="stages"
                                                options={this.props.driverTags && this.props.driverTags.edges && this.props.driverTags.edges.length > 0 && this.props.driverTags.edges.map((tags) => ({ value: tags.node.name, label: tags.node.name }))}
                                                placeholder="SELECT DRIVER TAGS"
                                                isLoading={this.props.driverTagsLoading}
                                                isDisabled={!this.state.isFilteredChecked}
                                                isMulti isClearable
                                                onChange={tags => this.setState({ tags: tags && tags.length > 0 ? tags.map(item => item.value) : null })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="groupType">Is driver driving WAV/Non WAV Car?</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                options={[{ value: true, label: "WAV" }, { value: false, label: "Non WAV" }]}
                                                placeholder="IS DRIVER WAV OR NON WAV"
                                                onChange={(group) => this.setState({ selectedGroupType: group.value, selectedGroupTypeName: group.label })}
                                                isDisabled={!this.state.isFilteredChecked}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Filter By Car Status</Label>
                                            <Select
                                                classNamePrefix="bos-select"
                                                className="bos-custom-select"
                                                name="carStatus"
                                                options={[{ value: "Enabled", label: "Enabled" }, { value: "Disabled", label: "Disabled" }]}
                                                placeholder="SELECT CAR STATUS"
                                                onChange={(filterStatus) => this.setState({ carStatus: filterStatus.value })}
                                                isDisabled={!this.state.isFilteredChecked}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="minBalance">Min Balance ${this.state.minBalance}</Label>
                                            <Input type="range" defaultValue={0} name="minBalance" id="balance" min="0" max="5000" step="10" onChange={this.updateInput} placeholder={"Min Balance $" + this.state.minBalance} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="maxBalance">Max Balance ${this.state.maxBalance}</Label>
                                            <Input type="range" defaultValue={10000} name="maxBalance" id="balance" min="5000" max="10000" step="10" onChange={this.updateInput} placeholder={"Max Balance $" + this.state.maxBalance} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="minAgreements">Min Agreements {this.state.minAgreements}</Label>
                                            <Input type="range" defaultValue={0} name="minAgreements" id="minAgreements" min="0" max="500" step="5" onChange={this.updateInput} placeholder={"Min Balance $" + this.state.minAgreements} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="maxAgreements">Max Agreements {this.state.maxAgreements}</Label>
                                            <Input type="range" name="maxAgreements" id="maxAgreements" defaultValue={1000} min="500" max="1000" step="5" onChange={this.updateInput} placeholder={"Max Balance $" + this.state.maxAgreements} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="minActiveDays">Min Active Days {this.state.minActiveDays}</Label>
                                            <Input type="range" defaultValue={0} name="minActiveDays" id="minActiveDays" min="0" max="5000" step="10" onChange={this.updateInput} placeholder={"Number Of Agreements " + this.state.minActiveDays} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="maxActiveDays">Max Active Days {this.state.maxActiveDays}</Label>
                                            <Input type="range" defaultValue={10000} name="maxActiveDays" id="maxActiveDays" min="5000" max="10000" step="10" onChange={this.updateInput} placeholder={"Total Active Days $" + this.state.maxActiveDays} disabled={!this.state.isFilteredChecked} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Override max message limit?<br />
                                                <small>By default, there's a limit to how many drivers that can be messaged at once.</small>
                                            </Label>

                                            <br />
                                            <span>No</span>
                                            <div className="toggle-switch">
                                                <Input
                                                    type="checkbox"
                                                    className="toggle-switch-checkbox"
                                                    name="overrideMaxDrivers"
                                                    id="overrideMaxDrivers"
                                                    checked={this.state.overrideMaxDrivers}
                                                    onChange={() => this.setState({ overrideMaxDrivers: !this.state.overrideMaxDrivers })}
                                                />
                                                <label className="toggle-switch-label" htmlFor="overrideMaxDrivers">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                            <span>Yes</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>}

                        {!this.state.messageSuccess &&
                            <Row>
                                <Col>
                                    <FormGroup>
                                        {/* <Label for="messageSubject">Message Subject</Label> */}
                                        <Input type="text" name="messageSubject" id="messageSubject" value={this.state.messageSubject} onChange={this.updateInput} placeholder="Enter the message subject" /><br />
                                        {/* <Label for="messageBody">Message to send</Label> */}
                                        <Input type="textarea" name="messageBody" id="messageBody" value={this.state.messageBody} onChange={this.updateInput} placeholder="Enter the message you'd like to send" rows={4} />
                                        <Row>
                                            <Col xs={12} className="pt-2">
                                                <p style={{ color: "grey" }}>
                                                    You can use following short_codes to add dynamic text.<br />
                                                    Driver Name: {"{name}"}, Balance: {"{balance}"}, Bad Debt Amount: {"{bad_debt_amount}"}<br /><br />
                                                    If you send: <strong>"Hello {"{name}"}, payfare paid {"{received_from_uber}"}, your balance is {"{balance}"}."</strong><br />
                                                    Driver will get: <strong>"Hello John Doe, payfare paid $0, your balance is $379."</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="primary" onClick={this.messageDrivers} disabled={!this.state.messageBody || this.state.messageSuccess}>Send Message</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>

            </div >
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllBranches, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { loading, allBranches } }) => (
        { loading, allBranches }
    )
})(
    graphql(TagsQuery, {
        props: ({ data: { loading, driverTags } }) => (
            { driverTagsLoading: loading, driverTags }
        )
    })(withApolloClient(
        MassMessageModal
      )  )
);

