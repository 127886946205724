import React, { Component } from 'react'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Container, Col, Row, Label, Input, Button } from 'reactstrap'
import Loader from "../../../Material/Loader"
import CreateUpdateSetting from '../../BranchMangement/CreateUpdateSetting'
import toTitleCase from "../../../Functions/toTitleCase"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const BranchOverviewQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        settings
    }
}`

const BranchSettingQuery = gql`query AllBranchSettings($branchId: ID!){
    allBranchSettings(branchId: $branchId){
        edges {
        node {
            id
            key{
                id
                name
                description
                isRequired
            }
            value
        }
        }
    }
}`

class SettingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    pObj = (jsonString) => {
        try {
            return JSON.parse(jsonString)
        } catch (error) {
            return {}
        }
    }
    objectToString = (obj) => {
        if (typeof obj === "object") {
            return JSON.stringify(obj)
        } else {
            return obj
        }
    }
    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.branch ? <Loader /> : <>
                    <Row className="px-2"><Col className="px-4 text-right pb-4">
                        <Button id="newSettingsKeyEdit" type="button" className="bos-btn-primary" size="sm" onClick={() => this.toggleModal('newSettingsKey')}>ADD/UPDATE SETTINGS</Button>
                        {this.state.openModal === "newSettingsKey" && <CreateUpdateSetting branchId={this.props.branch.id} target={"newSettingsKeyEdit"} open={this.state.openModal === "newSettingsKey"}
                            handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchSettingsQuery}
                        />}
                    </Col></Row>
                    <Row>
                        <Col className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                {this.props.allBranchSettings && this.props.allBranchSettings.edges && this.props.allBranchSettings.edges.map((setting, i) =>
                                    <Col xs={4} key={i}>
                                        <Label>{toTitleCase(setting.node.key.name)}{setting.node.key.isRequired && "*"}</Label>
                                        <Input disabled className="box-object-property-input" value={this.objectToString(setting.node.value)} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(BranchOverviewQuery, {
    options: ({ branchId }) => ({ variables: { id: branchId } }),
    props: ({ data: { branch, loading, refetch, variables } }) => ({
        branch,
        loading,
        variables,
        refetchQuery: () => {
            return refetch({
                query: BranchOverviewQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { branch: fetchMoreResult.branch }
                },
            })
        },
    })
})(graphql(BranchSettingQuery, {
    options: ({ branchId }) => ({ variables: { branchId } }),
    props: ({ data: { allBranchSettings, loading, refetch, variables } }) => ({
        allBranchSettings,
        loading,
        variables,
        refetchSettingsQuery: () => {
            return refetch({
                query: BranchSettingQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { allBranchSettings: fetchMoreResult.allBranchSettings }
                },
            })
        },
    })
})(withApolloClient(SettingTab)))
