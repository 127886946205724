import React, { Component } from 'react'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Container, Col, Row, Table } from 'reactstrap'
import Loader from "../../../Material/Loader"
import moment from "moment"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const BranchOverviewQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        history {
            date
            user
            comment
        }
    }
}`

const tableHeaders = [
    { id: "id", name: "ID", sortable: false }, { id: "date_added", name: "Date Added", sortable: false },
    { id: "user__username", name: "Username", sortable: false }, { id: "comment", name: "Comment", sortable: false },
]

class HistoryTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.branch ? <Loader /> : <>
                    <Row>
                        <Col className="bos-object-section-wrapper">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {tableHeaders.map(tableHeader =>
                                            tableHeader.sortable ?
                                                <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                                <th key={tableHeader.id}>{tableHeader.name}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.branch && this.props.branch.history && this.props.branch.history.length > 0 && this.props.branch.history.map((historyNode, i) =>
                                        <tr key={i + 1}>
                                            <td>{i + 1}</td>
                                            <td>{historyNode.date ? moment(historyNode.date).tz("America/New_York").format('lll') : "--"}</td>
                                            <td>
                                                {historyNode.user}
                                            </td>
                                            <td>
                                                {historyNode.comment}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(BranchOverviewQuery, {
    options: ({ branchId }) => ({ variables: { id: branchId } }),
    props: ({ data: { branch, loading, refetch, variables } }) => ({
        branch,
        loading,
        variables,
        refetchQuery: () => {
            return refetch({
                query: BranchOverviewQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { branch: fetchMoreResult.branch }
                },
            })
        },
    })
})(withApolloClient(HistoryTab))
