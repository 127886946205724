import React, { useState } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from "graphql-tag"
import { Container, Row, Col, Label, Input, Badge, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment-timezone'
import { PreferredDateTimeContext } from "../Context/PreferredDateTimeContext"
import TimeZoneSelectionPopover from "../TimeZoneSelectionPopover"
import UpdateUser from "../Organization/MembersManagement/UpdateUser"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const OverviewTab = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState("")

    const toggleModal = (modalName) => {
        setOpenModal(openModal === modalName ? "" : modalName)
    }

    return (
        <div className="tab-container">
            {props.currentUser &&
                <>
                    <div className="info-container">
                        <Row>
                            <Col xs={3}>
                                <Label>Username</Label>
                                <Input
                                    disabled
                                    className="box-object-property-input"
                                    value={props.currentUser.username || "--"}
                                />
                            </Col>
                            <PreferredDateTimeContext.Consumer>
                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                    <Col xs={3}>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>
                                                    Preferred Time Zone
                                                </Label>
                                            </Col>
                                            <Col xs={6} className="text-right">
                                                <i
                                                    className="fa fa-pencil-square"
                                                    aria-hidden="true"
                                                    onClick={() => toggleModal('preferredTimeZoneEdit')}
                                                />
                                            </Col>
                                        </Row>
                                        <Input
                                            id="preferredTimeZoneEdit"
                                            disabled
                                            className="box-object-property-input"
                                            value={preferredDateTimeInfo.timezone || "America/New_York"}
                                        />
                                        {openModal === 'preferredTimeZoneEdit' && (
                                            <TimeZoneSelectionPopover
                                                target="preferredTimeZoneEdit"
                                                open={openModal === "preferredTimeZoneEdit"}
                                                handleClose={() => toggleModal("")}
                                                userTimeZone={preferredDateTimeInfo.timezone}
                                                updateSelectedTimezone={(timezone) => setPreferredDateTimeInfo("timezone", timezone)}
                                            />
                                        )}
                                    </Col>
                                )}
                            </PreferredDateTimeContext.Consumer>
                            <PreferredDateTimeContext.Consumer>
                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                    <Col xs={3}>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>
                                                    Preferred Date Format
                                                </Label>
                                            </Col>
                                            <Col xs={6} className="text-right">
                                                <i
                                                    className="fa fa-pencil-square"
                                                    aria-hidden="true"
                                                    onClick={() => toggleModal('preferredDateFormatEdit')}
                                                />
                                            </Col>
                                        </Row>
                                        <Input
                                            id="preferredDateFormatEdit"
                                            disabled
                                            className="box-object-property-input"
                                            value={preferredDateTimeInfo.dateFormat}
                                        />
                                        {openModal === 'preferredDateFormatEdit' && (
                                            <UpdateUser
                                                userId={props.currentUser.id}
                                                title="Edit Preferred Date Format"
                                                name="preferredDateFormat"
                                                value={props.currentUser.member.preferredDateFormat}
                                                target="preferredDateFormatEdit"
                                                type="select"
                                                open={openModal === 'preferredDateFormatEdit'}
                                                handleClose={() => toggleModal("")}
                                                updateUser={(obj) => setPreferredDateTimeInfo("dateFormat", obj["preferredDateFormat"])}
                                                refetchQuery={props.refetchQuery}
                                            />
                                        )}
                                    </Col>
                                )}
                            </PreferredDateTimeContext.Consumer>
                            <PreferredDateTimeContext.Consumer>
                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                    <Col xs={3}>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>
                                                    Preferred Time Format
                                                </Label>
                                            </Col>
                                            <Col xs={6} className="text-right">
                                                <i
                                                    className="fa fa-pencil-square"
                                                    aria-hidden="true"
                                                    onClick={() => toggleModal('preferredTimeFormatEdit')}
                                                />
                                            </Col>
                                        </Row>
                                        <Input
                                            id="preferredTimeFormatEdit"
                                            disabled
                                            className="box-object-property-input"
                                            value={preferredDateTimeInfo.timeFormat}
                                        />
                                        {openModal === 'preferredTimeFormatEdit' && (
                                            <UpdateUser
                                                userId={props.currentUser.id}
                                                title="Edit Preferred Time Format"
                                                name="preferredTimeFormat"
                                                value={props.currentUser.member.preferredTimeFormat}
                                                target="preferredTimeFormatEdit"
                                                type="select"
                                                open={openModal === 'preferredTimeFormatEdit'}
                                                updateUser={(obj) => setPreferredDateTimeInfo("timeFormat", obj["preferredTimeFormat"])}
                                                handleClose={() => toggleModal("")}
                                                refetchQuery={props.refetchQuery}
                                            />
                                        )}
                                    </Col>
                                )}
                            </PreferredDateTimeContext.Consumer>
                            <Col xs={3}>
                                <Label>Tenant</Label>
                                <Input
                                    disabled
                                    className="box-object-property-input"
                                    value={props.currentUser.member.tenant.name}
                                />
                            </Col>
                            <Col xs={3}>
                                <Row>
                                    <Col xs={6}>
                                        <Label>
                                            First Name
                                        </Label>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <i
                                            className="fa fa-pencil-square"
                                            aria-hidden="true"
                                            onClick={() => toggleModal('firstNameEdit')}
                                        />
                                    </Col>
                                </Row>
                                <Input
                                    id="firstNameEdit"
                                    disabled
                                    className="box-object-property-input"
                                    value={props.currentUser.firstName || "--"}
                                />
                                {openModal === 'firstNameEdit' &&
                                    <UpdateUser
                                        userId={props.currentUser && props.currentUser.id}
                                        title="Edit First Name"
                                        name="firstName"
                                        value={props.currentUser.firstName}
                                        target="firstNameEdit"
                                        type="text"
                                        open={openModal === 'firstNameEdit'}
                                        handleClose={() => toggleModal("")}
                                        refetchQuery={props.refetchQuery}
                                    />
                                }
                            </Col>
                            <Col xs={3}>
                                <Row>
                                    <Col xs={6}>
                                        <Label>

                                            Last Name
                                        </Label>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <i
                                            className="fa fa-pencil-square"
                                            aria-hidden="true"
                                            onClick={() => toggleModal('lastNameEdit')}
                                        />
                                    </Col>
                                </Row>
                                <Input
                                    id="lastNameEdit"
                                    disabled
                                    className="box-object-property-input"
                                    value={props.currentUser.lastName || "--"}
                                />
                                {openModal === 'lastNameEdit' &&
                                    <UpdateUser
                                        userId={props.currentUser && props.currentUser.id}
                                        title="Edit First Name"
                                        name="lastName"
                                        value={props.currentUser.lastName}
                                        target="lastNameEdit"
                                        type="text"
                                        open={openModal === 'lastNameEdit'}
                                        handleClose={() => toggleModal("")}
                                        refetchQuery={props.refetchQuery}
                                    />
                                }
                            </Col>
                            <Col xs={3}>
                                <Label>
                                    Email
                                </Label>
                                <Input
                                    id="emailEdit"
                                    disabled
                                    className="box-object-property-input"
                                    value={props.currentUser.email || "--"}
                                />
                            </Col>
                            <Col xs={3}>
                                <Label>
                                    Default Branch
                                </Label>
                                <p className="columnContent">
                                    <Badge color="primary" style={{ fontSize: 12 }} className="p-2">{props.currentUser.member.defaultBranch.name}</Badge><br />
                                </p>
                            </Col>
                            <Col xs={3} className="bos-object-property-wrapper mb-2">
                                <Label>Branches</Label>
                                <p className="columnContent">
                                    {props.currentUser && props.currentUser.member && props.currentUser.member.branches && props.currentUser.member.branches.edges && props.currentUser.member.branches.edges.length > 0 ? (
                                        props.currentUser.member.branches.edges
                                            .filter((_, i) => i < 4)
                                            .map((branch, i) => (
                                                <React.Fragment key={i}>
                                                    <Badge size="sm" color="primary" className="p-2">
                                                        {branch.node.name}
                                                    </Badge>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            ))
                                    ) : (
                                        "None Added"
                                    )}
                                    {props.currentUser && props.currentUser.member && props.currentUser.member.branches && props.currentUser.member.branches.edges && props.currentUser.member.branches.edges.length > 1 && (
                                        <>
                                            <span id="more-branches">
                                                +{props.currentUser.member.branches.edges.length - 4} more
                                            </span>
                                            <UncontrolledTooltip
                                                className="more-info-tooltip"
                                                target="more-branches"
                                                placement="right"
                                                autohide={false}
                                            >
                                                {props.currentUser.member.branches.edges
                                                    .filter((_, i) => i >= 1)
                                                    .map((branch, i) => (
                                                        <React.Fragment key={i}>
                                                            <span>{branch.node.name || "--"}</span>
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                </p>
                            </Col>
                            <Col xs={3}>
                                <Label>Date Joined</Label>
                                <Input
                                    disabled
                                    className="box-object-property-input"
                                    value={moment(props.currentUser.dateJoined).tz("America/New_York").format('lll')}
                                />
                            </Col>
                            <Col xs={3}>
                                <Label>Last Login</Label>
                                <Input
                                    disabled
                                    className="box-object-property-input"
                                    value={moment(props.currentUser.lastLogin).tz("America/New_York").format('lll')}
                                />
                            </Col>
                        </Row>
                    </div >
                    <br />
                </>
            }
        </div>
    )
}
//Iagami - Code upgradation React 18
export default withApolloClient((OverviewTab))
