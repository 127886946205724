import React, { Component } from 'react'

import gql from 'graphql-tag'
import CarColumn from './CarColumn'
import moment from 'moment'
import toTitleCase from "../../Functions/toTitleCase"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const createNewCarMutation = gql`mutation createNewCarMutation($input: CreateNewCarMutationInput!){
    createNewCar(input:$input){
        car{
            id
            pk
            vin
            hasOpenIssues
            year
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
            location
            color
            notes
            stage
            fhv
            dmvPlate
            branch{
                id
                name
            }
            baseId{
                id
                name
            }
            currentAgreement{
                id
            }
        }
        ok
        errors{
            messages
        }
    }
}`

const CarQuery = gql`query CarQuery($vin: String!){
    car(vin: $vin){
        id
    }
}`

const CarModelsQuery = gql`query CarModelsQuery($vin: String!){
    getCarModelsByVin(vin:$vin) {
      edges {
        node {
          id
          make
          name
          series
          __typename
          groupType{
            id
            name    
            __typename
          }
        }
      }
    }
  }
`

const AllCarModelGroupTypes = gql`{
    allCarModelGroupTypes {
      edges {
        node {
          id
          name
        }
      }
    }
}`


class NewCarRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            error: "",
            editField: "",
            loadingVinData: false,
            isCreated: false,
            allGroups: null
        }
    }

    findError = (carData = this.props.carData) => {
        if (!this.props.isValid("vin", carData.vin)) return "vin"
        if (!this.props.isValid("group", carData.group)) return "group"
        if (!this.props.isValid("dealer", carData.dealer)) return "dealer"
        if (!this.props.isValid("titleOwner", carData.titleOwner)) return "titleOwner"
        if (!this.props.isValid("color", carData.color)) return "color"
        if (!this.props.isValid("owner", carData.owner)) return "owner"
        if (!this.props.isValid("baseId", carData.baseId)) return "baseId"
        if (!this.props.isValid("fhv", carData.fhv)) return "fhv"
        if (!this.props.isValid("dmvPlate", carData.dmvPlate)) return "dmvPlate"
        if (!this.props.isValid("purchaseDate", carData.purchaseDate)) return "purchaseDate"
        if (!this.props.isValid("purchasePrice", carData.purchasePrice)) return "purchasePrice"
        if (!this.props.isValid("branch", carData.branch)) return "branch"
        if (!this.props.isValid("operatingLease", carData.operatingLease)) return "operatingLease"
        return false
    }

    createCar = (carData = this.props.carData) => {

        this.setState({ error: "", loading: true })

        try {
            this.props.apolloClient.query({
                query: CarQuery,
                variables: { vin: carData.vin },
            }).then(result => {
                if (result && result.data && result.data.car && result.data.car.id) {
                    this.setState({ loading: false, error: `Unable to create car with vin = ${carData.vin}, as it already exist in database!` })
                }
                else {
                    let input = { vin: carData.vin ? carData.vin : null }
                    if (carData.color) input = { ...input, color: carData.color ? carData.color : null }
                    if (carData.owner) input = { ...input, owner: carData.owner ? carData.owner : null }
                    if (carData.base) input = { ...input, base: carData.baseId ? carData.baseId : null }
                    if (carData.group) input = { ...input, group: carData.group ? carData.group : null }
                    if (carData.dmvPlate) input = { ...input, dmvPlate: carData.dmvPlate ? carData.dmvPlate : null }
                    if (carData.purchaseDate) input = { ...input, purchaseDate: carData.purchaseDate ? carData.purchaseDate : null }
                    if (carData.titleOwner) input = { ...input, titleOwner: carData.titleOwner ? carData.titleOwner : null }
                    if (carData.dealer) input = { ...input, dealer: carData.dealer ? carData.dealer : null }
                    if (carData.branch) input = { ...input, branch: carData.branch ? carData.branch : null }
                    if (carData.operatingLease) input = { ...input, operatingLease: carData.operatingLease ? carData.operatingLease : null }
                    if (carData.notes) input = { ...input, notes: carData.notes ? carData.notes : null }
                    if (carData.fhv) input = { ...input, fhv: carData.fhv ? Number(carData.fhv) : null }
                    if (carData.purchasePrice) input = { ...input, purchasePrice: carData.purchasePrice ? Number(carData.purchasePrice) : null }
                    this.props.apolloClient.mutate({
                        mutation: createNewCarMutation,
                        variables: { input },
                    }).then((result) => {
                        if (result && result.data && result.data.createNewCar && result.data.createNewCar.ok && result.data.createNewCar.car && result.data.createNewCar.car.pk) {
                            this.props.updateInfo("pk", result.data.createNewCar.car.pk)
                            this.props.updateInfo("id", result.data.createNewCar.car.id)
                            this.props.updateInfo("group", result.data.createNewCar.car.group)
                            this.props.updateInfo("model", result.data.createNewCar.car.model)
                            this.props.updateInfo("modelSeries", result.data.createNewCar.car.modelSeries)
                            this.props.updateInfo("stage", result.data.createNewCar.car.stage)
                            this.props.updateInfo("year", result.data.createNewCar.car.year)
                            this.props.updateInfo("isCreated", true)
                            this.setState({ loading: false, error: "", isCreated: true })
                        } else if (result && result.data && result.data.createNewCar && result.data.createNewCar.errors && result.data.createNewCar.errors[0] && result.data.createNewCar.errors[0].messages)
                            this.setState({ loading: false, error: result.data.createNewCar.errors[0].messages.toString() })
                        else
                            this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }).catch((err) => {
                        this.setState({ loading: false, error: err.message })
                    })
                }
            }).catch((err) => this.setState({ loading: false, error: err.message }))
        } catch (err) {
            this.setState({ loading: false, error: err })
        }

    }

    render_group_types = () => {
        this.setState({ loadingVinData: true, loading: true })
        this.props.apolloClient.query({
            query: CarModelsQuery,
            variables: { vin: this.props.carData.vin }
        }).then(result => {
            let allGroups
            if (result.data.getCarModelsByVin && result.data.getCarModelsByVin.edges && result.data.getCarModelsByVin.edges.length > 0) {
                let model = toTitleCase(result.data.getCarModelsByVin.edges[0].node.make) + " " + result.data.getCarModelsByVin.edges[0].node.name + " | " + result.data.getCarModelsByVin.edges[0].node.series
                this.props.updateInfo("model", model)

                allGroups = result.data.getCarModelsByVin.edges.filter(model => model.node.groupType && model.node.groupType.name).map(model => model.node.groupType).filter((groupType, i, array) => array.findIndex(group => group.name == groupType.name && group.id == groupType.id) === i)
                if (allGroups && allGroups.length >= 1) {
                    this.setState({ allGroups })
                    if (allGroups.length === 1) {
                        this.props.updateInfo("group", allGroups[0].name)
                    }
                }
            }
            if (!allGroups || allGroups.length == 0) {
                this.props.apolloClient.query({
                    query: AllCarModelGroupTypes
                }).then(result => {
                    if (result.data && result.data.allCarModelGroupTypes && result.data.allCarModelGroupTypes.edges && result.data.allCarModelGroupTypes.edges.length > 0) {
                        allGroups = result.data.allCarModelGroupTypes.edges.map(groupType => groupType.node)
                        this.setState({ allGroups })
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            this.setState({ loadingVinData: false, loading: false })
        }).catch(err => {
            this.setState({ loadingVinData: false, loading: false })
        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.uploadCar && this.props.uploadCar !== prevProps.uploadCar)
            this.createCar()
        if (!this.state.isCreated && this.props.carData.vin !== prevProps.carData.vin && this.props.isValid("vin", this.props.carData.vin)) {
            this.render_group_types()
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
        this.render_group_types()
    }

    render() {
        return (
            <tr>
                <CarColumn name="row" isNew id={this.props.id} findError={this.findError()} isCreated={this.state.isCreated} handleClipBoardData={this.props.handleClipBoardData} deleteNewCar={this.props.deleteNewCar} handleUploadCar={this.createCar} loading={this.state.loading} error={this.state.error} />
                <CarColumn name="pk" placeholder="Car ID" value={this.props.carData.pk} isNew />
                <CarColumn isNew={this.state.isCreated ? false : true} editable={this.state.isCreated ? false : true} name="vin" loading={this.state.loadingVinData} placeholder="Car VIN" value={this.props.carData.vin} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} limitText name="carModel" value={this.props.carData.model} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="group" loading={this.state.loadingVinData} editable={this.state.isCreated ? false : true} value={this.props.carData.group} updateInfo={this.props.updateInfo} isValid={this.props.isValid} options={this.state.allGroups} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable={this.state.isCreated ? false : true} name="color" placeholder="Car Color" value={this.props.carData.color} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="stage" placeholder="Car Stage" value={this.props.carData.stage} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable name="branch" placeholder="Car Branch" value={this.props.carData.branch} options={this.props.allBranches && this.props.allBranches.edges.length > 0 ? this.props.allBranches.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="customName" placeholder="Custom Name" value={this.props.carData.customName} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable name="titleOwner" placeholder="Title Owner" value={this.props.carData.titleOwner} options={this.props.allTitleOwners && this.props.allTitleOwners.edges.length > 0 ? this.props.allTitleOwners.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable name="dealer" placeholder="Car Dealer" value={this.props.carData.dealer} options={this.props.allDealers && this.props.allDealers.edges.length > 0 ? this.props.allDealers.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable={this.state.isCreated ? false : true} name="purchaseDate" placeholder="Purchase Date" type="date" min={moment().subtract(4, 'months').tz("America/New_York").format(moment.HTML5_FMT.DATE)} max={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} value={this.props.carData.purchaseDate} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable name="purchasePrice" placeholder="Purchase Price" type="number" value={this.props.carData.purchasePrice} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable name="operatingLease" placeholder="Operating Lease" value={this.props.carData.operatingLease} options={this.props.allOperatingLease && this.props.allOperatingLease.edges.length > 0 ? this.props.allOperatingLease.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="inspectionDate" placeholder="Inspection Date" type="date" min={moment().subtract(4, 'months').tz("America/New_York").format(moment.HTML5_FMT.DATE)} max={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} value={this.props.carData.inspectionDate} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable={this.state.isCreated ? false : true} limitText name="baseId" placeholder="Car Base" value={this.props.carData.baseId} options={this.props.allBase && this.props.allBase.edges.length > 0 ? this.props.allBase.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="carPolicy" placeholder="Car Policy" value={this.props.carData.carPolicy} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="dmvPlate" placeholder="DMV #" value={this.props.carData.dmvPlate} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="fhv" placeholder="FHV" value={this.props.carData.fhv} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="fhvExpiration" placeholder="FHV Expiration" value={this.props.carData.fhvExpiration} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} name="owner" placeholder="Car Owner" value={this.props.carData.owner} options={this.props.allOwners && this.props.allOwners.edges.length > 0 ? this.props.allOwners.edges : null} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
                <CarColumn isNew={this.state.isCreated ? false : true} editable limitText name="notes" placeholder="Car Notes" value={this.props.carData.notes} updateInfo={this.props.updateInfo} isValid={this.props.isValid} />
            </tr>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(NewCarRow)
