import React, { useState } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import {
    Row, Col,
    Form, Input, FormGroup, Button, Label, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Progress, Popover, PopoverHeader, PopoverBody

} from "reactstrap"
import { CarTypeAhead, DriverTypeAhead, RepsTypeAhead } from "./TypeAheadComponents"
import { UpdateTaskMutation } from "./Mutations"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

function EditTaskPopOver({ isOpen, task, closeModal, target, title, name, value, type, maxLength, ...props }) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({})
    const [searchTerm, setSearchTerm] = useState("")
    if (!task || !props.apolloClient || !task.id) {
        return <span></span>
    }
    const setDriverId = (driverId) => {
        setInput({ driverId: driverId })
    }

    const editTask = (e) => {
        e.preventDefault()
        try {
            // const { client } = this.props
            if (!props.apolloClient || !task.id) {
                throw new Error("GQL Client not found")
            }

            let queryInput = { taskId: task.id, ...input }
            if (Object.keys(input).length === 0) {
                return
            }
            setLoading(true)
            props.apolloClient.mutate({
                mutation: UpdateTaskMutation,
                refetchQueries: ["AllTasks"],
                variables: { input: queryInput },
            }).then((result) => {
                setLoading(false)
                if (!result) {
                    throw new Error("Error creating task")
                }
                if (result.data && result.data.updateTask && result.data.updateTask.ok) {
                    setError("")
                    closeModal("")
                } else {
                    const errorMessage = result.data && result.data.updateTask && result.data.updateTask.errors && result.data.updateTask.errors.messages ? result.data.updateTask.errors.messages[0] : "Unknown Error"
                    throw new Error(errorMessage)
                }
            }).catch((error) => {
                setError("Error creating task" + error.toString())
                setLoading(false)
            })
        } catch (error) {
            console.log("Error creating task", error)
            setError("Error creating task" + error.toString())
            setLoading(false)
        }
    }

    return (
        <Popover placement="bottom" isOpen={isOpen} target={target} toggle={() => closeModal("")} className="popover-custom-width">
            <PopoverHeader onClick={() => closeModal("")}>{title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
            <PopoverBody style={{ "textAlign": "center" }}>
                {error && <Alert color="danger">{error}</Alert>}
                <div className='pb-2'>
                    {
                        name === "driverId" ?
                            <DriverTypeAhead searchTerm={searchTerm} setSearchTerm={setSearchTerm} defaultValue={value} onChange={(value) => setInput({ ...input, driverId: value })} />
                            : name === "vehicleId" ?
                                <CarTypeAhead searchTerm={searchTerm} setSearchTerm={setSearchTerm} defaultValue={value} onChange={(value) => setInput({ ...input, vehicleId: value })} />
                                : name === "assignedToIds" ?
                                    <RepsTypeAhead searchTerm={searchTerm} defaultValue={value} setSearchTerm={setSearchTerm} onChange={(value) => setInput({ ...input, assignedToIds: value })} />
                                    :
                                    type === "boolean" ?
                                        <>
                                            <br />
                                            <div>
                                                <span>Yes</span>
                                                <div className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="toggle-switch-checkbox"
                                                        name={name}
                                                        id={"taskEdit"}
                                                        checked={!input[name]}
                                                        onChange={() => setInput({ [name]: !input[name] })}
                                                    />
                                                    <label className="toggle-switch-label" htmlFor={"branchInfo"}>
                                                        <span className="toggle-switch-inner" />
                                                        <span className="toggle-switch-switch" />
                                                    </label>
                                                </div>
                                                <span>No</span>
                                            </div>
                                        </> :
                                        <Input autoComplete={"off"} type={type} maxLength={maxLength ? maxLength : 200}
                                            name={name} id={name} defaultValue={value}
                                            placeholder={title} onChange={(e) => setInput({ [name]: e.target.value })}
                                        />
                    }
                    {loading ? <Progress animated color="info" value={100} /> :
                        <Button className="float-right submit-button  my-2 py-1" type="button" size="sm" onClick={editTask} disabled={error || loading}>Submit</Button>
                    }
                </div>
            </PopoverBody>
        </Popover>
    )
}

export default withApolloClient(EditTaskPopOver)
