import gql from "graphql-tag"
const AllSubscriptions = gql`query AllSubscriptions($status: String, $driverPk:String, $types: [ID], $dateAddedLte: String, $dateAddedGte: String, $isDriverActive: Boolean, $searchTerm: String){
    allSubscriptions( first:30, orderBy:["-id"], status_Exact: $status, driverPk: $driverPk, types: $types, dateAddedLte: $dateAddedLte, dateAddedGte: $dateAddedGte, isDriverActive: $isDriverActive, searchTerm: $searchTerm){
        edges {
            node {
                id
                statusDisplay
                status
                dateAdded
                externalLink
                startDate
                endDate
                createdBy{
                    id
                    username
                    firstName
                    lastName
                }
                subscriptionType{
                    id
                    name
                    amount
                    deductibleAmount
                }
                driver{
                    id
                    name
                    phone
                    email
                    tlcLicense
                    dmvLicense
                    preferredLanguage
                    branch{
                        id
                        name
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            startCursor
            endCursor
        }
    }
}`

const Subscription = gql`query Subscription($id: ID!){
    subscription(id: $id, ){
        id
        pk
        statusDisplay
        stripeStatus
        status
        dateAdded
        externalLink
        startDate
        endDate
        createdBy{
            id
            username
            firstName
            lastName
        }
        driver{
            id
            name
        }
        subscriptionType{
            id
            name
            amount
            deductibleAmount
        }
    }
}
`
const SubscriptionTypes = gql`query SubscriptionTypes{
    allSubscriptionTypes(isActive:true){
        edges{
          node{
            id
            amount
            name
            deductibleAmount
          }
        }
    }
}`

const StripeCards = gql`query StripeCards($id: ID!){
    cards: getStripeCards(id: $id){
        id
        cards {
            id
            expYear
            expMonth
            last4
            brand
        }
    } 
}`

export { AllSubscriptions, Subscription, SubscriptionTypes, StripeCards }
