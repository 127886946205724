import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import ListingFilters from "../../Material/ListingFilters"
import toTitleCase from "../../Functions/toTitleCase"
import { AllCustomerHandlesQuery } from "./Queries"
import withApolloClient from "../../withApolloClient"


const filterType = [
    { value: "", label: "All" },
    { value: "App", label: "App" },
    { value: "Call", label: "Call", },
    { value: "Email", label: "Email" },
    { value: "Chat", label: "Chat" },
    { value: "Form", label: "Form" },
    { value: "Office Visit", label: "Office Visit" },
]

const filterDirections = [
    { value: "", label: "All" },
    { value: "Inbound", label: "Inbound" },
    { value: "Outbound", label: "Outbound", },
]

class InteractionsListFilters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    getFilterConfigurations = (interactionsType) => {
        return [
            {
                type: "select", name: "interactionType", title: "Interaction Types", options: filterType, labelSelector: "name", valueSelector: "value", placeholder: "Filter By Interaction Type", showFilter: true, isMulti: false,
            },
            {
                type: "select", name: "direction", title: "Interaction Direction", options: filterDirections, labelSelector: "name", valueSelector: "id", placeholder: "Filter by Interaction Direction", showFilter: true, isMulti: false,
            },
            {
                type: "date", name: "interactionDate", title: "Interaction Date", placeholder: "Filter by Interaction Date", showFilter: true
            },
            {
                type: "select", name: "handles", title: "Driver Handles", optionsQuery: AllCustomerHandlesQuery, labelSelector: "handle", valueSelector: "handle", placeholder: "Filter by Driver Handles", showFilter: interactionsType === "driver", isMulti: true, variables: { customerId: this.props.customerId }
            },
        ]
    }

    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.props.setFilterValues({ ...filterValues })
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    getFilters = () => {
        let conf = this.getFilterConfigurations(this.props.interactionsType)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                //Iagami - Code upgradation React 18
                this.props.apolloClient.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: options.node[filter.labelSelector]
                        }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        //Iagami - Code upgradation React 18
        const { apolloClient } = this.props
        this.getFilters()
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside = (event) => {

        // Check if the clicked element is inside a popover
        if (event.target.closest(".popover.show.bs-popover-auto")) {
            return
        }

        this.setState({ openModal: "" })
    };
    render() {
        return (
            <Row>
                {this.state.openModal === "viewInteractionFilters" &&
                    <ListingFilters open={this.state.openModal === "viewInteractionFilters"} handleClose={() => this.toggleModal("")}
                        target="viewInteractionFilters" filterValues={this.props.filterValues} setFilterValues={(filters => this.props.setFilterValues({ ...filters }))}
                        filters={this.state.configurations}
                    />
                }
                <Col xs={6} className="mt-2 search-filter-container">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                </Col>
                <Col xs={6} className="text-right mt-2">
                    <a id="viewInteractionFilters" className="driver-list-button" onClick={() => this.toggleModal("viewInteractionFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
            </Row>
        )
    }
}

export default (withApolloClient)(InteractionsListFilters)
