import React, { Component } from "react"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Popover, PopoverBody, PopoverHeader, Input, Button, Alert, Row, Col, Progress } from "reactstrap"
import { UpdateInsuranceStatus } from "./Mutations"
import Select from "react-select"
import { RepsQuery } from "../Functions/querys"
import withApolloClient from "../withApolloClient"

class UpdateInsuranceInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: { insuranceStatusId: this.props.id },
            allReps: [],
            loading: false
        }
    }
    updateInput = (name, value) => {
        this.setState({ input: { ...this.state.input, [name]: value } })
    }

    updateInsurance = () => {
        let input = this.state.input
        this.setState({ loading: true })
        this.props.apolloClient.mutate({
            mutation: UpdateInsuranceStatus,
            variables: { input }
        }).then(result => {
            if (result.data && result.data.updateInsuranceStatus && result.data.updateInsuranceStatus.ok) {
                this.props.handleClose()
                this.props.refetchQuery()
                this.setState({ loading: false })
            } else {
                this.setState({ errorMessage: result.errors.messages[0], loading: false })
            }
        })
    }

    componentDidMount() {
        if (this.props.name === "representative") {
            this.props.apolloClient.query({
                query: RepsQuery,
                variables: { permissions: ["custom_can_assign_insurance"] }
            }).then((result) => {
                if (result.data.allReps && result.data.allReps.edges && result.data.allReps.edges.length > 0) {
                    this.setState({ allReps: result.data.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name })) })
                }
            })
        }
        const { apolloClient } = this.props
    }

    render() {
        return (
            <Popover placement="bottom" isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader>
                    {this.props.title}&nbsp;&nbsp;<i className="fa fa-times-circle float-right" onClick={this.props.handleClose} aria-hidden="true"> </i>
                </PopoverHeader>
                <PopoverBody>
                    {this.state.errorMessage && <Row><Col xs={12}><Alert color="danger">{this.state.errorMessage}</Alert></Col></Row>}
                    <div>
                        {this.props.type == "select" ?
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                options={this.state.allReps}
                                isLoading={!this.state.allReps}
                                defaultValue={this.props.defaultValue}
                                onChange={(item) => this.updateInput(this.props.name, item.value)}
                            /> :
                            <Input
                                className="mt-2 mb-2" type={this.props.type}
                                name={this.props.name}
                                placeholder={this.props.placeholder}
                                defaultValue={this.props.defaultValue}
                                onChange={(e) => this.updateInput(e.target.name, e.target.value)}
                            />}
                    </div>
                    {this.state.loading ? <Progress animated color="info" value={100} /> : <Button className="float-right submit-button  my-2 py-1" onClick={this.updateInsurance}>UPDATE</Button>}
                </PopoverBody>
            </Popover>
        )
    }
}

//Iagami - Code upgradation React 18
export default (withApolloClient(UpdateInsuranceInfo))
