import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Badge } from 'reactstrap'
import './Argyle.css'
import DatetimeRenderer from "../Material/DatetimeRenderer"
import withApolloClient from "../withApolloClient"
const AllArgyleAccountsQuery = gql`query AllArgyleAccounts($argyleUserId: String!){
    allArgyleAccounts(argyleUserId: $argyleUserId){
        accountId
        employers
        userId
        source
        connectionStatus
        connectionCode
        connectionMessage
        createdAt
        updatedAt
    }
}`



class ArgyleAccountDetailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            openModal: "",
            error: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="argyle-account-detail-modal">
                    <ModalHeader>{this.props.driver && this.props.driver.firstName} Argyle Integrations</ModalHeader>
                    <ModalBody>
                        {this.props.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.props.allArgyleAccounts && this.props.allArgyleAccounts.length > 0 ? this.props.allArgyleAccounts.map((account) =>
                            <Row className="argyle-account-detail-modal-detail-box">
                                <Col xs={4}>
                                    <Label>Platform</Label>
                                    <p style={{ fontWeight: "600" }}>{account.employers.length > 0 ? (<Badge className="bg-primary text-white">{account.employers[0].toUpperCase()}</Badge>) : ("Not Found!")}</p>                                </Col>
                                <Col xs={4}>
                                    <Label>Created At</Label>
                                    <p><DatetimeRenderer datetime={account.createdAt} /></p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Updated At</Label>
                                    <p><DatetimeRenderer datetime={account.updatedAt} /></p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Connection Status</Label>
                                    <p><Badge className={account.connectionStatus !== "connected" ? "bg-danger" : "bg-success"} style={{ color: "white" }}>{account.connectionStatus.toUpperCase()}</Badge></p>                                </Col>
                                <Col xs={4}>
                                    <Label>Connection Code</Label>
                                    <p>{account.connectionCode ? account.connectionCode : "--"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Connection Source</Label>
                                    <p>{account.source ? account.source : "--"}</p>
                                </Col>
                                {account.connectionMessage &&
                                    <Col xs={12}>
                                        <Label>Connection Message</Label>
                                        <p>{account.connectionMessage}</p>
                                    </Col>
                                }
                            </Row>
                        ) : !this.props.loading && "No Integration Found!"}
                    </ModalBody>
                    <ModalFooter className='d-block'>
                        {!this.state.loading &&
                            <Row>
                                <Col className='text-right'>
                                    <Button outline color="secondary" onClick={() => this.props.handleClose()}>Close</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllArgyleAccountsQuery, {
    options: ({ argyleUserId }) => ({
        variables: { argyleUserId },
        fetchPolicy: 'cache-and-network'
    }),
    props: ({ data: { allArgyleAccounts, loading, variables } }) => ({
        allArgyleAccounts,
        loading,
        variables
    })
})(
    withApolloClient(ArgyleAccountDetailModal)
);

