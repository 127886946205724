import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import {
    Popover,
    PopoverBody,
    PopoverHeader,
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Alert,
    Progress
} from 'reactstrap'
import {
    UpdateWorkOrderMutation,
    UpdateWorkItemMutation,
    CreateWorkItemMutation
} from "./Mutations"
import { VendorsQuery } from "../Jobs/Queries"
import { JobTypeQuery } from "../Jobs/Queries"
import Select from "react-select"

class UpdateWorkOrderInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: "",
            value: this.props.value,
            name: this.props.name,
            input: this.props.createWorkItem ? { price: 0, quantity: 0, jobTypeId: "" } : {},
            loading: false,
            error: null,
        }
    }
    updateInput = (...args) => {
        let input = { ...this.state.input }
        input[args[0]] = args[1]
        this.setState({ input: input })
    }
    updateDocument = (e) => {
        let base64Img = e.target.files[0]
        let name = e.target.files[0].name
        let _this = this
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            _this.setState({ fileName: name, input: { "invoice": imageInBase64 } })
        }
    }
    updateWorkOrder = () => {
        let input = this.state.input
        this.setState({ loading: true })
        if ((!this.props.createWorkItem && input[this.props.name]) || (this.props.createWorkItem && input && input.price !== 0 && input.quantity !== 0 && input.jobTypeId !== "")) {
            if (this.props.updateWorkItem) {
                input["workItemId"] = this.props.workItemId
                this.props.updateWI(input).then(result => {
                    this.props.refetchData()
                    if (result.data && result.data.updateWorkItem && result.data.updateWorkItem.ok) {
                        this.props.refetchData()
                        this.setState({ loading: false })
                        this.handleClose()
                    } else {
                        this.setState({ error: result.data.updateWorkItem.errors[0].messages })
                        this.setState({ loading: false })
                    }
                })
            } else if (this.props.createWorkItem) {
                input["workOrderId"] = this.props.workOrderId
                this.props.createWI(input).then(result => {
                    if (result.data && result.data.createWorkItem && result.data.createWorkItem.ok) {
                        this.props.refetchData()
                        this.setState({ loading: false })
                        this.handleClose()
                    } else {
                        this.setState({ error: result.data.createWorkItem.errors[0].messages })
                        this.setState({ loading: false })
                    }
                })
            }
            else {
                input["workOrderId"] = this.props.workOrderId
                this.props.updateWO(input).then(result => {
                    if (result.data && result.data.updateWorkOrder && result.data.updateWorkOrder.ok) {
                        this.props.refetchData()
                        this.setState({ loading: false })
                        this.handleClose()
                    } else {
                        this.setState({ error: result.data.updateWorkOrder.errors[0].messages })
                        this.setState({ loading: false })
                    }
                })
            }
        } else {
            this.setState({ error: "Error: Required fields are missing" })
            this.setState({ loading: false })
        }
    }
    handleClose = () => {
        let state = {
            input: {}
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    componentDidMount() {
        if (this.props.name && this.props.value) {
            const name = this.props.name
            let value
            if (this.props.name === "jobTypeId" || this.props.name === "vendorId") {
                value = this.props.id
            } else {
                value = this.props.value
            }
            this.setState({ input: { [name]: value } })
        }
    }
    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody style={{ width: "200px" }}>
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.state.error && <Row><Col><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.props.name === "invoice" && this.state.input.invoice &&
                                <Row>
                                    <Col>
                                        <img
                                            src={this.state.input.invoice.includes("http") ? this.state.input.invoice : "data:image/jpeg;base64," + this.state.input.invoice}
                                            width="100"
                                        />
                                    </Col>
                                </Row>
                            }
                            {this.props.updateWorkItem ?
                                this.props.name === "jobTypeId" ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        name={this.props.name}
                                        options={this.props.allJobTypes &&
                                            this.props.allJobTypes.edges.length > 0 &&
                                            this.props.allJobTypes.edges.map((job) => ({ value: job.node.id, label: job.node.name }))}
                                        placeholder="Select Job"
                                        onChange={job => this.updateInput("jobTypeId", job.value)}
                                        defaultValue={
                                            [{
                                                label: this.props.allJobTypes &&
                                                    this.props.allJobTypes.edges &&
                                                    this.props.allJobTypes.edges.length > 0 &&
                                                    this.props.allJobTypes.edges.find(job => job.node.name === this.props.value).node.name,
                                                value: this.props.allJobTypes &&
                                                    this.props.allJobTypes.edges &&
                                                    this.props.allJobTypes.edges.length > 0 &&
                                                    this.props.allJobTypes.edges.find(job => job.node.name === this.props.value).node.id
                                            }]
                                        }
                                    /> :
                                    <Input
                                        type="number"
                                        min={this.props.name === "quantity" ? "1" : "0.1"}
                                        name={this.props.name}
                                        defaultValue={this.state.value}
                                        onChange={(e) =>
                                            this.updateInput(e.target.name, e.target.value)
                                        }
                                    />
                                :
                                !this.props.createWorkItem ?
                                    this.props.name === "completionDate" ?
                                        <FormGroup>
                                            <Label for="completionDate" className="d-block"><small>Completion Date (Optional)</small></Label>
                                            <Input
                                                type="date"
                                                name={this.props.name}
                                                onChange={(e) =>
                                                    this.updateInput(e.target.name, e.target.value)
                                                }
                                                defaultValue={this.state.value}
                                            />
                                        </FormGroup> :
                                        this.props.name === "vendorId" ?
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={this.props.loading}
                                                options={this.props.allVendors &&
                                                    this.props.allVendors.edges &&
                                                    this.props.allVendors.edges.length > 0 &&
                                                    this.props.allVendors.edges.map(item =>
                                                        ({ value: item.node.id, label: item.node.name })
                                                    )
                                                }
                                                name={this.props.name}
                                                placeholder="Select Car Vendor"
                                                onChange={vendor => this.updateInput("vendorId", vendor.value)}
                                                defaultValue={
                                                    [{
                                                        label: this.props.allVendors &&
                                                            this.props.allVendors.edges &&
                                                            this.props.allVendors.edges.length > 0 &&
                                                            this.props.allVendors.edges.find(vendor => vendor.node.name === this.props.value).node.name,
                                                        value: this.props.allVendors &&
                                                            this.props.allVendors.edges &&
                                                            this.props.allVendors.edges.length > 0 &&
                                                            this.props.allVendors.edges.find(vendor => vendor.node.name === this.props.value).node.id
                                                    }]
                                                }
                                            /> :
                                            this.props.name === "invoice" ?
                                                <Input
                                                    type="file"
                                                    name={this.props.name}
                                                    id="document"
                                                    onChange={this.updateDocument}
                                                    accept="image/*"
                                                    className="edit-item"
                                                /> :
                                                <Input
                                                    type="number"
                                                    min="1"
                                                    name={this.props.name}
                                                    defaultValue={this.state.value}
                                                    onChange={(e) =>
                                                        this.updateInput(e.target.name, e.target.value)
                                                    }
                                                /> :
                                    <div>
                                        <FormGroup>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={this.props.loading}
                                                options={this.props.allJobTypes &&
                                                    this.props.allJobTypes.edges &&
                                                    this.props.allJobTypes.edges.length > 0 &&
                                                    this.props.allJobTypes.edges.map(item =>
                                                        ({ value: item.node.id, label: item.node.name })
                                                    )
                                                }
                                                name={this.props.name}
                                                placeholder="Select Job Type"
                                                onChange={job => this.updateInput("jobTypeId", job.value)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="number"
                                                placeholder="Quantity"
                                                min="1"
                                                name="quantity"
                                                value={
                                                    this.state.input.quantity ? this.state.input.quantity : ""
                                                }
                                                onChange={(e) =>
                                                    this.updateInput(e.target.name, e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="number"
                                                placeholder="Price"
                                                min="0.1"
                                                name="price"
                                                value={this.state.input.price ? this.state.input.price : ""}
                                                onChange={(e) =>
                                                    this.updateInput(e.target.name, e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                            }
                        </FormGroup>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        <Button type="button" size="sm" onClick={this.updateWorkOrder} >Update</Button>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(JobTypeQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allJobTypes } }) => ({
        loading,
        allJobTypes
    })
})(graphql(VendorsQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allVendors } }) => ({
        loading,
        allVendors
    })
})(graphql(UpdateWorkOrderMutation, {
    props: ({ mutate }) => ({
        updateWO: (input) => mutate({ variables: { input } })
    })
})(graphql(UpdateWorkItemMutation, {
    props: ({ mutate }) => ({
        updateWI: (input) => mutate({ variables: { input } })
    })
})(graphql(CreateWorkItemMutation, {
    props: ({ mutate }) => ({
        createWI: (input) => mutate({ variables: { input } })
    })
})(withApolloClient(UpdateWorkOrderInfo))))));

