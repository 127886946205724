export const checkAllInputsFilled = (documentTypeDocumentData) => {
    return documentTypeDocumentData.every(
        (field) => field.value && field.value.toString().trim() !== ""
    )
}

export const handleDriverDataChange = (
    input,
    numbersOnly = false,
    allowNumbersAndDot = false,
    documentTypeDocumentData,
    updateState
) => {
    if (!input) return
    let { name, value } = input.target || input

    if (input.target && input.target.type === 'text') {
        if (allowNumbersAndDot) {
            // Allow only numbers and dots
            value = value.replace(/^(?!^$)(\d+(\.\d{0,2})?)?.*$/, '$1')
        } else if (numbersOnly) {
            // Allow only numbers
            value = value.replace(/[^0-9]/g, '')
        } else {
            // Allow alphanumeric
            value = value.replace(/[^a-zA-Z0-9\s]/g, '')
        }
    }

    // Need to send to database with single-capital-letter format
    if (name === 'GENDER') {
        const mapping = { 'MALE': 'M', 'FEMALE': 'F', 'OTHER': 'O' }
        value = mapping[value] || value
    }

    // Update the documentTypeDocumentData array
    const updatedData = documentTypeDocumentData.map((field) =>
        field.fieldCode === name ? { ...field, value } : field
    )

    // Call the state updater with the new data
    updateState(updatedData)
}


export const categoryNameMapping = {
    ID: "ID Information",
    DRIVER: "Driver Information",
    ADDRESS: "Address Information",
}

export const groupFieldsByCategory = (fields) => {
    if (!fields || fields.length === 0) return {}
    return fields.reduce((acc, field) => {
        if (!acc[field.dataCategory]) {
            acc[field.dataCategory] = []
        }
        acc[field.dataCategory].push(field)
        return acc
    }, {})
}

export const hasIssuedAndExpirationDates = (field) => {
    return field.fieldCode === "ISSUED_DATE" || field.fieldCode === "EXPIRATION_DATE"
}

export const validateDocumentData = (driverDocumentData, selectedDocumentType) => {
    if (!driverDocumentData || !driverDocumentData.documentData || !selectedDocumentType) {
        return []
    }

    for (const field of driverDocumentData.documentData) {
        if (field.documentName === selectedDocumentType.typeName) {
            return field.requiredData || []
        }
    }

    return []
};


