import React, { Component } from "react"
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from "graphql-tag"
import { Table, Badge } from "reactstrap"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
// import DriverUpdateTagsPopover from "../Drivers/Driver/Tags/DriverUpdateTagsPopover"
import "../Material/ListingHeader.css"
import "./Argyle.css"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import withApolloClient from "../withApolloClient"


const AllActiveDriversArgyleAccounts = gql`query AllActiveDriversArgyleAccounts($first:Int!, $endCursor: String, $ongoingRefreshStatus: String){
    allActiveDriversArgyleAccounts(first:$first, endCursor: $endCursor, ongoingRefreshStatus: $ongoingRefreshStatus){
        accountId
        userId
        source
        connectionStatus
        connectionCode
        connectionMessage
        createdAt
        updatedAt
	    employers
        endCursor
        driver{
            id
            firstName
            email
            name
            notes
            branch{
                id
                name
            }
        }
    } 
}`

const tableHeaders = [
    { id: "pk", name: "ID" },
    { id: "created_at", name: "Created At" },
    { id: "driver_info", name: "Driver Info" },
    { id: "driver_branch", name: "Driver Branch" },
    { id: "driver_notes", name: "Driver Notes" },
    { id: "platform", name: "Platform" },
    { id: "connection_status", name: "Connection Status" },
    { id: "connection_code", name: "Connection Code" },
    { id: "connection_message", name: "Connection Message" },
]



class ActiveDriverArgyleAccountListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    loadMore = () => {
        const { allActiveDriversArgyleAccounts } = this.props
        const lastItem = allActiveDriversArgyleAccounts[allActiveDriversArgyleAccounts.length - 1]
        const { endCursor } = lastItem
        this.props.fetchMore({
            variables: {
                endCursor: endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return Object.assign({}, prev, {
                    allActiveDriversArgyleAccounts: [...prev.allActiveDriversArgyleAccounts, ...fetchMoreResult.allActiveDriversArgyleAccounts],
                })
            },
        })
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} loadMoreEntries={this.loadMore}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(header => (
                                <th key={header.id}>{header.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allActiveDriversArgyleAccounts && this.props.allActiveDriversArgyleAccounts.length > 0 ? this.props.allActiveDriversArgyleAccounts.map((argyle, i) =>
                            <tr key={i}>
                                <td scope="row">{i + 1}</td>
                                <td scope="row"><DatetimeRenderer datetime={argyle.createdAt} placement={"right"} /></td>
                                <td>{argyle.driver && argyle.driver.name}</td>
                                <td><Badge color="primary" style={{ fontSize: 13 }}>{argyle.driver && argyle.driver.branch && argyle.driver.branch.name}</Badge></td>
                                <td>{argyle.driver && argyle.driver.notes ? argyle.driver.notes : "--"}</td>
                                <td >
                                    <Badge className="p-1" style={{ textTransform: "uppercase" }} >{argyle.employers && argyle.employers.length > 0 ? argyle.employers.map(item => item) : "N/A"}</Badge>
                                </td>
                                <td>
                                    <Badge className="p-1" style={{ background: argyle.connectionStatus === "error" ? "red" : "green", textTransform: "uppercase" }}>{argyle.connectionStatus ? argyle.connectionStatus : "N/A"}</Badge>
                                </td>
                                <td >
                                    {argyle.connectionCode ? argyle.connectionCode : "N/A"}
                                </td>
                                <td >
                                    {argyle.connectionMessage ? argyle.connectionMessage : "N/A"}
                                </td>
                            </tr>
                        ) : !this.props.loading &&
                        <tr>
                            <td>No Argyle Account Found</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer >
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllActiveDriversArgyleAccounts, {
    options: ({ ongoingRefreshStatus }) => ({
        variables: {
            first: 30,
            ongoingRefreshStatus: ongoingRefreshStatus
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    }),
    props: ({ data: { allActiveDriversArgyleAccounts, loading, fetchMore } }) => ({
        allActiveDriversArgyleAccounts,
        loading,
        fetchMore
    })
})(
    withApolloClient(ActiveDriverArgyleAccountListing)
);

