import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress, FormText } from 'reactstrap'
import Select from 'react-select'
import { CreateFinancialAccount } from "../Mutations"
import { AllBranches, AllChargeTypes, AllGroupTypes, FinancialAccountCarType } from "../Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class NewFinancialAccountModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {
                creditAccountNumber: "",
                debitAccountNumber: "",
                description: null,
                chargeTypeId: null,
                subChargeTypeId: null,
                branchIds: [],
                carType: null,
            },
            errorMessage: "",
            loading: false,
        }
    }
    createFinancialAccount = () => {
        this.setState({ loading: true })
        if (this.state.input.creditAccountNumber && this.state.input.debitAccountNumber && this.state.input.chargeTypeId && this.state.input.branchIds.length > 0 && this.state.input.carType)
            try {
                this.props.apolloClient.mutate({
                    mutation: CreateFinancialAccount,
                    variables: { input: this.state.input }
                }).then((result) => {
                    if (result && result.data && result.data.createFinancialAccount && result.data.createFinancialAccount.ok) {
                        this.setState({ errorMessage: "" })
                        this.props.handleClose()
                        window.location.reload()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createFinancialAccount && result.data.createFinancialAccount.errors && result.data.createFinancialAccount.errors[0] && result.data.createFinancialAccount.errors[0].messages) {
                            errorMessage = result.data.createFinancialAccount.errors[0].messages.toString()
                        }
                        this.setState({ errorMessage: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured " + err
                    this.setState({ errorMessage: errorMessage, loading: false })
                })
            }
            catch (err) {
                let errorMessage = "An error has occured " + err
                this.setState({ errorMessage: errorMessage, loading: false })
            }
        else {
            this.setState({ errorMessage: "Missing Required Field", loading: false })
        }
    }


    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ input: { ...this.state.input, [name]: value } })
    };
    componentDidMount() {
        const { apolloClient } = this.props;
          }    

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="bos-create-tenant-modal">
                    <ModalHeader>NEW FINANCIAL ACCOUNT</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.errorMessage && <Col xs={12}><Alert color="danger">{this.state.errorMessage}</Alert></Col>}
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Charge Type*</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0 && this.props.allChargeTypes.edges.map(chargeType => ({ label: chargeType.node.name, value: chargeType.node.id }))}
                                        onChange={(chargeType) => this.setState({ input: { ...this.state.input, chargeTypeId: chargeType.value, subChargeTypeId: null } })}
                                        placeholder="Select Charge Type"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Sub-Charge Type</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        key={this.state.input.chargeTypeId}
                                        isDisabled={!this.state.input.chargeTypeId}
                                        options={this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0 && this.props.allGroupTypes.edges.filter(item => item.node.chargeType.id === this.state.input.chargeTypeId).map(subChargeType => ({ label: subChargeType.node.name, value: subChargeType.node.id }))}
                                        onChange={(subChargeType) => this.setState({ input: { ...this.state.input, subChargeTypeId: subChargeType.value } })}
                                        placeholder="Select Sub-Charge Type"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="creditAccountNumber">Credit Account Number*</Label>
                                    <Input name="creditAccountNumber" id="creditAccountNumber" onChange={this.updateInput} placeholder="Credit Account Number" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="debitAccountNumber">Debit Account Number*</Label>
                                    <Input name="debitAccountNumber" id="debitAccountNumber" onChange={this.updateInput} placeholder="Debit Account Number" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Car Type*</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={this.props.financialAccountCarType && this.props.financialAccountCarType.values && this.props.financialAccountCarType.values.length > 0 && this.props.financialAccountCarType.values.map(carType => ({ label: carType.description, value: carType.description }))}
                                        onChange={(carType) => this.setState({ input: { ...this.state.input, carType: carType.value } })}
                                        placeholder="Select Car Type"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Branches *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ label: branch.node.name, value: branch.node.id }))}
                                        onChange={(branch) => this.setState({ input: { ...this.state.input, branchIds: branch.map(item => item.value) } })}
                                        isMulti
                                        placeholder="Select Branches"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input name="description" id="description" onChange={this.updateInput} placeholder="Description" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="new-tenant-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>&nbsp;&nbsp;
                        <Button className="new-tenant-modal-primary-btn" onClick={this.createFinancialAccount} disabled={this.state.loading}>Add Account</Button>
                    </ModalFooter>
                </Modal >
            </div >
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllBranches, { 
    props: ({ data: { allBranches } }) => ({ allBranches }) 
})(
    graphql(AllChargeTypes, { 
        props: ({ data: { allChargeTypes } }) => ({ allChargeTypes }) 
    })(
        graphql(AllGroupTypes, { 
            props: ({ data: { allGroupTypes } }) => ({ allGroupTypes }) 
        })(
            graphql(FinancialAccountCarType, { 
                props: ({ data: { financialAccountCarType } }) => ({ financialAccountCarType }) 
            })(
                withApolloClient(NewFinancialAccountModal)
            )
        )
    )
);
