import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import loader from './car-loader.gif'

//Iagami - Code upgradation React 18
export default class Loader extends Component {

    render() {
        return <Row>
            <Col className="text-center">
                <img src={loader} alt="loading..." />
            </Col>
        </Row>
    }
}