import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Label, Nav, NavItem, NavLink, Container, Badge } from 'reactstrap'
import Loader from "../../Material/Loader"
import OverviewTab from "./GroupDetailTab/OverviewTab"
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const GroupQuery = gql`query group($id: ID!){
    group(id: $id) {
      id
      name
    }
  }  
`

class GroupsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            activeTab: "overview"
        }
    }

    render() {
        return (

            <Container fluid className="bos-object-detail-container">
                {this.props.loading ? <Loader /> : this.props.group && <>
                    <Row className="bos-object-detail-header">
                        <Col xs="1">
                            <span id="box-object-icon" style={{ padding: 30 }}>
                                <i style={{ fontSize: 20 }} className="fa fa-universal-access" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col xs={5} className="bos-object-title-wrapper">
                            <Label>
                                {this.props.group.name ? this.props.group.name : "-"}
                            </Label>
                        </Col>
                    </Row>
                    <Row className="bos-object-detail-body">
                        <Nav pills>
                            <NavItem>
                                <NavLink onClick={() => this.setState({ activeTab: "overview" })} active={this.state.activeTab === "overview"}>Overview</NavLink>
                            </NavItem>
                        </Nav>
                        <div className="bos-object-nav-content">
                            {this.state.activeTab === "overview" && <OverviewTab groupId={this.props.group.id} />}
                        </div>
                    </Row>
                </>
                }
            </Container>
        )

    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(GroupQuery, {
    options: (props) => ({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            id: props.params.id,
        },
    }),
    props: ({ data: { group, loading, variables, refetch } }) => ({
        group,
        loading,
        variables,
        refetchGroupQuery: () => {
            return refetch({
                query: GroupQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { group: fetchMoreResult.group }
                },
            })
        },
    }),
})(withApolloClient(GroupsDetails));

