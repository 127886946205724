import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../BranchMangement/BranchListContainer.css'
import { Table } from 'reactstrap'
import { AllPermissions } from "../Queries"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { Link } from 'react-router-dom'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const tableHeaders = [
    { id: "pk", name: "ID", sortable: false },
    { id: "name", name: "Name", sortable: false },
    { id: "code_name", name: "Code Name", sortable: false },
    { id: "model", name: "Model", sortable: false },
]

class AllPermissionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false
        }
    }

    render() {
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.allPermissions && this.props.allPermissions.edges.length} pageInfo={this.props.allPermissions && this.props.allPermissions.pageInfo} loadMoreEntries={this.props.allPermissions && this.props.allPermissions.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaders.map(tableHeader => {
                                    return (
                                        tableHeader.sortable ?
                                            <th key={tableHeader.id} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )
                                })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.allPermissions && this.props.allPermissions.edges && this.props.allPermissions.edges.length > 0 ? this.props.allPermissions.edges.map((permission, i) =>
                                <tr key={i + 1}>
                                    <td>&nbsp;{i + 1}</td>
                                    <td><Link color="secondary" to={'/organization/permission/' + permission.node.id}>{permission.node.name}</Link></td>
                                    <td>{permission.node.codename ? permission.node.codename : "--"}</td>
                                    <td>{permission.node.contentType ? permission.node.contentType.appLabel : "--"} <span style={{ fontSize: 15 }}>|</span> {permission.node.contentType ? permission.node.contentType.model : "--"}</td>
                                </tr>
                            ) :
                                <tr>
                                    {!this.props.loading ?
                                        <td colSpan={7}>No Permission Found</td> :
                                        <td colSpan={7}>Loading ...</td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </Table>
                </PrimaryListingContainer>
            </>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllPermissions, {
        options: ({ searchTerm }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                name_Icontains: searchTerm,
                first: 30
            }
        }),
        props:({ data: { loading, allPermissions, fetchMore, variables } })=>({
                loading,
                allPermissions,
                variables,
                refetchQuery: () => {
                    let currentLength = allPermissions && allPermissions.edges && allPermissions.edges.length > 30 ? allPermissions.edges.length : 30
                    return fetchMore({
                        query: AllPermissions,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPermissions.edges
                            const pageInfo = fetchMoreResult.allPermissions.pageInfo
                            return {
                                allPermissions: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allPermissions.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllPermissions,
                        variables: {
                            cursor: allPermissions.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPermissions.edges
                            const pageInfo = fetchMoreResult.allPermissions.pageInfo

                            return {
                                allPermissions: {
                                    edges: [...previousResult.allPermissions.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allPermissions.__typename
                                },
                            }
                        },
                    })
                },
            })
    })
(withApolloClient(AllPermissionList));
