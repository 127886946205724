import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { useApolloClient } from '@apollo/client'
import { Button, Table, Progress, Card, CardBody, Row, Col, UncontrolledTooltip } from 'reactstrap'
import RegistrationModal from './RegistrationModal'
import { AllRegistrationsQuery, AllOwnersQuery } from '../Queries'
import toTitleCase from '../../Functions/toTitleCase'
import CarModelGroupIcon from '../../CA/CarModelGroupIcon'
import RegistrationActivityModal from './RegistrationActivityModal'
import RegistrationNotesHistory from './RegistrationNotesHistory'
import CarInfoModal from '../../CA/CarInfoModal'
import InstallCarPopover from './InstallCarPopover'
import InspectionModal from "../Inspections/InspectionModal"
import Badge from "reactstrap/lib/Badge"
import renderEnumToInt from "../../Functions/renderEnumToInt"

const tableHeaders = [
    // {id:"id",name:"ID", sortable:true},
    { id: "number", name: "Registration Details", sortable: true },
    // {id:"registration_type__name",name: "Type",sortable:true},
    { id: "expiration_date", name: "Expiration Date", sortable: true },
    { id: "notes", name: "Notes", sortable: false },
    // {id:"created_by",name:"Created By",sortable:false},
    // {id:"metadata",name:"Metadata",sortable:false},
    { id: "activity", name: "Registration Activity" },
]

class RegistrationsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            error: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    renderRegistrationDetails = (registration) => {
        let metaObject = JSON.parse(registration.metadata.replace(/'/g, '"'))
        return <>
            <h4>{registration.number}&nbsp;&nbsp;<i className={'fa fa-circle ' + (registration.activeRegistration ? registration.activeRegistration.isActive ? "text-success" : "text-warning" : "text-danger")}></i></h4>
            <small style={{ maxWidth: "300px", display: "block" }}>Created By: {registration.createdBy.firstName ? registration.createdBy.firstName + " " + registration.createdBy.lastName : registration.createdBy.username}
                {Object.keys(metaObject).map(item => ", " + toTitleCase(item.replace("_", " ")) + ": " + (item === "owner" && this.props.allOwners && this.props.allOwners.edges && this.props.allOwners.edges.length > 0 ? this.props.allOwners.edges.find(owner => owner.node.pk == metaObject[item]).node.name : metaObject[item]))}
            </small>
        </>
    }

    renderCarDetails = (carReg, i = 0) => {
        return <div className='car-details-section'>
            <span className='left-box__'>
                {this.state.openModal === "CarInfo" + carReg.car.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + carReg.car.pk} id={carReg.car.pk} carPk={carReg.car.pk} carId={carReg.car.id} refetchQuery={this.props.refetchQuery} />}
                <a onClick={() => this.toggleModal("CarInfo" + carReg.car.pk)}>
                    <CarModelGroupIcon carGroup={carReg.car.carModel && carReg.car.carModel.groupType ? carReg.car.carModel.groupType.name : null} carColor={carReg.car.color} id={carReg.car.pk} key={i + 1} />
                </a>
                <br />
                <span>{carReg.car.pk}</span>
            </span>
            <span className='right-box__'>
                <small>{carReg.car.year} | {carReg.car.color} | {carReg.car.branch.name}&nbsp;&nbsp;<a href={"/car/" + carReg.car.pk} target="_blank"><i className='fa fa-external-link-square' /></a></small>
                <h4>{toTitleCase(carReg.car.carModel.make)} {toTitleCase(carReg.car.carModel.name)}
                    {/* <small>{carReg.car.carModel.series ? "("+carReg.car.carModel.series+")" : ""}</small> */}
                    {carReg.car.stage == 10 && <>
                        <i id={"driverIsActive" + i} className="fa fa-circle text-success" ></i>
                        <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                    </>}
                </h4>
            </span>
            <span className="right-actions">
                <ul>
                    <li>
                        <Badge id={"carStage" + i} style={{ backgroundColor: "#F55DBA", fontSize: "12px", textTransform: "uppercase" }}>Stage {renderEnumToInt(carReg.car.stage)}</Badge>
                        <UncontrolledTooltip placement="bottom" target={"carStage" + i}>Car Stage</UncontrolledTooltip>
                    </li>
                </ul>
            </span>
        </div>
    }


    render() {
        return <>
            {/* <Row>            
                <Col xs={{size:3, offset:9}} className="text-right py-2">
                    {this.state.openModal && this.state.openModal === "createRegistration" && <RegistrationModal open={this.state.openModal === "createRegistration"} handleClose={()=>this.toggleModal("createRegistration")} refetchQuery={this.props.refetchQuery}/>}
                    <Button className='bos-btn-primary' onClick={()=>this.toggleModal("createRegistration")}><i className={"fa fa-plus"}></i> New Registration</Button>
                </Col>
            </Row> */}

            <Card className="car-registration-table-container mt-2"><CardBody><Row><Col sm="12">
                {this.props.loading || this.state.loading ? <Progress animated color="info" value="100" /> : ""}
                <Table responsive>
                    <thead>
                        <tr>
                            <th> </th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allRegistrations && this.props.allRegistrations.edges && this.props.allRegistrations.edges.length > 0 ? this.props.allRegistrations.edges.map((reg, i) =>
                            <tr>
                                <td scope="row">
                                    {i + 1}
                                    {this.state.openModal && this.state.openModal === "editRegistration" + i && <RegistrationModal open={this.state.openModal === "editRegistration" + i} isUpdate registration={reg.node} handleClose={() => this.toggleModal("editRegistration" + i)} refetchQuery={this.props.refetchQuery} />}
                                </td>
                                <td>
                                    <div className="registration-details-wrapper">
                                        <span className='left-box'>
                                            <i id={"editRegistration" + i} className="fa fa-pencil fa-lg" aria-hidden="true" onClick={() => this.toggleModal("editRegistration" + i)}></i>
                                            <br /><small>{reg.node.registrationType.name} - {reg.node.registrationType.stateCode}</small>
                                        </span>
                                        <span className='right-box'>
                                            {this.renderRegistrationDetails(reg.node)}
                                        </span>
                                        {
                                            reg.node.registrationType.name === "TLC" && reg.node.registrationType.stateCode === "NY" && <div className="right-actions">
                                                <ul>
                                                    {reg.node.upcomingInspection && <li>
                                                        {this.state.openModal === "upcomingInspection" + i && <InspectionModal isUpdate carInspection={{ ...reg.node.upcomingInspection, registration: reg.node }} open={this.state.openModal === "upcomingInspection" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                        <a id={"upcomingInspection" + i} type="button" className="cargadgets-button" onClick={() => this.toggleModal("upcomingInspection" + i)}>
                                                            <i className="fa fa-wpforms fa-lg text-info" aria-hidden="true"></i>
                                                        </a>
                                                        <UncontrolledTooltip placement="bottom" target={"upcomingInspection" + i}>Upcoming Inspection</UncontrolledTooltip>
                                                    </li>}
                                                    {!reg.node.upcomingInspection && reg.node.activeRegistration && reg.node.activeRegistration.car && [9, 10, 11].includes(renderEnumToInt(reg.node.activeRegistration.car.stage)) && <li>
                                                        {this.state.openModal === "renewInspection" + i && <InspectionModal carInspection={{ car: reg.node.activeRegistration.car, registration: reg.node }} open={this.state.openModal === "renewInspection" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                        < a id={"renewInspection" + i} type="button" className="cargadgets-button">
                                                            <i className="fa fa-refresh fa-lg text-info" aria-hidden="true"></i>
                                                        </a>
                                                        <UncontrolledTooltip placement="bottom" target={"renewInspection" + i}>Renew Inspection</UncontrolledTooltip>
                                                    </li>}
                                                    {!reg.node.upcomingInspection && reg.node.activeRegistration && reg.node.activeRegistration.car && [12, 13].includes(renderEnumToInt(reg.node.activeRegistration.car.stage)) && <li>
                                                        {this.state.openModal === "transferInspection" + i && <InspectionModal carInspection={{ registration: reg.node }} open={this.state.openModal === "transferInspection" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                        <a id={"transferInspection" + i} type="button" className="cargadgets-button">
                                                            <i className="fa fa-lg text-info fa-exchange" aria-hidden="true"></i>
                                                        </a>
                                                        <UncontrolledTooltip placement="bottom" target={"transferInspection" + i}>Start Inspection (Transfer Registation)</UncontrolledTooltip>
                                                    </li>}
                                                    {!reg.node.upcomingInspection && !reg.node.activeRegistration && <li>
                                                        {this.state.openModal === "newInspection" + i && <InspectionModal carInspection={{ registration: reg.node }} open={this.state.openModal === "newInspection" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                        <a id={"newInspection" + i} type="button" className="cargadgets-button">
                                                            <i className="fa fa-lg text-info fa-plus-square-o" aria-hidden="true"></i>
                                                        </a>
                                                        <UncontrolledTooltip placement="bottom" target={"newInspection" + i}>Start Inspection (New)</UncontrolledTooltip>
                                                    </li>}
                                                </ul>
                                            </div >}
                                    </div >
                                </td >
                                <td>{reg.node.expirationDate} </td>
                                <td>
                                    <div className="registration-details-wrapper">
                                        <span className='left-box'>
                                            {this.state.openModal === "notesHistory" + i && <RegistrationNotesHistory registration={reg.node} noteType="GENERAL" title="Registration Notes History" objectId={reg.node} target={"notesHistory" + i} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} placement={"bottom"} />}
                                            <i className="fa fa-history fa-lg" aria-hidden="true" id={"notesHistory" + i} onClick={() => this.toggleModal("notesHistory" + i)}></i>
                                        </span>
                                        <span className='right-box'>
                                            {reg.node.notes ? reg.node.notes : "--"}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="car-details-wrapper">
                                        <span className='left-box'>
                                            {this.state.openModal === "registrationActivityModal" + i && <RegistrationActivityModal open={this.state.openModal === "registrationActivityModal" + i} handleClose={() => this.toggleModal("")} registrationId={reg.node.id} refetchQuery={this.props.refetchQuery} />}
                                            <i className="fa fa-history fa-lg" aria-hidden="true" onClick={() => this.toggleModal("registrationActivityModal" + i)}></i>
                                        </span>
                                        {reg.node.activeRegistration ?
                                            <span className='right-box' style={{ flex: 1 }}>
                                                {this.renderCarDetails(reg.node.activeRegistration, i)}
                                            </span> : <>
                                                {/* <span className='pl-3 left-box'>
                                                    {this.state.openModal === "InstallCarPopover" + i && <InstallCarPopover target={"InstallCarPopover" + i} registrationId={reg.node.id} open={this.state.openModal === "InstallCarPopover" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                    <i id={"InstallCarPopover" + i} className="fa fa-plus fa-lg text-warning" aria-hidden="true" onClick={() => this.toggleModal("InstallCarPopover" + i)}></i>
                                                </span> */}
                                                <span className='right-box'>
                                                    No Active Car Found!
                                                </span>
                                            </>}
                                    </div>
                                </td>
                            </tr >
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Registrations Found!"}</td>
                        </tr>
                        }
                        {
                            this.props.allRegistrations && this.props.loading ? <tr><td colSpan={10}><Progress animated color="info" value="100" /></td></tr> :
                                this.props.allRegistrations && this.props.allRegistrations.pageInfo && this.props.allRegistrations.pageInfo.hasNextPage &&
                                <tr>
                                    <td colSpan={10}><Button onClick={this.props.loadMoreEntries}>Show More <i className={"fa fa-caret-down"}></i></Button></td>
                                </tr>
                        }
                    </tbody >
                </Table >
            </Col ></Row ></CardBody ></Card >
        </>
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllOwnersQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data }) => {
        // Safe check to ensure data is defined
        if (!data) {
            console.error('AllOwnersQuery data is undefined')
            return { allOwners: [] } // Fallback or loading state
        }
        const { allOwners } = data
        return { allOwners }
    }
})(
    graphql(AllRegistrationsQuery, {
        options: ({ searchTerm, orderBy, registrationsFor, registrationTypeIn, isInstalled, isActive, carStageIn, inInspection }) => ({
            variables: { searchTerm, orderBy, registrationsFor, registrationTypeIn, isInstalled, isActive, carStageIn, inInspection, first: 30 },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }),
        props: ({ data }) => {
            // Safe check for AllRegistrationsQuery data
            if (!data) {
                console.error('AllRegistrationsQuery data is undefined')
                return { loading: true, allRegistrations: [], variables: {} } // Provide safe fallback values
            }

            const { loading, allRegistrations, fetchMore, variables } = data

            return {
                loading,
                allRegistrations,
                variables,
                refetchQuery: () => {
                    // Handle refetching logic with pagination
                    let currentLength = allRegistrations && allRegistrations.edges ? allRegistrations.edges.length : 30
                    return fetchMore({
                        query: AllRegistrationsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allRegistrations.edges
                            const pageInfo = fetchMoreResult.allRegistrations.pageInfo
                            return {
                                allRegistrations: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allRegistrations.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllRegistrationsQuery,
                        variables: {
                            cursor: allRegistrations.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allRegistrations.edges
                            const pageInfo = fetchMoreResult.allRegistrations.pageInfo
                            return {
                                allRegistrations: {
                                    edges: [...previousResult.allRegistrations.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allRegistrations.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    })(
        RegistrationsList
    )
)
