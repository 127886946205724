import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, Form, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap'
import withApolloClient from "../../withApolloClient"
const ChangeReturnMutation = gql`
mutation ChangeReturn ($input:ChangeReturnInput!){
    changeReturn(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`
const AllCarLocationsQuery = gql`
  query AllCarLocations{
    allCarLocations{
        edges{
            node{
                name
                id
                pk
            }
        }
    }
  }
`
const AllReturnReasons = gql`query allReturnReasons($reasonType: String){
    allReturnReasons(reasonType:$reasonType)
}`

class UpdateReturnInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false
        }
    }
    updateCarReturn = () => {
        console.log("here---")
        try {
            if (this.props.carReturn && this.props.carReturn.id) {
                this.setState({ loading: true })
                let input = this.state.input
                input["carReturnId"] = this.props.carReturn.id
                input["ignoreUnscheduledWarning"] = true
                this.props.apolloClient.mutate({
                    mutation: ChangeReturnMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.changeReturn && result.data.changeReturn.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                        this.setState({ input: {}, loading: false, error: null })
                    } else {
                        let errorMessage = result && result.data && result.data.changeReturn && result.data.changeReturn.errors && result.data.changeReturn.errors.length > 0 && result.data.changeReturn.errors[0].messages ? result.data.changeReturn.errors[0].messages.join("; ") : "An error has occured. Please retry or contact admin."
                        this.setState({ input: {}, loading: false, error: errorMessage })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: "An error has occured. Please retry or contact admin." })
                })
            } else {
            }
        } catch (err) {
            this.setState({ loading: false, error: "An error has occured. Please retry or contact admin." })
        }

    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        let input = this.state.input
        input[name] = value
        this.setState({ input: input })
    }
    render() {
        return (
            <div>
                <Popover placement={this.props.placement ? this.props.placement : "bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

                    <PopoverBody>
                        <Form>
                            <FormGroup>
                                <Label for={this.props.name}>{this.props.title}</Label>
                                {this.props.name === "location" ?
                                    this.props.allCarLocations && this.props.allCarLocations.edges.length > 0 ?
                                        <Input type="select" name="location" id="location" onChange={this.updateInput} placeholder="Select Location">
                                            <option value={""}>--Select Location--</option>
                                            {this.props.allCarLocations.edges.filter((location => location.node.name !== this.props.value)).map((location, i) =>
                                                <option key={i} value={location.node.pk}>{location.node.name}</option>
                                            )}
                                        </Input> :
                                        <Alert color="info">You need to add location options before changing car location</Alert>
                                    : this.props.name === "returnReason" ?
                                        <Input type="select" name="returnReason" id="returnReason" onChange={this.updateInput}>
                                            <option value="">-- Choose Reason --</option>
                                            {this.props.allReturnReasons && this.props.allReturnReasons.length > 0 && this.props.allReturnReasons.map((returnReason, i) =>
                                                <option key={i} value={returnReason}>{returnReason}</option>
                                            )}
                                        </Input>
                                        :
                                        <Input type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                                }
                            </FormGroup>
                            {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="submit" size="sm" onClick={this.updateCarReturn}>Submit</Button>}
                        </Form>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllReturnReasons, {
    options: ({ reasonType }) => ({
        variables: { reasonType },
        notifyOnNetworkStatusChange: true,
    }),
    props: ({ data: { allReturnReasons, variables } }) => ({
        allReturnReasons,
        variables,
    }),
})(
    graphql(AllCarLocationsQuery, {
        options: { fetchPolicy: 'cache-first' },
        props: ({ data: { loading, allCarLocations } }) => ({
            loading,
            allCarLocations,
        }),
    })(
        withApolloClient(UpdateReturnInfo) // Fixed withApolloClient typo
    )
)
