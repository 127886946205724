import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Alert, Button, Row, Col, Progress, FormGroup, Label, Input, FormText, Card, CardBody, InputGroupText } from 'reactstrap'
import { CreateUserMutation } from "../Mutations"
import { AllBranches, AllGroups, AllPermissions, AllTenants } from "../Queries"
import Select from "react-select"
import InputGroup from "reactstrap/lib/InputGroup"
import moment from 'moment-timezone'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branchAccessIds: [],
            selectedBranchIds: [],
            selectedBranchAccessList: [],
            selectedUser: "",
            memberType: "",
            firstName: "",
            lastName: "",
            email: "",
            userIsActive: true,
            userIsStaff: true,
            selectedGroupIds: [],
            selectedPermissionIds: [],
            selectedTenant: "",
            openModal: "",
            usernameVerification: "",
            passwordVerification: "",
            success: false,
            loading: false,
            showPassword: false,
            error: null,
        }
    }

    handleSubmit = () => {
        const { firstName, lastName, email, userIsActive, userIsStaff, branchAccessIds, memberType, selectedTenant, username, password, cpassword, selectedGroupIds, selectedPermissionIds, selectedBranchIds } = this.state
        if (branchAccessIds && selectedBranchIds && memberType && selectedTenant && username && password && cpassword) {
            try {
                this.setState({ loading: true })
                const input = {
                    firstName: firstName || '',
                    lastName: lastName || '',
                    email: email || '',
                    isStaff: userIsStaff,
                    isActive: userIsActive,
                    memberType: memberType.toLowerCase() || 'internal',
                    branchIds: branchAccessIds || null,
                    groups: selectedGroupIds || [],
                    userPermissions: selectedPermissionIds || [],
                    selectedBranches: selectedBranchIds,
                    username: username,
                    password: password,
                    tenant: selectedTenant,
                }
                this.props.apolloClient
                    .mutate({
                        mutation: CreateUserMutation,
                        variables: { input },
                    })
                    .then((result) => {
                        if (result.data && result.data.createUser && result.data.createUser.ok) {
                            this.setState({ loading: false, error: null, success: false })
                            window.location = '/organization/members/'
                        } else {
                            let errorMessage = 'An error occurred, could not complete request.'
                            if (result.data && result.data.createUser && result.data.createUser.errors && result.data.createUser.errors[0] && result.data.createUser.errors[0].messages[0])
                                errorMessage = result.data.createUser.errors[0].messages[0]
                            this.setState({ loading: false, error: errorMessage, success: false })
                        }
                    })
                    .catch((error) => {
                        let errorMessage = 'An error has occurred ' + error
                        this.setState({ error: errorMessage, loading: false, success: false })
                    })
            } catch (error) {
                let errorMessage = 'An error has occurred ' + error
                this.setState({ error: errorMessage, loading: false, success: false })
            }
        } else {
            this.setState({ error: 'Error: Required fields are missing', success: false })
        }
    };


    updateInput = (e) => {
        const { name, value } = e.target
        let error = ""
        let isVerified = false

        if (name === "username") {
            const usernameRegex = /^[\w.@+-]{1,150}$/
            isVerified = usernameRegex.test(value)
            error = isVerified ? "" : "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
        } else if (name === "password" || (this.state.showPassword && name === "text")) {
            const passwordRegex = /(?=.{8,})(?!.*\s)(?!.*[a-zA-Z]{4,}).*/
            isVerified = passwordRegex.test(value)
            error = isVerified ? "" : "Invalid Password, Please follow the mentioned instructions for adding a strong password"
        } else if (e.target.type === "select-multiple") {
            let selectedBranchAccessArr = []
            const options = Array.from(e.target.options).filter((opt) => opt.selected).map((opt) => opt.value)
            const optionsLabel = Array.from(e.target.options).filter((opt) => opt.selected).map((opt) => opt.label)
            this.setState({ [name]: options })
            if (name === "branchAccessIds") {
                options.map((item, i) => {
                    selectedBranchAccessArr.push({ value: item, label: optionsLabel[i] })
                })
                this.setState({ selectedBranchAccessList: selectedBranchAccessArr })
            }
            return
        } else if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            value = value === "true"
        }
        if ((name === "cpassword" || (this.state.showPassword && name === "text")) && value !== this.state.password) {
            error = "Password Not Matched"
        }

        this.setState({ [name]: value, [`${name}Verification`]: isVerified ? "verified" : "not-verified", error })
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <br />
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.loading || this.props.loading ? <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row> : <>

                        <Row><Col xs={12}><Label ><b><h3 style={{ color: "#007BFF" }}>USER CREDENTIALS</h3></b></Label></Col></Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><b>Username*</b></Label><br />
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="username"
                                        placeholder="Enter Username"
                                        maxLength={10}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><b>Password*</b></Label><br />
                                    <InputGroup>
                                        <Input
                                            type={this.state.showPassword ? "text" : "password"}
                                            onChange={this.updateInput}
                                            name="password"
                                            placeholder="Enter Password"
                                        />
                                        <InputGroupText onClick={() => this.setState({ showPassword: !this.state.showPassword })} ><Button color="primary"><i className={this.state.showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true" size={10}></i></Button></InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><b>Confirm Password*</b></Label><br />
                                    <InputGroup>
                                        <Input
                                            type={this.state.showPassword ? "text" : "password"}
                                            onChange={this.updateInput}
                                            name="cpassword"
                                            placeholder="Confirm Password"
                                            disabled={this.state.passwordVerification !== "verified"}
                                        />
                                        <InputGroupText onClick={() => this.setState({ showPassword: !this.state.showPassword })} ><Button color="primary"><i className={this.state.showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true" size={10}></i></Button></InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="pt-0">
                                <FormGroup>
                                    <FormText >
                                        <b>Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.</b>
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <FormText >
                                        Your password can’t be too similar to your other personal information.<br />
                                        Your password must contain at least 8 characters.<br />
                                        Your password can’t be a commonly used password.<br />
                                        Your password can’t be entirely numeric.<br />
                                    </FormText> :
                                    <FormText>
                                        Raw passwords are not stored, so there is no way to see this user’s password
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row><Col xs={12}><Label ><b><h3 style={{ color: "#007BFF" }}>BASIC INFO</h3></b></Label></Col></Row>
                        <Row>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label><b>First Name</b></Label>
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="firstName"
                                        placeholder="Enter First Name"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label><b>Last Name</b></Label>
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="lastName"
                                        placeholder="Enter Last Name"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label><b>Email</b></Label>
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="email"
                                        placeholder="Enter Email"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row><Col xs={12}><Label ><b><h3 style={{ color: "#007BFF" }}>USER ACCESS</h3></b></Label></Col></Row>
                        <Row>
                            <Col xs={4} className="pt-2">
                                <Label><b>Designates whether this user should be treated as active.</b></Label><br />
                                <span>Active</span>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="userIsActive"
                                        id="userIsActive"
                                        checked={!this.state.userIsActive}
                                        onChange={() => this.setState({ userIsActive: !this.state.userIsActive })}
                                    />
                                    <label className="toggle-switch-label" htmlFor="userIsActive">
                                        <span className="toggle-switch-inner" />
                                        <span className="toggle-switch-switch" />
                                    </label>
                                </div>
                                <span>Inactive</span>
                            </Col>
                            <Col xs={4} className="pt-2">
                                <Label><b>Designates whether the user can log into this admin site.</b></Label><br />
                                <span>Is Staff</span>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="userIsStaff"
                                        id="userIsStaff"
                                        checked={!this.state.userIsStaff}
                                        onChange={() => this.setState({ userIsStaff: !this.state.userIsStaff })}
                                    />
                                    <label className="toggle-switch-label" htmlFor="userIsStaff">
                                        <span className="toggle-switch-inner" />
                                        <span className="toggle-switch-switch" />
                                    </label>
                                </div>
                                <span>Not Staff</span>
                            </Col>
                            <Col xs={4}></Col>
                        </Row>
                        <br /><br />
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label className="d-block mb-2" ><b><h3 style={{ color: "#007BFF" }}>GROUPS</h3></b></Label>
                                    <Input
                                        type="select"
                                        name="selectedGroupIds"
                                        id="exampleSelectMulti"
                                        onChange={this.updateInput}
                                        multiple
                                        style={{ height: "250px",width:"100%"}}
                                    >
                                        {this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.map((item) =>
                                            <option key={item && item.node && item.node.id} value={item && item.node && item.node.id}>{item && item.node && item.node.name}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label ><b><h3 style={{ color: "#007BFF" }}>PERMISSIONS</h3></b></Label>
                                    <Input
                                        type="select"
                                        name="selectedPermissionIds"
                                        onChange={this.updateInput}
                                        multiple
                                        style={{ height: "250px",width:"100%"}}
                                    >
                                        {this.props.allPermissions && this.props.allPermissions.edges && this.props.allPermissions.edges.length > 0 && this.props.allPermissions.edges.map((item) =>
                                            <option key={item && item.node && item.node.id} value={item && item.node && item.node.id}>{item && item.node && item.node.name && item.node.contentType && item.node.contentType.model + " - " + item.node.name}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}><Label ><b><h3 style={{ color: "#007BFF" }}>MEMBER SETTINGS</h3></b></Label></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Tenant</Label>
                                            {this.props.allTenants && this.props.allTenants.edges && this.props.allTenants.edges.length > 0 && <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                // isLoading={!this.props.allTenants || !this.props.allTenants.edges || !this.props.allTenants.edges.length > 0}
                                                options={this.props.allTenants.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                                placeholder={"Select Tenant"}
                                                onChange={(tenant) => this.setState({ selectedTenant: tenant.value })}
                                            />}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>User Type</Label>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                options={[{ value: "internal", label: "INTERNAL USER" }, { value: "external", label: "EXTERNAL USER" }]}
                                                placeholder="Select User Type"
                                                onChange={(type) => this.setState({ memberType: type.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label className="d-block mb-2">Branch Access</Label>
                                    <Input
                                        type="select"
                                        name="branchAccessIds"
                                        onChange={this.updateInput}
                                        multiple
                                        style={{ height: "250px", width: "100%" }}
                                    >
                                        {this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map((item) =>
                                            <option key={item && item.node && item.node.id} value={item && item.node && item.node.id}>{item && item.node && item.node.name}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col >
                                <FormGroup>
                                    <Label className="d-block mb-2">Selected Branches</Label>
                                    <Input
                                        type="select"
                                        name="selectedBranchIds"
                                        onChange={this.updateInput}
                                        multiple
                                        style={{ height: "250px", width: "100%" }}
                                        value={this.state.selectedBranchIds}
                                    >
                                        {this.state.selectedBranchAccessList && this.state.selectedBranchAccessList.map((item) =>
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="float-right p-10">
                            <Col>
                                <Button
                                    color="primary"
                                    onClick={() => this.handleSubmit()}
                                    disabled={!this.state.username || !this.state.password || !this.state.cpassword || this.state.usernameVerification !== "verified" || this.state.passwordVerification !== "verified"}
                                >
                                    Create User
                                </Button>
                            </Col>
                        </Row>
                    </>}
                </CardBody>
            </Card>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllBranches, {
    props: ({ data: { allBranches } }) => ({ allBranches }),
})(
    graphql(AllTenants, {
        props: ({ data: { allTenants } }) => ({ allTenants }),
    })(
        graphql(AllPermissions, {
            props: ({ data: { allPermissions, loading } }) => ({ allPermissions }),
        })(
            graphql(AllGroups, {
                props: ({ data: { allGroups } }) => ({ allGroups }),
            })(withApolloClient(CreateUser))
        )
    )
);

