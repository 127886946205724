import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Alert, Button, Row, Col, Progress, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap'
import gql from 'graphql-tag'
import CustomerHandleModal from "./CustomerHandleModal"
import "./CustomerHandleDetailModal.css"
import toTitleCase from "../Functions/toTitleCase"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const AllCustomerHandles = gql`query AllCustomerHandles($customerId: ID!, $handleType:[String], $orderBy: [String]){
    allCustomerHandles(customerId: $customerId, handleType:$handleType, orderBy:$orderBy){
        edges{
            node{
                id
                pk
                type
                title
                handle
                isPrimary
                isArchived
                isDuplicate
            }
        }
    }
}`

class CustomerHandleDetailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            error: null,
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    refetchQuery = () => {
        if (this.props.refetchAllCustomerHandleQuery)
            this.props.refetchAllCustomerHandleQuery()
        if (this.props.refetchQuery)
            this.props.refetchQuery()
    }
    render() {
        return (
            <Modal className="customer-handle-modal" isOpen={this.props.open}>
                <ModalHeader style={{ textTransform: "uppercase" }}>{this.props.handleType && this.props.handleType.includes("email") ? "Email Handles" : "Phone Handles"}</ModalHeader>
                <ModalBody>
                    {this.props.loading ? <Progress animated color="info" value={100} /> :
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            <Col xs={12}>
                                <Row style={{ textTransform: "uppercase", fontWeight: "400" }}>
                                    <Col xs={6}><Label>{toTitleCase(this.props.handleType)}</Label></Col>
                                    <Col xs={2}><Label>Is Primary</Label></Col>
                                    <Col xs={2}><Label>Is Archived</Label></Col>
                                    <Col xs={2}><Label>Action</Label></Col>
                                </Row>
                                {this.props.allCustomerHandles && this.props.allCustomerHandles.edges && this.props.allCustomerHandles.edges.length > 0 && this.props.allCustomerHandles.edges.length > 0 ?
                                    this.props.allCustomerHandles.edges.map((item, handleCount) =>
                                        <Row key={item.node.id}>
                                            <Col xs={6}>
                                                <a href={"mailto:" + item.node.handle}>{item.node.handle ? item.node.handle : "--"}</a>{item.node.title && ` (${item.node.title})`}
                                                {item.node.isDuplicate && <>
                                                    &nbsp;&nbsp;<i id={"handleDulicate" + handleCount} className="fa fa-exclamation-triangle text-danger" aria-hidden="true" />
                                                    <UncontrolledTooltip target={"handleDulicate" + handleCount} placement="top">Duplicate Email</UncontrolledTooltip>
                                                </>}
                                            </Col>
                                            <Col xs={2}>
                                                <p className="columnContent">
                                                    <span>
                                                        <i style={{ color: item.node.isPrimary === true ? "green" : "red" }} className={item.node.isPrimary === true ? "fa fa-toggle-on switch" : "fa fa-toggle-off switch"} aria-hidden="true"></i>
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col xs={2}>
                                                <p className="columnContent">
                                                    <span>
                                                        <i style={{ color: item.node.isArchived === true ? "red" : "green" }} className={item.node.isArchived === true ? "fa fa-toggle-on switch" : "fa fa-toggle-off switch"} aria-hidden="true"></i>
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col xs={2}>
                                                <i className={"fa fa-lg fa-pencil-square"} aria-hidden="true" onClick={() => this.toggleModal("editHandle" + item.node.pk)} ></i>
                                                {this.state.openModal === "editHandle" + item.node.pk &&
                                                    <CustomerHandleModal
                                                        handleType={this.props.handleType && this.props.handleType.includes("email") ? "email" : "phone"}
                                                        customerHandleId={item.node.id}
                                                        isUpdate={true}
                                                        open={this.state.openModal === "editHandle" + item.node.pk}
                                                        handleClose={() => this.toggleModal("")}
                                                        refetchQuery={this.refetchQuery}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    ) :
                                    <Row> <Col>{this.props.handleType && this.props.handleType.includes("email") ? "No email handle found" : "No phone handle found"}</Col></Row>
                                }
                            </Col>
                        </Row>}
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        <Button className="customer-handle-modal-secondary-btn" size="sm" onClick={this.props.handleClose}> CLOSE </Button> &nbsp;&nbsp;
                        <Button className="customer-handle-modal-primary-btn" size="sm" onClick={() => this.toggleModal("addHandle")}><i className="fa fa-plus" aria-hidden="true" /> {this.props.handleType && this.props.handleType.includes("email") ? "ADD EMAIL" : "ADD PHONE"} </Button>
                    </div>
                    {this.state.openModal === "addHandle" &&
                        <CustomerHandleModal
                            handleType={this.props.handleType && this.props.handleType.includes("email") ? "email" : "phone"}
                            customerId={this.props.customerId}
                            isUpdate={false}
                            open={this.state.openModal === "addHandle"}
                            handleClose={() => this.toggleModal("")}
                            refetchQuery={this.refetchQuery}
                            branchCountry={this.props.branchCountry}
                        />
                    }
                </ModalFooter>
            </Modal>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCustomerHandles, {
    options: ({ customerId, handleType }) => ({ variables: { customerId: customerId, handleType: handleType, orderBy: ["-isPrimary"] }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
    props: ({ data: { allCustomerHandles, loading, refetch, variables } }) => ({
        allCustomerHandles, loading, variables,
        refetchAllCustomerHandleQuery: () => {
            return refetch({
                query: AllCustomerHandles,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { allCustomerHandles: fetchMoreResult.allCustomerHandles }
                },
            })
        },
    })
})(withApolloClient(CustomerHandleDetailModal))
