import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import withApolloClient from "../withApolloClient"

const removeDriverInsuranceMutation = gql`
mutation removeDriverInsuranceMutation($input: RemoveDriverInsuranceMutationInput!){
    removeDriverInsurance(input:$input){
    ok
    errors{
        messages
    }
}
} `

const AllRemovedReasonTypes = gql`
    query AllRemovedReasonTypes{
        allRemovedReasonTypes(isActive:true){
            edges{
                node{
                    reasonName
                    id
                }
            }
      }
    }
`

class RemoveInsurance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            insuranceRemoved: false,
            openModal: "",
            reason: null
        }
    }
    handleClose = (e) => {
        this.props.handleClose()
    };
    removeInsurance = () => {
        try {
            this.setState({ loading: true })
            let input = {
                insuranceStatus: this.props.insurance.id,
                reason: this.state.reason
            }
            //Iagami - Code upgradation React 18
            this.props.apolloClient.mutate({
                mutation: removeDriverInsuranceMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.removeDriverInsurance && result.data.removeDriverInsurance.ok) {
                    this.setState({ insuranceRemoved: true, error: null, loading: false })
                    this.props.refetchDriverQuery()
                    this.props.handleClose()
                } else {
                    this.setState({ loading: false, insuranceRemoved: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, insuranceRemoved: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, insuranceRemoved: false, })
        }
    };
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    //Iagami - Code upgradation React 18
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Modal className="removeInsurance" isOpen={this.props.open}>
                    <ModalHeader>Remove Insurance</ModalHeader>
                    <ModalBody>
                        {this.state.error && !this.state.insuranceRemoved && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.state.insuranceRemoved && !this.state.error && <Row><Col xs={12}><Alert color="success">Insurance removed successfully!</Alert></Col></Row>}
                        {!this.state.insuranceRemoved &&
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="insuranceReason">Why are you removing this insurance?</Label><br />
                                        <Input style={{ padding: "5px" }} type="select" name="reason" id="reason" onChange={this.updateInput} placeholder="Select Reason">
                                            <option key={-1} value={null}>Choose Reason</option>
                                            {this.props.allRemovedReasonTypes && this.props.allRemovedReasonTypes.edges && this.props.allRemovedReasonTypes.edges.map((reason, i) =>
                                                <option key={i} value={reason.node.id} title={reason.node.reasonName}>{reason.node.reasonName}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>}
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={this.removeInsurance} disabled={!this.state.reason || this.state.insuranceRemoved || this.state.loading}>Remove Insurance</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllRemovedReasonTypes, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { loading, allRemovedReasonTypes } }) => ({
        loading,
        allRemovedReasonTypes,
    }),
})(withApolloClient(RemoveInsurance))
