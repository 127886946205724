import { Divider } from "@material-ui/core"
import React, { useState, useEffect } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import {
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Form, Input, FormGroup, Button, Label,
    Card, CardHeader, CardBody, CardText, CardFooter,
    Row, Col
} from "reactstrap"
import { AllTaskCommentReactionsQuery, RepsQuery, TaskQuery } from "./Queries"
import moment from 'moment-timezone'
import {
    CreateTaskCommentMutation,
    CreateTaskCommentReactionMutation,
    DeleteTaskCommentReactionMutation,
    CreateTaskChecklistMutation,
    UpdateTaskChecklistMutation,
} from "./Mutations"
import { MentionsInput, Mention } from 'react-mentions'
import FormText from "reactstrap/lib/FormText"
import ReactMarkdown from "react-markdown"
import { useRef } from "react"
import { Link } from "react-router-dom"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

function TaskDetailsSidePanel({ task, taskLoading, handleClose, ...props }) {
    const [reactedToCommentIds, setReactedToCommentIds] = useState([])
    const [activeTab, setActiveTab] = useState("#Updates")
    const [newChecklistTitle, setNewChecklistTitle] = useState("")
    useEffect(() => {
        if (task && task.id) { loadTaskCommentReactions() }
    }, [task])

    const taskId = props && props.params && props.params.taskId ? props.params.taskId : null
    // if (!taskId) {
    //     return null
    // }

    const loadTaskCommentReactions = () => {
        // Load task comment reactions
        try {
            // const { apolloClient } = this.props
            // if (!props.apolloClient || !task || !task.id) {
            //     return
            // }

            props.apolloClient.query({
                query: AllTaskCommentReactionsQuery,
                variables: { taskId: task.id },
            }).then((result) => {
                if (!result) { throw new Error("Error loading task comment reactions") }
                if (result.data && result.data.allTaskCommentReactions && result.data.allTaskCommentReactions.edges) {
                    setReactedToCommentIds(result.data.allTaskCommentReactions.edges.map(reaction => reaction.node.comment.id))
                } else {
                    console.log("Error loading task comment reactions", result.data.allTaskCommentReactions)
                }
            }).catch((error) => {
                console.log("Error loading task comment reactions", error)
            })
        } catch (error) {
            console.log("Error loading task comment reactions", error)
        }
    }

    const toggleChecklistItem = (checklistId, value) => {
        try {
            // const { apolloClient } = this.props
            // if (!props.apolloClient || !task || !task.id) {
            //     return
            // }

            const input = {
                checklistId: checklistId, isCompleted: value,
            }

            props.apolloClient.mutate({
                mutation: UpdateTaskChecklistMutation,
                variables: { input },
                refetchQueries: ["Task"],
            }).then((result) => {
                if (!result || !result.data || !result.data.updateTaskChecklist || !result.data.updateTaskChecklist.ok) { throw new Error("Error updating checklist item") }
            }).catch((error) => {
                console.log("Error updating checklist item", error)
            })
        } catch (error) {
            console.log("Error updating checklist item", error)
        }
    }

    const createChecklist = () => {
        try {
            // const { apolloClient } = this.props
            // if (!props.apolloClient || !task || !task.id) {
            //     return
            // }

            const input = {
                taskId: task.id,
                title: newChecklistTitle,
            }

            props.apolloClient.mutate({
                mutation: CreateTaskChecklistMutation,
                variables: { input },
                refetchQueries: ["Task"],
            }).then((result) => {
                if (!result || !result.data || !result.data.createTaskChecklist || !result.data.createTaskChecklist.ok) { throw new Error("Error creating checklist item") }
                setNewChecklistTitle("")
            }).catch((error) => {
                console.log("Error creating checklist item 1", error)
            })
        } catch (error) {
            console.log("Error creating checklist item 2", error)
        }
    }

    if (taskLoading && !task) {
        return (
            <div>
                <div className="TaskDetailsSidePanelContainer" >
                    <div className={`TaskDetailsSidePanel show}`}>
                        <Link to="/boards/tasks/" className="btn btn-outline-dark" onClick={handleClose}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </Link>
                        <h3 className="my-2"> </h3>
                        <p className="my-2"> </p>
                        <Nav tabs>
                            <NavItem><NavLink onClick={() => setActiveTab("#Updates")} active={activeTab === "#Updates"} style={{ cursor: "pointer" }}>Updates</NavLink></NavItem>
                            <NavItem><NavLink onClick={() => setActiveTab("#Activity")} active={activeTab === "#Activity"} style={{ cursor: "pointer" }}>Activity Log</NavLink></NavItem>
                            {/* <NavItem>
                                <NavLink href="#">Files</NavLink>
                            </NavItem> */}
                        </Nav>
                        <TabContent activeTab={"#Updates"}>
                            <TabPane tabId="#Updates">
                                <NewCommentForm {...props} />
                                <Divider className="my-4" />
                            </TabPane>
                        </TabContent>

                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className="TaskDetailsSidePanelContainer" >
                <div className={`TaskDetailsSidePanel show`}>
                    <Link to="/boards/tasks/" className="btn btn-outline-dark" onClick={handleClose}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </Link>
                    <h3 className="my-2">{task?.title || 'Untitled'}</h3>
                    <p className="my-2">{task?.description || ''}</p>
                    <p className="text-muted mb-2 text-xs">
                        <small>
                            In {task?.board ? task?.board.title : 'Board'}
                            {task?.dueDate && <span>  ·  Due date: {moment(task?.dueDate).tz("America/New_York").format('dddd, MMMM Do YYYY')}</span>}
                            {task?.createdBy && <span>  ·  Created by: {task?.createdBy.firstName ? task?.createdBy.firstName + " " + task?.createdBy.lastName : task?.createdBy.username}</span>}
                            {/* {task?.driver && task?.driver.name ? `· Driver: ${task?.driver.name}` : ''} */}
                            {task?.driver && <span>  ·  Driver: {task?.driver.name} <Link color="primary" to={'/driver/' + task?.driver.id} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></span>}
                            {task?.vehicle && <span>  ·  Car: {task?.vehicle.year} {task?.vehicle.make} {task?.vehicle.model} - {task?.vehicle.vin} <Link color="primary" to={'/car/' + task?.vehicle.id} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></span>}
                        </small>
                    </p>
                    <Nav tabs>
                        <NavItem><NavLink onClick={() => setActiveTab("#Updates")} active={activeTab === "#Updates"} style={{ cursor: "pointer" }}>Updates</NavLink></NavItem>
                        <NavItem><NavLink onClick={() => setActiveTab("#Activity")} active={activeTab === "#Activity"} style={{ cursor: "pointer" }}>Activity Log</NavLink></NavItem>
                        <NavItem>
                            <NavLink onClick={() => setActiveTab("#Checklist")} active={activeTab === "#Checklist"} style={{ cursor: "pointer" }}>
                                Checklist
                                {task?.taskchecklistSet && task?.taskchecklistSet.edges && task?.taskchecklistSet.edges.filter(
                                    checklist => !checklist.node.completedOn).length > 0 &&
                                    <span className="badge badge-pill badge-danger ml-2">
                                        {task?.taskchecklistSet && task?.taskchecklistSet.edges && task?.taskchecklistSet.edges.filter(checklist => !checklist.node.completedOn).length}
                                    </span>
                                }
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>

                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="#Updates">
                            <NewCommentForm commentId={task?.id} {...props} />
                            <Divider className="my-4" />
                            {task?.taskcommentSet && task?.taskcommentSet.edges && task?.taskcommentSet.edges.map((comment, index) => {
                                return <CommentCard
                                    key={index}
                                    comment={comment.node}
                                    loadTaskCommentReactions={loadTaskCommentReactions}
                                    reacted={reactedToCommentIds.includes(comment.node.id)}
                                    {...props} />
                            })}
                        </TabPane>
                        <TabPane tabId="#Activity">
                            <Divider className="my-4" />
                            <ul className="list-unstyled">
                                {task?.history && task?.history.map((item, index) => (
                                    <li key={index} className="mb-2">
                                        <Row className="align-items-center">
                                            <Col className="text-left">
                                                <p className="mb-0">{item.date ? moment(item.date).tz("America/New_York").format('lll') : ''}</p>
                                            </Col>
                                            <Col className="text-left">
                                                <p className="mb-0">{item.comment}</p>
                                            </Col>
                                            <Col className="text-left">
                                                <p className="mb-0">{item.user}</p>
                                            </Col>

                                        </Row>
                                        <hr className="my-1" />
                                    </li>
                                ))}
                            </ul>
                        </TabPane>
                        <TabPane tabId="#Checklist">
                            <Divider className="my-4" />
                            <ul className="list-unstyled">
                                {task && task?.taskchecklistSet && task?.taskchecklistSet.edges && task?.taskchecklistSet.edges.length > 0 &&
                                    task?.taskchecklistSet.edges.map((checklist, index) => (
                                        checklist = checklist.node,
                                        <li key={index} className="mb-2">
                                            <Row className="align-items-center">
                                                <Col className="text-left">
                                                    <FormGroup check>
                                                        <Input
                                                            id={checklist.id}
                                                            type="checkbox"
                                                            checked={checklist.completedOn}
                                                            onChange={() => toggleChecklistItem(checklist.id, !checklist.completedOn)}
                                                        />
                                                        {' '}
                                                        <Label check for={checklist.id}>
                                                            <span style={{ textDecoration: checklist.completedOn ? "line-through" : "none" }}>{checklist.title}</span>
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </li>
                                    ))}
                                {/* New checklist input text with submit button on the right */}
                                <li className="mt-4">
                                    <Row className="align-items-center">
                                        <Col className="text-left">
                                            <FormGroup>
                                                <Input
                                                    value={newChecklistTitle}
                                                    onChange={(e) => setNewChecklistTitle(e.target.value)}
                                                    type="text" name="checklist"
                                                    id="checklist"
                                                    placeholder="Add a new checklist item"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="text-right">
                                            <Button onClick={createChecklist} color="primary">Add</Button>
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </TabPane>
                    </TabContent>

                </div>
            </div>
        </div>
    )
}


export function CommentCard({ comment, loadTaskCommentReactions, reacted, ...props }) {
    if (!comment) {
        return null
    }

    const createTaskCommentReaction = () => {
        try {
            // const { apolloClient } = this.props
            if (!props.apolloClient) {
                throw new Error("GQL Client not found")
            }

            const input = {
                commentId: comment.id,
                reaction: "LIKE"
            }

            props.apolloClient.mutate({
                mutation: CreateTaskCommentReactionMutation,
                refetchQueries: ["Task"],
                variables: { input },
            }).then((result) => {
                if (!result) {
                    throw new Error("Error creating task update")
                }
                if (result.data && result.data.createTaskCommentReaction && result.data.createTaskCommentReaction.ok) {
                    loadTaskCommentReactions()
                } else {
                    console.log("Error creating task update", result.data.createTaskCommentReaction)
                }
            }).catch((error) => {
                console.log("Error creating task update", error)
            })
        } catch (error) {
            console.log("Error creating task update", error)
        }
    }

    const deleteTaskCommentReaction = () => {
        try {
            // const { apolloClient } = this.props
            if (!props.apolloClient) {
                throw new Error("GQL Client not found")
            }

            const input = {
                commentId: comment.id,
            }

            props.apolloClient.mutate({
                mutation: DeleteTaskCommentReactionMutation,
                refetchQueries: ["Task"],
                variables: { input },
            }).then((result) => {
                if (!result) {
                    throw new Error("Error creating task update")
                }
                if (result.data && result.data.deleteTaskCommentReaction && result.data.deleteTaskCommentReaction.ok) {
                    loadTaskCommentReactions()
                } else {
                    console.log("Error creating task update", result.data.deleteTaskCommentReaction)
                }
            }).catch((error) => {
                console.log("Error creating task update", error)
            })
        } catch (error) {
            console.log("Error creating task update", error)
        }
    }

    return (
        <Card className="my-4 CommentCard">
            <CardHeader>
                <div className="d-flex justify-content-between">
                    <div><span className="text-secondary">{comment.createdBy ? comment.createdBy.firstName ? comment.createdBy.firstName + " " + comment.createdBy.lastName : comment.createdBy.username : '-'}</span></div>
                    <div>{comment.dateAdded ? moment(comment.dateAdded).tz("America/New_York").format('lll') : ''}</div>
                </div>
            </CardHeader>
            <CardBody>
                <CardText className="font-">
                    {comment.comment ?
                        <ReactMarkdown
                            source={comment.comment}
                            transformLinkUri={({ uri }) => { return "#" }}
                        /> : ''
                    }
                </CardText>
                {comment.taskcommentattachmentSet && comment.taskcommentattachmentSet.edges && comment.taskcommentattachmentSet.edges && comment.taskcommentattachmentSet.edges.length > 0 &&
                    <div>
                        <Divider className="my-4" />
                        <ul>
                            {comment.taskcommentattachmentSet.edges.map((item, index) => {
                                let attachment = item.node
                                return <li key={index}>
                                    <a href={attachment.url} target="_blank" rel="noreferrer">
                                        {attachment.name}<br />
                                        {/* If image show image */}
                                        {["png", "jpeg", "jpg", "tiff", "webp", "gif"].includes(attachment.extension) &&
                                            <img style={{ maxWidth: "300px" }} src={attachment.url} alt={attachment.name} />}
                                        {["mp4"].includes(attachment.extension) &&
                                            <video style={{ maxWidth: "300px" }} src={attachment.url} alt={attachment.name} controls />}
                                        {["mp3"].includes(attachment.extension) &&
                                            <audio style={{ maxWidth: "300px" }} src={attachment.url} alt={attachment.name} controls />}
                                        {["pdf"].includes(attachment.extension) &&
                                            <iframe style={{ maxWidth: "300px" }} src={attachment.url} alt={attachment.name} title={attachment.url} />}
                                    </a>

                                </li>
                            })}
                        </ul>
                    </div>
                }

            </CardBody>
            <CardFooter>
                {/* Like, reply buttons  */}
                <div className="d-flex justify-content-between">
                    <div>
                        <div
                            onClick={() => reacted ? deleteTaskCommentReaction() : createTaskCommentReaction()}
                            className={`d-flex justify-content-between pointer`} role="button">
                            <span className="like">
                                <i className={`fa fa-thumbs-up ${reacted ? "liked" : ""}`} aria-hidden="true"></i>&nbsp;
                                {comment.reactionsCount && comment.reactionsCount > 0 ? comment.reactionsCount : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </CardFooter>
        </Card>
    )
}

export function NewCommentForm({ commentId, ...props }) {
    const [commentBody, setCommentBody] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])

    let inp = useRef()
    useEffect(() => {
        if (!inp && !inp.current) return
        inp.current.focus()
        return () => inp = null
    })

    // Submit when user presses Shift + Enter
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && e.shiftKey) {
                createTaskComment(e)
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [commentBody])

    // Find users with RepsQuery
    const searchReps = async (search, callBack) => {
        try {
            // const { apolloClient } = this.props
            if (!props.apolloClient) {
                throw new Error("GQL Client not found")
            }

            props.apolloClient.query({
                query: RepsQuery,
                variables: { searchTerm: search },
            }).then((result) => {
                if (!result) {
                    throw new Error("Error loading reps")
                }
                if (result.data && result.data.allReps && result.data.allReps.edges) {
                    let reps = result.data.allReps.edges.map(rep => { return { id: rep.node.id, display: rep.node.firstName ? rep.node.firstName + " " + rep.node.lastName : rep.node.username } })
                    callBack(reps)
                } else {
                    console.log("Error loading reps", result.data.allReps)
                }
            }).catch((error) => {
                console.log("Error loading reps", error)
            })
        } catch (error) {
            console.log("Error loading reps", error)
        }
    }

    // const handleFileChange = (e) => {



    const createTaskComment = async (e) => {
        e.preventDefault()
        try {
            // const { apolloClient } = this.props
            if (!props.apolloClient || !commentId) {
                throw new Error("GQL Client not found")
            }
            if (!commentBody || commentBody.length < 5) {
                setError("Comment is required or too short")
                return
            }
            setLoading(true)
            let filesInBase64 = [] // {name: "", type: "", data: ""}
            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                // Convert file to base64
                const reader = new FileReader()
                reader.readAsDataURL(file)
                await new Promise((resolve, reject) => {
                    reader.onload = () => {
                        filesInBase64.push({ name: file.name, type: file.type, data: reader.result })
                        resolve()
                    }
                    reader.onerror = error => reject(error)
                })
            }

            const input = {
                taskId: commentId,
                comment: commentBody,
                files: filesInBase64
            }

            props.apolloClient.mutate({
                mutation: CreateTaskCommentMutation,
                refetchQueries: ["Task"],
                variables: { input },
            }).then((result) => {
                setLoading(false)
                if (!result) {
                    throw new Error("Error creating task update")
                }
                if (result.data && result.data.createTaskComment && result.data.createTaskComment.ok) {
                    setError("")
                    setCommentBody("")
                    setFiles([])

                } else {
                    const errorMessage = result.data && result.data.createTaskComment && result.data.createTaskComment.errors && result.data.createTaskComment.errors.messages ? result.data.createTaskComment.errors.messages[0] : "Unknown Error"
                    throw new Error(errorMessage)
                }
            }).catch((error) => {
                setError("Error creating task update" + error.toString())
                setLoading(false)
            })
        } catch (error) {
            console.log("Error creating task update", error)
            setError("Error creating task update" + error.toString())
            setLoading(false)
        }
    }

    return (
        <Form className="NewCommentForm" name="NewCommentForm" onSubmit={createTaskComment} >
            <FormGroup className="NewCommentTextAreaFormGroup">
                <Label for="exampleText">  Write an update</Label>
                <div className="MentionsInputContainer">
                    <MentionsInput value={commentBody} onChange={(e) => setCommentBody(e.target.value)} className="text-area" inputRef={inp}>
                        <Mention
                            trigger="@"
                            data={(search, callBack) => searchReps(search, callBack)}
                            displayTransform={(id, display) => `@${display}`}
                            appendSpaceOnAdd={true}
                            renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                <div className="user-suggestion">{highlightedDisplay}</div>
                            )}
                            on
                        />
                    </MentionsInput>
                </div>
                <FormText>use @ to mention someone</FormText>
            </FormGroup>
            {/* Render files as confirmation */}
            <div>
                {files && files.length > 0 && <div>
                    <h6>Files:</h6>
                    <ul>
                        {files.map((file, index) => (
                            <li key={index} className="mb-2 ">
                                <span>{file.name}</span><br />
                                {/* if image, show, else show icon */}
                                {file.type.includes("image") && <img style={{ maxWidth: "200px" }} src={URL.createObjectURL(file)} alt={file.name} />}
                                {/* if video or audio, play */}
                                {file.type.includes("video") && <video style={{ maxWidth: "200px" }} src={URL.createObjectURL(file)} controls />}
                                {file.type.includes("audio") && <audio style={{ maxWidth: "200px" }} src={URL.createObjectURL(file)} controls />}
                                {/* if pdf or others use iframe */}
                                {file.type.includes("application/pdf") && <iframe src={URL.createObjectURL(file)} title={file.name} />}

                            </li>
                        ))}
                    </ul>
                </div>}
            </div>
            <Input type="file" multiple name="document" id="document" onChange={(e) => { setFiles([...e.target.files]) }} />
            <div className="d-flex justify-content-end">
                <Button type="submit" disabled={!commentBody || loading || !commentId}>{loading ? "Adding update..." : "Add update"}</Button>
            </div>
        </Form>
    )
}

// Iagami - Code upgradation React 18
//-----------------------------------

export default graphql(TaskQuery, {
    options: (props) => {
        const taskId = props?.params?.taskId || null
        return {
            variables: { id: taskId },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }
    },
    skip: (props) => !props?.params?.taskId,
    props: ({ data: { task, loading, refetch, variables } }) => {
        console.log('Task:', task) // Ensure this logs useful debug information
        return {
            task,
            taskLoading: loading,
            variables,
            refetchTaskQuery: refetch, // Refetch doesn't need to be redefined
        }
    },
})(withApolloClient(TaskDetailsSidePanel));


