/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { ReactComponent as FullSize } from '../assets/images/icons/full-size.svg'
import { ReactComponent as FullSizeHD } from '../assets/images/icons/full-size.svg'
import { ReactComponent as MidSize } from '../assets/images/icons/mid-size.svg'
import { ReactComponent as MidSizeHD } from '../assets/images/icons/mid-size-hd.svg'
import { ReactComponent as Suv } from '../assets/images/icons/suv.svg'
import { ReactComponent as SuvXL } from '../assets/images/icons/suv-xl.svg'
import { ReactComponent as SuvXLHD } from '../assets/images/icons/suv-xl-hd.svg'
import { ReactComponent as CompactSuv } from '../assets/images/icons/compact-suv.svg'
import { ReactComponent as WAV } from '../assets/images/icons/wav.svg'
import { ReactComponent as WAVUber } from '../assets/images/icons/wav-uber.svg'
import { ReactComponent as WAVXL } from '../assets/images/icons/wav-xl.svg'
import { ReactComponent as Metris } from '../assets/images/icons/metris.svg'
import { ReactComponent as Crossover } from '../assets/images/icons/crossover.svg'
import { ReactComponent as Minivan } from '../assets/images/icons/minivan.svg'
import { ReactComponent as EVCar } from '../assets/images/icons/ev.svg'

const carColorCodes = {
  'BK': '#000000',
  'BK/L': '#30302E',
  'BL': '#2f6feb',
  'SIL': '#B5B7BB',
  'Silver': '#B5B7BB',
  'GY': '#4e5157',
  'BL DK': '#282F81',
  'WHT': '#ebe6e6',
  'SIL/L': '#C7C9CB',
  'SIL/C': '#C7C9CB',
  'BR': '#977257',
  'RD': '#ab0505',
  'Red': '#ab0505',
  'WHT/C': '#ebe6e6',
  'BK/C': '#000000',
  'BR/C': '#977257',
  'GY/C': '#4e5157',
  'BR/L': '#8D7E6B',
  'BL/C': '#2f6feb',
  'BL/L': '#B2CFE7',
  'RD/C': '#ab0505',
  'BL/gry': '#4e5157',
  'BL LT': '#B2CFE7',
  'BR DK': '#977257',
  'BR DK/C': '#977257',
  'BL DK/C': '#2f6feb',
  'BG': '#000'
}

const CarModelGroupIcon = ({ carGroup, carColor, id }) => {
  return (
    <>
      <span id={"carGroup" + id}>
        {carGroup === "Full-Size" ? <FullSize fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
          carGroup === "Full-Size HD" ? <FullSizeHD fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
            carGroup === "Compact SUV" ? <CompactSuv fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
              carGroup === "Crossover" ? <Crossover fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                carGroup === "Metris" ? <Metris fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                  carGroup === "Mid-Size HD" ? <MidSizeHD fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                    carGroup === "Mid-Size" ? <MidSize fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                      carGroup === "Minivan" ? <Minivan fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                        carGroup === "SUV XL HD" ? <SuvXLHD fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                          carGroup === "SUV XL" ? <SuvXL fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                            carGroup === "SUV" ? <Suv fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                              carGroup === "WAV-Uber" ? <WAVUber fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                                carGroup === "WAV XL" ? <WAVXL fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                                  carGroup === "WAV" ? <WAV fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                                    carGroup === "EV" ? <EVCar fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" /> :
                                      <FullSize fill={carColor && carColorCodes[carColor] ? carColorCodes[carColor] : "#000"} width="35" />
        }
      </span>
      <UncontrolledTooltip placement="right" target={"carGroup" + id} >{carGroup ? carGroup : "--"}</UncontrolledTooltip>
    </>
  )
}

//Iagami - Code upgradation React 18
export default CarModelGroupIcon