import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from 'reactstrap'
import toTitleCase from "../../Functions/toTitleCase"
import { RegistrationQuery } from '../Queries'
import withApolloClient from '../../withApolloClient'

class InspectionSuccessConfirmationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    renderRegistrationDetails = (registration) => {
        let metaObject = JSON.parse(registration.metadata.replace(/'/g, '"'))
        return <>
            <h4>{registration.number}<br /><small>({registration.registrationType.name} - {registration.registrationType.stateCode})</small></h4>
            <small style={{ display: "block" }}>Expiration Date: {registration.expirationDate}</small>
        </>
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader style={{ textTransform: "uppercase" }}>Are you sure you want to update the status to SUCCESS?</ModalHeader>
            <ModalBody>
                <p className="text-danger" style={{ fontSize: "1em", letterSpacing: "1px", textTransform: "capitalize" }}>Following actions will be performed on confirming the inspection status to SUCCESS</p>
                <Row>
                    <Col className="text-center">
                        {this.props.registration && this.renderRegistrationDetails(this.props.registration)}
                    </Col>
                </Row>
                <Row className="py-3 mx-5" style={{ borderBottom: "1px solid #dee2e6" }}>
                    <Col>
                        <p className="text-danger">Registration will be removed from the following car:</p>
                        {this.props.registration && this.props.registration.activeRegistration && this.props.registration.activeRegistration.car && <>
                            <b>{toTitleCase(this.props.registration.activeRegistration.car.carModel.make)} {toTitleCase(this.props.registration.activeRegistration.car.carModel.name)} {this.props.registration.activeRegistration.car.year}</b><br />
                            {this.props.registration.activeRegistration.car.pk}-{this.props.registration.activeRegistration.car.vin}
                        </>}
                    </Col>
                    <Col>
                        <p className="text-success">Registration will be installed to the following car:</p>
                        {this.props.registration && this.props.registration.upcomingInspection && this.props.registration.upcomingInspection.car && <>
                            <b>{toTitleCase(this.props.registration.upcomingInspection.car.carModel.make)} {toTitleCase(this.props.registration.upcomingInspection.car.carModel.name)} {this.props.registration.upcomingInspection.car.year}</b><br />
                            {this.props.registration.upcomingInspection.car.pk} - {this.props.registration.upcomingInspection.car.vin}
                        </>}
                    </Col>
                </Row>
                {this.props.associatedRegistration && <>
                    <Row className="pt-4">
                        <Col className="text-center">
                            {this.renderRegistrationDetails(this.props.associatedRegistration)}
                        </Col>
                    </Row>
                    <Row className="py-3 mx-5">
                        <Col>
                            <p className="text-danger">Registration will be removed from the following car:</p>
                            {this.props.associatedRegistration && this.props.associatedRegistration.activeRegistration && this.props.associatedRegistration.activeRegistration.car && <>
                                <b>{toTitleCase(this.props.associatedRegistration.activeRegistration.car.carModel.make)} {toTitleCase(this.props.associatedRegistration.activeRegistration.car.carModel.name)} {this.props.associatedRegistration.activeRegistration.car.year}</b><br />
                                {this.props.associatedRegistration.activeRegistration.car.pk}-{this.props.associatedRegistration.activeRegistration.car.vin}
                            </>}
                        </Col>
                        <Col>
                            <p className="text-success">Registration will be installed to the following car:</p>
                            {this.props.registration && this.props.registration.upcomingInspection && this.props.registration.upcomingInspection.car && <>
                                <b>{toTitleCase(this.props.registration.upcomingInspection.car.carModel.make)} {toTitleCase(this.props.registration.upcomingInspection.car.carModel.name)} {this.props.registration.upcomingInspection.car.year}</b><br />
                                {this.props.registration.upcomingInspection.car.pk} - {this.props.registration.upcomingInspection.car.vin}
                            </>}
                        </Col>
                    </Row>
                </>}
            </ModalBody>
            <ModalFooter>
                {!this.props.loading && <Button className="bos-btn-primary" onClick={this.props.handleConfirmation}>YES</Button>}
                <Button onClick={this.props.handleClose} className="bos-btn-secondary">NO</Button>
            </ModalFooter>
        </Modal>
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(RegistrationQuery, {
    options: ({ registrationId }) => ({
        variables: { id: registrationId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ data: { loading, registration } }) => ({
        loading,
        registration
    })
})(
    graphql(RegistrationQuery, {
        options: ({ associatedRegistrationId }) => ({
            variables: { id: associatedRegistrationId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }),
        props: ({ data: { loading, registration } }) => ({
            loading,
            associatedRegistration: registration
        })
    })(withApolloClient(
        InspectionSuccessConfirmationModal
    ))
);


