import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import './MassMessageModal.css'
import {
    Row, Col, Alert, Progress, Label,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const editAuditLog = gql`
mutation editAuditLog($input: EditAuditLogInput!){
    editAuditLog(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}
`

class AuditLogModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: "",
            success: false
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name]: value })
    };
    handleClose = (e) => {
        let state = {
            loading: false,
            error: "",
            success: false
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    approveLog = () => {
        try {
            this.setState({ loading: true })
            let input = {
                auditLog: this.props.auditLog.id,
                managerApproved: this.props.currentUser ? this.props.currentUser.firstName ? this.props.currentUser.firstName + " " + this.props.currentUser.lastName : this.props.currentUser.username : "None",
                auditStage: 1
            }
            this.props.apolloClient.mutate({
                mutation: editAuditLog,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.editAuditLog && result.data.editAuditLog.ok) {
                    this.setState({ error: "", loading: false, success: true })
                    this.props.handleClose()
                    this.props.refetchQuery && this.props.refetchQuery()
                } else {
                    this.setState({ loading: false, success: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, success: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, success: false, })
        }
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div >
                <Modal className="massMessageModal" isOpen={this.props.open}>
                    <ModalHeader>Approve Audit Log</ModalHeader>
                    {this.props.auditLog &&
                        <ModalBody>
                            {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.state.success && <Row><Col xs={12}><Alert color="success">Approved successfully!</Alert></Col></Row>}
                            <Row>
                                <Col xs={6}>
                                    <strong>Type</strong>
                                    <p>{this.props.auditLog.auditType}</p>
                                </Col>
                                <Col xs={6}>
                                    <strong>Reason</strong>
                                    <p>{this.props.auditLog.auditReason}</p>
                                </Col>
                                {(this.props.auditLog.driver || (this.props.auditLog.agreement && this.props.auditLog.agreement.driver)) &&
                                    <Col xs={12}>
                                        <strong>Driver</strong>
                                        <p><a target="_blank" href={this.props.auditLog && (this.props.auditLog.driver ? "/driver/" + this.props.auditLog.driver.id : this.props.auditLog.agreement && this.props.auditLog.agreement.driver ? "/driver/" + this.props.auditLog.agreement.driver.id : '')}>
                                            {this.props.auditLog && (this.props.auditLog.driver ? this.props.auditLog.driver.name : this.props.auditLog.agreement && this.props.auditLog.agreement.driver ? this.props.auditLog.agreement.driver.name : '')}
                                        </a></p>
                                    </Col>
                                }
                                <Col xs={12}>
                                    <strong>Notes</strong>
                                    <p>{this.props.auditLog.auditNotes}</p>
                                </Col>
                                <Col xs={12}>
                                    <strong>Rep</strong>
                                    <p>{this.props.auditLog.representative ? this.props.auditLog.representative.firstName ? this.props.auditLog.representative.firstName + " " + this.props.auditLog.representative.lastName : this.props.auditLog.representative.username : "--"}</p>
                                </Col>
                                <Col xs={12}>
                                    <strong>Approved By</strong>
                                    <p>{this.props.auditLog.managerApproved ? this.props.auditLog.managerApproved : "--"}</p>
                                </Col>
                                <Col xs={12}>
                                    <strong>Date added</strong>
                                    <p>{this.props.auditLog.dateAdded ? this.props.auditLog.dateAdded.split("T")[0] : ""}</p>
                                </Col>
                            </Row>
                            {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        </ModalBody>
                    }
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    {!this.state.success && this.props.auditLog && !(this.props.auditLog && this.props.auditLog.stage && this.props.auditLog.stage > 0) && <><Button color="secondary" onClick={this.approveLog} disabled={this.state.success}>Approve Log</Button>&nbsp;&nbsp;</>}
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
export default (withApolloClient(AuditLogModal))
