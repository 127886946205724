import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import { Button, Row, Col } from 'reactstrap'
import Select from 'react-select'
import { AllEquipmentItemsGroup } from '../Queries'
import EquipmentsList from "./EquipmentsList"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class EquipmentsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            documentType: null,
            removed: false
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    toggleSwitch = (toggleName, toggleValue, updateDriver = false) => {
        let input = { [toggleName]: toggleValue }
        this.setState({ input: input }, () => {
            if (updateDriver === true) { this.updateDriver() }
        })
    }
    render() {
        return (
            <div className="equipments-tab">
                <Row style={{ marginBottom: "20px" }}>
                    <Col xs={2} className="text-left">
                        <span>Installed</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="removed"
                                id="removed"
                                checked={this.state.removed}
                                onChange={() => this.setState({ removed: !this.state.removed })}
                            />
                            <label className="toggle-switch-label" htmlFor="removed">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <span>Uninstalled</span>
                    </Col>
                    <Col className="text-left" xs={3}>
                        <Select
                            className="bos-custom-select"
                            classNamePrefix="bos-select"
                            isLoading={!(this.props.allEquipmentItemsGroup && this.props.allEquipmentItemsGroup.edges && this.props.allEquipmentItemsGroup.edges.length > 0)}
                            options={
                                this.props.allEquipmentItemsGroup &&
                                this.props.allEquipmentItemsGroup.edges &&
                                this.props.allEquipmentItemsGroup.edges.length > 0 &&
                                this.props.allEquipmentItemsGroup.edges.map(item => ({ value: item.node.id, label: item.node.name }))
                            }
                            placeholder="Select Equipment Type"
                            onChange={item => this.setState({ equipmentType: item.value })}
                        />
                    </Col>
                    <Col className="text-right" xs={7}>
                        <Button className="install-equipment" onClick={() => this.setState({ openModal: "InstallEquipment" })}>
                            Install Equipment
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EquipmentsList carId={this.props && this.props.params && this.props.params.pk} openModal={this.state.openModal} handleClose={() => this.setState({ openModal: "" })} removed={this.state.removed} equipmentType={this.state.equipmentType} />
                    </Col>
                </Row>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllEquipmentItemsGroup, {
    props: ({ data: { allEquipmentItemsGroup, loading } }) => (
        {
            allEquipmentItemsGroup,
            loading,
        }
    )
})(withApolloClient(EquipmentsTab))
