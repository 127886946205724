import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { Route, Routes, Link } from 'react-router-dom'
import DriverListContainerNew from './Drivers/DriversNew/DriversListContainer'
import ReservedCars from './CA/ReservedCars/ReservedCarsContainer'
import AvailableCars from './CA/AvailableCars/AvailableCarsContainer'
import FleetManagement from './CA/FleetManagement/FleetManagementContainer'
import AuditListContainer from './Audit/AuditList/AuditListContainer'
import { EnumQuery, UserQuery, AuditLogsQuery } from './Functions/querys'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import InactiveReservationsListContainer from './ReservationsNew/InactiveReservationsListContainer'
import AllReservationsListContainer from './ReservationsNew/AllReservationsListContainer'
import RentalsListContainer from './Rentals/RentalListNew/RentalsListContainer'
import CarReturnListContainer from "./CarReturn/CarReturnListContainer"
import NotificationsList from './Material/NotificationsList'
import TodoList from './Material/TodoList'
import { getToken, onMessageListener } from './Components/firebase'
import { ButtonGroup, Button, UncontrolledTooltip } from 'reactstrap'
import DriverDetailContainerNew from "./Drivers/Driver/Detail/DetailsNew/DriverDetailContainerNew"
import Page404 from './Components/Page404'
import PriceContainer from "./PriceManagement/PricingContainer"
import CarIssueContainer from './Maintenance/Issues/CarIssueContainer'
import JobContainer from './Maintenance/Jobs/JobContainer'
import CarDealsContainer from './CarDeals/CarDealsContainer'
import BranchListContainer from "./Organization/BranchMangement/BranchListContainer"
import BranchDetailWrapper from "./Organization/BranchMangement/BranchDetailWrapper"
import MembersListContainer from "./Organization/MembersManagement/MembersListContainer"
import MemberDetailsWrapper from "./Organization/MembersManagement/MemberDetailsWrapper"
import AttributeContainer from './Attributes/AttributeContainer'
import InteractionsContainer from './Drivers/Interactions/InteractionsContainer'
import WorkorderContainer from "./Maintenance/WorkOrders/WorkorderContainer"
import RegistrationsContainer from './RegistrationManagement/RegistrationsContainer'
import CarDetailContainerWrapper from "./Cars/CarDetailNew/CarDetailContainerWrapper"
import CollectionsViews from './Drivers/Collections/CollectionListViews'
import BranchSelectionPopover from "./BranchSelectionPopover"
import InsuranceListContainer from "./Insurances/InsuranceListContainer.js"
import LeadsListContainerNew from "./CustomerLeads/LeadsListContainer"
import TimeZoneSelectionPopover from "./TimeZoneSelectionPopover"
import TenantListContainer from "./Organization/TenantManagement/TenantListContainer"
import FinancialAccountListContainer from "./Organization/FinancialAccount/FinancialAccountListContainer"
import TenantDetailsWrapper from "./Organization/TenantManagement/TenantDetailsWrapper"
import PermissionsListContainer from "./Organization/PermissionsManagement/PermissionsListContainer"
import PermissionDetailsWrapper from "./Organization/PermissionsManagement/PermissionDetailsWrapper"
import GroupsListContainer from "./Organization/GroupsManagement/GroupsListContainer"
import GroupsDetailsWrapper from "./Organization/GroupsManagement/GroupsDetailsWrapper"
import SubscriptionsContainer from "./Drivers/Subscriptions/SubscriptionsContainer"
import ObjectPermissionsListContainer from "./Organization/ObjectPermissionManagement/ObjectPermissionsListContainer"
import ArgyleUserListContainer from "./Argyle/ArgyleUserListContainer"
import moment from "moment"
import Clock from './Clock'
import Login from "./Auth/Login"
import './App.css'
import FollowupCustomersContainer from "./FollowupCustomers/FollowupCustomersContainer"
import PromoContainer from "./PriceManagement/PromoContainer"
import SideBar from "./SideBar"
import UserDetailContainer from "./User/UserDetailContainer"
import { PreferredDateTimeProvider } from "./Context/PreferredDateTimeContext"
import AutomatelyContainer from "./Automately/AutomatelyContainer"
import { Badge } from "reactstrap"
import TaskManagementContainer from "./TaskManagement/TaskManagementContainer"
import PaymentSchedulesListContainer from "./Billing/PaymentSchedules/PaymentSchedulesListContainer"
import ChatBotContainer from "./ChatBot/ChatBotContainer"
import withApolloClient from './withApolloClient'
//Iagami - Code upgradation React 18
import DriverDetailContainerWrapper from "./Drivers/Driver/Detail/DetailsNew/DriverDetailContainerNewWrapper.js"
import NotificationTemplatesListContainer from "./NotificationTemplates/NotificationTemplatesContainer"

const PendingAuditLogsCountQuery = gql`
    query PendingAuditLogsCountQuery{
        pendingAuditLogsCount
}`

const UpdateMember = gql`
    mutation updateMember($input: UpdateMemberMutationInput!){
        updateMember(input:$input){
        ok
        errors{
            messages
            field
        }
    }
}`

const CreateDevice = gql`
    mutation createDevice($input: CreateDeviceMutationInput!){
        createDevice(input:$input){
        ok
        errors{
            messages
            field
        }
    }
}`


class RoutingInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            showLeftNav: true,
            showPinnedMenuItems: false,
            preferredMenuItems: [],
            newNotification: "",
            allowedPermissions: [],
            fireBaseTokenFound: "",
            notification: {},
            loading: false,
            branchList: [],
            keyVersion: 1,
            memberId: "",
            defaultBranch: null,
            preferredDateTimeInfo: {
                dateFormat: "MMM D, YYYY",
                timeFormat: "h:mm A",
                timezone: "America/New_York"

            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentUser && this.props.currentUser && prevProps.currentUser.id !== this.props.currentUser.id) {
            getToken((token) => {
                this.setState({ fireBaseTokenFound: token })
                if (token) {
                    this.props.apolloClient.mutate({
                        mutation: CreateDevice,
                        variables: { input: { deviceToken: token, name: "bos", deviceType: "web" } },
                    }).then((result) => {
                        if (result.data && result.data.createDevice && result.data.createDevice.ok) {
                        }
                    }).catch((error) => {
                    })
                }
            }
            )
        }
        try {
            onMessageListener(payload => {
                if (payload && payload.notification && payload.notification.body) {
                    this.setState({ newNotification: payload.notification.body })
                    // Refetch user query to get the new notification count
                    this.props.refetchUserQuery()
                    setTimeout(() => {
                        this.setState({ newNotification: "" })
                    }, 10000)
                }
            })
        } catch { }
        if (this.props.currentUser && this.props.currentUser.member && prevProps.currentUser !== this.props.currentUser) {
            let preferredDateTimeInfo = this.state.preferredDateTimeInfo
            if (this.props.currentUser.member.preferredTimeFormat && this.props.currentUser.member.preferredTimeFormat !== this.state.preferredDateTimeInfo.timeFormat)
                preferredDateTimeInfo = { ...preferredDateTimeInfo, timeFormat: this.props.currentUser.member.preferredTimeFormat }
            if (this.props.currentUser.member.preferredTimezone && this.props.currentUser.member.preferredTimezone !== this.state.preferredDateTimeInfo.timezone)
                preferredDateTimeInfo = { ...preferredDateTimeInfo, timezone: this.props.currentUser.member.preferredTimezone }
            if (this.props.currentUser.member.preferredDateFormat && this.props.currentUser.member.preferredDateFormat !== this.state.preferredDateTimeInfo.dateFormat)
                preferredDateTimeInfo = { ...preferredDateTimeInfo, dateFormat: this.props.currentUser.member.preferredDateFormat }
            this.setState({ preferredDateTimeInfo })
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
    }

    updateSelectedInfo = (memberId, name, value) => {
        try {
            this.setState({ loading: true })
            let input = { memberId, [name]: value }

            this.props.apolloClient.mutate({
                mutation: UpdateMember,
                variables: { input },
            }).then((result) => {
                this.setState({ loading: false })

                if (result.data && result.data.updateMember && result.data.updateMember.ok) {
                    this.props.refetchUserQuery()
                    this.setState({ loading: false, keyVersion: this.state.keyVersion + 1 })
                } else {
                    this.setState({ loading: false })
                }
            }).catch((error) => {
                let errorMessage = "An error has occurred"
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occurred"
            this.setState({ error: errorMessage, loading: false })
        }
    };

    renderSelectedBranchesValues = (selectedBranches) => {
        let maxValues = 2
        let hiddenCount = selectedBranches.length - maxValues
        let value = selectedBranches.filter((_, ii) => ii < maxValues).map(item => item.node.name).join(" | ")
        if (hiddenCount > 0) {
            return <span>
                {value}&nbsp;&nbsp;&nbsp;
                <small>+{hiddenCount} More Branch{hiddenCount > 1 ? "es" : ""}</small>
            </span>
        }
        return value
    }

    updatePreferredDateTimeInfo = (itemName, value) => {
        let name = "preferred" + itemName.charAt(0).toUpperCase() + itemName.slice(1)
        this.setState({ preferredDateTimeInfo: { ...this.state.preferredDateTimeInfo, [itemName]: value } })
        this.updateSelectedInfo(this.props.currentUser.member.id, name, value)
    }


    render() {
        const { currentUser } = this.props
        return (<>
            {this.state.openModal === "NotificationsList" && <NotificationsList handleClose={() => { this.toggleModal(""); this.props.refetchUserQuery() }} currentUser={currentUser} open={this.state.openModal === "NotificationsList"} />}
            {this.state.openModal === "TodoList" && <TodoList handleClose={() => this.toggleModal("")} currentUser={currentUser} open={this.state.openModal === "TodoList"} />}
            {<div className={`NotificationUpdate ${this.state.newNotification && "show"}`}>{this.state.newNotification && <ReactMarkdown source={this.state.newNotification} />}</div>}

            <SideBar currentUser={this.props.currentUser} refetchUserQuery={this.props.refetchUserQuery} showLeftNav={this.state.showLeftNav} updateShowLeftNav={() => this.setState({ showLeftNav: !this.state.showLeftNav })} />
            <PreferredDateTimeProvider preferredDateTimeInfo={this.state.preferredDateTimeInfo} setPreferredDateTimeInfo={this.updatePreferredDateTimeInfo}>
                <div className={"mainContent " + (this.state.showLeftNav ? "" : "full-width")}>
                    <div className="mainHeader">
                        {!this.state.showLeftNav && <Link to="#" style={{ position: "fixed", left: "13px", top: "47vh", color: "#007bff" }} onClick={() => this.setState({ showLeftNav: true })}><i className={`fa fa-2x fa-chevron-right`} aria-hidden="true" /></Link>}
                        <p className="header-greetings">Hello <a className="bos-custom-link" style={{ fontWeight: 400 }} href="/user/">{currentUser && currentUser.username && currentUser.username}</a> 👋👋, its {" "}
                            <Clock key={String(this.state.keyVersion)} />
                        </p>
                        <ButtonGroup className="float-right">
                            <Button id="Notifications" color="light" onClick={() => this.toggleModal("NotificationsList")}><i className={"fa fa-bell-o"} /> <sup><Badge color="danger" pill>{currentUser && currentUser.unreadNotificationsCount && currentUser.unreadNotificationsCount > 0 ? `${currentUser.unreadNotificationsCount}` : ""}</Badge></sup></Button>
                            <UncontrolledTooltip target="Notifications">Notifications</UncontrolledTooltip>
                            <Button id="AuditLogs" color="light" ><a href="/auditlog"><i className={"fa fa-book"} /> <sup>{this.props.pendingAuditLogsCount ? `${this.props.pendingAuditLogsCount}` : ""}</sup></a></Button>
                            <UncontrolledTooltip target="AuditLogs">Audit Logs</UncontrolledTooltip>
                            <Button id="preferredTimeZone" color="light" onClick={() => this.toggleModal("timezoneSelectionPopover")}>
                                <i className={"fa fa-clock-o"} /> <sup>{!this.props.currentUserLoading && this.props.currentUser && this.props.currentUser.member ? (this.state.preferredDateTimeInfo.timezone ? <span>{moment.tz(this.state.preferredDateTimeInfo.timezone).format('z')}</span> : "No Timezone Selected!") : (this.props.currentUser && this.props.currentUser.member ? "Updating Timezone..." : "Loading Timezone...")}</sup>
                            </Button>
                            <UncontrolledTooltip target="preferredTimeZone">Preferred Timezone</UncontrolledTooltip>
                            {this.props.currentUser && this.props.currentUser.member && this.state.openModal === "timezoneSelectionPopover" &&
                                <TimeZoneSelectionPopover
                                    target="preferredTimeZone"
                                    open={this.state.openModal === "timezoneSelectionPopover"}
                                    handleClose={() => this.toggleModal("")}
                                    updateSelectedTimezone={(timezone) => this.updateSelectedInfo(currentUser.member.id, 'preferredTimezone', timezone)}
                                />
                            }
                            <Button color="light" id="bos-branch-dropdown" onClick={() => this.toggleModal("branchSelectionPopover")} disabled={this.props.currentUserLoading || this.state.loading} >
                                <i className={"fa fa-code-fork"} /> <sup>{!this.props.currentUserLoading && this.props.currentUser && this.props.currentUser.member ? (this.props.currentUser.member.selectedBranches.edges.length > 0 ? this.renderSelectedBranchesValues(this.props.currentUser.member.selectedBranches.edges) : "No Branch Selected") : (this.props.currentUser && this.props.currentUser.member ? "Updating Branches..." : "Loading Branches...")}</sup>
                            </Button>
                            {this.props.currentUser && this.props.currentUser.member && this.state.openModal === "branchSelectionPopover" &&
                                <BranchSelectionPopover
                                    target="bos-branch-dropdown"
                                    open={this.state.openModal === "branchSelectionPopover"} handleClose={() => this.toggleModal("branchSelectionPopover")}
                                    userBranches={this.props.currentUser.member.branches.edges.sort((a, b) => (a.node.name > b.node.name) ? 1 : -1)}
                                    selectedBranches={this.props.currentUser.member.selectedBranches.edges.map(branch => branch.node.id)}
                                    updateSelectedBranches={(branchIds) => this.updateSelectedInfo(currentUser.member.id, 'selectedBranches', branchIds)}
                                />}
                            <Button type="link" id="Logout" color="light"><a href="/logout/?next=/"><i className={"fa fa-sign-out"} /></a></Button>
                            <UncontrolledTooltip target="Logout">Log Out</UncontrolledTooltip>
                        </ButtonGroup>
                    </div>
                    {this.props.loading && !currentUser && <div className=""></div>}
                    {!currentUser && !this.props.loading && !this.props.currentUserLoading &&
                        <Routes>
                            <Route path="/login/" element={<Login key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="*" element={<Login />} />
                        </Routes>
                    }
                    {currentUser &&
                        //Iagami - Code upgradation React 18
                        //----------------------------------
                        <Routes>
                            <Route exact path="/" element={<DriverListContainerNew key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/automations/*" element={<AutomatelyContainer key={String(this.state.keyVersion)} currentUser={currentUser} client={this.props.apolloClient} />} />
                            <Route exact path="/boards/:boardId/*" element={<TaskManagementContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/boards/*" element={<TaskManagementContainer key={String(this.state.keyVersion)} currentUser={currentUser} client={this.props.apolloClient} />} />
                            <Route exact path="/customers/followup" element={<FollowupCustomersContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/customers/leads" element={<LeadsListContainerNew key={String(this.state.keyVersion)} currentUser={currentUser} viewType="leadView" />} />
                            <Route exact path="/customers/applications" element={<LeadsListContainerNew key={String(this.state.keyVersion)} currentUser={currentUser} viewType="applicationView" />} />
                            <Route exact path="/customers/insurance/" element={<InsuranceListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/customers/collections/" element={<CollectionsViews key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/customers/scheduled_payments/" element={<PaymentSchedulesListContainer key={String(this.state.keyVersion)} currentUser={currentUser} navigate={this.props.navigate} location={this.props.location} />} />
                            <Route exact path="/customers/subscriptions" element={<SubscriptionsContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/driver/:id" element={<DriverDetailContainerWrapper key={String(this.state.keyVersion)} currentUser={this.props.currentUser} />} />
                            <Route exact path="/customers/interaction" element={<InteractionsContainer key={String(this.state.keyVersion)} currentUser={currentUser} interactionsType="allDrivers" />} />
                            <Route exact path="/customers/argyle" element={<ArgyleUserListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/auditlog/" element={<AuditListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/maintenance/issues/" element={<CarIssueContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/maintenance/jobs/" element={<JobContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/maintenance/work_order/" element={<WorkorderContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/attribute/:name" element={<AttributeContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route path="/car/" element={<FleetManagement key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="/car/buying_selling/" element={<CarDealsContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="/car/available/" element={<AvailableCars key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="/car/pricing/" element={<PriceContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="/car/promotions/" element={<PromoContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="/car/registrations/" element={<RegistrationsContainer key={String(this.state.keyVersion)} currentUser={currentUser} type="car" />} />
                            <Route path="/car/inspections/" element={<RegistrationsContainer key={String(this.state.keyVersion)} currentUser={currentUser} type="car" isInspectionView />} />
                            <Route exact path="/car/:pk" element={<CarDetailContainerWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/reservation/" element={<AllReservationsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/reservation/open/" element={<ReservedCars key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/reservation/inactive/" element={<InactiveReservationsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/carreturn/" element={<CarReturnListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/rental/" element={<RentalsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/organization/branches/" element={<BranchListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/branch/:id" element={<BranchDetailWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/tenants/" element={<TenantListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/tenant/:id" element={<TenantDetailsWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/permissions/" element={<PermissionsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/permission/:id" element={<PermissionDetailsWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/groups/" element={<GroupsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/group/:id" element={<GroupsDetailsWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/members/" element={<MembersListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/member/:id" element={<MemberDetailsWrapper key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/financial_accounts/" element={<FinancialAccountListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route exact path="/organization/object_permissions/" element={<ObjectPermissionsListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />

                            <Route exact path="/user/" element={<UserDetailContainer key={String(this.state.keyVersion)} />} />
                            <Route
                                path="/chatbot"
                                element={<ChatBotContainer key={String(this.state.keyVersion)} currentUser={this.props.currentUser} />}
                            />
                            <Route
                                path="/chatbot/:sessionId"
                                element={<ChatBotContainer key={String(this.state.keyVersion)} currentUser={this.props.currentUser} />}
                            />
                            <Route exact path="/notification_templates/" element={<NotificationTemplatesListContainer key={String(this.state.keyVersion)} currentUser={currentUser} />} />
                            <Route path="*" element={<Page404 />} />

                        </Routes>
                    }
                </div>
            </PreferredDateTimeProvider >
        </>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(EnumQuery, {
    props: ({ data: { loading, agreementStates, accountTypes, chargeTypes } }) => ({
        loading,
        agreementStates,
        accountTypes,
        chargeTypes
    })
})(
    graphql(UserQuery, {
        props: ({ data: { currentUser, refetch, loading } }) => ({
            currentUser,
            currentUserLoading: loading,
            refetchUserQuery: () => {
                return refetch({
                    query: UserQuery,
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return { currentUser: fetchMoreResult.currentUser }
                    }
                })
            }
        })
    })(
        graphql(AuditLogsQuery, {
            options: { fetchPolicy: 'cache-and-network' },
            props: ({ data: { allAuditLogs } }) => ({
                allAuditLogs
            })
        })(
            graphql(PendingAuditLogsCountQuery, {
                props: ({ data: { pendingAuditLogsCount } }) => ({
                    pendingAuditLogsCount
                })
            })(withApolloClient(RoutingInfo))
        )
    )
)

