import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import './ReservationsListContainer.css'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { RepsQuery } from '../Functions/querys'
import { AllCarLocations } from './ReservationQueries'
import ReservationsList from '../CA/ReservationsList'
import { AllAgreementTypes } from './ReservationQueries'
import ReservationsListHeader from '../CA/ReservationsListHeader'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

class AllReservationsListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                orderBy: ["-driver__in_office"],
            },
            selectedDrivers: [],
            refetchReservations: false,
        }
    }
    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-driver__in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }
    render() {
        return (
            <div className="ReservationsListContainer">
                <Container fluid>
                    <ReservationsListHeader
                        activeTab="all"
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        selectedDrivers={this.state.selectedDrivers} history={this.props.navigate} location={this.props.location}
                        defaultFilters={this.state.filterValues}
                        resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                        refetchQuery={() => this.setState({ refetchReservations: !this.state.refetchReservations })}
                    />
                    <Row>
                        <Col>
                            <ReservationsList
                                currentUser={this.props.currentUser}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                                updateOrder={this.updateOrder}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                                selectedTab="all"
                                driverId={this.state.filterValues && this.state.filterValues.driverId ? this.state.filterValues.driverId : null}
                                status={this.state.filterValues && this.state.filterValues.status ? this.state.filterValues.status : null}
                                hasCar={this.state.filterValues.hasCar}
                                driverType={this.state.filterValues && this.state.filterValues.driverType ? this.state.filterValues.driverType : null}
                                carReadyStatus={this.state.filterValues && this.state.filterValues.isReady !== null ? this.state.filterValues.isReady : null}
                                pickupDateRange={this.state.filterValues && this.state.filterValues.pickupDateRange ? this.state.filterValues.pickupDateRange : null}
                                pickupType={this.state.filterValues && this.state.filterValues.pickupType ? this.state.filterValues.pickupType : null}
                                pickUpLocation={this.state.filterValues && this.state.filterValues.pickUpLocation ? this.state.filterValues.pickUpLocation : null}
                                agreementType={this.state.filterValues && this.state.filterValues.agreementType ? this.state.filterValues.agreementType : null}
                                selectedDrivers={this.state.selectedDrivers}
                                setSelectedDrivers={(selectedDrivers) => this.setState({ selectedDrivers })}
                                refetchReservations={this.state.refetchReservations}
                                setRefetchQuery={() => this.setState({ refetchReservations: !this.state.refetchReservations })}
                                preferredCar={this.state.filterValues && this.state.filterValues.preferredCar ? this.state.filterValues.preferredCar : null}
                                hasPromo={this.state.filterValues && this.state.filterValues.hasPromo != null ? this.state.filterValues.hasPromo : null}
                                pickupDate_Gte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].startDate : null}
                                pickupDate_Lte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].endDate : null}
                                carModelNames={this.state.filterValues && this.state.filterValues.carModelNames ? this.state.filterValues.carModelNames : null}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(RepsQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { allReps } }) => ({
        allReps,
    }),
})(
    graphql(AllAgreementTypes, {
        props: ({ data: { allAgreementTypes } }) => ({
            allAgreementTypes,
        }),
    })(
        graphql(AllCarLocations, {
            props: ({ data: { allCarLocations } }) => ({
                allCarLocations,
            }),
        })(
            withApolloClient(AllReservationsListContainer)
        )
    )
);

