import React, { useState } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Link } from 'react-router-dom'
import { Table, Badge } from 'reactstrap'
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { AllAutomationsQuery } from "./Queries"
import moment from "moment"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

function AutomationList({ searchTerm, loadMoreEntries, allAutomations, loading, statuses, ...props }) {
    const headers = [
        { title: 'Name', key: 'name' },
        { title: 'Status', key: 'status' },
        { title: 'Triggers', key: 'triggers' },
        { title: 'Actions', key: 'actions' },
        { title: 'Date Added', key: 'dateAdded' },
        { title: 'Created By', key: 'createdBy' }
    ]


    return (
        <div className="AutomationList" >
            <PrimaryListingContainer listLoading={loading} totalFetchedRows={allAutomations && allAutomations.edges.length} pageInfo={allAutomations && allAutomations.pageInfo} loadMoreEntries={allAutomations && allAutomations.pageInfo && allAutomations.pageInfo.hasNextPage ? loadMoreEntries : null} refetchListQuery={null}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="select-all-checkbox"> </th>
                            {headers.map((header, index) => (
                                <th key={index}>{header.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {allAutomations && allAutomations.edges && allAutomations.edges.length === 0 && <tr><td colSpan={headers.length + 1} className="text-center">No data found</td></tr>}

                        {allAutomations && allAutomations.edges && allAutomations.edges.map((automation, index) => (
                            automation = automation.node,
                            <tr key={index}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {<strong className="listing-counter">{index + 1}</strong>}
                                </td>
                                <td className="shortText">
                                    <Link to={`/automations/${automation.id}`}>{automation.name}</Link>
                                </td>
                                <td>
                                    <Badge color={automation.isActive ? "success" : "danger"}>{automation.isActive ? "Active" : "Inactive"}</Badge>
                                </td>
                                <td>
                                    {automation.trigger &&
                                        <Badge color="primary">
                                            {automation.trigger.triggerType} {automation.trigger.contentType.model}
                                        </Badge>
                                    }
                                </td>
                                <td>
                                    {automation.actionSet.edges.map((action, index) => (
                                        <Badge color="primary">
                                            {action.node && action.node.actionType}
                                        </Badge>
                                    ))}
                                </td>
                                <td>
                                    {moment(automation.dateAdded).format("MM/DD/YYYY")}
                                </td>
                                <td>{automation.createdBy ? automation.createdBy.firstName ? automation.createdBy.firstName + " " + automation.createdBy.lastName : automation.createdBy.username : '--'}</td>
                            </tr>
                        ))}

                    </tbody >
                </Table >
            </PrimaryListingContainer >
        </div >

    )
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllAutomationsQuery, {
    options: ({ searchTerm, isActive, orderBy }) => ({
        variables: {
            searchTerm,
            isActive,
            orderBy,
            first: 30
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    }),
    props: ({ data: { loading, allAutomations, fetchMore, variables, refetch } }) => ({
        loading,
        allAutomations,
        variables,
        refetch,
        refetchDriversQuery: () => {
            //No apollo function that refetches in place with pagination considered so this function instead
            let currentLength = allAutomations && allAutomations.edges ? allAutomations.edges.length : 30
            return fetchMore({
                query: AllAutomationsQuery,
                variables: {
                    ...variables,
                    first: currentLength,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allAutomations.edges
                    const pageInfo = fetchMoreResult.allAutomations.pageInfo
                    return {
                        allAutomations: {
                            edges: [...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            __typename: previousResult.allAutomations.__typename
                        },
                    }
                },
            })
        },
        loadMoreEntries: () => {
            return fetchMore({
                query: AllAutomationsQuery,
                variables: {
                    cursor: allAutomations.pageInfo.endCursor,
                    ...variables
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allAutomations.edges
                    const pageInfo = fetchMoreResult.allAutomations.pageInfo

                    return {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        allAutomations: {
                            edges: [...previousResult.allAutomations.edges, ...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            __typename: previousResult.allAutomations.__typename
                        },
                    }
                },
            })
        },
    })
})(withApolloClient(AutomationList))