import React, { Component, Fragment } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Col, Row, Label, Nav, NavItem, NavLink, Card, Button, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody, FormGroup, Alert, Input } from 'reactstrap'
import Loader from "../../../Material/Loader"
import "react-awesome-lightbox/build/style.css"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import CarIssueModal from "../../../Maintenance/CarIssueModal"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
import Table from "reactstrap/lib/Table"
import Select from 'react-select'
import DateTimePicker from "../../../Material/DateTimePicker"
import moment from 'moment'
import { Link } from "react-router-dom"
import ConfirmationPopup from "../../../Material/ConfirmationPopup"
import ViewPDFDocument from "../../../Material/DocumentModal/ViewPDFDocument"
import toTitleCase from "../../../Functions/toTitleCase"
import { PreferredDateTimeContext } from "../../../Context/PreferredDateTimeContext"
import { getEnumDescription } from '../../../Functions/processEnumDescription'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const CreateIncidentMutation = gql`mutation CreateIncidentMutation($input: CreateIncidentMutationInput!){
    createIncident(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CarIssuesSeverityLevelQuery = gql`{
  carIssueSeverityLevels: __type(name: "CarIssuesSeverity") {
    states: enumValues {
      name
      description
    }
  }
}
`

const UpdateIncidentDamageMutation = gql`mutation UpdateIncidentDamageMutation($input: UpdateIncidentDamageMutationInput!){
    updateIncidentDamage(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateIncidentMutation = gql`mutation UpdateIncidentMutation($input: UpdateIncidentMutationInput!){
    updateIncident(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const DeleteIncidentMutation = gql`mutation DeleteIncidentMutation($input: DeleteIncidentMutationInput!){
    deleteIncident(input:$input){
        ok
        errors{
            messages
        }
    }
} `



const CarConditionDetailQuery = gql`query carCondition($id: ID!){
    carCondition(carConditionId:$id){
        id
        dateAdded
        dateModified
        formData
        gas
        mileage
        inspectionReportPdf
        carissuesSet(isIncidentDamage:false){
          edges {
            node {
              id
              pk
              dateAdded
              dateModified
              dateClosed
              status
              reportedBy
              notes
              externalId
              severity
              projectedCost
              needsReview
              canPickup
              pictures {
                edges {
                  node {
                    id
                    dateAdded
                    pictureUrl
                    pictureType
                    description
                  }
                }
              }
              car{
                id
                pk
              }
              issueCategory {
                id
                name: category
              }
              vendor {
                id
                name
              }
            }
          }
        }
        user{
          id
          username
          firstName
          lastName
        }
        incidentSet {
            edges {
                node {
                    id
                    pk
                    dateAdded
                    incidentDate
                    notificationDate
                    reportedBy
                    rental {
                        id
                        driver {
                            id
                            name
                        }
                    }
                    damageTransaction {
                        id
                        pk
                        dateAdded
                        dynamicAmount
                        notes
                        chargeType{
                            id
                            name
                        }
                    }
                    incidentdamageSet {
                        edges {
                            node {
                                id
                                damage {
                                    id
                                    car{
                                        id
                                        pk
                                    }
                                    dateAdded
                                    description: notes
                                    issueDesc
                                    status
                                    reportedBy
                                    canPickup
                                    projectedCost
                                    severity
                                    pictures{
                                        edges{
                                            node{
                                                id
                                                dateAdded
                                                pictureUrl
                                                pictureType
                                                description
                                            }
                                        }
                                    }
                                    issueCategory {
                                        id
                                        name: category
                                    }
                                    vendor{
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        carpicturesSet {
          edges {
            node {
              id
              dateAdded
              pictureUrl
              pictureType
              description
            }
          }
        }
      
    }     
}`

const AllTransactionsQuery = gql`query allTransactions($agreementId: ID!, $chargeTypeIds:[ID]){
    allTransactions(agreementId:$agreementId, chargeTypeIds:$chargeTypeIds){
        edges{
            node{
                id
                pk
                chargeTypeDisplay
                amount
                dateAdded
                agreement {
                    id
                }
            }
        }
      
    }     
}`

const IncidentReportedByChoices = gql`query IncidentReportedByChoices{
    allIncidentChoices: __type(name: "IncidentReportedBy") {
      values: enumValues {
          name
          description
      }
    }
}`

class CarInspectionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: null,
            loading: false,
            successMessage: "",
            picturesSetSubsection: "walkaroundPictures",
            carInspectionSubsection: "inspectionDetails",
            reportedIssueSubsection: "reportedIssues",
            damageTransactionSubsection: "damageTransactions",
            incidentDetailSubsection: "incidentDetial",
            activeIncidentId: this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges[0].node.id : "",
            selectedIncidentId: "",
            transactionId: "",
            transactions: [],
            incidentDatetime: new Date(),
            notificationDatetime: new Date(),
            reportedBy: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "", error: "" })
        } else {
            this.setState({ openModal: modalName, error: "" })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    viewPictures = (carPictures, index = 0) => {
        let pictures = carPictures.map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }

    pObj = (jsonString) => {
        let obj = JSON.parse(jsonString)
        return obj
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }

    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }

    handleClose = () => {
        this.setState({
            loading: false,
            error: "",
            successMessage: "",
            openModal: "",
            selectedIncidentId: "",
            incidentDatetime: new Date,
            notificationDatetime: new Date,
        })
    }

    createNewIncident = (e) => {
        try {
            if (this.props.id && this.state.incidentDatetime && this.state.notificationDatetime && this.state.reportedBy) {
                this.setState({ loading: true })
                let input = {
                    carConditionId: this.props.id,
                    incidentDatetime: this.state.incidentDatetime,
                    notificationDatetime: this.state.notificationDatetime,
                    reportedBy: this.state.reportedBy
                }
                this.props.apolloClient.mutate({
                    mutation: CreateIncidentMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createIncident && result.data.createIncident.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Created New Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createIncident && result.data.createIncident.errors && result.data.createIncident.errors[0] && result.data.createIncident.errors[0].messages) {
                            errorMessage = result.data.createIncident.errors[0].messages.toString()
                        }
                        this.setState({ error: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        }
    }

    updateIncident = (incidentId, action) => {
        try {
            if (incidentId) {
                this.setState({ loading: true })
                let input = {
                    incidentId: incidentId,
                }
                if (action === "incidentDateTime") {
                    input["incidentDatetime"] = this.state.incidentDatetime
                }
                if (action === "notificationDatetime") {
                    input["notificationDatetime"] = this.state.notificationDatetime
                }
                if (action === "reportedBy") {
                    input["reportedBy"] = this.state.reportedBy
                }
                if (action === "transaction") {
                    input["damageTransactionId"] = this.state.transactionId
                }

                this.props.apolloClient.mutate({
                    mutation: UpdateIncidentMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateIncident && result.data.updateIncident.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Updated New Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.updateIncident && result.data.updateIncident.errors && result.data.updateIncident.errors[0] && result.data.updateIncident.errors[0].messages) {
                            errorMessage = result.data.updateIncident.errors[0].messages.toString()
                        }
                        this.setState({ error: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        }
    }

    deleteIncident = (incidentId) => {
        try {
            if (incidentId) {
                this.setState({ loading: true })
                let input = {
                    incidentId: incidentId,
                }
                this.props.apolloClient.mutate({
                    mutation: DeleteIncidentMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.deleteIncident && result.data.deleteIncident.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Deleted Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.deleteIncident && result.data.deleteIncident.errors && result.data.deleteIncident.errors[0] && result.data.deleteIncident.errors[0].messages) {
                            errorMessage = result.data.deleteIncident.errors[0].messages.toString()
                        }
                        this.setState({ error: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        }
    }

    handleMoveDamage = (damageId) => {
        try {
            if (damageId) {
                this.setState({ loading: true })
                let input = {
                    incidentDamageId: damageId,
                    incidentId: this.state.selectedIncidentId,
                }
                this.props.apolloClient.mutate({
                    mutation: UpdateIncidentDamageMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateIncidentDamage && result.data.updateIncidentDamage.ok) {
                        this.setState({ error: "", successMessage: "Succesfully Moved The Damage To Selected Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.updateIncidentDamage && result.data.updateIncidentDamage.errors && result.data.updateIncidentDamage.errors[0] && result.data.updateIncidentDamage.errors[0].messages) {
                            error = result.data.updateIncidentDamage.errors[0].messages.toString()
                        }
                        this.setState({ error: error, successMessage: "" })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let error = "An error has occured"
                    this.setState({ error: error, loading: false, successMessage: "" })
                })
            }
            else this.setState({ error: "Missing Required Field", successMessage: "" })
        }
        catch (err) {
            let error = "An error has occured"
            this.setState({ error: error, loading: false, successMessage: "" })
        }
    }

    fetchTransactions = (agreementId) => {
        let chargeTypeIds = []
        if (process.env.NODE_ENV === "development") {
            chargeTypeIds = ["Q2hhcmdlVHlwZU5vZGU6NTQ=", "Q2hhcmdlVHlwZU5vZGU6NTM="]
        }
        else if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
            chargeTypeIds = ["Q2hhcmdlVHlwZU5vZGU6MjI=", "Q2hhcmdlVHlwZU5vZGU6MjE="]
        }
        this.props.apolloClient.query({
            query: AllTransactionsQuery,
            variables: {
                agreementId: agreementId,
                chargeTypeIds: chargeTypeIds
            }
        }).then(response => {
            if (response && response.data && response.data.allTransactions && response.data.allTransactions.edges.length > 0) {
                const transactions = response.data.allTransactions.edges.map(edge => ({
                    value: edge.node.pk,
                    label: `${edge.node.pk} | ${edge.node.chargeTypeDisplay} | $${edge.node.amount} | ${moment(edge.node.dateAdded).format('YYYY-MM-DD')}`
                }))
                this.setState({ transactions })
            }
        }).catch(error => {
            this.setState({ error: "Error fetching transactions: " + error })
        })
    }

    handleTransactionChange = (selectedOption) => {
        this.setState({ transactionId: selectedOption.value })
    }

    componentDidUpdate(prevProps, prevState) {
        const { carCondition, activeTab, location } = this.props
        const carConditionChanged = prevProps.carCondition !== carCondition
        const carIncidents = carCondition && carCondition.incidentSet && carCondition.incidentSet.edges && carCondition.incidentSet.edges.length > 0
        if (carIncidents &&
            ((this.state.carInspectionSubsection !== prevState.carInspectionSubsection && this.state.carInspectionSubsection === "carIssue") ||
                (activeTab !== prevProps.activeTab && activeTab === "inspections") ||
                carConditionChanged
            )
        ) {
            const defaultIncident = carCondition && carCondition.incidentSet && carCondition.incidentSet.edges && carCondition.incidentSet.edges.length > 0 && carCondition.incidentSet.edges[0].node
            const agreementId = defaultIncident && defaultIncident.rental && defaultIncident.rental.id
            const queryParams = new URLSearchParams(location.search)
            const existingIncidentId = queryParams.get("incidentId")

            if (existingIncidentId) {
                const validIncident = carCondition.incidentSet.edges.find(
                    inspection => inspection.node.id === existingIncidentId
                )

                const newActiveIncidentId = validIncident ? validIncident.node.id : defaultIncident.id
                this.setState({ activeIncidentId: newActiveIncidentId }, () => {
                    if (agreementId) {
                        this.fetchTransactions(agreementId)
                    }
                })

                if (!validIncident) {
                    this.props.setUrlParams(defaultIncident.id)
                }
            } else {
                this.setState({ activeIncidentId: defaultIncident.id }, () => {
                    if (agreementId) {
                        this.fetchTransactions(agreementId)
                    }
                })
                this.props.setUrlParams(defaultIncident.id)
            }
        }
    }


    componentDidMount() {
        const { apolloClient } = this.props
        if (this.props.location && this.props.location.search && this.props.location.search.includes("incidentId")) {
            this.setState({ carInspectionSubsection: "carIssue" })
        }
    }

    setIncidentId = (id) => {
        this.props.setUrlParams(id)
        this.setState({ activeIncidentId: id })
    }

    render() {
        const IssuesTable = [
            { id: "date_addded", name: "Date Added", sortable: true },
            { id: "issueCategory", name: "Category", sortable: false },
            { id: "status", name: "Status", sortable: false },
            { id: "projectedCost", name: "Projected Cost", sortable: false },
            { id: "severity", name: "Severity Level", sortable: false },
            { id: "canPickup", name: "Can Pickup", sortable: false },
            { id: "pictures", name: "Pictures", sortable: false },
            { id: "action", name: "Action", sortable: false },
        ]
        const PopoverMapping = {
            updateIncidentDate: "incidentDateTime",
            updateIncidentNotificationDate: "notificationDatetime",
            updateReportedBy: "reportedBy",
        }

        return (
            <>
                <Col xs={10}>
                    {this.state.selectedImage &&
                        <DocumentModalNew
                            document={this.state.selectedImage}
                            handleClose={() => this.setState({ selectedImage: null })}
                            next={this.next}
                            prev={this.prev}
                            objectType={"car"}
                            isPicture={true}
                            refetch={this.props.refetch}
                            currentUser={this.props.currentUser}
                        />
                    }
                    {this.props.carConditionLoading ? <Loader /> : <>
                        <div className="inspection-detail">
                            <Nav tabs className="d-flex w-100">
                                <NavItem className={this.state.tab === "inspectionDetails" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "inspectionDetails" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "inspectionDetails")}>
                                        INSPECTION DETAILS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "carIssue" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "carIssue" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "carIssue")}>
                                        REPORTED INCIDENTS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "otherIssues" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "otherIssues" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "otherIssues")}>
                                        OTHER ISSUES
                                    </NavLink>
                                </NavItem>
                                <div className="ml-auto">
                                    {this.state.carInspectionSubsection === "carIssue" &&
                                        <div>
                                            <Button id={"addNewIncident"} className="primary" onClick={() => this.setState({ openModal: "addNewIncident" })}>Add New Incident</Button>
                                        </div>
                                    }
                                    {this.state.openModal === "addNewIncident" && this.state.carInspectionSubsection === "carIssue" &&
                                        <Popover trigger="legacy" placement="left" target={"addNewIncident"} isOpen={this.state.openModal === "addNewIncident" && this.state.carInspectionSubsection === "carIssue"} toggle={() => this.toggleModal("addNewIncident")}>
                                            <PopoverHeader>Create New Incident</PopoverHeader>
                                            <PopoverBody>
                                                <Row>
                                                    {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                    {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <FormGroup>
                                                            <Label>When did the incident happen?</Label>
                                                            <PreferredDateTimeContext.Consumer>
                                                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                                                    <DateTimePicker
                                                                        type="datetime-local"
                                                                        name="incidentDatetime"
                                                                        id="incidentDatetime"
                                                                        value={this.state.incidentDatetime}
                                                                        max={moment().tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                        placeholder="Select incident datetime"
                                                                        setError={(error) => this.setState({ error })}
                                                                        updateInput={(name, value) => this.setState({ [name]: value })}
                                                                    />
                                                                )}
                                                            </PreferredDateTimeContext.Consumer>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <FormGroup>
                                                            <Label>When was the incident reported?</Label>
                                                            <PreferredDateTimeContext.Consumer>
                                                                {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                                                    <DateTimePicker
                                                                        type="datetime-local"
                                                                        name="notificationDatetime"
                                                                        id="notificationDatetime"
                                                                        min={moment(this.state.incidentDatetime).tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                        max={moment().tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                        value={this.state.notificationDatetime}
                                                                        placeholder="Select notification datetime"
                                                                        setError={(error) => this.setState({ error })}
                                                                        updateInput={(name, value) => this.setState({ [name]: value })}
                                                                    />
                                                                )}
                                                            </PreferredDateTimeContext.Consumer>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <FormGroup>
                                                            <Label>Incident reported by?</Label>
                                                            <Select
                                                                className="bos-custom-select"
                                                                classNamePrefix="bos-select"
                                                                placeholder="Choose who reported the incident"
                                                                options={this.props.allIncidentChoices && this.props.allIncidentChoices.values && getEnumDescription(this.props.allIncidentChoices.values).map(item => ({ value: item.name, label: item.description }))}
                                                                onChange={(item) => this.setState({ reportedBy: item.value })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-right">
                                                        <Button className="Add-driver-modal-primary-btn" onClick={this.createNewIncident} disabled={this.state.loading || !(this.state.incidentDatetime || this.state.notificationDatetime || this.state.reportedBy)}>{this.state.loading ? "Loading..." : "Add New Incident"}</Button>{' '}
                                                    </Col>
                                                </Row>
                                            </PopoverBody>
                                        </Popover>
                                    }
                                </div>
                            </Nav>
                            <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                <Row>
                                    <Col>
                                        {this.props.carCondition ?
                                            this.state.carInspectionSubsection === "inspectionDetails" ?
                                                <div>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Label>Date Added</Label>
                                                            <p>{this.props.carCondition.dateAdded ? <DatetimeRenderer datetime={this.props.carCondition.dateAdded} placement="right" /> : "--"}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Date Modified</Label>
                                                            <p>{this.props.carCondition.dateModified ? <DatetimeRenderer datetime={this.props.carCondition.dateModified} placement="right" /> : "--"}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Inspection Type</Label>
                                                            <p>
                                                                {this.props.carCondition.formData && this.pObj(this.props.carCondition.formData) && "form_type" in this.pObj(this.props.carCondition.formData) ? this.pObj(this.props.carCondition.formData)["form_type"] : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>User</Label>
                                                            <p className="columnContent">
                                                                {this.props.carCondition && this.props.carCondition.user ? this.props.carCondition.user.firstName ? this.props.carCondition.user.firstName + " " + this.props.carCondition.user.lastName : this.props.carCondition.user.username : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Gas</Label>
                                                            <p className="columnContent">{this.props.carCondition.gas}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Mileage</Label>
                                                            <p className="columnContent">{this.props.carCondition.mileage}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                this.state.carInspectionSubsection === "otherIssues" ?
                                                    <Row>
                                                        <Col>
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontWeight: 500 }}>Date Added</th>
                                                                        <th style={{ fontWeight: 500 }}>Category</th>
                                                                        <th style={{ fontWeight: 500 }}>Status</th>
                                                                        <th style={{ fontWeight: 500 }}>Projected Cost</th>
                                                                        <th style={{ fontWeight: 500 }}>Severity</th>
                                                                        <th style={{ fontWeight: 500 }}>Can Pickup</th>
                                                                        <th style={{ fontWeight: 500 }}>Pictures</th>
                                                                        <th style={{ fontWeight: 500 }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.props.carCondition && this.props.carCondition.carissuesSet && this.props.carCondition.carissuesSet.edges && this.props.carCondition.carissuesSet.edges.length > 0 ? this.props.carCondition.carissuesSet.edges.map((issue, i) =>
                                                                        <tr key={i}>
                                                                            <td><small>{issue.node.dateAdded ? <DatetimeRenderer datetime={issue.node.dateAdded} placement="right" /> : "---"}</small></td>
                                                                            <td><small>{issue.node.issueCategory ? issue.node.issueCategory.name : "---"}</small></td>
                                                                            <td><small>{issue.node.status ? issue.node.status : "---"}</small></td>
                                                                            <td><small>{issue.node.projectedCost ? `$${issue.node.projectedCost}` : "---"}</small></td>
                                                                            <td><small>{this.props.carIssueSeverityLevels && this.props.carIssueSeverityLevels.states && this.props.carIssueSeverityLevels.states.find(item => item.name === issue.node.severity).description}</small></td>
                                                                            <td><small>{issue.node.canPickup ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</small></td>
                                                                            <td>
                                                                                <small>
                                                                                    {issue.node.pictures && issue.node.pictures.edges && issue.node.pictures.edges.length > 0 ?
                                                                                        <div onClick={() => this.viewPictures(issue.node.pictures.edges)}>
                                                                                            <i className="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
                                                                                            {issue.node.pictures.edges.length > 1 &&
                                                                                                <a className="bos-custom-link" target="_blank">
                                                                                                    {" "}+{issue.node.pictures.edges.length - 1} more
                                                                                                </a>}
                                                                                        </div> :
                                                                                        "No Pictures Found"}
                                                                                </small>
                                                                            </td>
                                                                            <td>
                                                                                <i id={"editCarIssue" + i} class="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.setState({ openModal: "editCarIssue" + i })}></i>
                                                                                {this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={issue.node} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                                                            </td>
                                                                        </tr >
                                                                    ) : <tr><td colSpan={4} style={{ color: "red" }}>No Other Issues Found!</td></tr>
                                                                    }
                                                                </tbody>
                                                            </Table >
                                                        </Col >
                                                    </Row >
                                                    :
                                                    this.state.carInspectionSubsection === "carIssue" ?
                                                        <div>
                                                            <Row>
                                                                <Col className="inspections-list col-2" style={{ maxHeight: "370px", overflow: "auto" }}>
                                                                    {this.props.loading ? <Loader /> : <>
                                                                        {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.map((incident, i) =>
                                                                            <div className={"car-inspection" + (this.state.activeIncidentId === (incident.node.id) ? " active" : "")} onClick={() => this.setIncidentId(incident.node.id)} key={i} >
                                                                                <div className={"sidebar-listing-wrapper-text"} >
                                                                                    <p><small>{incident.node.pk} - {incident.node.dateAdded ? <DatetimeRenderer datetime={incident.node.dateAdded} placement="top" /> : "--"}</small></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : <div><p className="text-center no-rentals">No Car Condition Found!</p></div>
                                                                        }
                                                                    </>}
                                                                </Col>
                                                                <Col>
                                                                    {this.state.activeIncidentId ?
                                                                        <>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Nav tabs>
                                                                                        <NavItem className={this.state.tab === "incidentDetial" ? "active" : "inactive"}>
                                                                                            <NavLink className={this.state.incidentDetailSubsection === "incidentDetial" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("incidentDetailSubsection", "incidentDetial")}>
                                                                                                INCIDENT DETAILS
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Nav>
                                                                                    {this.state.incidentDetailSubsection === "incidentDetial" &&
                                                                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                            <Table responsive>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{ fontWeight: 500 }}>Driver</th>
                                                                                                        <th style={{ fontWeight: 500 }}>Date Added</th>
                                                                                                        <th style={{ fontWeight: 500 }}>Incident Date &nbsp;&nbsp; <i className={"fa fa-pencil-square-o"} onClick={() => this.toggleModal("updateIncidentDate")} id={"updateIncidentDate"}></i></th>
                                                                                                        <th style={{ fontWeight: 500 }}>Notification Date &nbsp;&nbsp; <i className={"fa fa-pencil-square-o"} onClick={() => this.toggleModal("updateIncidentNotificationDate")} id={"updateIncidentNotificationDate"}></i></th>
                                                                                                        <th style={{ fontWeight: 500 }}>Reported By &nbsp;&nbsp; <i className={"fa fa-pencil-square-o"} onClick={() => this.toggleModal("updateReportedBy")} id={"updateReportedBy"}></i></th>
                                                                                                        <th style={{ fontWeight: 500 }}>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map((incident, i) =>
                                                                                                        <tr key={i}>
                                                                                                            <td>{incident.node.rental ? <Link color="secondary" to={'/driver/' + incident.node.rental.driver.id} target="_blank">{incident.node.rental.driver.name}&nbsp;&nbsp;<i className="fa fa-external-link color-blue"></i></Link> : "No Rental/Driver Found!"}</td>
                                                                                                            <td>{incident.node.dateAdded ? <DatetimeRenderer datetime={incident.node.dateAdded} placement="right" /> : "---"}</td>
                                                                                                            <td>{incident.node.incidentDate ? <DatetimeRenderer datetime={incident.node.incidentDate} placement="right" /> : "---"}</td>
                                                                                                            <td>{incident.node.notificationDate ? <DatetimeRenderer datetime={incident.node.notificationDate} placement="right" /> : "---"}</td>
                                                                                                            <td>{incident.node.reportedBy ? toTitleCase(incident.node.reportedBy) : "---"}</td>
                                                                                                            <td>
                                                                                                                {!((incident.node.damageTransaction && incident.node.damageTransaction.id) || (incident.node.incidentdamageSet && incident.node.incidentdamageSet.edges && incident.node.incidentdamageSet.edges.length > 0)) ?
                                                                                                                    <>
                                                                                                                        <i class="fa fa-trash" aria-hidden="true" style={{ color: "red", fontSize: 20 }} onClick={() => this.toggleModal("deleteIncident" + i)} id={"deleteIncident" + i}></i>
                                                                                                                        <UncontrolledTooltip target={"deleteIncident" + i}>Delete Incident</UncontrolledTooltip>
                                                                                                                    </> :
                                                                                                                    <small>No Action Available</small>
                                                                                                                }
                                                                                                            </td>
                                                                                                            {["updateIncidentDate", "updateIncidentNotificationDate", "updateReportedBy"].includes(this.state.openModal) &&
                                                                                                                <Popover placement="left" target={this.state.openModal} isOpen={["updateIncidentDate", "updateIncidentNotificationDate", "updateReportedBy"].includes(this.state.openModal)} toggle={() => this.toggleModal(this.state.openModal)}>
                                                                                                                    <PopoverHeader>Update Incident</PopoverHeader>
                                                                                                                    <PopoverBody>
                                                                                                                        <Row>
                                                                                                                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                                                                                            {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            {this.state.openModal === "updateIncidentDate" &&
                                                                                                                                <Col>
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>Select Date</Label>
                                                                                                                                        <PreferredDateTimeContext.Consumer>
                                                                                                                                            {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                                                                                                                                <DateTimePicker
                                                                                                                                                    type="datetime-local"
                                                                                                                                                    name="incidentDatetime"
                                                                                                                                                    id="incidentDatetime"
                                                                                                                                                    value={incident.node.incidentDate ? incident.node.incidentDate : new Date()}
                                                                                                                                                    max={moment().tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                                                                                                    placeholder="Select Incident datetime"
                                                                                                                                                    setError={(error) => this.setState({ error })}
                                                                                                                                                    updateInput={(name, value) => this.setState({ [name]: value })}
                                                                                                                                                />
                                                                                                                                            )}
                                                                                                                                        </PreferredDateTimeContext.Consumer>
                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                            }
                                                                                                                            {this.state.openModal === "updateIncidentNotificationDate" &&
                                                                                                                                <Col>
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>Select Date</Label>
                                                                                                                                        <PreferredDateTimeContext.Consumer>
                                                                                                                                            {({ preferredDateTimeInfo, setPreferredDateTimeInfo }) => (
                                                                                                                                                <DateTimePicker
                                                                                                                                                    type="datetime-local"
                                                                                                                                                    name="notificationDatetime"
                                                                                                                                                    id="notificationDatetime"
                                                                                                                                                    value={incident.node.notificationDate ? incident.node.notificationDate : new Date()}
                                                                                                                                                    min={moment(incident.node.incidentDate ? incident.node.incidentDate : new Date()).tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                                                                                                    max={moment().tz(preferredDateTimeInfo.timezone).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                                                                                                                                    placeholder="Select notification datetime"
                                                                                                                                                    setError={(error) => this.setState({ error })}
                                                                                                                                                    updateInput={(name, value) => this.setState({ [name]: value })}
                                                                                                                                                />
                                                                                                                                            )}
                                                                                                                                        </PreferredDateTimeContext.Consumer>
                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                            }
                                                                                                                            {this.state.openModal === "updateReportedBy" &&
                                                                                                                                <Col>
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>Reported By</Label>
                                                                                                                                        <Select
                                                                                                                                            className="bos-custom-select"
                                                                                                                                            classNamePrefix="bos-select"
                                                                                                                                            placeholder="Choose who reported the incident"
                                                                                                                                            defaultValue={this.props.allIncidentChoices && this.props.allIncidentChoices.values && this.props.allIncidentChoices.values.filter(item => item.name === incident.node.reportedBy).map(item => ({ value: item.name, label: item.description }))}
                                                                                                                                            options={this.props.allIncidentChoices && this.props.allIncidentChoices.values && this.props.allIncidentChoices.values.map(item => ({ value: item.name, label: item.description }))}
                                                                                                                                            onChange={(item) => this.setState({ reportedBy: item.value })}
                                                                                                                                        />
                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                            }
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col className="text-right">
                                                                                                                                <Button className="Add-driver-modal-primary-btn" onClick={() => this.updateIncident(incident.node.id, PopoverMapping[this.state.openModal])} disabled={this.state.loading || !(this.state.incidentDatetime)}>{this.state.loading ? "Loading..." : "Update Incident"}</Button>{' '}
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </PopoverBody>
                                                                                                                </Popover>
                                                                                                            }
                                                                                                            {this.state.openModal === "deleteIncident" + i &&
                                                                                                                <ConfirmationPopup
                                                                                                                    open={this.state.openModal === "deleteIncident" + i}
                                                                                                                    title={"Delete Incident"}
                                                                                                                    loading={this.props.loading}
                                                                                                                    message={"Are you sure you want to delete this incident?"}
                                                                                                                    handleClose={() => this.toggleModal("deleteIncident" + i)}
                                                                                                                    action={"Delete Incident"}
                                                                                                                    confirmAction={() => this.deleteIncident(incident.node.id)}
                                                                                                                    color="danger"
                                                                                                                    error={this.state.error}
                                                                                                                    success={this.state.successMessage ? this.state.successMessage : ""}
                                                                                                                />
                                                                                                            }
                                                                                                        </tr >
                                                                                                    ) : <tr><td colSpan={4} style={{ color: "red" }}>No Incident Found!</td></tr>
                                                                                                    }
                                                                                                </tbody>
                                                                                            </Table >
                                                                                        </div >
                                                                                    }
                                                                                </Col >
                                                                            </Row >
                                                                            <br />
                                                                            <Row>
                                                                                <Col>
                                                                                    <Nav tabs>
                                                                                        <NavItem className={this.state.tab === "reportedIssues" ? "active" : "inactive"}>
                                                                                            <NavLink className={this.state.reportedIssueSubsection === "reportedIssues" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("reportedIssueSubsection", "reportedIssues")}>
                                                                                                INCIDENT DAMAGES
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Nav>
                                                                                    {this.state.reportedIssueSubsection === "reportedIssues" &&
                                                                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                            <Table responsive>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        {IssuesTable.map(tableHeader =>
                                                                                                            <th key={tableHeader.id} style={{ fontWeight: 500 }}>{tableHeader.name}</th>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {this.props.carCondition &&
                                                                                                        this.props.carCondition.incidentSet &&
                                                                                                        this.props.carCondition.incidentSet.edges &&
                                                                                                        this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).length > 0
                                                                                                        ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map(incident => (
                                                                                                            incident.node.incidentdamageSet.edges.length > 0
                                                                                                                ? incident.node.incidentdamageSet.edges.map((incidentDamage, i) => (
                                                                                                                    <tr key={`incidentDamage__${i}`}>
                                                                                                                        <td>
                                                                                                                            <small>{incidentDamage.node.damage.dateAdded ? <DatetimeRenderer datetime={incidentDamage.node.damage.dateAdded} placement="right" /> : "---"}</small>
                                                                                                                        </td>
                                                                                                                        <td><small>{incidentDamage.node.damage && incidentDamage.node.damage.issueCategory.name}</small></td>
                                                                                                                        <td><small>{incidentDamage.node.damage.status ? incidentDamage.node.damage.status : "---"}</small></td>
                                                                                                                        <td><small>{incidentDamage.node.damage.projectedCost ? `$${incidentDamage.node.damage.projectedCost}` : "---"}</small></td>
                                                                                                                        <td><small>{this.props.carIssueSeverityLevels && this.props.carIssueSeverityLevels.states && this.props.carIssueSeverityLevels.states.find(item => item.name === incidentDamage.node.damage.severity).description}</small></td>
                                                                                                                        <td><small>{incidentDamage.node.damage.canPickup ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</small></td>
                                                                                                                        <td>
                                                                                                                            <small>
                                                                                                                                {incidentDamage.node.damage.pictures && incidentDamage.node.damage.pictures.edges && incidentDamage.node.damage.pictures.edges.length > 0 ?
                                                                                                                                    <div onClick={() => this.viewPictures(incidentDamage.node.damage.pictures.edges)}>
                                                                                                                                        <i className="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
                                                                                                                                        {incidentDamage.node.damage.pictures.edges.length > 1 &&
                                                                                                                                            <a className="bos-custom-link" target="_blank">
                                                                                                                                                {" "}+{incidentDamage.node.damage.pictures.edges.length - 1} more
                                                                                                                                            </a>}
                                                                                                                                    </div> :
                                                                                                                                    "No Pictures Found"}
                                                                                                                            </small>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <i id={"moveDamges" + i} class="fa fa-exchange" aria-hidden="true" onClick={() => this.setState({ openModal: "moveDamges" + i })}></i> &nbsp;&nbsp;
                                                                                                                            <i id={"editCarIssue" + i} class="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.setState({ openModal: "editCarIssue" + i })}></i>
                                                                                                                            {this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={incidentDamage.node.damage} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                                                                                                        </td>
                                                                                                                        <UncontrolledTooltip target={"moveDamges" + i} >Move Damage</UncontrolledTooltip>
                                                                                                                        <UncontrolledTooltip target={"editCarIssue" + i} >Edit Car Issue</UncontrolledTooltip>

                                                                                                                        {this.state.openModal === "moveDamges" + i && this.state.carInspectionSubsection === "carIssue" &&
                                                                                                                            <Popover placement="left" target={"moveDamges" + i} isOpen={this.state.openModal === "moveDamges" + i && this.state.carInspectionSubsection === "carIssue"} toggle={() => this.toggleModal("moveDamges" + i)}>
                                                                                                                                <PopoverHeader>Move Damage</PopoverHeader>
                                                                                                                                <PopoverBody>
                                                                                                                                    <Row>
                                                                                                                                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                                                                                                        {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                                                                                                    </Row>
                                                                                                                                    <Row>
                                                                                                                                        <Col>
                                                                                                                                            <FormGroup>
                                                                                                                                                <Label>Select the incident to move the damage</Label>
                                                                                                                                                <Select
                                                                                                                                                    className="bos-custom-select" classNamePrefix="bos-select"
                                                                                                                                                    isLoading={this.props.tenantsLoading}
                                                                                                                                                    options={this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges.length > 0
                                                                                                                                                        ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id !== this.state.activeIncidentId).map((incident) => ({
                                                                                                                                                            value: incident.node.id,
                                                                                                                                                            label: `Incident #${incident.node.pk} - ${incident.node.dateAdded ? new Date(incident.node.dateAdded).toLocaleDateString() : "--"}`
                                                                                                                                                        }))
                                                                                                                                                        : []}
                                                                                                                                                    placeholder="Select Incident"
                                                                                                                                                    onChange={(incident) => this.setState({ selectedIncidentId: incident.value })}
                                                                                                                                                />
                                                                                                                                            </FormGroup>
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    <Row>
                                                                                                                                        <Col className="text-right">
                                                                                                                                            <Button className="Add-driver-modal-primary-btn" onClick={() => this.handleMoveDamage(incidentDamage.node.id)} disabled={this.state.loading || !(this.state.selectedIncidentId)}>{this.state.loading ? "Loading..." : "Move Damage"}</Button>{' '}
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </PopoverBody>
                                                                                                                            </Popover>
                                                                                                                        }
                                                                                                                    </tr>
                                                                                                                ))
                                                                                                                : <tr><td colSpan="8" style={{ color: "red" }}>No Damages Found!</td></tr>
                                                                                                        ))
                                                                                                        : <tr><td colSpan="8" style={{ color: "red" }}>No Incidents Found!</td></tr>
                                                                                                    }

                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <br />
                                                                            <Row>
                                                                                <Col>
                                                                                    <Nav tabs>
                                                                                        <NavItem className={this.state.tab === "damageTransactions" ? "active" : "inactive"}>
                                                                                            <NavLink className={this.state.damageTransactionSubsection === "damageTransactions" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("damageTransactionSubsection", "damageTransactions")}>
                                                                                                DAMAGE TRANSACTIONS
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                        <div className="ml-auto">
                                                                                            {this.state.damageTransactionSubsection === "damageTransactions" && this.props.carCondition &&
                                                                                                this.props.carCondition.incidentSet &&
                                                                                                this.props.carCondition.incidentSet.edges &&
                                                                                                this.props.carCondition.incidentSet.edges.length > 0 &&
                                                                                                this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId && !item.node.damageTransaction && (item.node.incidentDamageSet && item.node.incidentDamageSet.edges && item.node.incidentDamageSet.edges.length > 0)).length > 0 &&
                                                                                                <div>
                                                                                                    <Button id={"editDamageTransaction"} className="primary" onClick={() => this.setState({ openModal: "editDamageTransaction" })}><i class="fa fa-pencil-square-o" aria-hidden="true"></i></Button>
                                                                                                    <UncontrolledTooltip target={"editDamageTransaction"}>Edit Damage Transaction</UncontrolledTooltip>
                                                                                                </div>
                                                                                            }
                                                                                            {this.state.openModal === "editDamageTransaction" && this.state.damageTransactionSubsection === "damageTransactions" &&
                                                                                                <Popover placement="left" target={"editDamageTransaction"} isOpen={this.state.openModal === "editDamageTransaction" && this.state.damageTransactionSubsection === "damageTransactions"} toggle={() => this.toggleModal("editDamageTransaction")}>
                                                                                                    <PopoverHeader>Edit Damage Transaction</PopoverHeader>
                                                                                                    <PopoverBody style={{ width: 370 }}>
                                                                                                        <Row>
                                                                                                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                                                                            {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                                                                        </Row>
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <Label>Select Transaction</Label>
                                                                                                                    <Select
                                                                                                                        className="bos-custom-select"
                                                                                                                        classNamePrefix="bos-select"
                                                                                                                        onChange={this.handleTransactionChange}
                                                                                                                        options={this.state.transactions}
                                                                                                                        placeholder="Select Transaction"
                                                                                                                    />
                                                                                                                </FormGroup >
                                                                                                            </Col >
                                                                                                        </Row >
                                                                                                        <Row>
                                                                                                            <Col className="text-right">
                                                                                                                <Button className="Add-driver-modal-primary-btn" onClick={() => this.updateIncident(this.state.activeIncidentId, "transaction")} disabled={this.state.loading || !(this.state.transactionId)}>{this.state.loading ? "Loading..." : "Update"}</Button>{' '}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </PopoverBody >
                                                                                                </Popover >
                                                                                            }
                                                                                        </div >

                                                                                    </Nav >
                                                                                    {
                                                                                        this.state.damageTransactionSubsection === "damageTransactions" &&
                                                                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                            <Table responsive>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{ fontWeight: 500 }}>ID</th>
                                                                                                        <th style={{ fontWeight: 500 }}>Date Added</th>
                                                                                                        <th style={{ fontWeight: 500 }}>Charge Type</th>
                                                                                                        <th style={{ fontWeight: 500 }}>Amount</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map(incident =>
                                                                                                        incident.node.damageTransaction ?
                                                                                                            <tr>
                                                                                                                <td><small>{incident.node.damageTransaction.pk}</small></td>
                                                                                                                <td><small>{incident.node.damageTransaction.dateAdded ? <DatetimeRenderer datetime={incident.node.damageTransaction.dateAdded} placement="right" /> : "---"}</small></td>
                                                                                                                <td><small>{incident.node.damageTransaction.chargeType ? incident.node.damageTransaction.chargeType.name : "---"}<br /><small>{incident.node.damageTransaction.notes}</small></small></td>
                                                                                                                <td><small>{incident.node.damageTransaction.dynamicAmount ? `$${incident.node.damageTransaction.dynamicAmount}` : "---"}</small></td>
                                                                                                            </tr> : <tr><td colSpan={4} style={{ color: "red" }}>No Damage Transactions Found!</td></tr>
                                                                                                    ) : <tr><td colSpan={4} style={{ color: "red" }}>No Incident Found!</td></tr>}
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    }
                                                                                </Col >
                                                                            </Row >
                                                                        </>
                                                                        :
                                                                        <Row>
                                                                            <Col>
                                                                                <p>No incident found!</p>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                </Col >
                                                            </Row >
                                                        </div >
                                                        :
                                                        <div>

                                                        </div>
                                            : <p style={{ color: "red" }}>No Car Condition Found!</p>
                                        }
                                    </Col >
                                </Row >
                            </div >
                            <br />
                            <Nav tabs>
                                <NavItem className={this.state.tab === "walkaroundPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "walkaroundPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "walkaroundPictures")}>
                                        Walkaround Pictures
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "otherPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "otherPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "otherPictures")}>
                                        Other Pictures
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "inspectionReport" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "inspectionReport" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "inspectionReport")}>
                                        Inspection Report
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {
                                this.state.picturesSetSubsection === "walkaroundPictures" ?
                                    <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                        <Row>
                                            <Col>
                                                <Fragment>
                                                    <div className="DocumentCardContainer" >
                                                        {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture =>
                                                            picture.node.pictureType &&
                                                            picture.node.pictureType.toLowerCase() === "exterior_walkaround"
                                                        ).map((document, index) =>
                                                            <Card className="DocumentCard" key={index}>
                                                                <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture =>
                                                                    picture.node.pictureType &&
                                                                    picture.node.pictureType.toLowerCase() === "exterior_walkaround"
                                                                ), index)}>
                                                                    <p className="DocumentCardTitle">
                                                                        <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                        <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                    </p>
                                                                    <p><i className="fa fa-arrows-alt"></i></p>
                                                                </div>
                                                                <div className="DocumentCardPreview" >
                                                                    {document.node ? (
                                                                        <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                    ) : ''}
                                                                </div>
                                                            </Card>
                                                        )}
                                                    </div >
                                                </Fragment>
                                            </Col>
                                        </Row>
                                    </div> :
                                    this.state.picturesSetSubsection === "otherPictures" ?
                                        <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                            <Row>
                                                <Col>
                                                    <Fragment>
                                                        <div className="DocumentCardContainer" >
                                                            {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType && picture.node.pictureType.toLowerCase() !== "exterior_walkaround").map((document, index) =>
                                                                <Card className="DocumentCard" key={index}>
                                                                    <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType && picture.node.pictureType.toLowerCase() !== "exterior_walkaround"
                                                                    ), index)}>
                                                                        <p className="DocumentCardTitle">
                                                                            <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                            <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                        </p>
                                                                        <p><i className="fa fa-arrows-alt"></i></p>
                                                                    </div>
                                                                    <div className="DocumentCardPreview" >
                                                                        {document.node ? (
                                                                            <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                        ) : ''}
                                                                    </div>
                                                                </Card>
                                                            )}
                                                        </div >
                                                    </Fragment>
                                                </Col>
                                            </Row>
                                        </div> :
                                        <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                            <Row>
                                                <Col>
                                                    <Fragment>
                                                        <div className="DocumentCardContainer" >
                                                            {this.props.carCondition && this.props.carCondition.inspectionReportPdf &&
                                                                <Card className="DocumentCard" key={`report-${this.props.carCondition.pk}`}>
                                                                    {this.state.openModal === "viewInspectionReport" &&
                                                                        <ViewPDFDocument
                                                                            doc={this.props.carCondition.inspectionReportPdf}
                                                                            open={this.state.openModal === "viewInspectionReport"}
                                                                            handleClose={() => this.toggleModal("")}
                                                                            title="Inspection Report"
                                                                        />
                                                                    }
                                                                    <div className="DocumentCardHeader"
                                                                        onClick={() => this.toggleModal("viewInspectionReport")}
                                                                    >
                                                                        <p className="DocumentCardTitle">
                                                                            <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                            <span>Inspection Report <small>Inspection PDF Report</small></span>
                                                                        </p>
                                                                        <p><i className="fa fa-arrows-alt"></i></p>
                                                                    </div>
                                                                    <div className="DocumentCardPreview" >
                                                                        {this.props.carCondition.inspectionReportPdf ? (
                                                                            <iframe scrolling="no" className="DocumentCardPreviewEmbed DocViewerImage" src={`data:application/pdf;base64,${this.props.carCondition.inspectionReportPdf}`} />
                                                                        ) : ''}
                                                                    </div>
                                                                </Card>
                                                            }
                                                        </div >
                                                    </Fragment>
                                                </Col>
                                            </Row>
                                        </div>
                            }
                        </div >
                    </>}
                </Col >
            </>
        )
    }
}

export default graphql(CarConditionDetailQuery, {
    options: ({ id }) => ({
        variables: { id },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    }),
    props: ({ data: { carCondition, loading, refetch, variables } }) => ({
        carCondition,
        carConditionLoading: loading,
        variables,
        refetchQuery: () => {
            return refetch({
                query: CarConditionDetailQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { carCondition: fetchMoreResult.carCondition }
                },
            })
        },
    }),
})(
    graphql(CarIssuesSeverityLevelQuery, {
        props: ({ data: { carIssueSeverityLevels, loading } }) => ({
            carIssueSeverityLevels,
            loading,
        }),
    })(
        graphql(IncidentReportedByChoices, {
            props: ({ data: { allIncidentChoices } }) => ({
                allIncidentChoices,
            }),
        })(withApolloClient(CarInspectionDetail))
    )
);


