//Iagami - Code upgradation React 18
import React from 'react'
import { ApolloConsumer } from '@apollo/client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withApolloClient(Component) {
    return function WrappedComponent(props) {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        return (
            <ApolloConsumer>
                {(client) => <Component {...props} location={location} navigate={navigate} params={params} apolloClient={client} />}
            </ApolloConsumer>
        )
    }
}

export default withApolloClient