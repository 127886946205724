import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from "reactstrap"
import { Route, Switch, Link, useParams } from "react-router-dom" // using useParams for React Router v6 compatibility
import ChatBotSession from "./ChatBotSession"
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { firestoreDb } from "../Components/firebase"
import "./ChatBotContainer.css"

export default function ChatBotContainer({ currentUser, ...props }) {
    // Use useParams instead of props.match for React Router v6
    const { sessionId } = useParams()
    const [sessions, setSessions] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getSessions = async () => {
        const q = query(
            collection(firestoreDb, "chatbot_session"),
            where("is_archived", "==", false),
            orderBy("added_on", "desc")
        )
        try {
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const fetchedSessions = snapshot.docs.map(doc => doc.data())
                setSessions(fetchedSessions)
                setLoading(false) // Once data is fetched, stop loading
            })

            // Cleanup the Firestore listener on unmount
            return () => unsubscribe()
        } catch (err) {
            setError("Error fetching sessions")
            setLoading(false)
            console.error("Error fetching sessions: ", err)
        }
    }

    useEffect(() => {
        getSessions()
    }, [])

    if (loading) {
        return <div>Loading...</div> // You can replace with a loading spinner or skeleton loader
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>
    }

    return (
        <Container fluid>
            <Row>
                {/* Left column for session list */}
                <Col xs="2" className="ChatBotColumn">
                    <ul className="ChatBotList">
                        <li className={!sessionId ? "active" : ""}>
                            <Link to="/chatbot/">New Chat</Link>
                        </li>
                        {sessions.length > 0 && sessions.map((session, idx) => (
                            <li className={sessionId === session.session_id ? "active" : ""} key={idx}>
                                <Link to={`/chatbot/${session.session_id}/`}>{session.title}</Link>
                            </li>
                        ))}
                    </ul>
                </Col>

                {/* Right column for displaying the ChatBotSession */}
                <Col xs="10">
                    <ChatBotSession sessionIdFromParent={sessionId} currentUser={currentUser} />
                </Col>
            </Row>
        </Container>
    )
}
