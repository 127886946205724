import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import moment from 'moment-timezone'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, UncontrolledTooltip, Input } from 'reactstrap'
import Select from 'react-select'
import toTitleCase from "../Functions/toTitleCase"
import { GetConfigurationSettings, HasPermissionsQuery } from "../Functions/querys"
import withApolloClient from '../withApolloClient'
import { getEnumDescription } from "../Functions/processEnumDescription"
import "./ScheduleReturnForm.css"

const scheduleReturnForDriver = gql`
mutation scheduleReturnForDriver($input: ScheduleReturnInput!){
    scheduleReturnForDriver(input:$input){
    ok
    errors{
        messages
    }
}
} `
const scheduleSwitchForDriver = gql`
mutation scheduleSwitchForDriver($input: ScheduleSwitchInput!){
    scheduleSwitchForDriver(input:$input){
    ok
    errors{
        messages
    }
}
} `
const changeReturn = gql`
mutation changeReturn($input: ChangeReturnInput!){
    changeReturn(input:$input){
    ok
    errors{
        messages
    }
}
} `
const returnReasons = gql`
query returnReasons{
    globalSettings (settingsName_Icontains:"return_reasons") {
        edges {
          node {
            settingsName
            settingsValue
          }
        }
    }
}`
const AllCarLocationsQuery = gql`
  query AllCarLocations($branchId:ID!){
    allCarLocations(branchId:$branchId, isReturnLocation:true){
        edges{
            node{
                id
                pk
                name
                isDefaultReturnLocation
            }
        }
    }
  }
`

const categories = {
    Return: ["Final Return", "Mechanical Return", "Accident Return", "Repo Return"],
    Switch: ["Personal Switch", "Accident Switch", "Mechanical Switch", "Insurance/TLC Switch", "Split Switch"]
}
const returnTypeOptions = [
    { value: "Return", label: "Return" },
    { value: "Switch", label: "Switch" }
]

class ScheduleReturnForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            returnType: props.carReturn?.returnType ? toTitleCase(props.carReturn.returnType) : "Return",
            categories: categories[props.carReturn?.returnType ? toTitleCase(props.carReturn.returnType) : "Return"] || categories["Return"],
            returnCategory: props.carReturn?.returnCategory ? toTitleCase(props.carReturn.returnCategory) : "Final Return",
            returnScheduled: false,
            returnReason: props.carReturn?.returnReason ? props.carReturn.returnReason.trim() : null,
            returnDate: props.carReturn?.scheduledDate ? props.carReturn.scheduledDate : null,
            willPickInFuture: props.carReturn?.nextPickupDate ? true : false,
            futurePickupDate: props.carReturn?.nextPickupDate ? props.carReturn.nextPickupDate : "",
            autoDepositRefund: props.editReturn && props.carReturn?.autoDepositRefund ? props.carReturn.autoDepositRefund : false,
            actualReturnReason: null,
            carStage: null,
            returningLocation: null,
            location: null,
            ignoreUnscheduledWarning: false,
            loading: false,
            error: null,
            openModal: "",
            allReturnReasons: null,
            returnReasons: null,
            futurePickUpValid: true,
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value

        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            value = value === "true"
        }
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            value = !this.state[name]
            if (name === "willPickInFuture" && !value) this.setState({ futurePickupDate: "" })
        }
        if (name === "futurePickupDate") {
            const futurePickupDate = moment(value).tz("America/New_York")
            const returnDate = moment(this.state.returnDate).tz("America/New_York")

            if (futurePickupDate.isBefore(returnDate, 'day')) {
                this.setState({
                    error: "Future pickup date cannot be before return date",
                    futurePickUpValid: false
                })
            } else {
                this.setState({
                    error: null,
                    futurePickUpValid: true
                })
            }
        }

        this.setState({ [name]: value })
    };

    handleSelectChange = (name, selectedOption) => {
        const value = selectedOption ? selectedOption.value : null

        if (name === "returnType") {
            const newCategories = categories[value] || categories["Return"]
            const defaultCategory = value === "Return" ? "Final Return" : "Personal Switch"

            this.setState({
                categories: newCategories,
                returnCategory: defaultCategory,
                willPickInFuture: false,
                futurePickupDate: ""
            })

            this.setReturnReasons(this.props.globalSettings, defaultCategory)
        } else if (name === "returnCategory") {
            this.setReturnReasons(this.props.globalSettings, value)
        }

        this.setState({ [name]: value })
    };

    handleLocationSelect = (selectedOption) => {
        this.setState({ returningLocation: selectedOption ? selectedOption.value : null })
    };

    handleClose = () => {
        this.setState({
            loading: false,
            error: null,
            returnType: null,
            categories: null,
            returnCategory: null,
            returnScheduled: false,
            openModal: "",
            returnReason: null,
            actualReturnReason: null,
            location: null,
            returnDate: null,
            willPickInFuture: false,
            futurePickupDate: null,
            allReturnReasons: null,
            returnReasons: null,
        })

        if (this.props.refetchQuery) {
            this.props.refetchQuery()
        }
        this.props.handleClose()
    };

    scheduleReturn = () => {
        try {
            this.setState({ loading: true })

            if ((this.props.editReturn && !this.props.closeReturn) || (!this.props.editReturn && !this.props.closeReturn)) {
                if (!this.state.returnDate) {
                    this.setState({ loading: false, returnScheduled: false, error: "Please provide returning date, in order to proceed!" })
                    return
                }
            }

            if (!this.state.returnReason && ["Personal Switch", "Final Return"].includes(this.state.returnCategory)) {
                this.setState({ loading: false, returnScheduled: false, error: `Please provide reason for ${this.state.returnCategory}, in order to proceed!` })
                return
            }

            if (!this.state.returningLocation && !this.props.editReturn && !this.props.closeReturn) {
                this.setState({ loading: false, returnScheduled: false, error: "Please provide returning location, in order to proceed!" })
                return
            }

            let input = {
                returnReason: this.state.returnReason,
                returningLocation: this.state.returningLocation,
            }

            if (input.returningLocation && typeof input.returningLocation === "string") {
                input.returningLocation = Number(input.returningLocation)
            }

            if (this.props.editReturn) {
                input["carReturnId"] = this.props.carReturn?.id || null
                input["autoDepositRefund"] = this.state.autoDepositRefund
            } else {
                input["rentalId"] = this.props.rental?.id || null
            }

            if ((this.props.editReturn && moment(this.state.returnDate).format(moment.HTML5_FMT.DATETIME_LOCAL) !== moment(this.props.carReturn.scheduledDate).format(moment.HTML5_FMT.DATETIME_LOCAL))) {
                input["returnDate"] = moment(this.state.returnDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
            } else if (!this.props.editReturn) {
                input["returnDate"] = moment(this.state.returnDate).format(moment.HTML5_FMT.DATE)
            }

            if ((this.props.editReturn && this.props.closeReturn) || (!this.props.editReturn && !this.props.closeReturn)) {
                input["returnCategory"] = this.state.returnCategory
            }

            if (this.props.closeReturn) {
                input["returnStage"] = 4
            }

            if (this.state.returnType === "Return" && this.state.returnDate && moment(this.state.returnDate).diff(moment().tz("America/New_York").format(), 'hours') <= 144) {
                input["ignoreUnscheduledWarning"] = this.state.ignoreUnscheduledWarning
            }

            if (this.state.returnType === "Return" && this.state.willPickInFuture) {
                input["futurePickupDate"] = moment(this.state.futurePickupDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)
            }

            this.props.apolloClient.mutate({
                mutation: this.props.editReturn ? changeReturn : (this.state.returnType === "Return" ? scheduleReturnForDriver : scheduleSwitchForDriver),
                variables: { input },
            }).then((result) => {
                if (result.data && (
                    (this.props.editReturn && result.data.changeReturn?.ok) ||
                    (!this.props.editReturn && this.state.returnType === "Return" && result.data.scheduleReturnForDriver?.ok) ||
                    (!this.props.editReturn && this.state.returnType === "Switch" && result.data.scheduleSwitchForDriver?.ok)
                )) {
                    this.setState({ loading: false, returnScheduled: true, error: null })
                    this.handleClose()
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (!this.props.editReturn && this.state.returnType === "Return" && result.data?.scheduleReturnForDriver?.errors?.[0]?.messages?.[0]) {
                        errorMessage = result.data.scheduleReturnForDriver.errors[0].messages[0]
                    } else if (!this.props.editReturn && this.state.returnType === "Switch" && result.data?.scheduleSwitchForDriver?.errors?.[0]?.messages?.[0]) {
                        errorMessage = result.data.scheduleSwitchForDriver.errors[0].messages[0]
                    } else if (this.props.editReturn && result.data?.changeReturn?.errors?.[0]?.messages?.[0]) {
                        errorMessage = result.data.changeReturn.errors[0].messages[0]
                    }
                    this.setState({ loading: false, returnScheduled: false, error: errorMessage })
                }
            }).catch((error) => {
                this.setState({ error: `An error has occured: ${error}`, loading: false, returnScheduled: false })
            })
        } catch (err) {
            this.setState({ error: `An error has occured: ${err}`, loading: false, returnScheduled: false })
        }
    };

    getCarReturnState = (state) => {
        if (!this.props.returnStages) {
            return "------"
        }
        let sobj = this.props.returnStages.states.filter(s => s.name === state)
        if (sobj[0].description == null) {
            sobj = getEnumDescription(sobj)
            return sobj.length ? sobj[0].description.split(' ').slice(2).join(' ') : "------"
        } else {
            return sobj.length ? sobj[0].description : "------"
        }
    };

    locateCar = () => {
        if (this.props.car?.id) {
            this.setState({ loading: true })
            let input = { carId: this.props.car.id, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address,
                        longitude: Number(location.longitude),
                        latitude: Number(location.latitude),
                        speed: location.speed,
                        date: location.date
                    }
                    this.setState({ location: location, openModal: "Location", loading: false })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    };

    setReturnReasons = (gSettings = this.props.globalSettings, category = this.state.returnCategory) => {
        if (gSettings?.edges?.length > 0) {
            let returnReasons = (gSettings.edges[0].node.settingsValue)
            let allReturnReasons = returnReasons.returnReasons ? Object.keys(returnReasons.returnReasons) : []
            allReturnReasons = allReturnReasons.concat(returnReasons.returnReasons ? Object.keys(returnReasons.switchReasons) : [])

            if (category === "Repo Return" && returnReasons.repoReason) {
                returnReasons = returnReasons.repoReason
            } else if (category === "Final Return" && returnReasons.returnReasons) {
                returnReasons = returnReasons.returnReasons
            } else if (category === "Personal Switch" && returnReasons.switchReasons) {
                returnReasons = returnReasons.switchReasons
            } else {
                returnReasons = null
            }

            const reasons = returnReasons ? Object.keys(returnReasons) : null
            const currentReason = this.state.returnReason && reasons?.length > 0 && reasons.map(reason => reason.trim()).includes(this.state.returnReason)
                ? this.state.returnReason
                : null

            this.setState({
                returnReasons: reasons,
                allReturnReasons: allReturnReasons,
                returnReason: currentReason
            })
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.allCarLocations !== prevProps.allCarLocations) {
            let returningLocation = null

            if (!this.props.carReturn?.location) {
                const defaultLocation = this.props.allCarLocations?.edges?.find(loc => loc.node.isDefaultReturnLocation)
                const carLocation = this.props.car?.location
                    ? this.props.allCarLocations?.edges?.find(loc => loc.node.name.trim() === this.props.car.location.trim())
                    : null

                returningLocation = defaultLocation?.node.pk || carLocation?.node.pk || null
            } else if (this.props.carReturn?.location) {
                returningLocation = this.props.allCarLocations?.edges?.find(
                    loc => typeof loc.node.name === "string" && loc.node.name.trim() === this.props.carReturn.location.name.trim()
                )?.node.pk || null
            }

            this.setState({ returningLocation })
        }

        if (this.props.globalSettings !== prevProps.globalSettings && this.props.globalSettings?.edges?.length > 0) {
            this.setReturnReasons(this.props.globalSettings)
        }
    }

    componentDidMount() {
        if (this.props.globalSettings?.edges?.length > 0) {
            this.setReturnReasons(this.props.globalSettings)
        }

        if (!this.state.categories) {
            this.setState({
                categories: categories[this.state.returnType] || categories["Return"],
                returnCategory: this.state.returnCategory || "Final Return"
            })
        }
    }

    toggleSwitch = (toggleName) => {
        this.setState(prevState => ({ [toggleName]: !prevState[toggleName] }))
    }

    render() {
        const { returnType, returnCategory, categories, returnReasons, returningLocation } = this.state
        const { allCarLocations, carReturn, editReturn, closeReturn, rental } = this.props

        const categoryOptions = categories?.map(category => ({ value: category, label: category })) || []
        const returnReasonOptions = returnReasons?.map(reason => ({ value: reason.trim(), label: reason.trim() })) || []
        const locationOptions = allCarLocations?.edges?.map(location => ({ value: location.node.pk, label: location.node.name.trim() })) || []

        const selectedReturnType = returnType ? returnTypeOptions.find(opt => opt.value === returnType) : null
        const selectedCategory = returnCategory ? categoryOptions.find(opt => opt.value === returnCategory) : null
        const selectedReturnReason = this.state.returnReason ? returnReasonOptions.find(opt => opt.value === this.state.returnReason) : null
        const selectedLocation = returningLocation ? locationOptions.find(opt => opt.value === returningLocation) : null

        const isAwaitingCloseout = carReturn && this.getCarReturnState(carReturn.stage).includes("Awaiting Closeout")
        const isReturnTypeDisabled = !(editReturn && closeReturn && isAwaitingCloseout)
        const isCategoryDisabled = !(editReturn && closeReturn && isAwaitingCloseout && categories?.length > 0)

        return (
            <div>
                <Modal className="schedule-return-modal" isOpen={this.props.open}>
                    {rental && (
                        <>
                            <ModalHeader>{closeReturn ? "Close" : (editReturn ? "Edit" : "Schedule")} Return</ModalHeader>
                            <ModalBody>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                                {this.state.returnDate && editReturn && carReturn && carReturn.scheduledDate &&
                                    moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) !== moment(carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) &&
                                    <Row><Col xs={12}><Alert color="warning">
                                        {closeReturn && "Note: Updating the return date at this stage will not close the return!"}
                                        The return will be rescheduled since you have updated the return date.
                                    </Alert></Col></Row>
                                }
                                {this.state.error && !this.state.returnScheduled && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                {this.state.returnScheduled && !this.state.error && <Row><Col xs={12}><Alert color="success">Return scheduled successfully!</Alert></Col></Row>}

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnType">Is this a Return or Switch?</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                name="returnType"
                                                options={returnTypeOptions}
                                                value={selectedReturnType}
                                                onChange={(selected) => this.handleSelectChange("returnType", selected)}
                                                isDisabled={isReturnTypeDisabled}
                                                placeholder={`Select ${returnType || "Return"}`}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnCategory">Select {returnType || "Return"} Category</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                name="returnCategory"
                                                options={categoryOptions}
                                                value={selectedCategory}
                                                onChange={(selected) => this.handleSelectChange("returnCategory", selected)}
                                                isDisabled={isCategoryDisabled}
                                                placeholder={`Select ${returnType || "Return"} Category`}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnDate" className="d-block">
                                                When is the driver {returnType ? returnType.toLowerCase() : "return"}ing?
                                                {editReturn && (
                                                    <>
                                                        <a onClick={this.locateCar} id="returnDatePopup" aria-hidden="true" className="float-right">
                                                            <i className="fa fa-info-circle text-warning" aria-hidden="true"></i>
                                                        </a>
                                                        <UncontrolledTooltip placement="top" target="returnDatePopup">
                                                            Note: The return will be rescheduled if you update the return date!
                                                        </UncontrolledTooltip>
                                                    </>
                                                )}
                                            </Label>
                                            <Input
                                                type={editReturn ? "datetime-local" : "date"}
                                                disabled={carReturn && ["A_4_RETURNED", "A_0_CANCELLED", "A_3_AWAITING_CLOSEOUT"].includes(carReturn.stage)}
                                                min={moment().tz("America/New_York").format(editReturn ? moment.HTML5_FMT.DATETIME_LOCAL : moment.HTML5_FMT.DATE)}
                                                defaultValue={moment(this.state.returnDate).tz("America/New_York").format(editReturn ? moment.HTML5_FMT.DATETIME_LOCAL : moment.HTML5_FMT.DATE)}
                                                name="returnDate"
                                                onChange={this.updateInput}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returningLocation" className="d-block">Car Returning Location?</Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                name="returningLocation"
                                                options={locationOptions}
                                                value={selectedLocation}
                                                onChange={this.handleLocationSelect}
                                                isDisabled={!allCarLocations || (carReturn && ["A_4_RETURNED", "A_0_CANCELLED"].includes(carReturn.stage))}
                                                placeholder="Select Car Location"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnReason">
                                                Why is the driver {returnType ? returnType.toLowerCase() : "return"}ing?
                                            </Label>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                name="returnReason"
                                                options={returnReasonOptions}
                                                value={selectedReturnReason}
                                                onChange={(selected) => this.setState({ returnReason: selected ? selected.value : null })}
                                                isDisabled={!returnReasons || (carReturn && ["A_4_RETURNED"].includes(carReturn.stage))}
                                                placeholder={`Select reason for ${returnCategory || "return"}`}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <legend className="col-form-label">Will driver pickup in future?</legend>
                                            <Row>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check for="willPickInFuture">
                                                            <Input
                                                                type="radio"
                                                                name="willPickInFuture"
                                                                disabled={!returnType || returnType === "Switch"}
                                                                value={false}
                                                                checked={this.state.willPickInFuture !== true}
                                                                onChange={this.updateInput}
                                                            />{' '}NO
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check for="willPickInFuture">
                                                            <Input
                                                                type="radio"
                                                                name="willPickInFuture"
                                                                disabled={!returnType || returnType === "Switch"}
                                                                value={true}
                                                                checked={this.state.willPickInFuture === true}
                                                                onChange={this.updateInput}
                                                            />{' '}YES
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6}>
                                        <FormGroup>
                                            <Label for="futurePickupDate">Future pickup date?</Label>
                                            <Input
                                                min={this.state.returnDate ? moment(this.state.returnDate).add(1, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) : moment().add(1, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                                readOnly={!this.state.willPickInFuture || (!returnType || returnType === "Switch")}
                                                value={moment(this.state.futurePickupDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)}
                                                type="date"
                                                name="futurePickupDate"
                                                onChange={this.updateInput}
                                            />
                                        </FormGroup>
                                    </Col>

                                    {returnType && returnType.toLowerCase() !== "switch" && (!editReturn || moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) !== moment(carReturn?.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)) &&
                                        this.state.returnDate && moment(this.state.returnDate).diff(moment().tz("America/New_York").format(), 'hours') <= 144 && (
                                            <Col xs={12}>
                                                <FormGroup>
                                                    <Label for="ignoreUnscheduledWarning" className="text-danger">
                                                        Return might be unscheduled. Do you want to ignore this?
                                                    </Label>
                                                    <Select
                                                        className="bos-custom-select"
                                                        classNamePrefix="bos-select"
                                                        name="ignoreUnscheduledWarning"
                                                        options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]}
                                                        value={this.state.ignoreUnscheduledWarning ? { value: true, label: 'Yes' } : { value: false, label: 'No' }}
                                                        onChange={(selectedOption) => this.setState({ ignoreUnscheduledWarning: selectedOption.value })}
                                                        placeholder="Ignore unscheduled warning?"
                                                        isSearchable={false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                </Row>

                                {this.props.hasPermissions?.includes("custom_can_change_auto_deposit_refund") &&
                                    typeof this.props.enableAutoDepositRefund === "string" &&
                                    this.props.enableAutoDepositRefund === "true" && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="autoDepositRefund">Auto Deposit Refund</Label>
                                                    <Input
                                                        type="checkbox"
                                                        id="autoDepositRefund"
                                                        checked={this.state.autoDepositRefund}
                                                        onChange={() => this.toggleSwitch("autoDepositRefund")}
                                                        disabled={carReturn && carReturn.stage.includes("A_4_RETURNED")}
                                                        style={{ transform: 'scale(1.5)', marginLeft: "10px" }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                            </ModalBody>
                        </>
                    )}

                    <ModalFooter>
                        {!this.state.loading && (
                            <Row>
                                <Col xs={12}>
                                    <Button className="schedule-return-modal-secondary-btn" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    {(!editReturn || (carReturn && ["A_1_CREATED", "A_2_CONFIRMED", "A_3_AWAITING_CLOSEOUT"].includes(carReturn.stage))) && (
                                        <Button
                                            className="schedule-return-modal-primary-btn"
                                            onClick={this.scheduleReturn}
                                            disabled={this.state.loading || (this.state.willPickInFuture && !this.state.futurePickupDate) || !this.state.futurePickUpValid}
                                        >
                                            {closeReturn && this.state.returnDate && editReturn && carReturn && carReturn.scheduledDate &&
                                                moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) !== moment(carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)
                                                ? "Update"
                                                : closeReturn
                                                    ? "Close"
                                                    : editReturn
                                                        ? "Update"
                                                        : "Schedule"} Return
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default graphql(returnReasons, {
    props: ({ data: { globalSettings } }) => ({
        globalSettings,
    }),
})(
    graphql(AllCarLocationsQuery, {
        options: ({ rental }) => ({
            variables: {
                branchId: rental.driver?.branch?.id || rental.car.branch.id,
            },
        }),
        props: ({ data: { loading, allCarLocations } }) => ({
            loading,
            allCarLocations,
        }),
    })(
        graphql(HasPermissionsQuery, {
            options: () => ({
                variables: {
                    userPermissions: ['custom_can_change_auto_deposit_refund'],
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
            }),
            props: ({ data: { hasPermissions, loading, variables } }) => ({
                hasPermissions,
                loading,
                variables,
            }),
        })(
            graphql(GetConfigurationSettings, {
                options: ({ rental }) => ({
                    variables: {
                        name: 'ENABLE_AUTO_DEPOSIT_REFUND',
                        branchId: rental.driver?.branch?.id || rental.car.branch.id,
                    },
                }),
                props: ({ data: { getConfigurationSettings, loading } }) => ({
                    loading,
                    enableAutoDepositRefund: getConfigurationSettings,
                }),
            })(withApolloClient(ScheduleReturnForm))
        )
    )
)
