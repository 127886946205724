// Iagami - Code upgradation React 18
//-----------------------------------
import React from 'react';
import {
    GoogleMap,
    Marker,
    Polyline,
    InfoWindow,
    useJsApiLoader
} from '@react-google-maps/api';

const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const GoogleMapComponent = (props) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I"
    });

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const driverLocation = props.driverlatitude && props.driverlongitude ? { lat: props.driverlatitude, lng: props.driverlongitude } : null;
    const carLocation = props.carlatitude && props.carlongitude ? { lat: props.carlatitude, lng: props.carlongitude } : null;
    const distance = carLocation && driverLocation ? calculateDistance(props.driverlatitude, props.driverlongitude, props.carlatitude, props.carlongitude) : null;

    const driverIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="30" height="30" fill="#ff0000">
                <path d="M256 256a112 112 0 1 0-112-112 112 112 0 0 0 112 112zm80 32h-11.4a146.59 146.59 0 0 1-137.2 0H176a80 80 0 0 0-80 80v46.06A46.06 46.06 0 0 0 142.06 512h227.88A46.06 46.06 0 0 0 416 414.06V368a80 80 0 0 0-80-80z"/>
            </svg>
        `)}`,
        scaledSize: new window.google.maps.Size(30, 30),
    };

    const carIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="30" height="30" fill="#008000">
                <path d="M512 304v96a32 32 0 0 1-32 32h-32a64 64 0 0 1-128 0H192a64 64 0 0 1-128 0H32a32 32 0 0 1-32-32v-96a32 32 0 0 1 32-32l25.81-77.44A96 96 0 0 1 151 128h210a96 96 0 0 1 93.19 66.56L480 272a32 32 0 0 1 32 32zM151 208h210a32 32 0 0 0-32-32H183a32 32 0 0 0-32 32zm-71.17 64h352.34l-15-45.14A32 32 0 0 0 361 192H151a32 32 0 0 0-30.2 21.09zm55.17 144a32 32 0 1 0-32-32 32 32 0 0 0 32 32zm256 0a32 32 0 1 0-32-32 32 32 0 0 0 32 32z"/>
            </svg>
        `)}`,
        scaledSize: new window.google.maps.Size(30, 30),
    };

    return (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            zoom={16}
            center={driverLocation ? driverLocation : carLocation}
        >
            {driverLocation && (
                <Marker
                    position={driverLocation}
                    icon={driverIcon}
                />
            )}
            {carLocation && (
                <Marker
                    position={carLocation}
                    icon={carIcon}
                />
            )}
            {carLocation && driverLocation && (
                <Polyline
                    path={[driverLocation, carLocation]}
                    options={{
                        strokeColor: '#1b6ef3',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        geodesic: true,
                    }}
                />
            )}
            {carLocation && driverLocation && (
                <InfoWindow
                    position={{
                        lat: (props.driverlatitude + props.carlatitude) / 2,
                        lng: (props.driverlongitude + props.carlongitude) / 2,
                    }}
                >
                    <div style={{
                        fontSize: '14px',
                        color: '#333',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        maxWidth: '200px',
                        textAlign: 'center',
                        lineHeight: '1.5',
                    }}>
                        <strong style={{ color: '#007bff' }}>Distance</strong>
                        <div style={{ marginTop: '4px' }}>
                            {distance ? `${distance.toFixed(2)} km` : 'Distance unavailable'}
                        </div>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

export default GoogleMapComponent;
