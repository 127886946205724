/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Progress, Alert, FormGroup } from 'reactstrap'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import Select from 'react-select'
import UploadDocumentModal from './UploadDocumentModal'
import './DocumentModal.css'
import { AllCarDocumentTypesQuery, AllDriverDocumentTypesQuery, AllDocumentCategoryQuery } from "./Quries"
import { CarDocumentsList, DriverDocumentsList } from "./DocumentsListNew"

class CarDocumentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentTypes: [],
            docCats: [],
            isArchived: false,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return <Modal isOpen={this.props.open} className="car-document-modal">
            <ModalHeader> {this.props.objectType === "car" ? <i className="fa fa-car" aria-hidden="true"></i> : <i className="fa fa-user" aria-hidden="true"></i>}&nbsp;&nbsp;&nbsp;&nbsp;<span>{this.props.objectType === "car" ? "Car Document Folder" : "Driver Document Folder"}</span>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-folder-open" aria-hidden="true"></i></ModalHeader>
            {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
            {(this.props.loading || this.state.loading) && <Progress animated color="info" value="100" />}
            <ModalBody>
                <Row className="pb-3">
                    <Col>
                        <FormGroup className="mb-0">
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                isLoading={!(this.props.allDocumentCategory && this.props.allDocumentCategory.edges && this.props.allDocumentCategory.edges.length > 0)}
                                options={this.props.allDocumentCategory && this.props.allDocumentCategory.edges && this.props.allDocumentCategory.edges.length > 0 && this.props.allDocumentCategory.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                placeholder="All Categories"
                                isMulti
                                isClearable
                                onChange={cats => this.setState({ docCats: (cats.map(item => item.value)) })} />
                        </FormGroup>
                    </Col>
                    {this.props.objectType === "car" && <Col>
                        <FormGroup className="mb-0">
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                isLoading={this.props.allCarDocumentTypesloading}
                                options={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 ? this.props.allCarDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                placeholder="All Document Types"
                                isMulti
                                isClearable
                                onChange={types => this.setState({ documentTypes: (types.map(item => item.value)) })} />
                        </FormGroup>
                    </Col>}
                    {this.props.objectType === "driver" && <Col>
                        <FormGroup className="mb-0">
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                isLoading={this.props.allDriverDocumentTypesloading}
                                options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 ? this.props.allDriverDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                placeholder="All Document Types"
                                isMulti
                                isClearable
                                onChange={types => this.setState({ documentTypes: (types.map(item => item.value)) })} />
                        </FormGroup>
                    </Col>}
                    <Col className="pt-1">
                        <small>Exclude Archived</small>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="isArchived"
                                id="isArchived"
                                checked={this.state.isArchived}
                                onChange={() => this.setState({ isArchived: !this.state.isArchived })}
                            />
                            <label className="toggle-switch-label" htmlFor="isArchived">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <small>Show Archived</small>
                    </Col>
                </Row>
                {this.props.objectType === "driver" &&
                    <DriverDocumentsList
                        driverId={this.props.id}
                        typesIn={this.state.documentTypes}
                        categoriesIn={this.state.docCats.length > 0 ? this.state.docCats : []}
                        isArchived={this.state.isArchived}
                        currentUser={this.props.currentUser}
                    />
                }
                {this.props.objectType === "car" &&
                    <CarDocumentsList
                        carId={this.props.id}
                        carPk={this.props.carPk}
                        typesIn={this.state.documentTypes}
                        categoriesIn={this.state.docCats.length > 0 ? this.state.docCats : []}
                        isArchived={this.state.isArchived}
                        currentUser={this.props.currentUser}
                    />
                }
            </ModalBody>
            <ModalFooter>
                {this.state.openModal === "UploadDocumentModal" && <UploadDocumentModal handleClose={() => this.toggleModal("UploadDocumentModal")} open={this.state.openModal === "UploadDocumentModal"} objectType={this.props.objectType} id={this.props.id} refetchQuery={this.props.refetchOnUpdate ? this.props.refetchOnUpdate : () => { return true }} />}
                <Button className="primary" onClick={() => this.toggleModal("UploadDocumentModal")}>Upload New Doc</Button>
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCarDocumentTypesQuery, {
    props: ({ data: { loading, allCarDocumentTypes } }) => ({
        allDriverDocumentTypesloading: loading,
        allCarDocumentTypes
    })
})(
    graphql(AllDocumentCategoryQuery, {
        props: ({ data: { allDocumentCategory } }) => ({
            allDocumentCategory
        })
    })(
        graphql(AllDriverDocumentTypesQuery, {
            options: ({ id }) => ({
                variables: { driverId: id },
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true
            }),
            props: ({ data: { loading, allDriverDocumentTypes } }) => ({
                allDriverDocumentTypesloading: loading,
                allDriverDocumentTypes
            })
        })(CarDocumentModal)
    )
)
