import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Progress, Label, Button, FormGroup,
    Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText
} from 'reactstrap'
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const AllRideSharingPartnersQuery = gql`query AllRideSharingPartnersQuery{
    allRideSharingPartners{
        edges{
            node{
                name
                id
            }
        }
    }
}`
const updateOrCreateBaseStatusMutation = gql`
mutation updateOrCreateBaseStatusMutation($input: UpdateOrCreateBaseStatusMutationInput!){
    updateOrCreateBaseStatus(input:$input){
        ok
        errors{
            messages
        }
        newBaseStatus
}
} `
class UploadOrCreateBaseStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            updated: false,
            partnerId: null,
            openModal: "",
            newBaseStatus: null
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
            loading: false,
            error: null,
            updated: false,
            partnerId: null,
            openModal: "",
            newBaseStatus: null
        }
        this.setState({ ...state })
        this.props.refetchQuery()
        this.props.handleClose()
    };
    updateBaseStatus = () => {
        if (this.props.driver && this.props.driver.id && this.state.partnerId) {
            try {
                this.setState({ loading: true })
                let input = {
                    driverId: this.props.driver.id,
                    partnerId: this.state.partnerId
                }
                this.props.apolloClient.mutate({
                    mutation: updateOrCreateBaseStatusMutation,
                    variables: { input },
                }).then((result) => {
                    if (result.data && result.data.updateOrCreateBaseStatus && result.data.updateOrCreateBaseStatus.ok && result.data.updateOrCreateBaseStatus.newBaseStatus) {
                        this.setState({ loading: false, updated: true, error: null, newBaseStatus: result.data.updateOrCreateBaseStatus.newBaseStatus })
                        this.handleClose()
                    } else {
                        this.setState({ loading: false, updated: false, error: "An error has occured. Please contact system admin" })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false, updated: false, })
                })
            } catch (err) {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, updated: false, })
            }
        }
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.allRideSharingPartners && this.props.allRideSharingPartners.edges && this.props.allRideSharingPartners.edges.length > 0 && (this.props.open !== prevProps.open || (this.props.allRideSharingPartners !== prevProps.allRideSharingPartners))) {
            let partnerId = this.props.allRideSharingPartners.edges[0].node.id
            this.setState({ partnerId: partnerId })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Modal className="updateBaseStatus" isOpen={this.props.open}>
                    {this.props.driver &&
                        <>
                            <ModalHeader>Update Base Status For <strong>{this.props.driver.name}</strong></ModalHeader>
                            <ModalBody>
                                {this.state.error && !this.state.updated && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                {this.state.updated && !this.state.error && this.state.newBaseStatus && this.props.driver && <Row><Col xs={12}><Alert color="success">Status Updated successfully! {this.props.driver.name}'s new status is {this.state.newBaseStatus}</Alert></Col></Row>}
                                {this.props.allRideSharingPartners && this.props.allRideSharingPartners.edges && this.props.allRideSharingPartners.edges.length > 0 ?
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label style={{ display: "block", marginBottom: "10px" }} for="partnerId">Select Base</Label>
                                                <Input style={{ padding: "5px", display: "block", marginBottom: "10px", boxSizing: "border-box", width: "100%", borderRadius: "6px" }}
                                                    type="select" name="partnerId" id="partnerId" onChange={this.updateInput} placeholder="Select Base">
                                                    {this.props.allRideSharingPartners.edges.map((partner, i) =>
                                                        <option key={i} value={partner.node.id}>{partner.node.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row> :
                                    <Row>
                                        <Col xs={12}>Please add some partners before updating new status.</Col>
                                    </Row>
                                }
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={this.updateBaseStatus} disabled={!this.state.partnerId || this.state.updated || this.state.loading}>Update Base Status</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllRideSharingPartnersQuery, {
    props: ({ data: { allRideSharingPartners } }) => ({
        allRideSharingPartners,
    }),
})(withApolloClient(UploadOrCreateBaseStatus)) 
