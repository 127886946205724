import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import {
    Col,
    Row
} from 'reactstrap'
import moment from 'moment'
import { CarInsuranceQuery } from '../Queries'
import UpdateCarPolicy from './UpdateCarPolicy'
import renderEnumToInt from "../../../Functions/renderEnumToInt"
import Loader from "../../../Material/Loader"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class InsuranceTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        const insuranceTable = [
            { id: "date_addded", name: "DATE ADDED", sortable: true },
            { id: "policy", name: "Policy#", sortable: false },
            { id: "broker", name: "Broker", sortable: true },
            { id: "start_date", name: "START DATE", sortable: false },
            { id: "end_date", name: "END DATE", sortable: false },
            { id: "status", name: "STATUS", sortable: false },
        ]
        return (
            <div className="insurance-tab-container container-fluid">
                {this.props.loading ? <Loader /> : <>
                    {this.state.openModal && this.state.openModal === "updatePolicyModal" && <UpdateCarPolicy handleClose={() => this.toggleModal("updatePolicyModal")} open={this.state.openModal === "updatePolicyModal"} refetchPolicies={this.props.refetchPoliciesQuery} carpk={this.props && this.props.params && this.props.params.pk} id={this.props.id} />}
                    <Row>
                        <Col>
                            <table responsive>
                                <thead>
                                    <tr>
                                        {insuranceTable.map(tableHeader =>
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.allCarInsurancePolicies &&
                                        this.props.allCarInsurancePolicies.edges &&
                                        this.props.allCarInsurancePolicies.edges.length > 0 ? this.props.allCarInsurancePolicies.edges.map((carPolicy, i) =>
                                            <tr className={carPolicy.node && carPolicy.node.status && carPolicy.node.status === "A_1" ? "active-price" : ""}>
                                                <td>{carPolicy.node && carPolicy.node.dateAdded ? <DatetimeRenderer datetime={carPolicy.node.dateAdded} /> : "---"}</td>
                                                <td>{carPolicy.node.insurancePolicy && carPolicy.node.insurancePolicy.policyNumber ? carPolicy.node.insurancePolicy.policyNumber : "---"}</td>
                                                <td>{carPolicy.node.insurancePolicy && carPolicy.node.insurancePolicy.broker && carPolicy.node.insurancePolicy.broker.name ? carPolicy.node.insurancePolicy.broker.name : "---"}</td>
                                                <td>{carPolicy.node.insurancePolicy && carPolicy.node.insurancePolicy.startDate ? <DatetimeRenderer datetime={carPolicy.node.insurancePolicy.startDate} /> : "---"}</td>
                                                <td>{carPolicy.node.insurancePolicy && carPolicy.node.insurancePolicy.endDate ? <DatetimeRenderer datetime={carPolicy.node.insurancePolicy.endDate} /> : "---"}</td>
                                                <td>{carPolicy.node && carPolicy.node.status ? renderEnumToInt(carPolicy.node.status) === 0 ? "InActive" : "Active" : "---"}</td>
                                            </tr>) :
                                        <tr>
                                            <td>No Insurance</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </>}
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarInsuranceQuery, {
    options: (props) => ({ variables: { carPk: props && props.params && props.params.pk } }),
    props: ({ data: { allCarInsurancePolicies, fetchMore, loading, variables } }) => (
        {
            allCarInsurancePolicies,
            loading,
            refetchPoliciesQuery: () => {
                return fetchMore({
                    query: CarInsuranceQuery,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            car: fetchMoreResult.car
                        }
                    },
                })
            }
        }
    )
})(withApolloClient(InsuranceTab))
