import gql from 'graphql-tag'
import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Col, Row, Popover, PopoverBody, PopoverHeader, Label, Progress, Button, Alert } from 'reactstrap'
import "./TablePreferencePicker.css"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const UpdateMemberTablePreference = gql`
    mutation updateMemberTablePreference($input: UpdateMemberTablePreferenceInput!) {
        updateMemberTablePreference(input: $input) {
            ok
            errors {
                messages
                field
            }
        }
    }
`

class TablePreferencePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            columnConfig: this.props.columnConfig,
            showColumnPicker: this.props.open,
            loading: false,
            error: null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    toggleColumnPicker = () => {
        this.setState(prevState => ({ showColumnPicker: !prevState.showColumnPicker }))
    };

    handleDragStart = (e, index) => {
        const { columnConfig } = this.state
        if (columnConfig[index].positonIsFixed) return
        e.dataTransfer.setData("dragIndex", index)
    };

    handleDragOver = (e) => {
        e.preventDefault()
    };


    handleDrop = (e, dropIndex) => {
        e.preventDefault()
        const dragIndex = parseInt(e.dataTransfer.getData("dragIndex"), 10)
        const { columnConfig } = this.state
        const nonFixedColumns = columnConfig.filter(column => !column.positonIsFixed)
        const fixedColumns = columnConfig.filter(column => column.positonIsFixed)

        // If the source (dragged) or target (dropped) index is beyond the length of the nonFixedColumns array, it means the operation involves a fixed column. In such cases, abort the drop operation.
        if (dragIndex >= nonFixedColumns.length || dropIndex >= nonFixedColumns.length) return

        // Rearrange the non-fixed columns
        const draggedItem = nonFixedColumns.splice(dragIndex, 1)[0]
        nonFixedColumns.splice(dropIndex, 0, draggedItem)

        // Combine the fixed and rearranged non-fixed columns
        const updatedColumns = []
        let nonFixedIndex = 0, fixedIndex = 0
        for (let i = 0; i < columnConfig.length; i++) {
            if (fixedIndex < fixedColumns.length && columnConfig[i].positonIsFixed) {
                updatedColumns.push(fixedColumns[fixedIndex++])
            } else {
                updatedColumns.push(nonFixedColumns[nonFixedIndex++])
            }
        }

        this.setState({ columnConfig: updatedColumns })
    };

    updateColumnVisibility = (columnId) => {
        const newColumns = this.state.columnConfig.map(col => ({
            ...col,
            visible: col.id === columnId ? !col.visible : col.visible
        }))
        this.setState({ columnConfig: newColumns })
    };

    updateMemberPreference = () => {
        this.setState({ loading: true, error: null })
        let input = {
            memberId: this.props.currentUser?.member?.id,
        }
        if (this.props.preferenceType) {
            input['tableId'] = this.props.preferenceType
        }
        if (this.state.columnConfig) {
            input['preferences'] = this.state.columnConfig.filter((col) => col.visible).map((col) => col.id)
        }

        this.props.apolloClient.mutate({
            mutation: UpdateMemberTablePreference,
            variables: { input },
        }).then((response) => {
            if (response.data.updateMemberTablePreference.ok) {
                //window.location.reload()
                this.props.refetchPreferences()
                this.toggleColumnPicker()
            }
            if (response.data?.updateMemberTablePreference?.errors?.length > 0) {
                const error = response.data.updateMemberTablePreference.errors[0]
                if (error.messages?.length > 0) {
                    this.setState({ error: error.messages[0] })
                } else {
                    this.setState({ error: 'An unknown error occurred.' })
                }
            }
            this.setState({ loading: false })
        }).catch((error) => {
            this.setState({ error: error.message, loading: false })
        })
    };

    saveTableConfig = () => {
        this.updateMemberPreference()
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <Popover trigger="legacy" placement="left" isOpen={this.state.showColumnPicker} target={this.props.target} toggle={this.toggleColumnPicker} className={"table-preferences-popover"}>
                <PopoverHeader>Customize Columns</PopoverHeader>
                <PopoverBody>
                    {this.state.error &&
                        <Row>
                            <Col><Alert color="danger">{this.state.error}</Alert></Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={10}>
                            <Label>Columns</Label>
                        </Col>
                        <Col xs={2}>
                            <Label>Visible</Label>
                        </Col>
                    </Row>
                    {this.state.columnConfig && this.state.columnConfig.filter(item => item.showInPicker).map((column, index) => (
                        <Row key={index} draggable={!column.positonIsFixed} onDragStart={(e) => this.handleDragStart(e, index)} onDragOver={this.handleDragOver} onDrop={(e) => this.handleDrop(e, index)}>
                            <Col xs={10}>
                                <Label><span className="drag-btn"><i className="fa fa-bars" aria-hidden="true"></i></span>&nbsp;{column.name}</Label>
                            </Col>
                            <Col xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    checked={column.visible}
                                    onChange={() => this.updateColumnVisibility(column.id)}
                                />
                            </Col>
                        </Row>
                    ))}
                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    <Row>
                        <Col className="d-flex justify-content-end m-3">
                            <Button className={"save-view-btn"} onClick={this.saveTableConfig}>Save View</Button>
                        </Col>
                    </Row>
                </PopoverBody>
            </Popover>
        )
    }
}
//Iagami - Code upgradation React 18
export default (withApolloClient(TablePreferencePicker))
