import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { hasIssuedAndExpirationDates } from '../DocumentModal/utils'

function DocumentDataInput({ field, handleDriverDataChange, issuedDateValidation, dateToCompare, }) {
    const hasIssuedExpirationDates = hasIssuedAndExpirationDates(field);

    const [errorMessage, setErrorMessage] = useState("");

    const validateIssuedDate = (e) => {
        const value = e.target.value;
        const issuedDate = new Date(value);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (issuedDate > today) {
            setErrorMessage(
                "The issued date cannot be in the future. Please enters today's date or an earlier date.",
            );
            issuedDateValidation(false);
        } else {
            setErrorMessage("");
            issuedDateValidation(true);
        }
        handleDriverDataChange(e);
    };

    const validateExpirationDate = (e) => {
        const value = e.target.value;
        const expirationDate = new Date(value);
        const comparisonDate = new Date(dateToCompare);

        comparisonDate.setHours(0, 0, 0, 0);
        expirationDate.setHours(0, 0, 0, 0);

        if (expirationDate < comparisonDate) {
            setErrorMessage(
                `The expiration date must be the same date or after the issued date.`,
            );
            issuedDateValidation(false);
        } else {
            setErrorMessage("");
            issuedDateValidation(true);
        }
        handleDriverDataChange(e);
    };

    return (
        <div>
            <Input
                type={field.fieldType}
                name={field.fieldCode}
                value={field.value || ''}
                onChange={(e) => {
                    switch (true) {
                        case field.fieldCode.startsWith("ZIPCODE") || field.fieldCode === "TRIPS" || field.fieldCode === "YEARS_ON_PLATFORM":
                            // Handle ZIPCODE, TRIPS, YEARS_ON_PLATFORM cases (numbers only)
                            handleDriverDataChange(e, true)
                            break
                        case field.fieldCode === "RATING":
                            // Handle RATING case (numbers and dot)
                            handleDriverDataChange(e, false, true)
                            break
                        case field.fieldCode === "ISSUED_DATE":
                            validateIssuedDate(e);
                            break;
                        case field.fieldCode === "EXPIRATION_DATE":
                            validateExpirationDate(e);
                            break;
                        default:
                            // Default case (alphanumeric only)
                            handleDriverDataChange(e)
                    }
                }}
                // Need to have max/min exceptions for different type of dates
                max={field.fieldCode === 'DATE_OF_BIRTH' ||
                    field.fieldCode === 'ISSUED_DATE' ||
                    field.fieldCode === 'ANTIQUITY'
                    ? new Date().toISOString().split('T')[0] : undefined}
                min={hasIssuedExpirationDates && field.fieldCode === 'EXPIRATION_DATE'
                    ? new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
                    : field.fieldCode === 'EXPIRATION_DATE'
                        ? new Date().toISOString().split('T')[0]
                        : undefined
                }
                style={{
                    backgroundColor: field.value && field.value.toString().trim() !== "" ? '' : 'rgba(255, 0, 0, 0.1)'
                }}
            />
            {errorMessage && (
                <small style={{ color: "red", fontSize: "12px" }}>
                    {errorMessage}
                </small>
            )}
        </div>
    )
}

export default DocumentDataInput

