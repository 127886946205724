import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './CreatePermissionModal.css'
import Select from 'react-select'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const CreatePermissionMutation = gql`mutation CreatePermissionMutation($input: CreatePermissionMutationInput!){
    createPermission(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const AllContentTypes = gql`query AllContentTypes{
    allContentTypes{
        edges{
            node{
                id
                model
                appLabel
            }
        }
    } 
}`
class CreatePermissionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            contentType: "",
            codeName: "",
            errorMessage: "",
            successMessage: "",
            loading: false
        }
    }
    createNewPermission = (e) => {
        try {
            if (this.state.name && this.state.contentType && this.state.codeName) {
                this.setState({ loading: true })
                let input = {
                    name: this.state.name,
                    contentTypeId: this.state.contentType,
                    codeName: this.state.codeName,
                }
                this.props.apolloClient.mutate({
                    mutation: CreatePermissionMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createPermission && result.data.createPermission.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Created New Permission" })
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createPermission && result.data.createPermission.errors && result.data.createPermission.errors[0] && result.data.createPermission.errors[0].messages) {
                            errorMessage = result.data.createPermission.errors[0].messages.toString()
                        }
                        this.setState({ errorMessage: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ errorMessage: errorMessage, loading: false })
                })
            }
            else this.setState({ errorMessage: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ errorMessage: errorMessage, loading: false })
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };
    componentDidMount() {
        const { apolloClient } = this.props;
          }    

    render() {
        return (
            <div>
                <Modal className="create-permission-modal" isOpen={this.props.open}>
                    <ModalHeader>Create Permission</ModalHeader>
                    <ModalBody>
                        {this.state.errorMessage &&
                            <Col xs={12}>
                                <Alert color="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </Col>
                        }
                        {this.state.successMessage &&
                            <Col xs={12}>
                                <Alert color="success">
                                    {this.state.successMessage}
                                </Alert>
                            </Col>
                        }
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="name">Permission Name*</Label>
                                    <Input name="name" id="name" onChange={this.updateInput} placeholder="Permission Name" invalid={!this.state.name} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="codeName">Code Name*</Label>
                                    <Input name="codeName" id="codeName" onChange={this.updateInput} placeholder="Code Name" invalid={!this.state.codeName} />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="selectedBranch">Select Content Type*</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allContentTypes && this.props.allContentTypes.edges && this.props.allContentTypes.edges.length > 0)}
                                        options={this.props.allContentTypes && this.props.allContentTypes.edges && this.props.allContentTypes.edges.length > 0 && this.props.allContentTypes.edges.map(contentType => ({ value: contentType.node.id, label: (contentType.node.appLabel + " | " + contentType.node.model) }))}
                                        placeholder="Select Content Type"
                                        onChange={(contentType) => this.setState({ contentType: contentType.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline className="create-permission-modal-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                        <Button className="create-permission-modal-primary-btn" onClick={this.createNewPermission} disabled={!this.state.name || !this.state.contentType || !this.state.codeName}>{this.state.loading ? "Loading..." : "Add New Permission"}</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllContentTypes, {
        options: { fetchPolicy: 'cache-and-network' },
        props: ({ data: { loading, allContentTypes } }) => ({ loading, allContentTypes  })
    })
(withApolloClient(CreatePermissionModal));
