import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { useApolloClient } from '@apollo/client'
import { Container, Row, Col } from 'reactstrap'

class PageCrash extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Container fluid className="Page404">
                <Row>
                    <Col xs={{ size: 4, offset: 4 }} className="section-404">
                        <p>{this.props.title ? this.props.title : "Oops!"}</p>
                        <small style={{ color: "red" }}>{this.props.error ? this.props.error : "Something went wrong. Please contact admin. Thanks!"}</small>
                        <br /><br /><a className="btn btn-primary" href="/">Go To HomePage</a>
                    </Col>
                </Row>
            </Container>
        )
    }
}

//Iagami - Code upgradation React 18
export default PageCrash
