import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Alert, Button, Row, Col, Progress, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText } from 'reactstrap'
import Select from 'react-select'
import gql from 'graphql-tag'
import './ExternalSystemIdentifierModal.css'
import DatetimeRenderer from "./DatetimeRenderer"
import withApolloClient from "../withApolloClient"
const SYSTEM_NAMES = [
    { value: "STRIPE", label: "STRIPE" },
    { value: "TOKU", label: "TOKU" },
]

const ExternalSystemIdentifier = gql`query ExternalSystemIdentifier($id: ID!){
    externalSystemIdentifier(id: $id){
        id
        pk
        dateAdded
        externalId
        notes
        isDefault
        isArchived
        systemName
    }
}`

const CreateExternalIdentifier = gql`mutation CreateExternalIdentifier($input: CreateExternalSystemIdentifierMutationInput!){
    createExternalIdentifier(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateExternalIdentifier = gql`mutation UpdateExternalIdentifier($input: UpdateExternalSystemIdentifierMutationInput!){
    updateExternalIdentifier(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

class ExternalSystemIdentifierModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifierId: this.props.identifierId,
            externalId: "",
            systemName: "",
            isDefault: false,
            isArchived: false,
            notes: "",
            openModal: "",
            success: false,
            loading: false,
            error: null,
        }
    }

    handleSubmit = () => {
        const { externalId, isArchived, isDefault, systemName, notes } = this.state
        const { customerId } = this.props
        if (this.props.isUpdate || (externalId && customerId && systemName)) {
            try {
                this.setState({ loading: true })
                const input = {}
                if (this.props.isUpdate) {
                    input["externalSystemId"] = this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.id ? this.props.externalSystemIdentifier.id : ""
                    if (isDefault !== this.props.externalSystemIdentifier.isDefault)
                        input["isDefault"] = isDefault
                    if (isArchived !== this.props.externalSystemIdentifier.isArchived)
                        input["isArchived"] = isArchived
                    if (notes !== this.props.externalSystemIdentifier.notes)
                        input["notes"] = notes
                }
                if (!this.props.isUpdate) {
                    input["customerId"] = customerId
                    input["isDefault"] = isDefault
                    input["systemName"] = systemName || ''
                    input["externalId"] = externalId || ''
                    input["notes"] = notes

                }
                this.props.appolloClient
                    .mutate({
                        mutation: this.props.isUpdate ? UpdateExternalIdentifier : CreateExternalIdentifier,
                        variables: { input },
                    })
                    .then((result) => {
                        if (result.data && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'] && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'].ok) {
                            this.setState({ loading: false, error: null, success: false })
                            if (this.props.handleClose)
                                this.props.handleClose()
                            if (this.props.refetchQuery)
                                this.props.refetchQuery()
                        } else {
                            let errorMessage = 'An error occurred, could not complete request.'
                            if (result.data && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'] && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'].errors && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'].errors[0] && result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'].errors[0].messages[0]) {
                                errorMessage = result.data[this.props.isUpdate ? 'updateExternalIdentifier' : 'createExternalIdentifier'].errors[0].messages[0]
                            } else if (!this.props.isUpdate && result.data && result.data.createExternalIdentifier && result.data.createExternalIdentifier.errors && result.data.createExternalIdentifier.errors[0] && result.data.createExternalIdentifier.errors[0].messages[0]) {
                                errorMessage = result.data.createExternalIdentifier.errors[0].messages[0]
                            }
                            this.setState({ loading: false, error: errorMessage, success: false })
                        }
                    })
                    .catch((error) => {
                        let errorMessage = 'An error has occurred ' + error
                        this.setState({ error: errorMessage, loading: false, success: false })
                    })
            } catch (error) {
                let errorMessage = 'An error has occurred ' + error
                this.setState({ error: errorMessage, loading: false, success: false })
            }
        } else {
            this.setState({ error: 'Error: Required fields are missing', success: false })
        }
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value, error: "" })
    };

    componentDidUpdate(prevProps) {
        if (this.props.externalSystemIdentifier !== prevProps.externalSystemIdentifier && this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.id) {
            this.setState({
                externalId: this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.externalId ? this.props.externalSystemIdentifier.externalId : "",
                notes: this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.notes ? this.props.externalSystemIdentifier.notes : "",
                isDefault: this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.isDefault,
                isArchived: this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.isArchived,
                systemName: this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.systemName ? this.props.externalSystemIdentifier.systemName : "",
            })
        }
    }



    render() {
        return (
            <Modal className={"external-system-identifier"} isOpen={this.props.open}>
                <ModalHeader>{this.props.isUpdate ? "UPDATE" : "CREATE"} EXTERNAL SYSTEM IDENTIFIER</ModalHeader>
                <ModalBody>
                    {this.props.loading || this.state.loading ? <Progress animated color="info" value={100} /> :
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            {this.props.isUpdate && <Col xs={6}>
                                <FormGroup>
                                    <Label><b>Date Added</b></Label><br />
                                    <div className="input-alternative-box">
                                        <DatetimeRenderer dateTime={this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.dateAdded} />
                                    </div>
                                </FormGroup>
                            </Col>}
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>External System Name *</b></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={SYSTEM_NAMES.map(item => ({ value: item.value, label: item.label }))}
                                        defaultValue={this.props.isUpdate ? SYSTEM_NAMES.filter(item => item.value.toLowerCase() === (this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.systemName).toLowerCase()).map(item => ({ value: item.value, label: item.label })) : ""}
                                        placeholder="CHOOSE SYSTEM NAME"
                                        onChange={(system) => this.setState({ systemName: system.value })}
                                        isDisabled={this.props.isUpdate}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>External Identifier ID *</b></Label><br />
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="externalId"
                                        placeholder={"Add external ID."}
                                        defaultValue={this.props.isUpdate ? (this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.externalId) : ""}
                                        disabled={this.props.isUpdate}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>Notes</b></Label><br />
                                    <Input
                                        type="text"
                                        onChange={this.updateInput}
                                        name="notes"
                                        defaultValue={this.props.isUpdate ? (this.props.externalSystemIdentifier && this.props.externalSystemIdentifier.notes) : ""}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label><b>Is Default</b></Label><br />
                                    <div>
                                        <span>Yes</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id="isDefault"
                                                name={"isDefault"}
                                                checked={!this.state.isDefault}
                                                onChange={() => this.setState({ isDefault: !this.state.isDefault })}
                                            />
                                            <label className="toggle-switch-label" htmlFor={"isDefault"}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>No</span>
                                    </div>
                                </FormGroup>
                            </Col>
                            {this.props.isUpdate && <Col xs={6}>
                                <FormGroup>
                                    <Label><b>Is Archived</b></Label><br />
                                    <div>
                                        <span>Yes</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id={"isArchived"}
                                                name={"isArchived"}
                                                checked={!this.state.isArchived}
                                                onChange={() => this.setState({ isArchived: !this.state.isArchived })}
                                            />
                                            <label className="toggle-switch-label" htmlFor={"isArchived"}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>No</span>
                                    </div>
                                </FormGroup>
                            </Col>}

                        </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button className="external-system-identifier-secondary-btn" onClick={this.props.handleClose}>CLOSE</Button>&nbsp;&nbsp;
                    <Button className="external-system-identifier-primary-btn" disabled={this.state.loading} onClick={() => this.handleSubmit()}>{this.props.isUpdate ? "UPDATE IDENTIFIER" : "CREATE IDENTIFIER"}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default graphql(ExternalSystemIdentifier, {
    options: ({ identifierId }) => ({
        skip: ({ identifierId }) => identifierId === null,
        variables: { id: identifierId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }),
    props({ data: { externalSystemIdentifier, loading, refetch, variables } }) {
        return {
            externalSystemIdentifier, loading, variables,
            refetchDriverQuery: () => {
                return refetch({
                    query: ExternalSystemIdentifier,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return { externalSystemIdentifier: fetchMoreResult.externalSystemIdentifier }
                    },
                })
            },
        }
    },
})(withApolloClient(ExternalSystemIdentifierModal))
