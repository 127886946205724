/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { useApolloClient } from '@apollo/client'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class InfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> {this.props.title} </ModalHeader>
            <ModalBody>
                {this.props.message}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    }
}

//Iagami - Code upgradation React 18
export default InfoModal