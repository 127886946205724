import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Progress, Button, Alert, Row, Col, CardText, Card, CardBody, Popover, PopoverBody, PopoverHeader, Label } from 'reactstrap'
import gql from 'graphql-tag'
import './Modals/NewReservationModal.css'
import SwiperCore from 'swiper'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AllPromotionsTemplates } from './Modals/Queries'
import 'swiper/swiper-bundle.css'
import CardHeader from "reactstrap/lib/CardHeader"
import CardFooter from "reactstrap/lib/CardFooter"
import 'swiper/css'
import 'swiper/css/scrollbar'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])


const AllPricingTemplates = gql`query AllPricingTemplates($agreementTypeId: ID,$carId: ID){
    allPricingTemplates(agreementTypeId: $agreementTypeId,carId: $carId,isActive:true){
      edges{
        node{
            id
            price
            minimumPrice
            maximumPrice
            interval
            intervalUnit
            currency { code symbol }
            title
            tierType
            tiers{
                edges{
                    node{
                        id
                        price
                        interval
                        intervalUnit
                        currency { code symbol }
                        upperLimit
                        upperLimitUnit
                    }
                }
            }
            agreementType{
                id
                name
                chargeFrequency
                chargeFrequencyUnit
            }
            carModel{
                id
                name
            }
        }
      }
} }`

class PriceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: "",
    }
  }

  updateInput = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value })
  };

  toggleModal = (modalName) => {
    if (this.state.openModal !== modalName) {
      this.setState({ openModal: modalName })
    } else {
      this.setState({ openModal: "" })
    }
  }
  render() {
    const allTemplates = this.props.allPricingTemplates && this.props.allPromotionsTemplates && this.props.allPricingTemplates !== null &&
      this.props.allPromotionsTemplates !== null && [...this.props.allPricingTemplates.edges, ...this.props.allPromotionsTemplates.edges]
    return (
      allTemplates && allTemplates.length > 0 ?
        <Swiper spaceBetween={0} slidesPerView={3} navigation>
          {allTemplates.map((pricingTemplate, i) =>
            pricingTemplate && pricingTemplate.node &&
            <SwiperSlide key={i}>
              <Card className={pricingTemplate.__typename == 'PricingTemplateNodeEdge' ? "price-card" : "promo-card"}>
                <CardHeader>{pricingTemplate.node.title}</CardHeader>
                <CardBody>
                  {pricingTemplate.__typename == 'PromotionTemplateNodeEdge' && <CardText><b><span>{pricingTemplate.node.isPercentage ? +pricingTemplate.node.promoPrice + ' %' : '$' + pricingTemplate.node.promoPrice}</span></b> per {pricingTemplate.node.interval > 1 ? pricingTemplate.node.interval : ""}{pricingTemplate.node.intervalUnit}</CardText>}
                  {pricingTemplate.__typename == 'PricingTemplateNodeEdge' && <CardText><b>$<span>{pricingTemplate.node.price}</span></b> per {pricingTemplate.node.interval > 1 ? pricingTemplate.node.interval : ""}{pricingTemplate.node.intervalUnit}  {pricingTemplate.node.tiers && pricingTemplate.node.tiers.edges && pricingTemplate.node.tiers.edges.length > 0 && <i className={this.state.openModal === "TierDetails" + i ? "fa fa-chevron-circle-up" : "fa fa-chevron-circle-down"} aria-hidden="true" onClick={() => this.toggleModal("TierDetails" + i)} id={"tier" + i}></i>}</CardText>}
                  {/* <CardText><b>$<span>{pricingTemplate.node.price}</span></b> per {pricingTemplate.node.interval>1?pricingTemplate.node.interval:""}{pricingTemplate.node.intervalUnit}</CardText> */}
                  {this.state.openModal === "TierDetails" + i &&
                    <Popover isOpen={this.state.openModal === "TierDetails" + i} placement="right" target={"tier" + i}>
                      <PopoverHeader onClick={() => this.setState({ openModal: "" })}>Tier Details <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                      <PopoverBody style={{ minWidth: "200px" }}>
                        <Label><b>TIERS - {pricingTemplate.node.tierType}</b></Label><br />
                        <ul>
                          {pricingTemplate.node.tiers && pricingTemplate.node.tiers.edges && pricingTemplate.node.tiers.edges.length > 0 ? pricingTemplate.node.tiers.edges.map((tier, j) =>
                            <li>
                              <span>{i + 1} ${tier.node.price}/{tier.node.interval > 1 ? tier.node.interval : ""}{tier.node.intervalUnit}: {tier.node.upperLimit} {tier.node.upperLimitUnit}</span><br />
                            </li>
                          ) : "No Tier Found"
                          }
                        </ul>
                      </PopoverBody>
                    </Popover>
                  }
                </CardBody>
                <CardFooter>
                  {pricingTemplate.__typename == 'PricingTemplateNodeEdge' &&
                    <Row><Col xs={12}>
                      <Button className="float-right" onClick={(e) => this.props.updatePlan("add", pricingTemplate.node, e)}>Add Plan</Button>
                    </Col></Row>
                  }
                  {pricingTemplate.__typename == 'PromotionTemplateNodeEdge' && <Button className="float-right"
                    onClick={(e) => this.props.updatePromoPlan("add", pricingTemplate.node, e)}>Add Promo</Button>
                  }
                </CardFooter>
              </Card></SwiperSlide>
          )}
        </Swiper> :

        this.props.loading ? <Progress animated color="info" value="100">Loading Prices...</Progress> : <Alert color="warning">No price avaliable for car and agreement type</Alert>
    )
  }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllPricingTemplates, {
  options: ({ agreementTypeId, carId }) => ({
    variables: { agreementTypeId, carId, first: 30 }
  }),
  props: ({ data: { loading, allPricingTemplates, fetchMore, variables } }) => ({
    loading,
    allPricingTemplates,
    variables,
    refetchAllPricingTemplates: () => {
      // No Apollo function that refetches in place with pagination considered so this function instead
      let currentLength =
        allPricingTemplates && allPricingTemplates.edges
          ? allPricingTemplates.edges.length
          : 30
      return fetchMore({
        query: AllPricingTemplates,
        variables: {
          ...variables,
          first: currentLength
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.allPricingTemplates.edges
          const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo
          return {
            allPricingTemplates: {
              edges: [...newEdges],
              pageInfo,
              __typename: previousResult.allPricingTemplates.__typename
            }
          }
        }
      })
    },
    loadMoreEntries: () => {
      return fetchMore({
        query: AllPricingTemplates,
        variables: {
          cursor: allPricingTemplates.pageInfo.endCursor,
          ...variables
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.allPricingTemplates.edges
          const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo
          return {
            allPricingTemplates: {
              edges: [...previousResult.allPricingTemplates.edges, ...newEdges],
              pageInfo,
              __typename: previousResult.allPricingTemplates.__typename
            }
          }
        }
      })
    }
  })
})(
  graphql(AllPromotionsTemplates, {
    options: ({ agreementTypeId, carId }) => ({
      variables: { agreementTypeId, carId, first: 30 }
    }),
    props: ({ data: { loading, allPromotionsTemplates, fetchMore, variables } }) => ({
      loading,
      allPromotionsTemplates,
      variables,
      refetchAllPromotionsTemplates: () => {
        // No Apollo function that refetches in place with pagination considered so this function instead
        let currentLength =
          allPromotionsTemplates && allPromotionsTemplates.edges
            ? allPromotionsTemplates.edges.length
            : 30
        return fetchMore({
          query: AllPromotionsTemplates,
          variables: {
            ...variables,
            first: currentLength
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.allPromotionsTemplates.edges
            const pageInfo = fetchMoreResult.allPromotionsTemplates.pageInfo
            return {
              allPromotionsTemplates: {
                edges: [...newEdges],
                pageInfo,
                __typename: previousResult.allPromotionsTemplates.__typename
              }
            }
          }
        })
      },
      loadMoreEntries: () => {
        return fetchMore({
          query: AllPromotionsTemplates,
          variables: {
            cursor: allPromotionsTemplates.pageInfo.endCursor,
            ...variables
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.allPromotionsTemplates.edges
            const pageInfo = fetchMoreResult.allPromotionsTemplates.pageInfo
            return {
              allPromotionsTemplates: {
                edges: [
                  ...previousResult.allPromotionsTemplates.edges,
                  ...newEdges
                ],
                pageInfo,
                __typename: previousResult.allPromotionsTemplates.__typename
              }
            }
          }
        })
      }
    })
  })(
    withApolloClient(PriceList)
  )
)
