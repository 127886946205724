import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    Input,
    Badge,
    FormGroup,
    Progress,
    Alert
} from 'reactstrap'
import { AllEquipmentItems } from '../Queries'
import { InstallCarEquipment } from '../Mutations'

class InstallEquipment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: 1,
            error: "",
            openModal: "",
            equipment: null,
            customId: null
        }
    }

    addCarEquipment = (e) => {
        e.preventDefault()
        if (this.state.equipment) {
            this.setState({ loading: true })
            //Iagami - Code upgradation React 18
            let input = { carPk: parseInt(this.props.pk), equipmentItemPk: parseInt(this.state.equipment) }
            if (this.state.customId)
                input['customDeviceId'] = this.state.customId

            this.props.apolloClient.mutate({
                mutation: InstallCarEquipment,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.createEquipment && result.data.createEquipment.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ loading: false })
                } else if (result && result.data && result.data.createEquipment && result.data.createEquipment.errors && result.data.createEquipment.errors.length > 0) {
                    this.setState({ error: result.data.createEquipment.errors[0].messages[0] })
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false })
                    this.setState({ error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false })
                this.setState({ error: err })
            })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> Install Equipment</ModalHeader>
            <ModalBody>
                {this.props.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                {this.props.error && <Row><Col xs={12}><Alert color="danger">{this.props.error}</Alert></Col></Row>}
                <FormGroup>
                    <Label for="equipmentItem" className="dropdown_label float-left"><b>Equipment Name</b></Label>
                    <Input style={{ padding: "5px", display: "block", marginBottom: "10px", boxSizing: "border-box", width: "100%", borderRadius: "6px" }}
                        type="select" name="equipmentItem" id="equipmentItem" defaultValue="" value={this.props.equipment} onChange={(e) => this.setState({ equipment: e.target.value })}>
                        <option disabled value="">-- Select Equipment To Add -- </option>
                        {this.props && this.props.allEquipmentItems && this.props.allEquipmentItems.edges && this.props.allEquipmentItems.edges.length > 0 && this.props.allEquipmentItems.edges.map(item =>
                            //Iagami - Code upgradation React 18
                            <option value={parseInt(item.node.pk, 10)}>{item.node.name}</option>
                        )}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="customDeviceId" className="float-left"><b>Custom Device ID</b></Label>
                    <Input type="text" name="customDeviceId" id="customDeviceId" value={this.props.customId} onChange={(e) => this.setState({ customId: e.target.value })} placeholder="Custom Device ID (if any)" />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                <Button type="submit" color="primary" onClick={this.addCarEquipment}>Install Equipment</Button>
            </ModalFooter>
        </Modal>
    };
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllEquipmentItems, {
    options: () => ({
        variables: {
            first: 8
        }
    }),
    props: ({ data: { allEquipmentItems } }) => (
        { allEquipmentItems }
    )
})(withApolloClient(InstallEquipment))