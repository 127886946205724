import React, { Component } from 'react'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const UpdateCarDealMutation = gql`mutation UpdateCarDealMutation($input: UpdateCarDealMutationInput!){
    updateCarDeal(input:$input){
        ok
        errors{
            messages
        }
    }
} `


class UpdateCarDeal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            carDeal: props.carDeal,
            input: {},
            loading: false
        }
    }

    updateCarDeal = () => {
        if (this.state.input.dealId) {
            try {
                this.setState({ loading: true })
                let input = this.state.input
                this.props.apolloClient.mutate({
                    mutation: UpdateCarDealMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateCarDeal && result.data.updateCarDeal.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                        this.setState({ input: {}, loading: false })
                    } else {
                        this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                })
            } catch (err) {
                this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
            }
        } else {
        }
    }

    updateInput = (e) => {
        if (this.state.carDeal) {
            let name = e.target.name
            let value = e.target.value
            let input = this.state.input
            input["dealId"] = this.state.carDeal.id
            input[name] = value
            this.setState({ input: input })
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.carDeal !== prevProps.carDeal) {
            let carDeal = this.props.carDeal
            this.setState({ carDeal: carDeal })
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.type === "select" ?
                                <Input type={this.props.type} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput}>
                                    {this.props.selectOptions && this.props.selectOptions.map((option, i) =>
                                        <option key={i} value={option.value}>{option.name}</option>
                                    )}
                                </Input>
                                :
                                <Input type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="button" size="sm" onClick={this.updateCarDeal}>Submit</Button>}
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(UpdateCarDeal)