// src/components/AutomationForm.js

import React, { useEffect, useState, useRef } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { AllAvailableModelsQuery, ActionTypesAndParamsQuery, ConditionTypes, TriggerTypes } from './Queries'
import {
    Button,
    Form,
    FormText,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody,
    Badge,
} from 'reactstrap'
import Select from 'react-select'
import gql from 'graphql-tag'
import { Link } from "react-router-dom"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const CREATE_AUTOMATION_WORKFLOW = gql`
  mutation createAutomationWorkflow($input: CreateAutomationWorkflowMutationInput!) {
    createAutomationWorkflow(input: $input) {
      ok
      errors {
        messages
        field
      }
      automation {
        id
        name
        isActive
      }
    }
  }
`


const AutomationForm = ({ allAvailableModels, actionTypesAndParams, conditionTypes, triggerTypes, createAutomationWorkflow, ...props }) => {
    const [name, setName] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [triggerType, setTriggerType] = useState('')
    const [columnsUpdated, setColumnsUpdated] = useState([])
    const [availableModel, setAvailableModel] = useState(null)
    const DEFAULT_CONDITION = { version: 'CURRENT', fieldName: '', conditionType: '', conditionValue: '' }
    const DEFAULT_GROUP = { logicalOperator: 'AND', conditions: [DEFAULT_CONDITION], childGroups: [] }
    const [conditionGroup, setConditionGroup] = useState(DEFAULT_GROUP)
    const [actionType, setActionType] = useState()
    const [actionParams, setActionParams] = useState({})
    const [actionParamsValues, setActionParamsValues] = useState({})
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState('')
    const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
    const [updateInstanceActionFields, setUpdateInstanceActionFields] = useState([{ fieldName: '', value: '' }])
    const inputRefs = useRef({})

    const customFields = []

    const handleUpdateInstanceActionFieldsChange = (index, field, value) => {
        const newFields = [...updateInstanceActionFields]
        newFields[index][field] = value
        setUpdateInstanceActionFields(newFields)
        setActionParamsValues({ ...actionParamsValues, [field]: value })
    }

    const parseRecentObjects = (recentObjects) => {
        const result = JSON.parse(recentObjects)
        return result
    }

    const handleAddCondition = (group, groupPath) => {
        const newGroup = JSON.parse(JSON.stringify(conditionGroup))

        // Navigate to the correct parent group using the groupPath
        let currentGroup = newGroup

        for (let i = 0; i < groupPath.length; i++) {
            currentGroup = currentGroup.childGroups[groupPath[i]]
        }

        // Add a new condition to the current group
        currentGroup.conditions.push({ ...DEFAULT_CONDITION })

        // Update the condition group state
        setConditionGroup(newGroup)
    }

    const handleAddChildGroup = (group, groupPath) => {
        // Allow a maximum of nested groups
        if (groupPath.length >= 2) {
            return
        }
        const newGroup = JSON.parse(JSON.stringify(conditionGroup))

        // Navigate to the correct parent group using the groupPath
        let currentGroup = newGroup
        for (let i = 0; i < groupPath.length; i++) {
            currentGroup = currentGroup.childGroups[groupPath[i]]
        }

        // Add a new child group to the current group
        currentGroup.childGroups.push({ ...DEFAULT_GROUP })

        // Update the condition group state
        setConditionGroup(newGroup)
    }

    const handleRemoveChildGroup = (groupPath) => {
        // Deep copy the condition group to avoid mutating the existing state directly.
        const newGroup = JSON.parse(JSON.stringify(conditionGroup))

        // If it's a top-level group, we cannot remove it, so simply return.
        if (groupPath.length === 0) {
            return
        }

        // Navigate to the correct parent group using the groupPath
        let currentGroup = newGroup
        for (let i = 0; i < groupPath.length - 1; i++) {
            currentGroup = currentGroup.childGroups[groupPath[i]]
        }

        // Remove the target group from its parent
        const targetIndex = groupPath[groupPath.length - 1]
        currentGroup.childGroups.splice(targetIndex, 1)

        // Update the condition group state
        setConditionGroup(newGroup)
    }

    const handleLogicalOperatorChange = (groupIndex, value) => {
        const newGroup = { ...conditionGroup }
        newGroup.logicalOperator = value
        setConditionGroup(newGroup)
    }

    const constructConditionGroupInput = (group) => {
        return {
            logicalOperator: group.logicalOperator,
            conditions: group.conditions.filter((condition) => condition.fieldName && condition.conditionType),
            childGroups: group.childGroups.map((childGroup) => constructConditionGroupInput(childGroup)),
        }
    }

    const handleActionParamChange = (field, value, append) => {
        const newActionParamsValues = { ...actionParamsValues }
        newActionParamsValues[field] = append && newActionParamsValues[field] ? `${newActionParamsValues[field]}${value}` : value
        setActionParamsValues(newActionParamsValues)
    }

    const handleInstanceFieldClick = (paramName, field) => {
        const newActionParamsValues = { ...actionParamsValues }
        const inputRef = inputRefs.current[paramName]
        const value = newActionParamsValues[paramName]
        const newValue = `${value.slice(0, currentCursorPosition)}{{instance.${field}}}${value.slice(currentCursorPosition)}`
        newActionParamsValues[paramName] = newValue
        setActionParamsValues(newActionParamsValues)
        inputRef.focus()
        inputRef.setSelectionRange(currentCursorPosition + 11, currentCursorPosition + 11 + field.length)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // Validations
        if (triggerType === 'on_update' && !columnsUpdated.length) {
            setError('At least one trigger column should be selected for on_update trigger type')
            return
        }
        if (!availableModel || !name || !triggerType || !actionType) {
            setError('All fields are required')
            return
        }

        // Construct condition group input
        const conditionGroupInput = constructConditionGroupInput(conditionGroup)

        // Run mutation
        createAutomationWorkflow({
            variables: {
                input: {
                    name,
                    isActive,
                    triggerType,
                    availableModelId: availableModel.id,
                    columnsUpdated,
                    conditionGroup: conditionGroupInput,
                    actionType,
                    actionParams: JSON.stringify(actionParamsValues),
                },
            },
        }).then(({ data }) => {
            if (data.createAutomationWorkflow.ok) {
                window.location.href = '/automations/'
            } else {
                setError(data.createAutomationWorkflow.errors[0].messages[0])
            }
        })
    }

    useEffect(() => {
        // When actionTypes changes, set default values for actionParams
        if (actionType && actionParams && actionParams.length > 0) {
            const defaultActionParams = {}
            actionParams.forEach((param) => {
                defaultActionParams[param.name] = param.default || ''
            })
            setActionParamsValues(defaultActionParams)
        }
    }, [actionType, actionParams])

    const renderConditionGroup = (group, groupPath = []) => {
        const nestingLevel = groupPath.length
        const paddingLeft = Math.max(nestingLevel, 1) * 10 // Increase padding by 20px for each level of nesting
        const backgroundColor = nestingLevel % 2 === 0 ? '#e0e1e8' : '#f1f1f1'
        return (
            <div key={groupPath.join('-')} className="condition-group"
                style={{
                    paddingLeft: `${paddingLeft}px`,
                    paddingRight: `5px`,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    margin: "20px 0",
                    backgroundColor: backgroundColor,
                }}>
                <FormGroup>
                    <Label for={`logicalOperator-${groupPath.join('-')}`}>Logical Operator</Label>
                    <Input
                        type="select"
                        id={`logicalOperator-${groupPath.join('-')}`}
                        value={group.logicalOperator}
                        onChange={(e) => {
                            const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                            let currentGroup = newGroup
                            for (let index of groupPath) {
                                currentGroup = currentGroup.childGroups[index]
                            }
                            currentGroup.logicalOperator = e.target.value
                            setConditionGroup(newGroup)
                        }}
                    >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                    </Input>
                </FormGroup>

                {group.conditions.map((condition, index) => (
                    <Row key={`${groupPath.join('-')}-${index}`}>
                        <Col>
                            <FormGroup>
                                <Label for={`version-${groupPath.join('-')}-${index}`}>Version</Label>
                                <Input
                                    type="select"
                                    id={`version-${groupPath.join('-')}-${index}`}
                                    value={condition.version}
                                    onChange={(e) => {
                                        const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                                        let currentGroup = newGroup
                                        for (let pathIndex of groupPath) {
                                            currentGroup = currentGroup.childGroups[pathIndex]
                                        }
                                        currentGroup.conditions[index].version = e.target.value
                                        setConditionGroup(newGroup)
                                    }}
                                >
                                    <option value="CURRENT">CURRENT</option>
                                    {triggerType === 'ON_UPDATE' && <option value="PREVIOUS">PREVIOUS</option>}
                                    {availableModel && availableModel.availableModelProperties && availableModel.availableModelProperties.length > 0 && <option value="CUSTOM_FIELDS">CUSTOM FIELDS</option>}
                                </Input>
                            </FormGroup>
                        </Col>
                        {condition.version === 'CUSTOM_FIELDS' ?
                            <Col>
                                <FormGroup>
                                    <Label for="fieldName">Field Name</Label>
                                    <Select className="bos-custom-select"
                                        classNamePrefix="bos-select" isLoading={false}
                                        options={
                                            availableModel ? availableModel.availableModelProperties.map((field) => ({
                                                label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                                value: field,
                                            })) : []
                                        }
                                        placeholder=""
                                        onChange={(option) => {
                                            const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                                            let currentGroup = newGroup
                                            for (let pathIndex of groupPath) {
                                                currentGroup = currentGroup.childGroups[pathIndex]
                                            }
                                            currentGroup.conditions[index].fieldName = option.value
                                            setConditionGroup(newGroup)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            :
                            <Col>
                                <FormGroup>
                                    <Label for={`fieldName-${groupPath.join('-')}-${index}`}>Field Name</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={
                                            availableModel ? availableModel.availableModelFields.map((field) => ({
                                                label: field.replaceAll('_', ' ').toUpperCase(),
                                                value: field,
                                            })) : []
                                        }
                                        placeholder=""
                                        onChange={(option) => {
                                            const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                                            let currentGroup = newGroup
                                            for (let pathIndex of groupPath) {
                                                currentGroup = currentGroup.childGroups[pathIndex]
                                            }
                                            currentGroup.conditions[index].fieldName = option.value
                                            setConditionGroup(newGroup)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        <Col>
                            <FormGroup>
                                <Label for={`conditionType-${groupPath.join('-')}-${index}`}>Condition Type</Label>
                                <Select
                                    className="bos-custom-select"
                                    classNamePrefix="bos-select"
                                    options={
                                        conditionTypes && conditionTypes.states.map((item) => ({
                                            label: item.name.replaceAll('_', ' ').toUpperCase(),
                                            value: item.name,
                                        }))
                                    }
                                    placeholder=""
                                    onChange={(option) => {
                                        const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                                        let currentGroup = newGroup
                                        for (let pathIndex of groupPath) {
                                            currentGroup = currentGroup.childGroups[pathIndex]
                                        }
                                        currentGroup.conditions[index].conditionType = option.value
                                        setConditionGroup(newGroup)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for={`conditionValue-${groupPath.join('-')}-${index}`}>Condition Value</Label>
                                <Input
                                    type="text"
                                    id={`conditionValue-${groupPath.join('-')}-${index}`}
                                    value={condition.conditionValue}
                                    onChange={(e) => {
                                        const newGroup = JSON.parse(JSON.stringify(conditionGroup))
                                        let currentGroup = newGroup
                                        for (let pathIndex of groupPath) {
                                            currentGroup = currentGroup.childGroups[pathIndex]
                                        }
                                        currentGroup.conditions[index].conditionValue = e.target.value
                                        setConditionGroup(newGroup)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                ))}

                <Button color="secondary" onClick={() => handleAddCondition(group, groupPath)}>Add Condition</Button>
                <Button color="secondary" onClick={() => handleAddChildGroup(group, groupPath)} className="ml-2">Add Nested Condition Group</Button>

                {/* Add a "Remove Nested Condition Group" button for all nested groups except the root */}
                {groupPath.length > 0 && (
                    <Button color="danger" onClick={() => handleRemoveChildGroup(groupPath)} className="ml-2">Remove Nested Condition Group</Button>
                )}

                {/* Recursively render child groups */}
                {group.childGroups.map((childGroup, index) =>
                    renderConditionGroup(childGroup, [...groupPath, index])
                )}
            </div>
        )
    }




    return (
        <Container fluid>
            <Row>
                <Col xs="6">
                    <Link to="/automations/" className="btn btn-sm btn-secondary mb-2"> {"<"} Back</Link>
                    <h2>New Automation Workflow</h2>
                    <p>Automations are workflows that are triggered based on certain conditions and perform actions based on those conditions.</p>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Model to trigger from</Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                options={
                                    allAvailableModels && allAvailableModels.edges.map(({ node }) => ({
                                        label: `${node.contentType.appLabel.toUpperCase()} - ${node.contentType.model.toUpperCase()}`,
                                        value: node,
                                    }))
                                }
                                placeholder="-- Select model --"
                                onChange={(option) => setAvailableModel(option.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="triggerType">Trigger Type</Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                options={
                                    triggerTypes && triggerTypes.states.map((item) => ({
                                        label: item.name.replaceAll('_', ' ').toUpperCase(),
                                        value: item.name,
                                    }))
                                }
                                placeholder="-- Select trigger --"
                                onChange={(option) => setTriggerType(option.value)}
                            />
                        </FormGroup>

                        {triggerType === 'ON_UPDATE' && (
                            <FormGroup>
                                <Label for="columnsUpdated">Updated Columns</Label>
                                <Select
                                    className="bos-custom-select"
                                    classNamePrefix="bos-select"
                                    isMulti
                                    options={
                                        availableModel ? availableModel.availableModelFields.map((field) => ({
                                            label: field.replaceAll('_', ' ').toUpperCase(),
                                            value: field,
                                        })) : []
                                    }
                                    placeholder="Select columns"
                                    onChange={(options) => setColumnsUpdated(options.map((option) => option.value))}
                                />
                            </FormGroup>
                        )}

                        <hr />
                        <h3>Conditions</h3>
                        {renderConditionGroup(conditionGroup)}

                        <hr />
                        <h3>Action</h3>
                        <small>To use instance properties in the action fields, enter {"{{instance.field_name}}"}. e.g To say hello to a driver with their name, use this:
                            <strong><br />Hello {"{{instance.name}}"}<br /></strong>
                        </small>
                        <FormGroup>
                            <Label for="actionType">Action Type</Label>
                            <Select className="bos-custom-select"
                                classNamePrefix="bos-select" isLoading={false}
                                options={
                                    actionTypesAndParams && actionTypesAndParams.map((item) => ({
                                        label: item.actionType ? item.actionType.replaceAll('_', ' ').toUpperCase() : '',
                                        value: item,
                                    }))
                                }
                                placeholder="-- Select action --"
                                onChange={(option) => {
                                    setActionType(option.value.actionType)
                                    setActionParams(JSON.parse(option.value.actionParams))
                                    setActionParamsValues({})
                                }}
                            />
                        </FormGroup>
                        {actionType && actionType === "update_instance" &&
                            <FormGroup>
                                <Label>Update Instance Fields</Label>
                                {updateInstanceActionFields.map((field, index) => (
                                    <Row key={index} className="mb-3">
                                        <Col xs="5">
                                            <Select className="bos-custom-select"
                                                classNamePrefix="bos-select" isLoading={false}
                                                options={
                                                    availableModel && availableModel.availableModelFields ?
                                                        availableModel.availableModelFields.filter(
                                                            field => !updateInstanceActionFields.map(field => field.fieldName).includes(field)).map((field) => ({
                                                                label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                                                value: field,
                                                            })) : []
                                                }
                                                placeholder=""
                                                onChange={(option) => handleUpdateInstanceActionFieldsChange(index, 'fieldName', option.value)}
                                            />
                                        </Col>
                                        <Col xs="5">
                                            <Input
                                                type="text"
                                                placeholder="Value"
                                                value={field.value}
                                                onChange={(e) => handleUpdateInstanceActionFieldsChange(index, 'value', e.target.value)}
                                            />
                                        </Col>
                                        <Col xs="2">
                                            <Button color="danger" onClick={() => setUpdateInstanceActionFields(updateInstanceActionFields.filter((_, i) => i !== index))}>Remove</Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button color="secondary" onClick={() => setUpdateInstanceActionFields([...updateInstanceActionFields, { fieldName: '', value: '' }])}>Add Field</Button>
                            </FormGroup>
                        }
                        {actionType && actionParams && actionParams.length > 0 && (
                            <div>
                                {actionParams.map((param) => (
                                    <FormGroup key={param.name}>
                                        <Label for={param.name}>{param.name.toUpperCase()}{param.is_required && " *"}</Label>
                                        {param.description && <FormText color="muted">{param.description}</FormText>}
                                        {param.input === 'input' ? (
                                            <Input
                                                type="text"
                                                id={param.name + "actionParam"}
                                                value={actionParamsValues[param.name]}
                                                defaultValue={param.default || ''}
                                                required={param.is_required}
                                                onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                                onFocus={(e) => setOpenModal(`Popover${param.name}`)}
                                                onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                                innerRef={(el) => inputRefs.current[param.name] = el}
                                            />
                                        ) : param.input === 'textarea' ? (
                                            <Input
                                                type="textarea"
                                                id={param.name + "actionParam"}
                                                value={actionParamsValues[param.name]}
                                                defaultValue={param.default || ''}
                                                required={param.is_required}
                                                onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                                onFocus={(e) => setOpenModal(`Popover${param.name}`)}
                                                onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                                innerRef={(el) => inputRefs.current[param.name] = el}
                                            />
                                        ) : param.input === 'select' ? (
                                            <Input
                                                type="select"
                                                id={param.name + "actionParam"}
                                                value={actionParamsValues[param.name]}
                                                required={param.is_required}
                                                onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                            >
                                                {param.choices.map((choice) => (
                                                    <option key={choice} value={choice}>{choice}</option>
                                                ))}
                                            </Input>
                                        ) : null}

                                        <Popover placement="bottom" target={param.name + "actionParam"} isOpen={openModal === `Popover${param.name}`} toggle={() => setOpenModal('')}>
                                            <PopoverHeader>Populate from {availableModel && `${availableModel.contentType.model}`} fields</PopoverHeader>
                                            <PopoverBody>
                                                {availableModel && availableModel.availableModelFields.map((field) => (
                                                    <Badge
                                                        className="text-dark m-2 p-2" key={field}
                                                        style={{ cursor: "pointer", fontSize: 10 }} color="warning"
                                                        onClick={() => handleInstanceFieldClick(param.name, field)}>
                                                        {field.replaceAll('_', ' ').toUpperCase()}
                                                    </Badge>
                                                ))}
                                            </PopoverBody>
                                        </Popover>
                                    </FormGroup>
                                ))}
                            </div>
                        )}
                        <hr />

                        {error && <div className="alert alert-danger">{error}</div>}
                        <FormGroup className="text-right">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!availableModel || !name || !triggerType || !actionType}
                            >Create Automation</Button>
                        </FormGroup>
                    </Form>
                </Col>

                <Col xs="6">
                    <h2>Preview</h2>
                    <p>Showing fields of recent samples from available model selected</p>

                    <p>Recent objects from {availableModel && availableModel.contentType && availableModel.contentType.model ? availableModel && availableModel.contentType && availableModel.contentType.model.toUpperCase() : ""} model:</p>
                    <pre>
                        {availableModel && availableModel.recentObjects && parseRecentObjects(availableModel.recentObjects).length > 0 ?
                            Object.entries(parseRecentObjects(availableModel.recentObjects)[0]).map(([key, value]) => (
                                <pre key={key}>
                                    <span>{key}: {JSON.stringify(value)}</span>
                                </pre>
                            ))
                            : 'No recent objects'
                        }
                    </pre>
                </Col>
            </Row>
        </Container>
    )
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllAvailableModelsQuery, {
    options: () => ({
        variables: {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, allAvailableModels } }) => ({
        loading,
        allAvailableModels,
    }),
})(graphql(ActionTypesAndParamsQuery, {
    options: () => ({
        variables: {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, actionTypesAndParams } }) => ({
        loading,
        actionTypesAndParams,
    }),
})(graphql(ConditionTypes, {
    options: () => ({
        variables: {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, conditionTypes } }) => ({
        loading,
        conditionTypes,
    }),
})(graphql(CREATE_AUTOMATION_WORKFLOW, { name: 'createAutomationWorkflow' })(
    graphql(TriggerTypes, {
        options: () => ({
            variables: {},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { loading, triggerTypes } }) => ({
            loading,
            triggerTypes,
        }),
    })(
        withApolloClient(AutomationForm)
    )
))));

