import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import ListingFilters from '../Material/ListingFilters'
import { debounce } from 'lodash'
import toTitleCase from '../Functions/toTitleCase'
import { Row, Col, Input } from "reactstrap"
import { Link } from "react-router-dom"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18


const AutomatelyListHeader = ({ activeTab, filterValues, setFilterValues, selectedIds, resetSelectedIds, client, ...props }) => {
    const [openModal, setOpenModal] = useState("")
    const [configurations, setConfigurations] = useState(null)
    const [searchTerm, setSearchTerm] = useState(filterValues && filterValues.searchTerm ? filterValues.searchTerm : '')
    const getFilterConfigurations = (activeTab) => {
        return [
            {
                type: "boolean", name: "isActive", title: "Active", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Active Status", showFilter: true
            },
        ]
    }
    const toggleModal = (modalName) => {
        setOpenModal(openModal === modalName ? "" : modalName)
    }
    const updateSearchTerm = useCallback(debounce((searchTerm) => {
        setFilterValues({ ...filterValues, searchTerm })
    }, 500), [filterValues])
    const removeFilter = (filterName) => {
        let newFilterValues = { ...filterValues }
        if (newFilterValues.hasOwnProperty(filterName)) {
            delete newFilterValues[filterName]
            setFilterValues(newFilterValues)
        }
    }
    const getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type === "select") {
            let options = filterConfigs.options
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value === item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    } else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range") {
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        }
        return Array.isArray(value) ? value.join(", ") : value
    }

    useEffect(() => {
        const conf = getFilterConfigurations(activeTab)
        const fetchConfigurations = async () => {
            const configurations = await Promise.all(conf.filter(filter => filter.showFilter).map(async filter => {
                if (filter.optionsQuery) {
                    const result = await client.query({
                        query: filter.optionsQuery,
                        variables: { ...filter.variables }
                    })
                    let options = []
                    if (result && result.data.optionsList) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector],
                            label: options.node[filter.labelSelector]
                        }))
                    }
                    filter.options = options
                    delete filter.optionsQuery
                }
                return filter
            }))
            setConfigurations(configurations)
        }
        fetchConfigurations()
    }, [client, activeTab])

    if (!client) {
        return null
    }

    return (
        <Row>
            {openModal === "viewFilters" && (
                <ListingFilters open={openModal === "viewFilters"} handleClose={() => toggleModal("")}
                    target="viewFilters" filterValues={filterValues} setFilterValues={setFilterValues}
                    filters={configurations} />
            )}
            <Col xs={6}>
                <span className="driver-search-filter">
                    <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => { setSearchTerm(e.target.value); updateSearchTerm(e.target.value) }} value={searchTerm} />
                    <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                </span>
            </Col>
            <Col xs={6} className="text-right mt-2">
                <Link to="/automations/new" className="driver-list-button mr-4">New Automation <i className="fa fa-plus-square" aria-hidden="true"></i></Link>

                <a id="viewFilters" className="driver-list-button" onClick={() => toggleModal("viewFilters")}>
                    Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {filterValues && Object.keys(filterValues).filter(key => filterValues[key] !== null && configurations && configurations.find(setting => setting.name === key) && configurations.find(setting => setting.name === key).showFilter).length || ""}
                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
            <Col xs={12} className="my-4">
                {Object.keys(filterValues).filter(key => filterValues[key] !== null && configurations && configurations.find(setting => setting.name === key) && configurations.find(setting => setting.name === key).showFilter).map(key =>
                    <span className="search-filter-preview" key={key}>
                        <span>
                            <i className="fa fa-times-circle" onClick={() => removeFilter(configurations.find(setting => setting.name === key).name)}></i>&nbsp;&nbsp;
                            {configurations.find(setting => setting.name === key).title}
                        </span>
                        {getValueByFilterType(configurations.find(setting => setting.name === key), filterValues[key])}
                    </span>
                )}
                {selectedIds && selectedIds.length > 0 && (
                    <span className="search-filter-preview">
                        <span>
                            <i className="fa fa-times-circle" onClick={resetSelectedIds}></i> &nbsp;&nbsp; Selected Items
                        </span>
                        {selectedIds.length}
                    </span>
                )}
            </Col>
        </Row>
    )
}
//Iagami - Code upgradation React 18
export default (withApolloClient(AutomatelyListHeader))