import React, { Component } from "react"
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Form,
  FormGroup,
  Input,
  Badge,
  Button,
  Label
} from "reactstrap"
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { UpdatePricingTemplateMutation } from "./Mutations"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

class UpdatePopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pricingTemplate: this.props.dataId,
      value: this.props.value ? this.props.value : "",
      error: "",
    }
  }

  componentDidMount() {
    const { apolloClient } = this.props
}

  handleSubmit = (e) => {
    e.preventDefault()
    try {
      let input = {
        pricingTemplate: this.state.pricingTemplate,
        [this.props.name]: ['price', 'minimumPrice', 'maximumPrice'].includes(this.props.name) ? parseFloat(this.state.value)
          : ['carMileageMin', 'carMileageMax'].includes(this.props.name) ? { mileage: parseFloat(this.state.value), mileageUnit: this.props.mileageUnit === 'KM' ? 'KM' : 'MI' }
            : ['interval', 'carYearMin', 'carYearMax'].includes(this.props.name) ? parseInt(this.state.value)
              : this.state.value,
      }


      this.setState({ loading: true })
      this.props.apolloClient
        .mutate({
          mutation: UpdatePricingTemplateMutation,
          variables: { input },
        })
        .then((result) => {
          if (result.data && result.data.updatePricingTemplate && result.data.updatePricingTemplate.ok) {
            this.props.refetchAllPricingTemplates()
            this.props.handleClose()
          } else if (result.data && result.data.updatePricingTemplate && !result.data.updatePricingTemplate.ok && result.data.updatePricingTemplate.errors && result.data.updatePricingTemplate.errors[0]) {
            this.setState({ loading: false, error: result.data.updatePricingTemplate.errors[0].messages.toString() })
          } else {
            this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
          }
        })
        .catch((err) => {
          this.setState({ loading: false, error: err.message })
        })
    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  render() {
    return (
      <Popover isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.handleClose} placement="left">
        <PopoverHeader>{this.props.header}</PopoverHeader>
        <PopoverBody>
          <Form onSubmit={this.handleSubmit}>
            {this.props.type === "number" &&
              <FormGroup>
                <Input type="number" step="any" name={this.props.name} id="templatepricing" placeholder={this.props.header} value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
              </FormGroup>
            }
            {this.props.type === "checkbox" &&
              <FormGroup check inline>
                <Input type="checkbox" checked={this.state.value} onChange={(e) => this.setState({ value: e.target.checked ? true : false })} />
                &nbsp;&nbsp;
                <p>{this.props.name}</p>
              </FormGroup>
            }
            <br />
            {this.state.loading ? "Loading..." :
              <Button color="success" type="submit">Update</Button>
            }
          </Form>
          {this.state.error && (
            <Badge pill="true" style={{ whiteSpace: "pre-wrap" }} color="danger">
              {this.state.error}
            </Badge>
          )}
        </PopoverBody>
      </Popover>
    )
  }
}

//Iagami - Code upgradation React 18
export default withApolloClient(UpdatePopup)
