import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Button,
    Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText
} from 'reactstrap'
import './NotificationsList.css'
import StyledToast from '../Components/StyledToast'

const sendMessageMutation = gql`
mutation sendMessage($driverIDs:[ID]!, $messageBody:String!, $subject:String!){
  sendMessage(input:{drivers:$driverIDs, messageText: $messageBody, messageSubject: $subject}){
    ok
  }
}`


class NotificationsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    sendReport = () => {

    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Modal className="NotificationsListModal" isOpen={this.props.open} toggle={this.props.handleClose}>
                <ModalHeader>Hi, you have 3 open items on your list</ModalHeader>
                <ModalBody>
                    {this.state.error && !this.state.messageSent && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong><br /><span>MARK AS COMPLETE</span>
                            </StyledToast>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!this.state.loading &&
                        <Row>
                            <Col xs={12}>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                            </Col>
                        </Row>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default NotificationsList