import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../CarDetail.css'
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap'
import UpdatePopup from "../../../PriceManagement/UpdatePopup"
import { CarPricingQuery } from '../Queries'
import Loader from "../../../Material/Loader"
import Label from "reactstrap/lib/Label"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

class PricingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        const pricingTable = [
            { id: "pk", name: "id", sortable: false, colSize: 1 },
            { id: "dateAdded", name: "Date Added", sortable: false },
            { id: "interval", name: "Interval", sortable: false, colSize: 1 },
            { id: "price", name: "Price", sortable: false, colSize: 1 },
            { id: "priceRange", name: "Price Range", sortable: false },
            { id: "carYearMax", name: "Model Year Range", sortable: false },
            { id: "carMileageMax", name: "Car Mileage Range", sortable: false },
            { id: "visibleToCustomers", name: "Customer Visibility", sortable: false },
        ]
        return (
            <div className="pricing-tab-container">
                {this.props.loading ? <Loader /> :
                    <div className="info-container">
                        <Row>
                            {pricingTable.map(header => <Col xs={header.colSize}><Label id={header.id}>{header.name}</Label></Col>)}
                        </Row>
                        <hr />
                        {this.props.allPricingTemplates && this.props.allPricingTemplates.edges && this.props.allPricingTemplates.edges.length > 0 ?
                            this.props.allPricingTemplates.edges.filter(price => price.node !== null).map((price, index) =>
                                <>
                                    <Row key={price.node.id} id={price.node.id}>
                                        <Col scope="row" xs={1}>{index + 1}</Col>
                                        <Col>
                                            <p>{price.node && price.node.dateAdded ? <DatetimeRenderer datetime={price.node.dateAdded} format={"dddd, MMMM Do, h:mm a"} /> : "---"}</p>
                                        </Col>
                                        <Col xs={1}>
                                            <p>
                                                {price.node.interval} {price.node.intervalUnit}&nbsp;&nbsp;
                                                {price.node.isActive ? <>
                                                    <i id={"priceIsActive" + index} className="ml-2 fa fa-circle text-success" />
                                                    <UncontrolledTooltip placement="right" target={"priceIsActive" + index}>Active Price</UncontrolledTooltip>
                                                </> : <>
                                                    <i id={"priceIsNotActive" + index} className="ml-2 fa fa-circle" style={{ color: '#DC3546' }} />
                                                    <UncontrolledTooltip placement="right" target={"priceIsNotActive" + index}>In-Active Price</UncontrolledTooltip>
                                                </>}
                                            </p>
                                        </Col>
                                        <Col xs={1}>
                                            <p>
                                                ${price.node.price}&nbsp;&nbsp;
                                            </p>
                                            {this.state.openModal === "updatePrice" + index &&
                                                <UpdatePopup
                                                    target={"updatePrice" + index}
                                                    isOpen={this.state.openModal == "updatePrice" + index}
                                                    header="Update date Price"
                                                    dataId={price.node.id}
                                                    value={price.node.price}
                                                    name="price"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updatePrice" + index)}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <p>
                                                ${price.node.minimumPrice}&nbsp;&#8212;&nbsp;${price.node.maximumPrice}
                                            </p>
                                            {this.state.openModal === "updatemaximumPrice" + index &&
                                                <UpdatePopup
                                                    target={"updatemaximumPrice" + index}
                                                    isOpen={this.state.openModal == "updatemaximumPrice" + index}
                                                    header="Update Template Maximum Price"
                                                    dataId={price.node.id}
                                                    value={price.node.maximumPrice}
                                                    name="maximumPrice"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updatemaximumPrice" + index)}
                                                />
                                            }
                                            {this.state.openModal === "updateMinimumPrice" + index &&
                                                <UpdatePopup
                                                    target={"updateMinimumPrice" + index}
                                                    isOpen={this.state.openModal == "updateMinimumPrice" + index}
                                                    header="Update Template Minimum Price"
                                                    dataId={price.node.id}
                                                    value={price.node.minimumPrice}
                                                    name="minimumPrice"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updateMinimumPrice" + index)}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <p>
                                                {price.node.carYearMin}&nbsp;&#8212;&nbsp;{price.node.carYearMax}
                                            </p>
                                        </Col>
                                        <Col>
                                            <p>
                                                {price.node.agreementType && price.node.agreementType.branch && price.node.agreementType.branch.defaultMileageUnit && price.node.agreementType.branch.defaultMileageUnit === 'KM' ?
                                                    `${price.node.carMileageMinInKm ? parseFloat(price.node.carMileageMinInKm).toFixed(2) : ""} — ${price.node.carMileageMaxInKm ? parseFloat(price.node.carMileageMaxInKm).toFixed(2) : ""} KM` : `${price.node.carMileageMin ? parseFloat(price.node.carMileageMin).toFixed(2) : ""} — ${price.node.carMileageMax ? parseFloat(price.node.carMileageMax).toFixed(2) : ""} MI`
                                                }
                                            </p>
                                        </Col>
                                        <Col>
                                            <p>
                                                {price.node.visibleToCustomers ? "True" : "False"}
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            ) : <Row> <Col colSpan={20}><p className="column-text">No Templates Found!</p> </Col> </Row>
                        }
                        {this.props.allPricingTemplates && this.props.allPricingTemplates.pageInfo && this.props.allPricingTemplates.pageInfo.hasNextPage &&
                            <Row>
                                <Col colSpan={20}>
                                    <Button onClick={this.props.loadMoreEntries} color="add-new-car link linkHoverEffect">
                                        Show More <i className={"fa fa-caret-down"}></i>
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(CarPricingQuery, {
    options: ({ id }) => ({ variables: { carId: id, first: 10 } }),
    props: ({ data: { loading, allPricingTemplates, fetchMore, variables } }) => (
        {
            variables,
            loading,
            allPricingTemplates,
            refetchQuery: () => {
                let currentLength = allPricingTemplates && allPricingTemplates.edges ? allPricingTemplates.edges.length : 30
                return fetchMore({
                    query: CarPricingQuery,
                    variables: {
                        ...variables,
                        first: currentLength,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allPricingTemplates.edges
                        const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo
                        return {
                            allPricingTemplates: {
                                edges: [...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.allPricingTemplates.__typename
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: CarPricingQuery,
                    variables: {
                        cursor: allPricingTemplates.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allPricingTemplates.edges
                        const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo

                        return {
                            allPricingTemplates: {
                                edges: [...previousResult.allPricingTemplates.edges, ...newEdges],
                                pageInfo,
                                __typename: previousResult.allPricingTemplates.__typename
                            },
                        }
                    },
                })
            },

        }
    )
})(withApolloClient(PricingTab))