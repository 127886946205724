import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress, FormText } from 'reactstrap'
import Select from 'react-select'
import { CreateTenantMutation } from "../Mutations"
import { AllBranches } from "../Queries"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import "./TenantListContainer.css"

class NewTenantModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            customId: null,
            description: null,
            selectedBranch: null,
            logoImage: "",
            isActive: true,
            errorMessage: "",
            loading: false,
        }
    }
    createTenant = (e) => {
        if (this.state.name && this.state.customId && this.state.selectedBranch && this.state.logoImage) {
            try {
                this.setState({ loading: true })
                let input = {
                    name: this.state.name,
                    customId: this.state.customId,
                    defaultBranchId: this.state.selectedBranch,
                    logoImage: this.state.logoImage,
                    description: this.state.description,
                    isActive: this.state.isActive
                }
                this.props.apolloClient.mutate({
                    mutation: CreateTenantMutation,
                    variables: { input }
                }).then((result) => {
                    if (result && result.data && result.data.createTenant && result.data.createTenant.ok) {
                        this.setState({ errorMessage: "" })
                        this.props.handleClose()
                        window.location.reload()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createTenant && result.data.createTenant.errors && result.data.createTenant.errors[0] && result.data.createTenant.errors[0].messages) {
                            errorMessage = result.data.createTenant.errors[0].messages.toString()
                        }
                        this.setState({ errorMessage: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured " + err
                    this.setState({ errorMessage: errorMessage, loading: false })
                })
            }
            catch (err) {
                let errorMessage = "An error has occured " + err
                this.setState({ errorMessage: errorMessage, loading: false })
            }
        } else this.setState({ errorMessage: "Missing Required Field", loading: false })
    }


    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };
    componentDidMount() {
        const { apolloClient } = this.props;
          }    

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="bos-create-tenant-modal">
                    <ModalHeader>ADD NEW TENANT</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.errorMessage && <Col xs={12}><Alert color="danger">{this.state.errorMessage}</Alert></Col>}
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="name">Name*</Label>
                                    <Input name="name" id="name" onChange={this.updateInput} placeholder="Tenant Name" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="customId">Custom ID *</Label>
                                    <Input name="customId" id="customId" onChange={this.updateInput} placeholder="Custom ID e.g MIAMI_FL" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Select Default Branch For Tenant *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ label: branch.node.name, value: branch.node.id }))}
                                        onChange={(branch) => this.setState({ selectedBranch: branch.value })}
                                        placeholder="Select Default Branch"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <Label>Is Active</Label>
                                <FormGroup>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isActive"
                                            id="isActive"
                                            checked={!this.state.isActive}
                                            onChange={() => this.setState({ isActive: !this.state.isActive })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isActive">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="logoImage">Upload Tenant Logo URL *</Label>
                                    <Input name="logoImage" id="logoImage" onChange={this.updateInput} placeholder="Upload logo url" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input name="description" id="description" onChange={this.updateInput} placeholder="Description" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="new-tenant-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>&nbsp;&nbsp;
                        <Button className="new-tenant-modal-primary-btn" onClick={this.createTenant} disabled={this.state.loading}>Add Tenant</Button>
                    </ModalFooter>
                </Modal >
            </div >
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllBranches, { props:({ data: { allBranches } }) => ({  allBranches }) })
(withApolloClient(NewTenantModal));
