// Iagami - Code Upgradation to React 18-useParams() cannot be used in class components. To resolve this, we've introduced wrapper files.
import React from 'react'
import { useParams } from 'react-router-dom'
import RentalTab from './RentalTab'

const RentalTabWrapper = (props) => {
    const params = useParams()
    return <RentalTab {...props} params={params} />
}

export default RentalTabWrapper
