import React, { Component } from "react"
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Document, Page, pdfjs } from "react-pdf"
import "./PDFViewer.css"
import 'react-pdf/dist/Page/AnnotationLayer.css'
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

class PDFViewer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numPages: null,
            pageNumber: 1,
            document: null,
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages, pageNumber: 2 })
    }
    handlePageChange = (e, pageNumber) => {
        e.preventDefault()
        this.setState({ pageNumber: pageNumber })
    }
    async urlToBase64(pdfURL) {
        try {
            const response = await fetch(pdfURL)
            const data = await response.blob()

            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve(reader.result.split(',')[1])
                }
                reader.onerror = () => {
                    reject('Error converting PDF to base64')
                }
                reader.readAsDataURL(data)
            })
        } catch (error) {
            throw new Error('Error fetching PDF from URL')
        }
    }
    base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length)

        const bytes = atob(base64WithoutPrefix)
        let length = bytes.length
        let out = new Uint8Array(length)

        while (length--) {
            out[length] = bytes.charCodeAt(length)
        }

        return new Blob([out], { type: 'application/pdf' })
    };
    componentDidMount() {
        this.urlToBase64(this.props.documentURL)
            .then(base64String => {
                const documentBlob = this.base64toBlob(base64String)
                this.setState({ document: documentBlob })
            })
            .catch(error => {
                console.error(error)
            })
    }
    render() {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
        return (
            <div className="pdf-container" >
                <div className="pdf-canvas">
                    <>
                        <Document file={this.state.document} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={this.state.pageNumber} />
                        </Document>
                        <br />
                        <div className="pagination">
                            <button
                                onClick={(e) => this.handlePageChange(e, this.state.pageNumber - 1)}
                                disabled={this.state.pageNumber <= 1}
                            >
                                <i className="fa fa-chevron-left"></i>
                            </button>&nbsp;
                            <p>Page {this.state.pageNumber} of {this.state.numPages}</p>&nbsp;
                            <button
                                onClick={(e) => this.handlePageChange(e, this.state.pageNumber + 1)}
                                disabled={this.state.pageNumber >= this.state.numPages}
                            >
                                <i className="fa fa-chevron-right"></i>
                            </button>
                        </div>
                    </>
                </div>
                <div className="close">
                    <i className="fa fa-times fa-lg close" aria-hidden="true" onClick={this.props.handleClose}></i>
                </div>

            </div>
        )
    }
}
//Iagami - Code upgradation React 18
export default (withApolloClient(PDFViewer))