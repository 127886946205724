import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../BranchMangement/BranchListContainer.css'
import { Table, Badge, UncontrolledTooltip } from 'reactstrap'
import CreateUpdateMemberModal from "./CreateUpdateMemberModal"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import { MembersQuery } from "../Queries"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { Link } from 'react-router-dom'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'


const tableHeaders = [
    { id: "date_modified", name: "Last Modified", sortable: false },
    { id: "user__username", name: "Username", sortable: false },
    { id: "user__full_name", name: "Full Name", sortable: false },
    { id: "other_branches", name: "Branches", sortable: false },
    { id: "lastLogin", name: "Last Login", sortable: false },
    { id: "dateJoined", name: "Date Joined", sortable: false },
    { id: "type", name: "Type", sortable: false },
    { id: "action", name: "Actions", sortable: false }
]

class AllMembersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            showEdit: "",
            input: {},
            searchTerm: "",
            openModal: "",
            selectedMembers: [],
            member: {},
            selectAll: false,
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        let currentState = this.state
        currentState[name] = value
        this.setState({ ...currentState })
    }

    updateSelected = (value) => {
        let selectedMembers = this.props.selectedMembers
        if (selectedMembers.includes(value)) {
            selectedMembers = selectedMembers.filter(item => item !== value)
        } else {
            selectedMembers.push(value)
        }
        this.props.setSelectedMembers(selectedMembers)
    };

    toggleSelectAllClick = () => {
        if (this.props.members && !this.state.selectAll) {
            this.props.setSelectedDrivers(this.props.members.edges.map(n => n.node.user.id))
            this.setState({ selectAll: true })
        } else {
            this.props.setSelectedDrivers([])
            this.setState({ selectAll: false })
        }
    };


    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };


    render() {
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.members && this.props.members.edges.length} pageInfo={this.props.members && this.props.members.pageInfo} loadMoreEntries={this.props.members && this.props.members.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                                {tableHeaders.map(tableHeader => {
                                    return (
                                        tableHeader.sortable ?
                                            <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )
                                })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.members && this.props.members.edges && this.props.members.edges.length > 0 ? this.props.members.edges.map((member, i) =>
                                <tr key={i + 1}>
                                    <td scope="row" className="listing-checkbox-wrapper">
                                        {!this.props.selectedMembers.includes(member.node.user.id) && <span className="listing-counter">{i + 1}</span>}
                                        <span className="listing-checkbox" onClick={() => this.updateSelected(member.node.user.id)}>
                                            {this.props.selectedMembers.includes(member.node.user.id) ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                        </span>
                                    </td>
                                    <td>{member.node.dateModified ? <DatetimeRenderer datetime={member.node.dateModified} /> : "--"}</td>
                                    <td>
                                        <br /><Link color="secondary" to={'/organization/member/' + member.node.id}>{member.node.user ? member.node.user.firstName ? member.node.user.firstName + " " + member.node.user.lastName : member.node.user.username : '--'}</Link>
                                        <i id={"carIsActive" + i} className={"ml-2 fa fa-circle " + (member.node.user.isActive ? "text-success" : "text-danger")} ></i>
                                        <UncontrolledTooltip placement="right" target={"carIsActive" + i}>{member.node.user.isActive ? "Active" : "Inactive"}</UncontrolledTooltip><br />
                                        <small>{member.node.user ? member.node.user.email : '--'}</small>
                                    </td>
                                    <td>{member.node.user ? `${member.node.user.firstName ? member.node.user.firstName : "-"} ${member.node.user.lastName ? member.node.user.lastName : "-"}` : '--'}</td>
                                    <td>{member.node.branches && member.node.branches.edges && member.node.branches.edges.length > 0 && member.node.branches.edges.map((item) => <><Badge style={{ fontSize: 10 }} color={member.node && member.node.defaultBranch && member.node.defaultBranch.id === item.node.id ? "primary" : "secondary"}>{item.node.name}</Badge>&nbsp;</>)}</td>
                                    <td>{member.node.user && member.node.user.lastLogin ? <DatetimeRenderer datetime={member.node.user.lastLogin} /> : "--"}</td>
                                    <td>{member.node.user && member.node.user.dateJoined ? <DatetimeRenderer datetime={member.node.user.dateJoined} /> : "--"}</td>
                                    <td>{member.node.type}</td>
                                    <td className="text-center">
                                        {this.state.openModal === ("removeMember" + i) && <CreateUpdateMemberModal handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === ("removeMember" + i)} refetchQuery={() => this.props.refetchQuery()} memberDetails={member} action={"delete"} isMultiUpdate={false} branchId={this.props.branchId ? this.props.branchId : ""} />}
                                        <i id={"remove" + i} onClick={() => this.toggleModal("removeMember" + i)} className={"fa fa-trash-o text-danger fa-lg"} />
                                        <UncontrolledTooltip target={"remove" + i}>Remove All Branches</UncontrolledTooltip>
                                    </td>
                                </tr>
                            ) :
                                <tr>
                                    {!this.props.loading ?
                                        <td colSpan={7}>No Member Found</td> :
                                        <td colSpan={7}>Loading ...</td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </Table>
                </PrimaryListingContainer>
            </>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(MembersQuery, {
    options: ({ branchIds, isSuperuser, groups, tenant, permissions, isActive, searchTerm }) => ({
        fetchPolicy: 'cache-and-network',
        variables: {
            isSuperuser,
            tenant,
            branchIds,
            groups,
            permissions,
            searchTerm,
            isActive,
            first: 30
        }
    }),
    props:({ data: { loading, members, fetchMore, variables, name, branchId } }) =>({
            loading,
            members,
            variables,
            refetchQuery: () => {
                let currentLength = members && members.edges && members.edges.length > 30 ? members.edges.length : 30
                return fetchMore({
                    query: MembersQuery,
                    variables: {
                        ...variables,
                        first: currentLength,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.members.edges
                        const pageInfo = fetchMoreResult.members.pageInfo
                        return {
                            members: {
                                edges: [...newEdges],
                                pageInfo,
                                __typename: previousResult.members.__typename
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: MembersQuery,
                    variables: {
                        cursor: members.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.members.edges
                        const pageInfo = fetchMoreResult.members.pageInfo

                        return {
                            members: {
                                edges: [...previousResult.members.edges, ...newEdges],
                                pageInfo,
                                __typename: previousResult.members.__typename
                            },
                        }
                    },
                })
            },
        })
})(withApolloClient(AllMembersList));