import React, { useState } from "react"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Table, Button } from "reactstrap"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { Badge } from "reactstrap"
import { AllPaymentSchedulesQuery } from "./Queries"
import PaymentScheduleModal from "./PaymentScheduleModal"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
import { PAYMENT_SCHEDULE_STATUS_COLOR_MAP } from "../../Constants"

const tableHeaders = [
    { id: "driver_id", name: "Driver", sortable: true },
    { id: "amountToCharge", name: "Amount to Charge", sortable: true },
    { id: "scheduledDatetime", name: "Scheduled Datetime", sortable: false },
    { id: "status", name: "Status", sortable: true },
    { id: "notes", name: "Notes", sortable: false },
    { id: "action", name: "Actions", sortable: false }
]

const PaymentSchedulesList = ({ updateOrder, loading, history, allPaymentSchedules, refetchAllPaymentSchedulesQuery, loadMoreEntries, orderBy }) => {
    const [openModal, setOpenModal] = useState("")
    const toggleModal = (modal) => {
        setOpenModal(openModal === modal ? "" : modal)
    }

    return (
        <div className="AutomationList" >
            <PrimaryListingContainer listLoading={loading} totalFetchedRows={allPaymentSchedules && allPaymentSchedules.edges.length} pageInfo={allPaymentSchedules && allPaymentSchedules.pageInfo} loadMoreEntries={allPaymentSchedules && allPaymentSchedules.pageInfo.hasNextPage ? loadMoreEntries : null} refetchListQuery={refetchAllPaymentSchedulesQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {allPaymentSchedules && allPaymentSchedules.edges && allPaymentSchedules.edges.length > 0 ? allPaymentSchedules.edges.map((paymentSchedule, index) => (
                            <tr key={paymentSchedule.node.id}>
                                <td>{index + 1}</td>
                                <td>
                                    <div className="d-flex" id={"driverInfo" + index}>
                                        <span>
                                            <small><b className={paymentSchedule.node.driver.balance && paymentSchedule.node.driver.balance > 0 ? "text-danger" : "text-success"}>${paymentSchedule.node.driver.balance}</b></small>
                                            <br />
                                            <a className="bos-custom-link" href={'/driver/' + paymentSchedule.node.driver.id} target="_blank">
                                                {paymentSchedule.node.driver.name.trim() ? paymentSchedule.node.driver.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                            </a>
                                            &nbsp;&nbsp;({paymentSchedule.node.driver.dmvLicense ? paymentSchedule.node.driver.dmvLicense : "--"})
                                        </span>
                                    </div>
                                </td>
                                <td>{paymentSchedule.node.amountToCharge}</td>

                                <td>
                                    {paymentSchedule.node && paymentSchedule.node.scheduleDatetime ? <DatetimeRenderer datetime={paymentSchedule.node.scheduleDatetime} /> : "--"}
                                </td>
                                <td>
                                    <Badge color={PAYMENT_SCHEDULE_STATUS_COLOR_MAP[paymentSchedule.node.status] || 'primary'} style={{ fontSize: 10, padding: 5 }}>{paymentSchedule.node.status}</Badge>
                                </td>
                                <td>{paymentSchedule.node.notes ? paymentSchedule.node.notes : "--"}</td>
                                <td>
                                    {openModal === ("viewPaymentSchedule" + index) &&
                                        <PaymentScheduleModal
                                            paymentScheduleId={paymentSchedule.node.id}
                                            open={openModal === "viewPaymentSchedule" + index}
                                            handleClose={() => toggleModal("")}
                                            refetchQuery={refetchAllPaymentSchedulesQuery}
                                            action={paymentSchedule.status === "CANCELED" ? "VIEW" : "UPDATE"} />
                                    }
                                    <i className={"fa " + (paymentSchedule.node.status === "CANCELED" ? "fa-eye" : "fa-pencil")} style={{ cursor: "pointer" }} onClick={() => toggleModal("viewPaymentSchedule" + index)}></i>
                                </td>
                            </tr>
                        )) :
                            <tr>
                                <td colSpan={tableHeaders.length + 1} className="text-center">No Payment Schedules Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        </div>
    )
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllPaymentSchedulesQuery, {
    options: ({ orderBy, driverId, statuses, searchTerm }) => ({ variables: { first: 30, orderBy, driverId, statuses, searchTerm } }),
    props: ({ data: { loading, error, variables, allPaymentSchedules, fetchMore } }) => ({
        loading, error, allPaymentSchedules,
        loadMoreEntries: () => {
            return fetchMore({
                query: AllPaymentSchedulesQuery,
                variables: {
                    cursor: allPaymentSchedules.pageInfo.endCursor,
                    ...variables
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allPaymentSchedules.edges
                    const pageInfo = fetchMoreResult.allPaymentSchedules.pageInfo

                    return {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        allPaymentSchedules: {
                            edges: [...previousResult.allPaymentSchedules.edges, ...newEdges],
                            pageInfo,
                            __typename: previousResult.allPaymentSchedules.__typename
                        },
                    }
                },
            })
        },
        refetchAllPaymentSchedulesQuery: () => {
            let currentLength = allPaymentSchedules.edges.length
            fetchMore({
                query: AllPaymentSchedulesQuery,
                variables: {
                    ...variables,
                    first: currentLength
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.allPaymentSchedules.edges
                    const pageInfo = fetchMoreResult.allPaymentSchedules.pageInfo
                    return {
                        allPaymentSchedules: {
                            edges: [...newEdges],
                            pageInfo,
                            __typename: previousResult.allPaymentSchedules.__typename
                        },
                    }
                }
            })
        }
    })
})
    (withApolloClient(PaymentSchedulesList))
