import { debounce } from "lodash"
import flattenObject from "./FlattenObject"

// Iagami - Code upgradation React 18
const updateFilters = debounce((filters, configurations, navigate, location, setFilterValues) => {
    let visibleFilters = configurations.filter(item => item.showFilter).map(item => item.name)
    let newFilterValues = {}

    Object.keys(filters).map(key => {
        if (visibleFilters.includes(key) || key === "searchTerm")
            newFilterValues[key] = filters[key]
    })

    newFilterValues = flattenObject(newFilterValues)
    const queryString = new URLSearchParams(newFilterValues).toString()
    navigate({
        pathname: location.pathname,
        hash: location.hash,
        search: queryString,
    })
    setFilterValues({ ...filters })

}, 1000)

export default updateFilters
