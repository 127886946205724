import React, { Component } from "react"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Popover, PopoverBody, PopoverHeader, Button } from "reactstrap"
import Select from "react-select"
import moment from "moment"
import { PreferredDateTimeContext } from "./Context/PreferredDateTimeContext"
import { TIMEZONES } from "./Constants"
import withApolloClient from './withApolloClient'

class TimeZoneSelectionPopover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTimeZone: null,
            loading: false
        }
    }

    updateTimeZone = () => {
        this.props.updateSelectedTimezone(this.state.selectedTimeZone)
        this.props.handleClose()
    }

    render() {
        const { target, open, handleClose } = this.props
        const { selectedTimeZone, loading } = this.state
        return (
            <PreferredDateTimeContext.Consumer>
                {({ preferredDateTimeInfo }) => (
                    <Popover placement="bottom" target={target} isOpen={open} toggle={handleClose} >
                        <PopoverHeader>Select Preferred Timezone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</PopoverHeader>
                        <PopoverBody>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                options={TIMEZONES.map(zone => ({ value: zone.value, label: moment.tz(zone.label).format('zz') + " | " + zone.label }))}
                                defaultValue={TIMEZONES.filter(timezone => timezone.value === preferredDateTimeInfo.timezone).map(zone => ({ value: zone.value, label: moment.tz(zone.label).format('z') + " | " + zone.label }))}
                                onChange={item => this.setState({ selectedTimeZone: item.value })}
                            />
                            <br />
                            <Button
                                size="sm"
                                onClick={this.updateTimeZone}
                                className="ghost"
                                style={{ float: "right", alignContent: "center", padding: "5px" }}
                                disabled={loading}
                            >
                                {loading ? "Loading..." : "UPDATE"}
                            </Button>
                            <br /><br />
                        </PopoverBody>
                    </Popover>
                )}
            </PreferredDateTimeContext.Consumer>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(TimeZoneSelectionPopover)
