import React, { Component } from 'react'
import { Badge, Table, UncontrolledTooltip, Button } from 'reactstrap'
import DocumentModal from '../../Material/DocumentModal/DocumentModal'
import Languages from "../../Material/Language.json"
import MassMessageModal from "../../Material/MassMessageModal"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { AllSubscriptions } from "./Queries"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import datetimeToString from "../../Functions/datetimeToString"
import "../../Material/ListingHeader.css"
import "../../index-dark.css"
import toTitleCase from "../../Functions/toTitleCase"
import renderEnumToInt from "../../Functions/renderEnumToInt"
import SubscriptionDetail from "./SubscriptionDetail"
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18


const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, views: true },
    { id: "diver__id", name: "Driver Info", sortable: true, views: true },
    { id: "id", name: "Subscription Info", sortable: true, views: true },
    { id: "amount", name: "Amount", sortable: false, views: true },
    { id: "deductible", name: "Deductible Amount", sortable: false, views: true },
    { id: "status", name: "Status", sortable: false, views: true },
    { id: "created_by", name: "Created By", sortable: false, views: true },
]

class SubscriptionsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectAll: false,
            showEdit: "",
            selectedDriverPreview: null
        }
    }


    renderLangCode(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    render() {
        let allSubscriptions = this.props.allSubscriptions
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={allSubscriptions && allSubscriptions.edges.length} pageInfo={allSubscriptions && allSubscriptions.pageInfo} loadMoreEntries={allSubscriptions && allSubscriptions.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                {tableHeaders.map(tableHeader => {
                                    return tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {allSubscriptions && allSubscriptions.edges && allSubscriptions.edges.length > 0 ? allSubscriptions.edges.map((subscription, i) => <tr key={subscription?.node?.id} className={subscription.node && subscription?.node?.inOffice ? "driver-in-office-row" : ""}>
                                <td scope="row">
                                    {<span className="listing-counter">{i + 1}</span>}
                                </td>
                                <td>
                                    <small>
                                        <DatetimeRenderer datetime={subscription?.node?.dateAdded} placement={"left"} />
                                        <br />
                                        <i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(subscription?.node?.dateAdded)}
                                    </small>
                                </td>
                                <td>
                                    {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[subscription?.node?.driver?.id]} />}
                                    {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={subscription?.node?.driver?.id} driverId={subscription?.node?.driver?.id} currentUser={this.props.currentUser} />}
                                    <div className="driver-detail-block">
                                        {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                            <label>{subscription?.node?.driver?.name}</label>
                                            <span className="bos-custom-tooltip-header">
                                                <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{subscription?.node?.driver?.email || "--"}<br />
                                                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{subscription?.node?.driver?.phone || "--"}<br />
                                            </span>
                                            <p className="bos-custom-tooltip-body">
                                                <b>Driver License:</b> {subscription?.node?.driver?.dmvLicense || "--"} <br />
                                                {subscription?.node?.driver?.isTlc && subscription?.node?.driver?.tlcLicense && <><b>TLC License:</b> {subscription?.node?.driver?.tlcLicense}<br /></>}
                                                <b>Preferred Language:</b> {subscription?.node?.driver?.preferredLanguage ? this.renderLangCode(subscription?.node?.driver?.preferredLanguage) : "--"}<br />
                                            </p>
                                            <span className="bos-custom-tooltip-footer">
                                                {<><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                {<><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                            </span>
                                        </UncontrolledTooltip>}
                                        <div className="d-flex" id={"driverInfo" + i}>
                                            <span>
                                                <small>
                                                    {subscription?.node?.driver?.dmvLicense || "--"}&nbsp;&nbsp;<Badge color="primary" className='bos-badge-small' >{subscription?.node?.driver?.branch && subscription?.node?.driver?.branch.name ? subscription?.node?.driver?.branch.name : "No Branch Selected"}</Badge>
                                                </small>
                                                <br />
                                                <a className="bos-custom-link" href={'/driver/' + subscription?.node?.driver?.id} target="_blank">
                                                    {subscription?.node?.driver?.name.trim() ? subscription?.node?.driver?.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {this.state.openModal === "subscriptionDetail" + i && <SubscriptionDetail handleClose={() => this.toggleModal("subscriptionDetail" + i)} open={this.state.openModal === "subscriptionDetail" + i} id={subscription?.node?.id} />}
                                    <small className={new Date(subscription?.node?.startDate) < new Date() && "text-success"}>Start @ {subscription?.node?.startDate ? <DatetimeRenderer datetime={subscription?.node?.startDate} /> : "--"}</small>
                                    <br />
                                    <a className="bos-custom-link" id={"subscriptionDetail" + i} onClick={() => this.toggleModal("subscriptionDetail" + i)}>
                                        {subscription?.node?.subscriptionType.name}
                                    </a>
                                </td>
                                <td>
                                    {"$" + subscription?.node?.subscriptionType.amount}
                                </td>
                                <td>
                                    {"$" + subscription?.node?.subscriptionType.deductibleAmount}
                                </td>
                                <td>
                                    <Badge className={(subscription?.node?.status) === "ACTIVE_1" ? "bg-success" : "bg-danger"}>{subscription?.node?.statusDisplay}</Badge>                                 </td>
                                <td>
                                    {subscription?.node?.createdBy ? toTitleCase(subscription?.node?.createdBy.firstName ? subscription?.node?.createdBy.firstName + " " + subscription?.node?.createdBy.lastName : subscription?.node?.createdBy.username) : "--"}
                                </td>
                            </tr>) :
                                this.props.loading ? <tr><td colSpan={7}>Loading Subscriptions List...</td></tr>
                                    : <tr>
                                        <th scope="row">&nbsp;</th>
                                        <td colSpan={7}>No Subscription Found</td>
                                    </tr>}
                        </tbody>
                    </Table>
                </PrimaryListingContainer >
            </>
        )
    }
}
// Iagami - Code upgradation React 18
const mapStatus = (status) => {
    const statusMap = {
        "1": "ACTIVE_1",
        "0": "INACTIVE_0"
    }
    return statusMap[status] || status // Default to itself if not in the map
}

export default graphql(AllSubscriptions, {
    options: ({ searchTerm, orderBy, statuses, driverPk, types, dateAddedGte, dateAddedLte, isDriverActive }) => ({
        variables: {
            searchTerm,
            orderBy,
            first: 30,
            status: Array.isArray(statuses) ? mapStatus(statuses[0]) : mapStatus(statuses), // Convert & map status
            driverPk,
            types,
            dateAddedGte,
            dateAddedLte,
            isDriverActive
        },
        notifyOnNetworkStatusChange: true, fetchPolicy: "cache-and-network"
    }),
    // This function re-runs every time `data` changes, including after `updateQuery`,
    // meaning our loadMoreEntries function will always have the right cursor
    props: ({ data: { loading, allSubscriptions, fetchMore, searchTerm, variables } }) => (
        {
            loading,
            allSubscriptions,
            variables,
            refetchQuery: () => {
                //No apollo function that refetches in place with pagination considered so this function instead
                let currentLength = allSubscriptions && allSubscriptions.edges ? allSubscriptions.edges.length : 30
                return fetchMore({
                    query: AllSubscriptions,
                    variables: {
                        ...variables,
                        first: currentLength,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allSubscriptions.edges
                        const pageInfo = fetchMoreResult.allSubscriptions.pageInfo
                        return {
                            allSubscriptions: {
                                edges: [...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.allSubscriptions.__typename
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: AllSubscriptions,
                    variables: {
                        cursor: allSubscriptions.pageInfo.endCursor,
                        ...variables
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.allSubscriptions.edges
                        const pageInfo = fetchMoreResult.allSubscriptions.pageInfo

                        return {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            allSubscriptions: {
                                edges: [...previousResult.allSubscriptions.edges, ...newEdges],
                                pageInfo,
                                searchTerm: previousResult.searchTerm,
                                __typename: previousResult.allSubscriptions.__typename
                            },
                        }
                    },
                })
            },
        }
    )
})(withApolloClient(SubscriptionsList))
