import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert } from 'reactstrap'
import Select from 'react-select'
import { getStatesByCountry, getStateOptions, getCitiesByState, getCityOptions } from "../../utils"
import { COUNTRIES } from "../../Constants"


const UpdateBranch = gql`mutation updateBranchMutation($input: UpdateBranchMutationInput!){
    updateBranch(input:$input){
        ok
        errors{
            field
            messages
        }
    }
} `

const AllDriverDocumentTypesQuery = gql`
    query AllDriverDocumentTypes($driverId:ID, $branchId: ID){
        allDriverDocumentTypes(isActive:true, driverId: $driverId, branchId:$branchId){
            edges{
                node{
                    id
                    typeName
                }
            }
      }
    }
`
const AllCarLocationsQuery = gql`
    query AllCarLocations($branchId:ID!){
        allCarLocations(branchId:$branchId){
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`
const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypes{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                    id
                    typeName
                }
            }
        }
    }
`
const AllTransactionSettingsQuery = gql`
    query AllTransactionSettings{
        allTransactionSettings {
            edges {
                node {
                    id
                    chargeType{
                        name
                    }
                    subChargeType{
                        name
                    }
                    isFixed
                    chargeAmount
                    chargeMinAmount
                    chargeMaxAmount
                    chargeInterval
                    createdBy{
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }   
        }
    }
`

const BranchLegalEntity = gql`
    query BranchLegalEntity{
      BranchLegalEntity:__type(name: "BranchLegalEntity") {
            values: enumValues {
                name
                description
            }
        }
    }`

const BranchDefaultLanguageQuery = gql`
    query BranchDefaultLanguage{
      BranchDefaultLanguage:__type(name: "BranchDefaultLanguage") {
            values: enumValues {
                name
                description
            }
        }
    }`

const AllLineOfBusinessQuery = gql`
    query AllLineOfBusiness{
        allLineOfBusiness {
            edges {
                node {
                    id
                    name
                }
            }   
        }
    }
`

const UpdateBranchInfo = (props) => {
    const [input, setInput] = useState({})
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [updateBranchMutation, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(UpdateBranch)
    const { data: allCarDocumentTypesData, loading: allCarDocumentTypesLoading } = useQuery(AllCarDocumentTypesQuery, {
        fetchPolicy: 'cache-first',
    })
    const { data: allDriverDocumentTypesData, loading: allDriverDocumentTypesLoading } = useQuery(AllDriverDocumentTypesQuery, {
        variables: { branchId: props.name !== "driverDocTypes" ? props.branchId : null },
        fetchPolicy: 'cache-first',
    })
    const { data: allCarLocationsData, loading: allCarLocationsLoading } = useQuery(AllCarLocationsQuery, {
        variables: { branchId: props.branchId },
        fetchPolicy: 'cache-first',
    })
    const { data: allTransactionSettingsData, loading: allTransactionSettingsLoading } = useQuery(AllTransactionSettingsQuery, {
        fetchPolicy: 'cache-first',
    })
    const { data: branchLegalEntityData } = useQuery(BranchLegalEntity, {
        fetchPolicy: 'cache-first',
    })
    const { data: branchDefaultLanguageData } = useQuery(BranchDefaultLanguageQuery, {
        fetchPolicy: 'cache-first'
    })
    const { data: allLineOfBusinessData, loading: allLineOfBusinessLoading } = useQuery(AllLineOfBusinessQuery, {
        fetchPolicy: 'cache-first',
    })

    const allCarDocumentTypes = allCarDocumentTypesData?.allCarDocumentTypes
    const allDriverDocumentTypes = allDriverDocumentTypesData?.allDriverDocumentTypes
    const allCarLocations = allCarLocationsData?.allCarLocations
    const allTransactionSettings = allTransactionSettingsData?.allTransactionSettings

    const branchLegalEntityOptions = branchLegalEntityData?.BranchLegalEntity?.values?.map(item => ({
        value: item.description, label: item.description,
    })) || []
    const branchDefaultLanguageOptions = branchDefaultLanguageData?.BranchDefaultLanguage?.values?.map(item => ({
        value: item.name,
        label: item.description,
    })) || []
    const allLineOfBusinessOptions = allLineOfBusinessData?.allLineOfBusiness?.edges?.map(item => ({
        value: item.node.id,
        label: item.node.name,
    })) || []
    const allDriverDocumentTypesOptions = allDriverDocumentTypes?.edges?.map(item => ({
        value: item.node.id,
        label: item.node.typeName,
    })) || []
    const allCarDocumentTypesOptions = allCarDocumentTypes?.edges?.map(item => ({
        value: item.node.id,
        label: item.node.typeName,
    })) || []
    const allCarLocationsOptions = allCarLocations?.edges?.map(item => ({
        value: item.node.id,
        label: item.node.name,
    })) || []

    const updateBranch = () => {
        if (props.branchId) {
            let updatedInput = { ...input, branchId: props.branchId }

            updateBranchMutation({ variables: { input: updatedInput } })
                .then((result) => {
                    if (result?.data?.updateBranch?.ok) {
                        props.refetchBranchQuery()
                        props.handleClose()
                        setInput({})
                    } else if (result?.data?.updateBranch?.errors) {
                        console.error("GraphQL Error:", result.data.updateBranch.errors)
                    } else {
                        console.error("Unknown Error:", result)
                    }
                })
                .catch((err) => {
                    console.error("Network Error:", err)
                })
        }
    }

    const updateInput = (e) => {
        if (props.branchId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                value = isNaN(parseFloat(value)) ? 0 : parseFloat(value)
            }
            setInput(prevInput => ({ ...prevInput, [name]: value }))
        }
    }

    useEffect(() => {
        if (props.type === "boolean") {
            setInput({ [props.name]: props.value })
        }
        if (props.countryCode && props.name === "stateCode") {
            const statesByBranchCountry = getStatesByCountry(props.countryCode)
            setStates(getStateOptions(statesByBranchCountry))
        }
        if (props.countryCode && props.stateCode && props.name === "city") {
            const citiesByBranchState = getCitiesByState(props.stateCode, props.countryCode)
            setCities(getCityOptions(citiesByBranchState))

        }
    }, [props.type, props.name, props.value, props.countryCode, props.stateCode])


    const formatTransactionLabel = (node) => {
        const chargeTypeName = node.chargeType?.name || ''
        const subChargeTypeName = node.subChargeType?.name ? ` ${node.subChargeType.name}` : ''
        const amount = node.isFixed
            ? `$${node.chargeAmount}`
            : `$${node.chargeMinAmount} - $${node.chargeMaxAmount}`
        const interval = node.chargeInterval || ''

        return `${chargeTypeName}${subChargeTypeName} ${amount} ${interval}`
    }

    return (
        <div>
            <Popover placement="bottom" isOpen={props.open} target={props.target} toggle={props.handleClose}>
                <PopoverHeader onClick={props.handleClose}>{props.title}&nbsp;<i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                <PopoverBody>
                    {mutationError && <Alert color="danger">{mutationError.message}</Alert>}
                    {mutationData && mutationData.updateBranch.errors && (<Alert color="danger">{mutationData.updateBranch.errors[0].messages}</Alert>)}
                    {mutationData && mutationData.updateBranch.ok && (<p>Branch updated successfully!</p>)}
                    <FormGroup>
                        <Label for={props.name}>{props.title}</Label>
                        {props.name === "stateCode" ?
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                placeholder="Choose State"
                                id={props.name}
                                options={states}
                                defaultValue={props.value ? states.find(option => option.value === props.value) : null}
                                onChange={(selectedState) => setInput({ [props.name]: selectedState ? selectedState.value : "" })}
                            />
                            :
                            props.name === "country" ?
                                <Select
                                    className="bos-custom-select"
                                    classNamePrefix="bos-select"
                                    placeholder="Choose a country"
                                    id={props.name}
                                    options={COUNTRIES}
                                    defaultValue={COUNTRIES.find(country => country.value === props.value) || null}
                                    onChange={(selectedCountry) => { setInput({ [props.name]: selectedCountry ? selectedCountry.value : "" }) }}
                                />

                                : ["driverDocsRequiredForInsurance", "driverDocsRequiredForRental", "driverDocsRequiredForApplication", "driverDocTypes"].includes(props.name) ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={allDriverDocumentTypesLoading}
                                        options={allDriverDocumentTypesOptions}
                                        defaultValue={props.value?.edges?.map(item => ({
                                            value: item.node.id,
                                            label: item.node.typeName,
                                        })) || null}
                                        placeholder="Driver Document Types"
                                        isMulti
                                        isClearable={false}
                                        onChange={(docTypes) => setInput({ [props.name]: docTypes.map(item => item.value) })} />
                                    : ["carDocsRequiredForRental", "carDocsRequiredForInspection"].includes(props.name) ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={allCarDocumentTypesLoading}
                                            options={allCarDocumentTypesOptions}
                                            defaultValue={props.value?.edges?.map(item => ({
                                                value: item.node.id,
                                                label: item.node.typeName,
                                            })) || null}
                                            placeholder="Car Document Types"
                                            isMulti
                                            isClearable={false}
                                            onChange={(docTypes) => setInput({ [props.name]: docTypes.map(item => item.value) })} />
                                        : "transactionSettings" === props.name ?
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                isLoading={allTransactionSettingsLoading}
                                                options={allTransactionSettings?.edges?.map(item => ({
                                                    value: item.node.id,
                                                    label: formatTransactionLabel(item.node),
                                                })) || []}
                                                defaultValue={props.value?.edges?.map(item => ({
                                                    value: item.node.id,
                                                    label: formatTransactionLabel(item.node),
                                                })) || null}
                                                placeholder="Branch Transaction Settings"
                                                isMulti
                                                isClearable={false}
                                                onChange={(transactionSettings) => setInput({ [props.name]: transactionSettings.map(item => item.value) })}
                                            />
                                            : ["returnLocationId", "pickupLocationId"].includes(props.name) ?
                                                <Select
                                                    className="bos-custom-select"
                                                    classNamePrefix="bos-select"
                                                    isLoading={allCarLocationsLoading}
                                                    options={allCarLocationsOptions}
                                                    defaultValue={props.value ? { value: props.value.id, label: props.value.name } : null}
                                                    placeholder="Car Locations"
                                                    onChange={(location) => setInput({ [props.name]: location.value })}
                                                />
                                                : props.name === "legalEntity" ?
                                                    <Select
                                                        className="bos-custom-select" classNamePrefix="bos-select"
                                                        options={branchLegalEntityOptions}
                                                        defaultValue={props.value ? ({ value: props.value, label: props.value }) : null}
                                                        placeholder="Choose Branch Legal Entity"
                                                        onChange={(legalEntity) => setInput({ [props.name]: legalEntity.value })} />
                                                    : props.name === "defaultLanguage" ?
                                                        <Select
                                                            className="bos-custom-select" classNamePrefix="bos-select"
                                                            options={branchDefaultLanguageOptions}
                                                            defaultValue={props.value ? ({ value: props.value, label: props.value }) : null}
                                                            placeholder="Choose Branch Language"
                                                            onChange={(language) => setInput({ [props.name]: language.value })} />
                                                        : props.name === "lineOfBusinessId" ?
                                                            <Select
                                                                className="bos-custom-select"
                                                                classNamePrefix="bos-select"
                                                                isLoading={allLineOfBusinessLoading}
                                                                options={allLineOfBusinessOptions}
                                                                defaultValue={props.value ? {
                                                                    value: props.value,
                                                                    label: props.allLineOfBusiness?.edges?.find(item => item.node.id === props.value)?.node.name || "",
                                                                } : null}
                                                                placeholder="Choose Line Of Business"
                                                                isClearable={false}
                                                                onChange={(lineOfBusiness) => setInput({ [props.name]: lineOfBusiness.value })}
                                                            />
                                                            : props.name === "defaultMileageUnit" ?
                                                                <Select
                                                                    className="bos-custom-select" classNamePrefix="bos-select"
                                                                    options={[{ value: "KM", label: "Kilometers" }, { value: "MI", label: "Miles" }]}
                                                                    defaultValue={props.value ? ({ value: props.value, label: props.value === "KM" ? "Kilometers" : "Miles" }) : null}
                                                                    placeholder="Choose Mileage Unit"
                                                                    isClearable={false}
                                                                    onChange={(defaultMilageUnit) => setInput({ [props.name]: defaultMilageUnit.value })} />
                                                                : props.name === "city" ?
                                                                    <Select
                                                                        className="bos-custom-select"
                                                                        classNamePrefix="bos-select"
                                                                        options={cities}
                                                                        defaultValue={props.value ? cities.find(option => option.value === props.value) : null}
                                                                        placeholder="Choose City" onChange={(city) => setInput({ [props.name]: city.value })}
                                                                    />
                                                                    : props.type === "boolean" ?
                                                                        <>
                                                                            <br />
                                                                            <div>
                                                                                <span>Yes</span>
                                                                                <div className="toggle-switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="toggle-switch-checkbox"
                                                                                        name={props.name}
                                                                                        id={"branchInfo"}
                                                                                        checked={!input[props.name]}
                                                                                        onChange={() => setInput(prevInput => ({ ...prevInput, [props.name]: !prevInput[props.name] }))}
                                                                                    />
                                                                                    <label className="toggle-switch-label" htmlFor={"branchInfo"}>
                                                                                        <span className="toggle-switch-inner" />
                                                                                        <span className="toggle-switch-switch" />
                                                                                    </label>
                                                                                </div>
                                                                                <span>No</span>
                                                                            </div>
                                                                        </>
                                                                        : props.name === "postalCode" ?
                                                                            <>
                                                                                <Input autoComplete={"off"} type={props.type} maxLength={5}
                                                                                    name={props.name} id={props.name} defaultValue={props.value}
                                                                                    placeholder={props.title} onChange={updateInput}
                                                                                />
                                                                            </>
                                                                            : <Input autoComplete={"off"} type={props.type} maxLength={props.maxLength ? props.maxLength : 200}
                                                                                name={props.name} id={props.name} defaultValue={props.value}
                                                                                placeholder={props.title} onChange={updateInput}
                                                                            />
                        }
                    </FormGroup>
                    {mutationLoading ? <Progress animated color="info" value={100} /> :
                        <Button type="button" size="sm" onClick={updateBranch} disabled={!props.branchId}>Submit</Button>
                    }
                </PopoverBody>
            </Popover>
        </div>
    )
}

export default UpdateBranchInfo
