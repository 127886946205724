import React, { Component } from "react"
import gql from "graphql-tag"
import { debounce } from "lodash"
import { Input, Row, Col } from "reactstrap"
import "./DriverSearch.css"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const AllDrivers = gql`query AllDrivers($searchTerm: String){
    drivers: allDrivers(searchTerm: $searchTerm,first:10){
      edges{
        node{
          id
          name
          tlcLicense
          branch{
              id
              name
          }
        }
      }
} }`


class DriverSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    updateDriverSearchInput = debounce((searchTerm) => {
        this.setState({ loading: true })
        this.props.apolloClient.query({
            query: AllDrivers,
            variables: { searchTerm }
        }).then((result) => {
            this.setState({ loading: false })
            if (result.data && result.data.drivers && result.data.drivers.edges && result.data.drivers.edges.length > 0) {
                this.setState({ driverSuggestions: result.data.drivers.edges })
            }
        })
    }, 500)

    render() {
        return (
            <div className="search">
                <span className="d-flex flex-row align-middle">
                    <Input type="text" onChange={(e) => this.updateDriverSearchInput(e.target.value)} name="driverSearch" id="driverSearch" placeholder="Search by driver name, tlc, phone, email" />
                    {this.state.loading &&
                        <div className="snippet" data-title="dot-pulse" style={{ position: "absolute", right: 50, top: 25 }} >
                            <div className="stage">
                                <div className="dot-pulse"></div>
                            </div>
                        </div>
                    }
                </span>
                <div className="option-list">
                    {this.state.driverSuggestions && this.state.driverSuggestions.length > 0 && this.state.driverSuggestions.map((driver, index) =>
                        <Row key={index} className="option" onClick={() => this.props.setDriver(driver.node.id)}>
                            <Col>
                                <div className={"option-list-wrapper-text"} style={{ margin: "-5px" }}>
                                    <p>{driver.node.name + " - " + driver.node.tlcLicense}</p>
                                    <small>{driver.node.branch ? driver.node.branch.name : "No Branch Found"}</small>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(DriverSearch)