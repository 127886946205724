import React, { useState, useEffect, useCallback } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import ListingFilters from '../Material/ListingFilters'
import { debounce } from 'lodash'
import '../Drivers/DriversNew/DriversListContainer.css'
import toTitleCase from '../Functions/toTitleCase'
import { Row, Col, Input } from "reactstrap"
import NewTaskModal from "./NewTaskModal"
import NewBoardModal from "./NewBoardModal"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const BoardsQuery = gql`
query AllBoards($orderBy:[String], $first: Int, $after: String){
    optionsList: allBoards(orderBy: $orderBy, first: $first, after: $after){
        edges{
            node{
                id
                title
            }
        }
    }
}`

const TaskManagementListHeader = ({ client, ...props }) => {
    const [openModal, setOpenModal] = useState("")
    const [configurations, setConfigurations] = useState(null)

    const updateSearchTerm = useCallback(debounce((searchTerm) => {
        props.setFilterValues({ ...props.filterValues, searchTerm })
    }, 500), [props.filterValues])
    const getFilterConfigurations = (activeTab) => {
        return [
            {
                type: "select", name: "statuses", title: "Status", options: [
                    { value: "10_OPEN", label: "OPEN" },
                    { value: "20_IN_PROGRESS", label: "IN-PROGRESS", },
                    { value: "30_DONE", label: "DONE", },
                ], placeholder: "Filter By Status", showFilter: ["all"].includes(activeTab), isMulti: true,
            },
            {
                type: "select", name: "dueDateShortRange", title: "Due Date", options: [
                    { label: "Next 7 days", value: "7" },
                    { label: "Next 14 days", value: "14" },
                    { label: "Next 30 Days", value: "14" },
                    { label: "No due date", value: null }
                ], placeholder: "Filter By Due Date", valueSelector: "name", labelSelector: "name", showFilter: ["inactive", "all"].includes(activeTab)
            },
            {
                type: "select", name: "assignedToIds", title: "Assigned Reps", valueSelector: "id", labelSelector: "firstName",
                optionsQuery: RepsQuery, variables: [{ groups: ["Sales Team"] }], placeholder: "Filter By Assigned Reps",
                showFilter: true, isMulti: true,
            },
            {
                type: "select", name: "boardIds", title: "Boards", valueSelector: "id", labelSelector: "title",
                optionsQuery: BoardsQuery, variables: [], placeholder: "Filter By Boards",
                showFilter: true, isMulti: true,
            }
        ]
    }
    useEffect(() => {
        const conf = getFilterConfigurations(props.activeTab)
        const fetchConfigurations = async () => {
            const configurations = await Promise.all(conf.filter(filter => filter.showFilter).map(async filter => {
                if (filter.optionsQuery) {
                    const result = await props.apolloClient.query({
                        query: filter.optionsQuery,
                        variables: { ...filter.variables }
                    })
                    let options = []
                    if (result && result.data.optionsList) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector],
                            label: options.node[filter.labelSelector]
                        }))
                    }
                    filter.options = options
                    delete filter.optionsQuery
                }
                return filter
            }))
            setConfigurations(configurations)
        }
        fetchConfigurations()
    }, [props.apolloClient, props.activeTab])

    if (!props.apolloClient) {
        return null
    }


    const toggleModal = (modalName) => {
        setOpenModal(openModal === modalName ? "" : modalName)
    }

    const removeFilter = (filterName) => {
        let newFilterValues = { ...props.filterValues }
        if (newFilterValues.hasOwnProperty(filterName)) {
            delete newFilterValues[filterName]
            props.setFilterValues(newFilterValues)
        }
    }

    const getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type === "select") {
            let options = filterConfigs.options
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value === item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    } else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range") {
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        }
        return Array.isArray(value) ? value.join(", ") : value
    }

    return (

        <Row>
            {openModal === "viewFilters" && (
                <ListingFilters open={openModal === "viewFilters"} handleClose={() => toggleModal("")}
                    target="viewFilters" filterValues={props.filterValues} setFilterValues={props.setFilterValues}
                    filters={configurations} />
            )}
            {openModal === "NewTaskModal" && (
                <NewTaskModal
                    isOpen={openModal === "NewTaskModal"}
                    closeModal={() => toggleModal("")}
                />
            )}
            {openModal === "NewBoardModal" && (
                <NewBoardModal
                    isOpen={openModal === "NewBoardModal"}
                    closeModal={() => toggleModal("")}
                />
            )}

            <Col xs={6}>
                <span className="driver-search-filter">
                    <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => updateSearchTerm(e.target.value)} />
                    <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                </span>
            </Col>
            <Col xs={6} className="text-right mt-2">
                {props.activeTab === "all" && (
                    <>
                        <a className="driver-list-button" onClick={() => toggleModal("NewTaskModal")}>
                            New Task | <i className="fa fa-plus-square" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="driver-list-button" onClick={() => toggleModal("NewBoardModal")}>
                            New Board | <i className="fa fa-plus-square" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                )}

                <a id="viewFilters" className="driver-list-button" onClick={() => toggleModal("viewFilters")}>
                    Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {props.filterValues && Object.keys(props.filterValues).filter(key => props.filterValues[key] !== null && configurations && configurations.find(setting => setting.name === key) && configurations.find(setting => setting.name === key).showFilter).length || ""}
                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
            <Col xs={12} className="my-4">
                {Object.keys(props.filterValues).filter(key => props.filterValues[key] !== null && configurations && configurations.find(setting => setting.name === key) && configurations.find(setting => setting.name === key).showFilter).map(key =>
                    <span className="search-filter-preview" key={key}>
                        <span>
                            <i className="fa fa-times-circle" onClick={() => removeFilter(configurations.find(setting => setting.name === key).name)}></i>&nbsp;&nbsp;
                            {configurations.find(setting => setting.name === key).title}
                        </span>
                        {getValueByFilterType(configurations.find(setting => setting.name === key), props.filterValues[key])}
                    </span>
                )}
            </Col>
        </Row>
    )
}

export default (withApolloClient(TaskManagementListHeader))