import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Row, Col, Label, Button, Progress, Alert } from 'reactstrap'
import DatetimeRenderer from "../Material/DatetimeRenderer"
import { StripeCharges } from "./Queries"
import { HasPermissionsQuery } from "../Functions/querys"
import RefundModal from "./RefundModal"
import "./StripeCard.css"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

class StripeCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: "",
            loading: false,
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <div className="card-component">
                {this.state.error && <Row><Col><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                {(this.state.loading || this.props.loading) && <Row><Col><Progress animated color="info" value="100" /></Col></Row>}
                <Row>
                    <Col className="stipe-charge-history-wrapper">
                        {this.props.stripeCharges && this.props.stripeCharges.length > 0 && this.props.stripeCharges.map((charge, i) => {
                            let metadata = JSON.parse(charge.metadata)
                            return <Row className="stipe-charge-history-row">
                                <Col xs={3}>
                                    <Label><small>Date Added</small></Label>
                                    <p><DatetimeRenderer datetime={charge.createdAt} /></p>
                                </Col>
                                <Col xs={3}>
                                    <Label><small>Amount</small></Label>
                                    <p>${charge.amount} - {charge.refunded ? "Refunded" : charge.disputed ? "Disputed" : "Payment"}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label><small>Amount Refunded</small></Label>
                                    <p>${charge.amountRefunded}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label><small>Status</small></Label>
                                    <p>{charge.status}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label><small>Last 4 Digits</small></Label>
                                    <p>{charge.last4}</p>
                                </Col>
                                <Col xs={6}>
                                    <Label><small>Metadata</small></Label>
                                    <p>{metadata}</p>
                                </Col>
                                <Col xs={3}>
                                    <Label><small>Actions</small></Label>
                                    <p>
                                        {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_refund_stripe_payment") && <Button color="danger" onClick={() => this.toggleModal("refundModal" + i)}>
                                            <i className="fa fa-repeat" aria-hidden="true"></i>&nbsp;&nbsp;Refund
                                        </Button>}
                                        {this.state.openModal === "refundModal" + i && <RefundModal isOpen={this.state.openModal === "refundModal" + i} handleClose={() => this.toggleModal("refundModal" + i)} paymentId={charge.id} driverId={this.props.driverId} maxRefund={charge.amount - charge.amountRefunded} refetchQuery={this.props.refetchQuery} />}
                                    </p>
                                </Col>
                            </Row>
                        })}
                        {this.props.stripeCharges && this.props.stripeCharges[this.props.stripeCharges.length - 1].hasNext && <Row>
                            <Col>
                                <Button color="info" onClick={this.props.loadMoreEntries}>Load More</Button>
                            </Col>
                        </Row>
                        }
                    </Col>
                </Row >

            </div >
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(StripeCharges, {
    options: ({ driverId }) => ({
        variables: { driverId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }),
    props: ({ data: { loading, stripeCharges, variables, refetch, fetchMore } }) => ({
        loading,
        stripeCharges,
        variables,
        refetchQuery: () =>
            refetch({
                variables: { ...variables },
            }),
        loadMoreEntries: () => {
            if (!stripeCharges || stripeCharges.length === 0) {
                return Promise.resolve()
            }

            return fetchMore({
                variables: {
                    cursor: stripeCharges[stripeCharges.length - 1].id,
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }

                    return {
                        stripeCharges: [
                            ...previousResult.stripeCharges,
                            ...fetchMoreResult.stripeCharges,
                        ],
                    }
                },
            })
        },
    }),
})(
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: { userPermissions: ['custom_can_refund_stripe_payment'] },
        }),
        props: ({ data: { hasPermissions, loading, variables } }) => ({
            hasPermissions,
            loading,
            variables,
        }),
    })(withApolloClient(StripeCard))
)
