import React, { Component } from 'react'
import { Col, Row, Alert, Button } from 'reactstrap'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import TextField from "@material-ui/core/TextField"
import Select from '@material-ui/core/Select'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import LinearProgress from '@material-ui/core/LinearProgress'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core/styles'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
const Terminals = gql`query Terminals($driverId: ID){
    terminals: listStripeTerminals(driverId: $driverId){
        id label status
    } 
}`

const CreateStripeTerminalPayment = gql`mutation createStripeTerminalPayment($input: CreateStripeTerminalPaymentMutationInput!){
    createStripeTerminalPayment(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }
})

class StripeTerminal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTerminal: "",
            loading: false,
            success: false,
            error: false,
            amountToCharge: props.amountToCharge ? props.amountToCharge : "",
        }
    }

    createStripeTerminalPayment = () => {
        this.setState({ loading: true, error: '' })
        try {
            if (this.state.selectedTerminal && this.state.amountToCharge > 0) {
                let input = {
                    driverId: this.props.driverId,
                    amount: this.state.amountToCharge,
                    terminalId: this.state.selectedTerminal,
                    description: this.props.description ? this.props.description : "Bill Payment",
                    metadata: JSON.stringify({ "is_pickup_payment": this.props.isPickupPayment ? true : false }),
                }
                this.props.apolloClient.mutate({
                    mutation: CreateStripeTerminalPayment,
                    variables: { input }
                }).then((result) => {
                    if (result && result.data && result.data.createStripeTerminalPayment && result.data.createStripeTerminalPayment.ok) {
                        if (this.props.paymentSuccess)
                            this.props.paymentSuccess(this.state.amountToCharge)
                        this.setState({ success: true, loading: false })
                    } else {
                        let errorMessage = "Payment Error. Please try again or contact admin."
                        if (result && result.data && result.data.createStripeTerminalPayment &&
                            result.data.createStripeTerminalPayment.errors && result.data.createStripeTerminalPayment.errors[0] && result.data.createStripeTerminalPayment.errors[0].messages) {
                            errorMessage = result.data.createStripeTerminalPayment.errors[0].messages
                        }
                        this.setState({ error: errorMessage, loading: false })
                    }
                })
            }
        } catch (error) {
            this.setState({ error: "Payment Error. Please try again or contact admin", loading: false })
        }

    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.terminals && nextProps.terminals !== this.props.terminals) {
            if (nextProps.terminals && nextProps.terminals.length > 0 && !this.state.selectedTerminal) {
                this.setState({ selectedTerminal: nextProps.terminals[0].id })
            }
        }
        if (nextProps.amountToCharge && nextProps.amountToCharge !== this.state.amountToCharge) {
            this.setState({ amountToCharge: nextProps.amountToCharge })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props;
    }    

    render() {
        return (
            <div className="stripeCards">
                {this.state.error && !this.state.success && <Alert color="danger">{this.state.error}</Alert>}

                {this.state.success && <Alert color="success">Payment successful.</Alert>}

                <Row className="stripe-card-wrapper">
                    <Col xs={12}>
                        {!this.props.amountToCharge &&
                            <TextField
                                id="amountToCharge"
                                name="amountToCharge"
                                label="Amount to Charge Termianal"
                                placeholder="Amount to Charge"
                                type="number"
                                value={this.state.amountToCharge}
                                fullWidth
                                onChange={(e) => this.setState({ amountToCharge: e.target.value })}
                            />
                        }
                    </Col>
                    <Col xs={12}>
                        <FormControl style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                            <InputLabel shrink>Terminals</InputLabel>
                            <Select value={this.state.selectedTerminal}
                                onChange={(e) => this.setState({ selectedTerminal: e.target.value })}
                                input={<Input name="selectedTerminal" />}
                            >
                                {
                                    this.props.terminals && this.props.terminals.length > 0 && this.props.terminals.map(terminal =>
                                        <MenuItem key={terminal.id} value={terminal.id}>{terminal.label}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Col>
                    {this.state.loading ?
                        <Col xs={12}>
                            <Alert>Please insert or tap payment card on terminal</Alert>
                            <LinearProgress />
                        </Col>
                        :
                        <Col xs={12}>
                            <Button onClick={() => this.createStripeTerminalPayment()} color="primary" disabled={this.state.amountToCharge < 1 || this.state.loading}>
                                Begin Payment Flow
                            </Button>
                        </Col>
                    }
                </Row>
            </div >
        )
    }
}


//Iagami - Code upgradation React 18
export default withStyles(styles)(
    graphql(Terminals, {
        options: ({ driverId }) => ({
            variables: { driverId },
        }),
        props: ({ data }) => ({
            loading: data.loading,
            terminals: data.terminals,
        }),
    })(withApolloClient(StripeTerminal))
);



